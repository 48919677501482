import { useContext, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Header } from "../atoms/Header"
import { Footer } from "../atoms/Footer"
import { MSalon } from '../API';
import { SelectedSalonContext } from '../providers/SelectedSalonProvider';
import { Loading } from "../atoms/Loading";
import { BsArrowLeftCircle } from 'react-icons/bs';
import '../css/common.css';

export const StoreAccountDetail = ( props ) => {

    const { setUser, setAuthState } = props;
    
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { selectedSalonContext } = useContext( SelectedSalonContext ) ;  
    const [ salonAccount , setSalonAccount ] = useState<MSalon>({  __typename:"MSalon", createdAt:"create", updatedAt:"update" , storeId: "99999" });
//    const [ personelName , setPersonelName ] = useState<String|null>();

    const navigate = useNavigate();
    const OnClickReturn = () => navigate('/MainMenu')
     
    useEffect(() => {
        setIsLoading(true);

        if (selectedSalonContext == null){
            let salonJson = sessionStorage.getItem('salonJson'); // JSON
            let salon = null; if (salonJson != null) salon = JSON.parse(salonJson);
            let userId = sessionStorage.getItem('userId');
//            let personelName = sessionStorage.getItem('personelName');
            if (salon == null || userId == null) {
                //セッション情報も無い（ログアウト状態）
                //ログイン画面に遷移
                navigate('/');
                return;
            }
            //セッション情報セット
            setSalonAccount(salon.selectedSalon);
            //setPersonelName(personelName);
        }else{
            //コンテキスト情報セット
            setSalonAccount(selectedSalonContext);
            //setPersonelName(selectedPersonelName);
        }

        setIsLoading(false);
    }, []);

    return (
        <div>
            <Header setUser={setUser} setAuthState={setAuthState} setIsLoading={setIsLoading} />
            {isLoading ? (
                <Loading />
            ) : (
                <div className="container mt-2 mb-5">
                    <section className="row">
                        <section className="col-12 page-title-row">
                            <div className="d-flex">
                                <button className="btn" onClick={OnClickReturn}><h2 className="icon-gray"><BsArrowLeftCircle/></h2></button>
                                <header className="d-flex page-title">
                                    <h1 className="fs-4 m-1">アカウント情報</h1>
                                </header>
                            </div>
                        </section>
                    </section>
                    <div className="row g-3 mt-2">
                        <div className="col-md-7 ">
                            <h6 className="list-group-title">加盟店情報</h6>
                            <ul className="list-group mb-3 shadow-sm">
                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                <div>
                                <h6 className="list-group-title my-0">加盟店番号</h6>
                                </div>
                                <span>{salonAccount?.storeId}</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                <div>
                                <h6 className="list-group-title my-0">加盟店名</h6>
                                </div>
                                <span>{salonAccount?.storeName}</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                <div>
                                <h6 className="list-group-title my-0">ご住所１</h6>
                                </div>
                                <span>{salonAccount?.storeAddress1}</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                <div>
                                <h6 className="list-group-title my-0">ご住所２</h6>
                                </div>
                                <span>{salonAccount?.storeAddress2}</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                <div>
                                <h6 className="list-group-title my-0">電話番号</h6>
                                </div>
                                <span>{salonAccount?.phone}</span>
                            </li>
                            </ul>
                        </div>
                        
                        {/*
                        <div className="col-md-7 ">
                            <h6 className="list-group-title">ログインアカウント情報</h6>
                            <ul className="list-group mb-3 shadow-sm">
                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                <div>
                                <h6 className="list-group-title my-0">担当者１氏名</h6>
                                </div>
                                <span>{salonAccount?.personelName1}</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                <div>
                                <h6 className="list-group-title my-0">担当者１メールアドレス</h6>
                                </div>
                                <span>{salonAccount?.personelMail1}</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                <div>
                                <h6 className="list-group-title my-0">担当者２氏名</h6>
                                </div>
                                <span>{salonAccount.personelName2}</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                <div>
                                <h6 className="list-group-title my-0">担当者２メールアドレス</h6>
                                </div>
                                <span>{salonAccount?.personelMail2}</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                <div>
                                <h6 className="list-group-title my-0">担当者３氏名</h6>
                                </div>
                                <span>{salonAccount?.personelName3}</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                <div>
                                <h6 className="list-group-title my-0">担当者３メールアドレス</h6>
                                </div>
                                <span>{salonAccount?.personelMail3}</span>
                            </li>
                            </ul>
                        </div>
                        */}

                        <div className="col-md-7 ">
                            <h6 className="list-group-title">ログインアカウント情報</h6>
                            <ul className="list-group mb-3 shadow-sm">
                            <li className="list-group-item list-group-title d-flex justify-content-between lh-sm">
                                <div>
                                    <h6 className="my-0">担当者</h6>
                                </div>
                                <div>
                                    <h6 className="my-0">ユーザID</h6>
                                </div>
                            </li>
                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                <div>{salonAccount.personelName1}</div>
                                <div>{salonAccount?.personelMail1}</div>
                            </li>
                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                <div>{salonAccount.personelName2}</div>
                                <div>{salonAccount.personelMail2}</div>
                            </li>
                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                <div>{salonAccount?.personelName3}</div>
                                <div>{salonAccount?.personelMail3}</div>
                            </li>
                            </ul>
                        </div>

                        <div className="col-md-5 order-md-last">
                            <h6 className="list-group-title">オーナー口座情報</h6>
                            <ul className="list-group mb-3 shadow-sm">
                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                <div>
                                <h6 className="list-group-title my-0">銀行名</h6>
                                </div>
                                <span>{salonAccount?.bankCode}</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                <div>
                                <h6 className="list-group-title my-0">支店名</h6>
                                </div>
                                <span>{salonAccount?.bankBranchCode}</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                <div>
                                <h6 className="list-group-title my-0">預金種別</h6>
                                </div>
                                <span>{salonAccount?.bankDepositType.length === 0 ? <span></span> : (salonAccount?.bankDepositType === '0' ? <span>普通</span> : <span>当座</span>)}</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                <div>
                                <h6 className="list-group-title my-0">口座番号</h6>
                                </div>
                                <span>{salonAccount?.bankAccountNumber}</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                <div>
                                <h6 className="list-group-title my-0">預金者名</h6>
                                </div>
                                <span>{salonAccount?.bankDepositerName}</span>
                            </li>
                            </ul>
                        </div>
                    </div>
                    <section className="row">
                        <section className="col">
                            <div className="attention-box">登録内容に変更があった場合は<br/>本部までお問い合わせください。 </div>
                        </section>
                    </section>
                </div>
            )}
            <Footer />
        </div>
    ); 
};