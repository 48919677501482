import React, { useEffect, useState } from "react";
import { useNavigate , useLocation } from "react-router-dom";
import { Header } from "../atoms/Header"
import { Footer } from "../atoms/Footer"
import { useService } from "../hooks/useService";
import { MService } from "../API";
import { Loading } from "../atoms/Loading";
import { useForm } from 'react-hook-form'
import { Button, Modal} from 'react-bootstrap';
import { listServiceByStoreId } from '../graphql/queries';
import { ListServiceByStoreIdQuery } from '../API';
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import { BsTrash, BsArrowLeftCircle } from 'react-icons/bs';
import '../css/common.css';

type Inputs = {
    courseName: string;
    monthlyPrice: number;
}

export const StoreServiceMenuEdit = ( props ) => {
  
    const { setUser, setAuthState } = props;

    const {
        register, 
        handleSubmit, 
        //watch, 
        formState: { errors },
    } = useForm<Inputs>({
        criteriaMode: "all",
        shouldFocusError: true,
    }); 

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { serviceMenu, setServiceMenu, deleteServiceMenu, updateServiceMenu } = useService();
    
    // クエリからListで指定されたサービスIDを取得
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const serviceId = query.get("serviceId");
    const index = query.get("index");

    const getServiceMenu = async (storeId : string) => {
       
        // この部分をuseServices.tsを参照するようにするとLoadingの解除とデータ取得のタイミングが合わないので、こちらに記述している。
        const res = (await API.graphql(graphqlOperation(listServiceByStoreId, {
            storeId: `${storeId}`,
            serviceId: {eq: `${serviceId}`},
            limit: 1, //default = 10
            //nextToken: nextToken,
        })) as GraphQLResult<ListServiceByStoreIdQuery>);

        console.log(res);
        const mService = res.data?.ListServiceByStoreId?.items[0] as MService;
        setServiceMenu(mService)
        setIsLoading(false);
            
        // 以下がuseServices.tsを参照してその中でLoading(false)にする処理。Loading解除はデータ取得後の位置だが画面上の表示が切り替わる前となってしまう。
        // ログイン中の自店舗のIDをセッションから取得し編集するサービスIDはクエリを利用しサービスメニューを取得する。
        //getServiceMenuByServiceId(String(salonId), String(serviceId)); // サロンIDとサービスIDで１件の情報を取得しserviceMenuにセットする
        //setIsLoading(false);
    }

    const navigate = useNavigate();

    /**
     * 戻るボタン押下
     */
     const OnClickReturn = () => {
         navigate('/StoreServiceMenuList');
     }

     /**
     * 登録するボタン押下
     */
    const onSubmit = async () => {
        setIsLoading(true);
        await updateServiceMenu(serviceMenu as MService);
        navigate(`/StoreServiceMenuList`);
    }
    /**
     * 削除するボタン押下
     */
    const OnClickDelete = async () => {
        setIsLoading(true);
        await deleteServiceMenu(Number(index), serviceMenu.id);
        setIsLoading(false);
        navigate(`/StoreServiceMenuList`);
    }

    const onChangeCourseName = (event: { target: { value: any; }; }) => { setServiceMenu({ ...serviceMenu as MService, courseName: event.target.value }); }
    const onChangeMonthlyPrice = (event: { target: { valueAsNumber: any; }; }) => {
        setServiceMenu({ ...serviceMenu as MService, monthlyPrice: event.target.valueAsNumber });
    }

    /**
     * 更新確認モーダルダイアログの制御用
     */
    const [showEditConfirm, setShowEditConfirm] = useState(false);
    const handleEditConfirmClose = () => setShowEditConfirm(false);
    const handleEditConfirmShow = () => setShowEditConfirm(true);
    /**
     * 削除確認モーダルダイアログの制御用
     */
     const [showDeleteConfium, setShowDeleteConfirm] = useState(false);
     const handleDeleteConfirmClose = () => setShowDeleteConfirm(false);
     //const handleDeleteConfiumShow = () => setShowDeleteConfium(true);
 
     const handleDeleteConfirmShow = () => {
        // 誰も利用者がいなかった時のみ削除可能
        if (serviceMenu.salonMembers.items.length === 0) {
            setShowDeleteConfirm(true) ;        //削除警告ダイアログを表示
        } else {
            setShowCantDeleteConfirm(true) ;    //削除不能メッセージを表示
        }
    }

    /**
     * 削除不能モーダルダイアログの制御用
     */
     const [showCantDeleteConfirm, setShowCantDeleteConfirm] = useState(false);
     const handleCantDeleteConfirmClose = () => setShowCantDeleteConfirm(false);
 
    const getData = async () => {
        let storeId = sessionStorage.getItem('selectedSalon.storeId'); // サロンID
        if (storeId == null) {
            //セッション情報も無い（ログアウト状態）
            //ログイン画面に遷移
            navigate('/');
            return;
        }
        await getServiceMenu(String(storeId));
        
        setIsLoading(false);
    }

    // 画面開始時処理
    useEffect(() => {
        getData();    
    }, []);
    
    return (
        <>
            <Header setUser={setUser} setAuthState={setAuthState} setIsLoading={setIsLoading}/>
            {isLoading ? (
                <Loading />
            ) : (
                <div className="container mt-2 mb-5">
                    <section className="row">
                        <section className="col-9 page-title-row">
                            <div className="d-flex">
                                <button className="btn" onClick={OnClickReturn}><h2 className="icon-gray"><BsArrowLeftCircle/></h2></button>
                                <header className="d-flex page-title">
                                    <h1 className="fs-4 m-1">メニュー編集</h1>
                                </header>
                            </div>
                        </section>
                        <section className="col-3">
                            <div className="d-flex mb-0">
                                <button className="btn ms-auto" type="button" onClick={handleDeleteConfirmShow}><h3 className="icon-gray"><BsTrash/></h3></button>
                            </div>
                        </section>
                    </section>
                    <section className="col">
                        <div className="col-md-12">
                            <div className="row g-2">
                                <div className="col-3">
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="CourseName" className="form-label item-title required">コース名称</label>
                                    <input type="text" autoComplete="off" {...register('courseName' , { required:true, })} className="form-control" id="courseName" value={String(serviceMenu?.courseName)} onChange={onChangeCourseName}/>
                                    {errors.courseName?.types?.required && <div className="text-danger">コース名称を入力してください</div>}
                                </div>
                                <div className="col-3">
                                </div>

                                <div className="col-3">
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="monthlyPrice" className="form-label item-title required">月額（税込）</label>
                                    <div className="input-group" style={{width: 300}}>
                                        <span className="input-group-text">&yen;</span>
                                        <input type="number" autoComplete="off" style={{textAlign: 'right'}} {...register('monthlyPrice' , { required:true, min:1, })} className="form-control" id="monthlyPrice" value={Number(serviceMenu?.monthlyPrice)} onChange={onChangeMonthlyPrice} placeholder="" />
                                    </div>
                                    {errors.monthlyPrice?.types?.required && <div className="text-danger">必須入力です。</div>}
                                    {errors.monthlyPrice?.types?.min && <div className="text-danger">&yen;1以上で入力してください。</div>}
                                </div>
                                <div className="col-3">
                                </div>

                                <section className="row">
                                    <section className="col">
                                        <div className="attention-box">更新すると当コースを登録中の<br/>会員情報も即時変更されます。</div>
                                    </section>
                                </section>

                                <div className="col-3">
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <button className="btn btn-success btn-block mx-auto" type="button" onClick={handleEditConfirmShow}>更新する</button>
                                    </div>
                                </div>
                                <div className="col-3">
                                </div>

                            </div>
                        </div>

                        {/*更新確認ダイアログ*/}
                        <Modal show={showEditConfirm} onHide={handleEditConfirmClose}>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Modal.Header closeButton>
                                    <Modal.Title>確認</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>更新します。よろしいですか？</Modal.Body>
                                <Modal.Footer>
                                    <Button className="btn-dialog-cancel" type="button" onClick={handleEditConfirmClose}>いいえ</Button>
                                    <button className="btn btn-dialog-ok m-1 ms-auto" type="submit" onClick={handleEditConfirmClose}>はい</button>
                                </Modal.Footer>
                            </form>
                        </Modal>

                        {/*削除確認ダイアログ*/}
                        <Modal show={showDeleteConfium} onHide={handleDeleteConfirmClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>確認</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>削除します。よろしいですか？</Modal.Body>
                            <Modal.Footer>
                                <Button className="btn-dialog-cancel" onClick={handleDeleteConfirmClose}>いいえ</Button>
                                <button className="btn btn-dialog-ok m-1 ms-auto" onClick={OnClickDelete}>はい</button>
                            </Modal.Footer>
                        </Modal>

                        {/*削除不能ダイアログ*/}
                        <Modal show={showCantDeleteConfirm} onHide={handleCantDeleteConfirmClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>サービス利用中</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>サービス利用中のユーザがいる為、削除できません。</Modal.Body>
                            <Modal.Footer>
                                <Button variant="btn-dialog-ok" onClick={handleCantDeleteConfirmClose}>確認</Button>
                            </Modal.Footer>
                        </Modal>

                    </section>
                </div>
            )}
            <Footer />
        </>
    );
};

