import { createTSalonPayment } from './../graphql/mutations';
import { useSalon } from './useSalon';
import { useBilling } from './useBilling';
import { TSalonPayment, CreateTSalonPaymentInput, CreateTSalonPaymentMutation, GetTSalonPaymentQuery } from './../API';
import { listStoreByTransferDate, getTSalonPayment, listPaymentByStoreId } from './../graphql/queries';
import { GraphQLResult } from '@aws-amplify/api';
import { API, graphqlOperation } from 'aws-amplify';
import { ListStoreByTransferDateQuery, ListPaymentByStoreIdQuery } from '../API';

export const useSalonPayment = () => {

    const { listSalonAccounts } = useSalon();
    const { listBillingDataByTransferDate } = useBilling();

    const listPaymentByTransferDate = async (transferDate: string) => {

        const res = (await API.graphql(graphqlOperation(listStoreByTransferDate, {
            transferDate: transferDate,
            limit: 999999
        })) as GraphQLResult<ListStoreByTransferDateQuery>);

        const ret = res.data.listStoreByTransferDate.items as TSalonPayment[];

        return (ret)
    }

    const listPaymentByTransferDateStoreId = async (transferDate: string, storeId: string) => {

        const res = (await API.graphql(graphqlOperation(listStoreByTransferDate, {
            transferDate: transferDate,
            storeId: {eq: `${storeId}`},
            limit: 999999
        })) as GraphQLResult<ListStoreByTransferDateQuery>);

        const ret = res.data.listStoreByTransferDate.items as TSalonPayment[];

        return (ret)
    }

    /**
     * 指定した店舗IDの支払い通知書を全件取得する。
     * 
     * @param storeId 店舗ID
     * @returns 
     */
    const getListPaymentByStoreId = async (storeId: string) => {

        try {

            const res = (await API.graphql(graphqlOperation(listPaymentByStoreId, {
                storeId: `${storeId}`,
                limit: 999999
            })) as GraphQLResult<ListPaymentByStoreIdQuery>);
    
            const tSalonPayment = res.data.listPaymentByStoreId.items as TSalonPayment[];

            return (tSalonPayment)
                
        } catch (error) {
             // 途中でエラーが出たら強制でエラーをスロー
            throw new Error(error.status);
        }

    }

    const makePaymentData = async (transferDate: string) => {

        try {

            const salons = await listSalonAccounts();
            //            const billings = await listBillingDataByTransferDate(transferDate);
            const billingLists = await listBillingDataByTransferDate(transferDate);

            if (billingLists.length === 0) {
                return ('no billling data');
            }

            for (const salon of salons) {

                const payment: CreateTSalonPaymentInput = {
                    storeId: salon.storeId,
                    storeName: salon.storeName,
                    transferDate: transferDate,
                    totalNumber: 0,
                    totalFee: 0,
                    totalNumberOfSuccess: 0,
                    totalFeeOfSuccess: 0,
                    totalNumberOfFailure: 0,
                    totalFeeOfFailure: 0,
                    basicCharge: salon.basicCharge,
                    pricePerCase: salon.pricePerCase
                }

                for (const billings of billingLists) {
                    for (const billing of billings) {
                        if (salon.storeId === billing.storeId) {

                            payment.totalNumber++;
                            payment.totalFee += Number(billing.transferAmount);

                            switch (billing.transferResultCode) {
                                case '0': // 振替済み
                                    payment.totalNumberOfSuccess++;
                                    payment.totalFeeOfSuccess += Number(billing.transferAmount);
                                    break;
                                case '1': // 資金不足
                                case '2': // 預金取引無し
                                case '3': // 預金者都合による振替停止
                                case '4': // 預金口座振替依頼書なし
                                case '8': // 委託者都合による振替停止
                                case '9': // その他：請求データ不備（データフォーマット上のエラー）
                                default:
                                    payment.totalNumberOfFailure++;
                                    payment.totalFeeOfFailure += Number(billing.transferAmount);
                            }
                        }
                    }
                }
                const result = await createSalonPayment(payment);
                console.log(result)
            }

            return ('make payment succeed');
        } catch (e) {
            return (e);
        }
    }

    const createSalonPayment = async (payment: CreateTSalonPaymentInput) => {
        const res = (await API.graphql(graphqlOperation(createTSalonPayment, {
            input: {
                storeId: payment.storeId,
                storeName: payment.storeName,
                transferDate: payment.transferDate,
                totalNumber: payment.totalNumber,
                totalFee: payment.totalFee,
                totalNumberOfSuccess: payment.totalNumberOfSuccess,
                totalFeeOfSuccess: payment.totalFeeOfSuccess,
                totalNumberOfFailure: payment.totalNumberOfFailure,
                totalFeeOfFailure: payment.totalFeeOfFailure,
                basicCharge: payment.basicCharge,
                pricePerCase: payment.pricePerCase
            },
        })) as GraphQLResult<CreateTSalonPaymentMutation>);

        return (res);
    }

    const getSalonPayment = async (paymentId: string) => {

        try {
            const res = (await API.graphql(graphqlOperation(getTSalonPayment, {
                id: paymentId
            })) as GraphQLResult<GetTSalonPaymentQuery>);

            return (res);
        } catch (e) {
            console.log(e);
            return (e);
        }
    }

    return { listPaymentByTransferDate, listPaymentByTransferDateStoreId, getListPaymentByStoreId, makePaymentData, getSalonPayment }
}


