import { useBankcodejp, Mbank, Mbranch } from "../hooks/useBankcodejp";
import { useNavigate } from "react-router-dom"
import { Header } from "../atoms/Header"
import { Footer } from "../atoms/Footer"
import React, { useContext, useEffect, useState } from "react";
import { useSalon } from "../hooks/useSalon";
import { useSalonMembers } from "../hooks/useSalonMembers";
import { useService } from "../hooks/useService";
import { MSalonMember, MService, TServicePeriod } from "../API";

import { SelectedSalonContext } from "../providers/SelectedSalonProvider";
import { Loading } from "../atoms/Loading";
import { useForm } from 'react-hook-form'
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// get our fontawesome imports
import { BsArrowLeftCircle } from 'react-icons/bs';
/* ReactDatePicker(ここから) */
import ReactDatePicker, { registerLocale } from "react-datepicker";
import getYear from "date-fns/getYear";
import getMonth from "date-fns/getMonth";
import ja from 'date-fns/locale/ja';
import '../css/common.css';
import 'react-datepicker/dist/react-datepicker.css'; // common.cssの前にimportすること
import { DEPOSIT_TYPE_CURRENT, DEPOSIT_TYPE_ORDINARY } from '../global-define';
import { useCommonTools } from '../hooks/useCommonTools';

ja.options.weekStartsOn = 0;    // jaのロケールの設定が週頭が月曜始まりになっているので日曜始まりにする
registerLocale('ja', ja);       // ReactDatepickerのロケール登録
/* ReactDatePicker(ここまで) */

type Inputs = {
    storeId: string;
    memberId: string;
    applicationDate: string;
    memberName: string;
    furigana: string;
    dateOfBirth: string;
    zipCode: string;
    address1: string;
    address2: string;
    phoneNumber: string;
    phoneNumberMobile: string;
    mailAddress: string;
    bankType: string;
    bankSearch: string;
    bankList: string;
    bankBranchSearch: string;
    bankBranchList: string;
    depositType: string;
    accountNumber: string;
    accountKigou: string;
    accountBangou: string;
    depositerName: string;
}

export const StoreMemberRegister = (props) => {

    const { setUser, setAuthState } = props;

    const Y = (new Date()).getFullYear();
    const M = ("00" + ((new Date()).getMonth() + 1)).slice(-2);
    const D = ("00" + (new Date()).getDate()).slice(-2);
    const TodayString = Y + "/" + M + "/" + D

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        //getValues
    } = useForm<Inputs>({
        criteriaMode: "all",
        shouldFocusError: true,
    });

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const { setSelectedSalonContext, selectedSalonContext, selectedMemberContext, selectedServiceMenusContext
        , setSelectedMemberContext, setSelectedServiceMenusContext, selectedServicePeriodsContext, setSelectedServicePeriodsContext } = useContext(SelectedSalonContext);
    const { salonAccount, getSalonAccount } = useSalon();
    const { salonMember, setSalonMember, initSalonMember, getNewMemberId } = useSalonMembers();
    const { serviceMenus, getServiceMenus } = useService();

    const { getBanks, getBranches, getBank, getBranch } = useBankcodejp();
    const { zenNum2HanNum, hira2ZenKana, getTransferDateString, dateFormatString, isSp } = useCommonTools();

    let [checks, setChecks] = useState<boolean[]>([]);
    // checksに対応するTServicePeriodのstateを定義する
    const [servicePeriods, setServicePeriods] = useState<TServicePeriod[]>([...Array(99)].map(() => ({ __typename: "TServicePeriod", id: "", serviceId: "", startDate: "", endDate: "", createdAt: "", updatedAt: "" })));

    const [totalServicePrice, setTotalServicePrice] = useState<number>(0);
    const [totalServiceCount, setTotalServiceCount] = useState<number>(0);
    const [isPhoneCheck, setIsPhoneCheck] = useState<boolean>(true);
    const [accountKigouRequired, setAccountKigouRequired] = useState<boolean>(false);
    const [accountBangouRequired, setAccountBangouRequired] = useState<boolean>(false);
    const [bankListRequired, setBankListRequired] = useState<boolean>(false);
    const [bankBranchListRequired, setBankBranchListRequired] = useState<boolean>(false);
    const [accountNumberRequired, setAccountNumberRequired] = useState<boolean>(false);
    const [firstTimeBank, setFirstTimeBank] = useState<boolean>(true); // 画面表示から銀行の検索ボタンを押すまでの間
    const [firstTimeBankBranch, setFirstTimeBankBranch] = useState<boolean>(true); // 画面表示から支店の検索ボタンを押すまでの間
    // 申込日の入力フォーム用
    const [formApplicationDate, setFormApplicationDate] = useState(new Date());
    // 誕生日の入力フォーム用
    const [formDateOfBirth, setFormDateOfBirth] = useState(null);
    // 口座登録日のフォーム用
    const [formBankSetDate, setFormBankSetDate] = useState(new Date());

    const getData = async () => {
        // 店舗ID・店舗名のみセッションから取得
        const storeId = sessionStorage.getItem('selectedSalon.storeId');
        let salonJson = sessionStorage.getItem('salonJson'); // JSON
        let salon = null; if (salonJson != null) salon = JSON.parse(salonJson);
        let userId = sessionStorage.getItem('userId');
        if (salon == null || userId == null) {
            //セッション情報も無い（ログアウト状態）
            //ログイン画面に遷移
            navigate('/');
            return;
        }

        // MSalonの情報については次ページから戻った際にselectedSalonContextからstoreNameがundefinedとなってしまうためDynamoDBより取得する
        await getSalonAccount(storeId);

        if (selectedMemberContext == null || selectedMemberContext?.memberId === '') {
            // 画面に初めて来た時にmemberIdを取得する(リロード時も同じ)
            await createNewMember(storeId);
            // 画面に表示する店舗IDの全サービスを取得
            const mServices = await getServiceMenus(storeId);
            /* サービス数分チェック変数を初期値で追加する */
            mServices.forEach((service, index) => {
                checks[index] = false;
            })

            // 申込日に本日の日付を初期値として入れる（react-hook-formのdefaultValuesにもセットします。しないとEnterでSubmitしても入力無しとなってしまいます）
            const Y = formApplicationDate.getFullYear();
            const M = ("00" + (formApplicationDate.getMonth() + 1)).slice(-2);
            const D = ("00" + formApplicationDate.getDate()).slice(-2);
            salonMember.applicationDate = String(Y + "/" + M + "/" + D);
            setSalonMember({ ...salonMember, applicationDate: salonMember.applicationDate } as MSalonMember);
            setValue('applicationDate', salonMember.applicationDate);

            salonMember.bankSetDate = String(Y + "/" + M + "/" + D);
            setSalonMember({ ...salonMember, bankSetDate: salonMember.bankSetDate } as MSalonMember);

            // 預金種別の初期値をreact-hook-formに与える
            salonMember.depositType = DEPOSIT_TYPE_ORDINARY;
            setSalonMember({ ...salonMember, depositType: salonMember.depositType } as MSalonMember);
            setValue('depositType', salonMember.depositType);

            setTotalServicePrice(0);
            setTotalServiceCount(0);

            // 入力項目を制限を更新する
            setYokinClassName("col-sm-6 d-none");
            setYokinClassName2("col-sm-6 d-none");
            setYokinClassName3("col-sm-6 d-none");
            setIsLoading(false);
        } else {
            // 次ページの確認画面から戻ってきた場合はmenberIdは入っているので保存している内容をセットする
            setSalonMember(selectedMemberContext as MSalonMember);
            //setServiceChecks(selectedMemberContext as MSalonMember);
            //setSalonMemberはhook変数で画面上で表示する向け。onSubmit用に直接変数にセットもおこなう。
            salonMember.storeId = selectedMemberContext.storeId;
            salonMember.memberId = selectedMemberContext.memberId;
            salonMember.applicationDate = selectedMemberContext.applicationDate;
            salonMember.memberName = selectedMemberContext.memberName;
            salonMember.furigana = selectedMemberContext.furigana;
            salonMember.dateOfBirth = selectedMemberContext.dateOfBirth;
            salonMember.zipCode = selectedMemberContext.zipCode;
            salonMember.address1 = selectedMemberContext.address1;
            salonMember.address2 = selectedMemberContext.address2
            salonMember.phoneNumber = selectedMemberContext.phoneNumber;
            salonMember.phoneNumberMobile = selectedMemberContext.phoneNumberMobile;
            salonMember.mailAddress = selectedMemberContext.mailAddress;
            salonMember.bankSetDate = selectedMemberContext.bankSetDate;
            salonMember.bankCode = selectedMemberContext.bankCode;
            salonMember.bankBranchCode = selectedMemberContext.bankBranchCode;
            salonMember.depositType = selectedMemberContext.depositType;
            salonMember.accountNumber = selectedMemberContext.accountNumber;
            salonMember.depositerName = selectedMemberContext.depositerName;
            //カレンダーコントロールの表示用の値をセット
            if (selectedMemberContext.applicationDate != null && 0 < selectedMemberContext.applicationDate.length) {
                setFormApplicationDate(new Date(selectedMemberContext.applicationDate));
            }
            if (selectedMemberContext.dateOfBirth != null && 0 < selectedMemberContext.dateOfBirth.length) {
                setFormDateOfBirth(new Date(selectedMemberContext.dateOfBirth));
            }
            // React-hook-formの管理するform変数にセット
            setValue('storeId', salonMember.storeId);
            setValue('memberId', salonMember.memberId);
            setValue('applicationDate', salonMember.applicationDate);
            setValue('memberName', salonMember.memberName);
            setValue('furigana', salonMember.furigana);
            setValue('dateOfBirth', salonMember.dateOfBirth);
            setValue('zipCode', salonMember.zipCode);
            setValue('address1', salonMember.address1);
            setValue('address2', salonMember.address2);
            setValue('phoneNumber', salonMember.phoneNumber);
            setValue('phoneNumberMobile', salonMember.phoneNumberMobile);
            setValue('mailAddress', salonMember.mailAddress);
            //setValue('bankCode', salonMember.bankCode);
            //setValue('bankBranchCode', salonMember.bankBranchCode);
            setValue('depositType', salonMember.depositType);
            setValue('accountNumber', salonMember.accountNumber);
            setValue('depositerName', salonMember.depositerName);

            if (salonMember.accountNumber != null && salonMember.accountNumber.length === 14) {
                // 郵便局の場合
                setValue('bankType', '2');
                updateBankType("2");
                setValue('accountKigou', salonMember.accountNumber.slice(0, 5));
                setAccountKigou(salonMember.accountNumber.slice(0, 5));
                setValue('accountBangou', salonMember.accountNumber.slice(6));
                setAccountBangou(salonMember.accountNumber.slice(6));
                setIsLoading(false);
            } else {
                // 銀行の場合
                setValue('bankType', '1');
                updateBankType("1");

                setAccountNumber(salonMember.accountNumber);

                // リストの更新
                getBankInfo();

            }
            setTotalServicePrice(0);
            setTotalServiceCount(0);

            setServiceChecks(salonMember, selectedServicePeriodsContext as TServicePeriod[]);
        }
    }

    /**
     * ページロード時の処理
     */
    useEffect(() => {
        getData();

        // リロード時に確認を表示するための設定（リロードすると内容が消えるため）
        window.addEventListener("beforeunload", handleBeforeUnloadEvent);
        return () => window.removeEventListener("beforeunload", handleBeforeUnloadEvent);

    }, []);

    const getBankInfo = async () => {

        // 銀行名取得
        var bank = await getBank(salonMember.bankCode);
        // 検索ボックスにセット
        setBankSearch(bank.fullWidthKana);
        // 検索を行いドロップダウンを更新する
        var bankList = await getBanks("fullWidthKana==" + bank.fullWidthKana + "*");
        setBankList(bankList);
        // ドロップダウンの値を設定する
        setBankName(salonMember.bankCode);
        // ドロップダウンのdisableを解除する
        setBankSearchButtonDisabled(false);
        setBankListDisabled(false);

        // 支店名取得
        var bankBranch = await getBranch(salonMember.bankCode, salonMember.bankBranchCode);
        // 検索ボックスにセット
        setBankBranchSearch(bankBranch.fullWidthKana);
        // 検索を行いドロップダウンを更新する
        var bankBranchesList = await getBranches(salonMember.bankCode, "fullWidthKana==" + bankBranch.fullWidthKana + "*");
        setBankBranchList(bankBranchesList);
        // ドロップダウンの値を設定する
        setBankBranchName(salonMember.bankBranchCode);
        // ドロップダウンのdisableを解除する
        setBankBranchSearchDisabled(false);
        setBankBranchSearchButtonDisabled(false);
        setBankBranchListDisabled(false);

        // 非同期完了でローディングを解除
        setIsLoading(false);

    }

    /**
     * 月額サービスリストのチェック状態を更新する
     * @param member 
     */
    const setServiceChecks = async (member: MSalonMember, selectedServicePeriods: TServicePeriod[]) => {
        const services = await getServiceMenus(String(member.storeId));
        /* サービス数分チェック変数を初期値で追加する */
        services.forEach((service, index) => {
            checks[index] = false;
        })
        let checkArray = Array(services.length).fill(false);
        let servicePrice = 0;
        let serviceCount = 0;
        console.log(checkArray);
        services.map((service, index) => {
            selectedServiceMenusContext?.map((useService) => {
                if (service.id === useService.id) {
                    console.log(typeof service.id);

                    checkArray[index] = true;

                    // selectedServicePeriodsからidの等しいものを探してservicePeriodsにセットする
                    selectedServicePeriods.forEach((servicePeriod) => {
                        if (servicePeriod) {
                            if (service.serviceId === servicePeriod.serviceId) {
                                const periods = servicePeriods;
                                periods[index] = servicePeriod;
                                setServicePeriods(periods);
                            }
                        }
                    })

                    servicePrice += Number(service.monthlyPrice);
                    serviceCount += 1;
                    //console.log( checks); 
                    //console.log( checks.map(( check , i  ) => ( i === index ? true : check )) ); 
                    console.log(checkArray);
                }
                return [];
            })
            return [];
        })
        setChecks(checkArray);  // 全部falseにする

        if (0 < serviceCount) {
            // 1つ以上選択がある場合に手数料を追加する
            servicePrice += selectedSalonContext.bankTransferFee;
        }

        setTotalServicePrice(servicePrice);
        setTotalServiceCount(serviceCount);
    }

    /**
     * サロン情報の変数の初期化
     */
    const createNewMember = async (storeId: string) => {
        try {
            const nextId = await getNewMemberId(storeId); // 新規会員のIDを取得
            initSalonMember(storeId, String(nextId));     // 店舗IDとメンバーIDのみ指定して初期化
        } catch (e) {
            initSalonMember(storeId, "000001");            // 店舗IDとメンバーIDのみ指定して初期化
        }
    }

    /**
     * 月額サービスのうちチェックが付いているメニューを取得する
     * 
     * @return チェックのあるサービス数
     */
    const setUseServiceContext = (allMenus: MService[], checks: boolean[]) => {
        const selectedServices = allMenus.filter((menu, index) => {
            if (checks[index]) {
                return (allMenus[index]);
            }
            return (null);
        }
        ) as MService[]
        setSelectedServiceMenusContext(selectedServices);

        // チェックがついているサービスのServicePeriodをコンテキストにセット        
        //const selectedServicePeriods = (checks.map((check, index) => {
        //    if (check) {
        //        return (servicePeriods[index]);
        //    }
        //}) as TServicePeriod[])

        const selectedServicePeriods = servicePeriods.filter((_, index) => checks[index])
            .map(servicePeriod => servicePeriod as TServicePeriod);

        setSelectedServicePeriodsContext(selectedServicePeriods);

        return selectedServices.length;

    }

    /**
     * 戻るボタン押下
     * @returns 
     */
    const OnClickReturn = () => navigate('/StoreMemberAgreement')
    /**
     * 登録するボタン押下
     */
    const onSubmit = async () => {
        // 電話番号か携帯電話番号のいずれかがセットされているか？
        setIsPhoneCheck(true);
        if (salonMember.phoneNumber.length === 0 && salonMember.phoneNumberMobile.length === 0) {
            setIsPhoneCheck(false);
        }
        // 郵便局の場合（記号、番号の入力チェック）
        else if ((typePost === true) && (accountKigou == null || accountKigou.length !== 5)) {
            setAccountKigouRequired(true);
        }
        else if ((typePost === true) && (accountBangou == null || accountBangou.length !== 8)) {
            setAccountKigouRequired(false);
            setAccountBangouRequired(true);
        }
        // 銀行の場合（銀行名、支店名、口座番号の入力チェック）
        else if ((typeBank === true) && (bankList == null || bankList.length === 0)) {
            setBankListRequired(true);
        }
        else if ((typeBank === true) && (bankBranchList == null || bankBranchList.length === 0)) {
            setBankListRequired(false);
            setBankBranchListRequired(true);
        }
        // 口座番号
        else if ((typeBank === true) && (accountNumber == null || accountNumber.length !== 7)) {
            setBankListRequired(false);
            setBankBranchListRequired(false);
            setAccountNumberRequired(true);
        }
        else {
            if (typePost === true) {
                // 郵便局の場合は初期値をセットする SWEBデータレイアウトに合わせる
                salonMember.depositType = DEPOSIT_TYPE_ORDINARY; // 普通預金固定
                salonMember.bankCode = "9900"; // ゆうちょ銀行固定
                salonMember.bankName = ' '.repeat(15);// スペース
                salonMember.bankBranchCode = accountKigou.slice(1, 4);
                salonMember.bankBranchName = ' '.repeat(15);// スペース

            }
            // メモリにセットして登録確認画面に遷移する
            setSelectedSalonContext(salonAccount);
            setSelectedMemberContext(salonMember);
            setUseServiceContext(serviceMenus, checks);
            navigate('/StoreMemberRegisterConfirm')
        }
    }

    /**
     * フォーム内のアイテムのonChangeイベント処理
     * @param date 
     */
    const onChangeApplicationDate = (date) => {
        try {
            const Y = date.getFullYear();
            const M = ("00" + (date.getMonth() + 1)).slice(-2);
            const D = ("00" + date.getDate()).slice(-2);
            setSalonMember({ ...salonMember, applicationDate: Y + "/" + M + "/" + D } as MSalonMember);
            setFormApplicationDate(new Date(Y + "/" + M + "/" + D));
            setValue('applicationDate', Y + "/" + M + "/" + D);
        } catch (e) {
            // 入力フォームの内容が消された場合（必須項目なので今日の日付をセットする）
            setSalonMember({ ...salonMember, applicationDate: TodayString } as MSalonMember);
            setFormApplicationDate(new Date(TodayString));
            setValue('applicationDate', TodayString);
        }
    }
    const onChangeMemberName = (event: React.ChangeEvent<HTMLInputElement>) => { setSalonMember({ ...salonMember, memberName: event.target.value } as MSalonMember); }
    const onChangeFurigana = (event: React.ChangeEvent<HTMLInputElement>) => { setSalonMember({ ...salonMember, furigana: event.target.value } as MSalonMember); }
    //const onBlurFurigana = (event: React.ChangeEvent<HTMLInputElement>) => { setSalonMember({ ...salonMember, furigana: hira2ZenKana(event.target.value) } as MSalonMember); }    
    const onChangeDateOfBirth = (date) => {
        try {
            const Y = date.getFullYear();
            const M = ("00" + (date.getMonth() + 1)).slice(-2);
            const D = ("00" + date.getDate()).slice(-2);
            setSalonMember({ ...salonMember, dateOfBirth: Y + "/" + M + "/" + D } as MSalonMember);
            setFormDateOfBirth(new Date(Y + "/" + M + "/" + D));
        } catch (e) {
            // 入力フォームの内容が消された場合
            setSalonMember({ ...salonMember, dateOfBirth: "" } as MSalonMember);
            setFormDateOfBirth(null);
        }
    }
    const onChangeZipCode = (event: React.ChangeEvent<HTMLInputElement>) => { setSalonMember({ ...salonMember, zipCode: zenNum2HanNum(event.target.value) } as MSalonMember); }
    const onChangeAddress1 = (event: React.ChangeEvent<HTMLInputElement>) => { setSalonMember({ ...salonMember, address1: event.target.value } as MSalonMember); }
    const onChangeAddress2 = (event: React.ChangeEvent<HTMLInputElement>) => { setSalonMember({ ...salonMember, address2: event.target.value } as MSalonMember); }
    const onChangePhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => { setSalonMember({ ...salonMember, phoneNumber: zenNum2HanNum(event.target.value) } as MSalonMember); }
    const onChangePhoneNumberMobile = (event: React.ChangeEvent<HTMLInputElement>) => { setSalonMember({ ...salonMember, phoneNumberMobile: zenNum2HanNum(event.target.value) } as MSalonMember); }
    const onChangeMailAddress = (event: React.ChangeEvent<HTMLInputElement>) => { setSalonMember({ ...salonMember, mailAddress: event.target.value } as MSalonMember); }
    //const onChangeBankCode = (event) => { setSalonMember({ ...salonMember , bankCode: event.target.value }); }
    //const onChangeBankBranchCode = (event) => { setSalonMember({ ...salonMember , bankBranchCode: event.target.value }); }
    const onChangeAccountNumber = (event) => {
        setAccountNumber(zenNum2HanNum(event.target.value));
        setSalonMember({ ...salonMember, accountNumber: zenNum2HanNum(event.target.value) });
    }
    const onChangeDepositerName = (event) => { setSalonMember({ ...salonMember, depositerName: event.target.value }); }
    //const onBlurDepositerName = (event) => { setSalonMember({ ...salonMember , depositerName: hira2ZenKana(event.target.value) }); }
    const onChangeDepositType = (event) => { setSalonMember({ ...salonMember, depositType: event.target.value }); }
    /**
     * 銀行名・支店名関連
     */
    const [yokinClassName, setYokinClassName] = useState<string>("col-sm-6");
    const [yokinClassName2, setYokinClassName2] = useState<string>("col-sm-6");
    const [yokinClassName3, setYokinClassName3] = useState<string>("col-sm-6");
    const [typeBank, setTypeBank] = useState<boolean>(false);
    const [typePost, setTypePost] = useState<boolean>(false);
    // 区別
    const onChangeBankType = (event) => {
        updateBankType(event.target.value);
    }
    const updateBankType = (type) => {
        if (type === "1") {
            // 銀行の場合
            setTypeBank(true);
            setTypePost(false);
            // 入力項目を制限を更新する
            setYokinClassName("col-sm-6");
            setYokinClassName2("col-sm-6 d-none");
            setYokinClassName3("col-sm-6");
        }
        else if (type === "2") {
            // 郵便局の場合
            setTypeBank(false);
            setTypePost(true);
            // 入力項目を制限を更新する
            setYokinClassName("col-sm-6 d-none");
            setYokinClassName2("col-sm-6");
            setYokinClassName3("col-sm-6");
        }
    }
    // 銀行名検索
    const [bankSearch, setBankSearch] = useState<string>('');
    // 銀行名検索ボタンの有効/無効
    const [bankSearchButtonDisabled, setBankSearchButtonDisabled] = useState<boolean>(true);
    // 銀行名リストselectコントロールの値保存用
    const [bankName, setBankName] = useState<string>('');
    // 銀行名リスト
    const [bankList, setBankList] = useState<Mbank[]>([]);
    // 銀行名Optionリストの有効/無効
    const [bankListDisabled, setBankListDisabled] = useState<boolean>(true);
    // 銀行名OptionHTML
    const optionBankList = bankList.map((data, index) => <option value={data.code}>({data.code}) {data.name}</option>)
    // イベント　銀行名検索ボックス入力
    const onChangeBankSearch = (event) => {
        if (event.target.value.length === 0) {
            setBankSearchButtonDisabled(true);
        } else {
            setBankSearchButtonDisabled(false);
        }
        setBankSearch(event.target.value);
    }
    const onBlurBankSearch = (event) => { setBankSearch(hira2ZenKana(event.target.value)); };

    // イベント　銀行名検索ボタン押下
    const onButtonBankSearch = async () => {
        if (0 < bankSearch.length) {
            // 1文字以上の場合は検索を行いドロップダウンを更新する
            var bankList = await getBanks("fullWidthKana==" + bankSearch + "*");
            setBankList(bankList);
            // ドロップダウンのdisableを解除する
            setBankListDisabled(false);
        } else {
            // ドロップダウンのdisableを有効にする
            setBankListDisabled(true);
        }
        setFirstTimeBank(false);
    }
    // イベント　銀行名Optionリストの変更確定時イベント
    const onChangeBankList = (event) => {
        salonMember.bankCode = event.target.value;
        setSalonMember({ ...salonMember, bankCode: event.target.value });
        salonMember.bankName = event.target.options[event.target.selectedIndex].label.split(' ')[1];
        setSalonMember({ ...salonMember, bankName: salonMember.bankName });
        // 銀行名が確定したので支店名入力を有効にする。
        setBankBranchSearchDisabled(false);
        // Selectの値を更新する
        setBankName(event.target.value);
    }
    // 支店名検索
    const [bankBranchSearch, setBankBranchSearch] = useState<string>('');
    // 支店名リストselectコントロールの値保存用
    const [bankBranchName, setBankBranchName] = useState<string>('');
    // 支店名検索ボックスの有効/無効
    const [bankBranchSearchDisabled, setBankBranchSearchDisabled] = useState<boolean>(true);
    // 支店名検索ボタンの有効/無効
    const [bankBranchSearchButtonDisabled, setBankBranchSearchButtonDisabled] = useState<boolean>(true);
    // 支店名リスト
    const [bankBranchList, setBankBranchList] = useState<Mbranch[]>([]);
    // 支店名Optionリストの有効/無効
    const [bankBranchListDisabled, setBankBranchListDisabled] = useState<boolean>(true);
    // イベント　支店名検索ボックス入力
    const onChangeBankBranchSearch = (event) => {
        if (event.target.value.length === 0) {
            setBankBranchSearchButtonDisabled(true);
        } else {
            setBankBranchSearchButtonDisabled(false);
        }
        setBankBranchSearch(event.target.value);
    }
    const onBlurBankBranchSearch = (event) => { setBankBranchSearch(hira2ZenKana(event.target.value)); };

    // イベント　支店名OptionHTML
    const optionBankBranchList = bankBranchList.map((data, index) => <option value={data.code}>({data.code}) {data.name}</option>)
    // イベント　支店検索ボタン押下
    const onButtonBankBranchSearch = async () => {
        if (0 < bankBranchSearch.length) {
            // 1文字以上の場合は検索を行いドロップダウンを更新する
            var bankBranchesList = await getBranches(salonMember.bankCode, "fullWidthKana==" + bankBranchSearch + "*");
            setBankBranchList(bankBranchesList);
            // ドロップダウンのdisableを解除する
            setBankBranchListDisabled(false);
        } else {
            // ドロップダウンのdisableを有効にする
            setBankBranchListDisabled(true);
        }
        setFirstTimeBankBranch(false);
    }
    // イベント　支店名Optionリストの変更確定時イベント
    const onChangeBankBranchList = (event) => {
        salonMember.bankBranchCode = event.target.value;
        setSalonMember({ ...salonMember, bankBranchCode: event.target.value });
        salonMember.bankBranchName = event.target.options[event.target.selectedIndex].label.split(' ')[1];
        setSalonMember({ ...salonMember, bankBranchName: salonMember.bankBranchName });
        // Selectの値を更新する
        setBankBranchName(event.target.value);
    }
    const [accountNumber, setAccountNumber] = useState<string>(null);
    const [accountKigou, setAccountKigou] = useState<string>(null);
    const [accountBangou, setAccountBangou] = useState<string>(null);
    const onChangeAccountKigou = (event) => {
        setAccountKigou(zenNum2HanNum(event.target.value));
        // 記号番号を"-"で結合した値を保存
        salonMember.accountNumber = zenNum2HanNum(event.target.value) + "-" + accountBangou;
        setSalonMember({ ...salonMember, accountNumber: salonMember.accountNumber });
    }
    const onChangeAccountBangou = (event) => {
        setAccountBangou(zenNum2HanNum(event.target.value));
        // 記号番号を"-"で結合した値を保存
        salonMember.accountNumber = accountKigou + "-" + zenNum2HanNum(event.target.value);
        setSalonMember({ ...salonMember, accountNumber: salonMember.accountNumber });
    }

    /**
     * 申込コース
     */
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        setChecks(checks.map((check, i) => (i === index ? event.target.checked : check)));
        if (event.target.checked) {
            if (Number(totalServiceCount) === 0) {
                // 0個→1個 手数料,コース費用を追加
                setTotalServicePrice(Number(totalServicePrice) + Number(serviceMenus[index].monthlyPrice) + Number(selectedSalonContext?.bankTransferFee));
            }
            else {
                // コース費用のみ加算
                setTotalServicePrice(Number(totalServicePrice) + Number(serviceMenus[index].monthlyPrice));
            }
            // コース選択数を更新
            setTotalServiceCount(Number(totalServiceCount) + 1);

            // インデックスの一致するServicePeriodのStartDateとEndDateを設定する
            setServicePeriods(servicePeriods.map((servicePeriod, i) => (i === index ? { ...servicePeriod, serviceId: serviceMenus[index].serviceId, startDate: dateFormatString(getTransferDateString(8, 1)), endDate: "2099/12/31" } : servicePeriod)));

        } else {
            if (Number(totalServiceCount) === 1) {
                // 1個→0個 手数料を減算
                setTotalServicePrice(Number(totalServicePrice) - Number(serviceMenus[index].monthlyPrice) - Number(selectedSalonContext?.bankTransferFee));
            }
            else {
                // コース費用のみ減算
                setTotalServicePrice(Number(totalServicePrice) - Number(serviceMenus[index].monthlyPrice));
            }
            // コース選択数を更新
            setTotalServiceCount(Number(totalServiceCount) - 1);

            // インデックスの一致するServicePeriodのStartDateとEndDateをクリアする
            setServicePeriods(servicePeriods.map((servicePeriod, i) => (i === index ? { ...servicePeriod, serviceId: '', startDate: '', endDate: '' } : servicePeriod)));

        }
    }

    /* ReactDatePicker設定（ここから） */
    var eraHandler = yearNow => {
        const generate = (era, startYear) => {
            let yearDsp = yearNow - startYear + 1;
            let yearTop = "";
            if (yearDsp === 1) {
                yearTop = "元";
            } else {
                yearTop = ('00' + yearDsp).slice(-2);
            }
            return `${era}${yearTop}年`;
        };

        if (yearNow >= 2019) {
            return generate('令和', 2019);
        }

        if (yearNow >= 1989) {
            return generate('平成', 1989);
        }

        if (yearNow >= 1926) {
            return generate('昭和', 1926);
        }

        if (yearNow >= 1912) {
            return generate('大正', 1912);
        }
    }
    var startYear = 1912; // カレンダーに表示する最初の西暦（大正元年となる1912を指定）
    var futureListUp = 0; // カレンダーに表示する未来の年数
    var years = Array.from({ length: getYear(new Date()) - startYear + futureListUp }, (v, k) => k + startYear).reverse();
    const months = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
    /* ReactDatePicker設定（ここまで） */

    /**
     * リロード時のイベント
     * @param event 
     */
    const handleBeforeUnloadEvent = (event: BeforeUnloadEvent): void => {
        // 表示メッセージのカスタム（GoogleChromeは固定のみで効きません）
        event.returnValue = "ページを離れると入力内容が消えます。このページを離れますか？";
    };

    const onChangeBankSetDate = (date) => {
        try {
            const Y = date.getFullYear();
            const M = ("00" + (date.getMonth() + 1)).slice(-2);
            const D = ("00" + date.getDate()).slice(-2);
            setSalonMember({ ...salonMember, bankSetDate: Y + "/" + M + "/" + D } as MSalonMember);
            setFormBankSetDate(new Date(Y + "/" + M + "/" + D));
            //setValue('applicationDate', Y+"/"+M+"/"+D);
        } catch (e) {
            // 入力フォームの内容が消された場合（必須項目なので今日の日付をセットする）
            setSalonMember({ ...salonMember, bankSetDate: TodayString } as MSalonMember);
            setFormBankSetDate(new Date(TodayString));
            //setValue('applicationDate', TodayString);
        }
    }
    const onChangePeriodStart = (date, index) => {
        try {
            const Y = date.getFullYear();
            const M = ("00" + (date.getMonth() + 1)).slice(-2);
            const D = ("00" + date.getDate()).slice(-2);
            const periods = servicePeriods.map((servicePeriod, i) => (i === index ? { ...servicePeriod, serviceId: serviceMenus[index].serviceId, startDate: Y + "/" + M + "/" + D } : servicePeriod));
            setServicePeriods(periods);
        } catch (e) {
            // クリアする
            setServicePeriods(servicePeriods.map((servicePeriod, i) => (i === index ? { ...servicePeriod, serviceId: '', startDate: '', endDate: '' } : servicePeriod)));
        }
    }

    const onChangePeriodEnd = (date, index) => {
        try {
            const Y = date.getFullYear();
            const M = ("00" + (date.getMonth() + 1)).slice(-2);
            const D = ("00" + date.getDate()).slice(-2);
            const periods = servicePeriods.map((servicePeriod, i) => (i === index ? { ...servicePeriod, serviceId: serviceMenus[index].serviceId, endDate: Y + "/" + M + "/" + D } : servicePeriod));
            setServicePeriods(periods);
        } catch (e) {
            // クリアする
            setServicePeriods(servicePeriods.map((servicePeriod, i) => (i === index ? { ...servicePeriod, serviceId: '', startDate: '', endDate: '' } : servicePeriod)));
        }
    }

    return (
        <>
            <Header setUser={setUser} setAuthState={setAuthState} setIsLoading={setIsLoading} />
            {isLoading ? (
                <Loading />
            ) : (
                <div className="container mt-2 mb-5">
                    <section className="row">
                        <section className="col-12">
                            <div className="d-flex page-title-row">
                                <button className="btn" onClick={OnClickReturn}><h2 className="icon-gray"><BsArrowLeftCircle /></h2></button>
                                <header className="d-flex page-title">
                                    <h1 className="fs-4 m-1">新規会員登録</h1>
                                </header>
                            </div>
                        </section>
                    </section>

                    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                        <div className="row g-3 mt-2">
                            <div className="col-md-12">
                                <div className="row g-3">
                                    <div className="col-6">
                                        <label htmlFor="storeName" className="form-label item-title">契約店舗</label>
                                        <div className="input-group">
                                            <input type="text" autoComplete="off" className="form-control" id="storeName" value={salonAccount.storeName} readOnly />
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <label htmlFor="applicationDate" className="form-label item-title ">会員登録日</label>
                                        <ReactDatePicker
                                            id="applicationDate"
                                            locale='ja'
                                            placeholderText="選択してください"
                                            dateFormat="yyyy/MM/dd"
                                            //isClearable
                                            todayButton="今日"
                                            onChange={onChangeApplicationDate}
                                            selected={formApplicationDate as Date}
                                            onFocus={e => e.target.blur()} // <--- ソフトウェアキーボード非表示
                                            disabled={true}
                                        />
                                    </div>

                                    <div className="col-sm-6">
                                        <label htmlFor="memberName" className="form-label item-title required">お名前</label>
                                        <input type="text" autoComplete="off" {...register('memberName', { required: true })} className="form-control" id="memberName" placeholder="氏名" value={salonMember.memberName} onChange={onChangeMemberName} />
                                        {errors.memberName?.types?.required && <div className="text-danger">お名前を入力してください</div>}
                                    </div>

                                    <div className="col-sm-6">
                                        <label htmlFor="furigana" className="form-label item-title required">フリガナ</label>
                                        <input type="text" autoComplete="off" {...register('furigana', { required: true })} className="form-control" id="furigana" placeholder="フリガナ" value={salonMember.furigana} onChange={onChangeFurigana} /*onBlur={onBlurFurigana}*/ />
                                        {errors.furigana?.types?.required && <div className="text-danger">フリガナを入力してください</div>}
                                    </div>

                                    <div className="col-6">
                                        <label htmlFor="dateOfBirth" className="form-label item-title">生年月日</label>
                                        <ReactDatePicker
                                            id="dateOfBirth"
                                            locale='ja'
                                            placeholderText="選択してください"
                                            dateFormat="yyyy/MM/dd"
                                            isClearable
                                            showMonthDropdown
                                            showYearDropdown
                                            //todayButton="今日"
                                            dropdownMode="select"
                                            onChange={onChangeDateOfBirth}
                                            selected={formDateOfBirth as Date}
                                            onFocus={e => e.target.blur()} // <--- ソフトウェアキーボード非表示
                                            // カレンダーのヘッダ部分をカスタマイズする
                                            renderCustomHeader={({
                                                date,
                                                changeYear,
                                                changeMonth,
                                                decreaseMonth,
                                                increaseMonth,
                                                prevMonthButtonDisabled,
                                                nextMonthButtonDisabled
                                            }) => {
                                                return (
                                                    <div
                                                        style={{ margin: 10, display: "flex", justifyContent: "center" }}>
                                                        {/* 前月ボタン */}
                                                        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</button>
                                                        {/* 年の部分 */}
                                                        <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)} >
                                                            {years.map((option) => (
                                                                // eraHandler()で年のプルダウンに元号を付ける
                                                                <option key={option} value={option}>{option}年（{eraHandler(option)}）</option>
                                                            ))}
                                                        </select>
                                                        {/* 月の部分 */}
                                                        <select value={months[getMonth(date)]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))} >
                                                            {months.map(option => (
                                                                <option key={option} value={option}> {option}月 </option>
                                                            ))}
                                                        </select>
                                                        {/* 次月ボタン */}
                                                        <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</button>
                                                    </div>
                                                );
                                            }
                                            }
                                        />
                                    </div>
                                    <div className="col-6">
                                    </div>

                                    <div className="col-sm-6">
                                        <label htmlFor="zipCode" className="form-label item-title required">郵便番号</label>
                                        <input type="text" autoComplete="off" inputMode="numeric" {...register('zipCode', { required: true, pattern: /[0-9]{7}/ })} className="form-control" id="zipCode" value={salonMember.zipCode} onChange={onChangeZipCode} placeholder="半角7桁ハイフン無し0～9の数字" />
                                        {errors.zipCode?.types?.required && <div className="text-danger">郵便番号を入力してください</div>}
                                        {errors.zipCode?.types?.pattern && <div className="text-danger">郵便番号はハイフン無しの7桁の0～9の数字で入力してください</div>}
                                    </div>
                                    <div className="col-6">
                                    </div>

                                    <div className="col-sm-6">
                                        <label htmlFor="address1" className="form-label item-title required">住所１</label>
                                        <input type="text" autoComplete="off" {...register('address1', { required: true })} className="form-control" id="address1" value={salonMember.address1} onChange={onChangeAddress1} placeholder="県市区町村" />
                                        {errors.address1?.types?.required && <div className="text-danger">住所１を入力してください</div>}
                                    </div>
                                    <div className="col-sm-6">
                                        <label htmlFor="address2" className="form-label item-title">住所２</label>
                                        <input type="text" autoComplete="off" {...register('address2', { required: false })} className="form-control" id="address2" value={salonMember.address2} onChange={onChangeAddress2} placeholder="番地・ビル名・部屋番号" />
                                    </div>

                                    <div className="col-md-6">
                                        <label htmlFor="phoneNumber" className="form-label item-title required">電話番号</label>
                                        <input type="text" autoComplete="off" inputMode="tel" {...register('phoneNumber', { required: false, pattern: /^[0-9]+$/ })} className="form-control" id="phoneNumber" value={salonMember.phoneNumber} onChange={onChangePhoneNumber} placeholder="半角ハイフン無し0～9の数字" />
                                        {isPhoneCheck === false && <div className="text-danger">電話番号か携帯番号のいずれかを入力してください</div>}
                                        {errors.phoneNumber?.types?.pattern && <div className="text-danger">ハイフン無しの0～9の数字で入力してください</div>}
                                    </div>

                                    <div className="col-md-6">
                                        <label htmlFor="phoneNumberMobile" className="form-label item-title required">携帯番号</label>
                                        <input type="text" autoComplete="off" inputMode="tel" {...register('phoneNumberMobile', { required: false, pattern: /^[0-9]+$/ })} className="form-control" id="phoneNumberMobile" value={salonMember.phoneNumberMobile} onChange={onChangePhoneNumberMobile} placeholder="半角ハイフン無し0～9の数字" />
                                        {isPhoneCheck === false && <div className="text-danger">電話番号か携帯番号のいずれかを入力してください</div>}
                                        {errors.phoneNumberMobile?.types?.pattern && <div className="text-danger">ハイフン無しの0～9の数字で入力してください</div>}
                                    </div>

                                    <div className="col-md-6">
                                        <label htmlFor="mailAddress" className="form-label item-title">メールアドレス</label>
                                        <input type="text" autoComplete="off" inputMode="email" {...register('mailAddress', { required: false, pattern: /\S+@\S+\.\S+/ })} className="form-control" id="mailAddress" value={salonMember.mailAddress} onChange={onChangeMailAddress} />
                                        {errors.mailAddress?.types?.pattern && <div className="text-danger">メールアドレスの形式が違います</div>}
                                    </div>

                                    <div className="col-sm-6 mt-0"></div>

                                    <h4 className="d-flex justify-content-between align-items-center mt-4 item-title">
                                        預金口座振替情報
                                    </h4>

                                    <div className="col-md-6" style={{ zIndex: 9 }} >
                                        <label htmlFor="bankSetDate" className="form-label">口座登録日</label>
                                        <ReactDatePicker
                                            id="bankSetDate"
                                            locale='ja'
                                            placeholderText="選択してください"
                                            dateFormat="yyyy/MM/dd"
                                            //isClearable
                                            todayButton="今日"
                                            onChange={onChangeBankSetDate}
                                            selected={formBankSetDate as Date}
                                            onFocus={e => e.target.blur()} // <--- ソフトウェアキーボード非表示
                                            disabled={true}
                                        />
                                    </div>

                                    <label htmlFor="bankType" className="form-label item-title required">銀行またはゆうちょ銀行を選択してください</label>
                                    <div className="btn-group m-1">
                                        <input type="radio" autoComplete="off" {...register('bankType', { required: true })} className="btn-check" id="bank" value="1" onChange={onChangeBankType} checked={typeBank} />
                                        <label htmlFor="bank" className="btn btn-outline-primary w-50">銀行</label>

                                        <input type="radio" autoComplete="off" {...register('bankType', { required: true })} className="btn-check" id="post" value="2" onChange={onChangeBankType} checked={typePost} />
                                        <label htmlFor="post" className="btn btn-outline-primary w-50">ゆうちょ銀行</label>
                                    </div>
                                    {errors.bankType?.types?.required && <div className="text-danger">銀行またはゆうちょ銀行を選択してください</div>}

                                    <div className={yokinClassName}>
                                        <label htmlFor="bankList" className="form-label item-title required">銀行名を検索して選択してください</label>
                                        <div className="input-group">
                                            <input type="text" autoComplete="off" {...register('bankSearch')} className="form-control" id="bankSearch" value={bankSearch} onChange={onChangeBankSearch} onBlur={onBlurBankSearch} placeholder="全角カタカナで１文字以上入力" />
                                            <span className="input-group-btn">
                                                <button type="button" className="btn btn-primary shadow-none" onClick={onButtonBankSearch} disabled={bankSearchButtonDisabled}>
                                                    <i><FontAwesomeIcon icon={faSearch} cursor='pointer' /></i>
                                                </button>
                                            </span>
                                        </div>
                                        {0 < optionBankList.length ? (
                                            <select autoComplete="off" {...register('bankList')} className="form-control" id="bankList" value={bankName} onChange={onChangeBankList} disabled={bankListDisabled}>
                                                <option value=''>選択してください</option>
                                                {optionBankList}
                                            </select>
                                        ) : firstTimeBank === false ? (
                                            <div className="text-danger">銀行名は見つかりませんでした</div>
                                        ) : ('')}
                                        {bankListRequired === true && <div className="text-danger">銀行名を選択してください</div>}
                                    </div>
                                    <div className={yokinClassName}>
                                        <label htmlFor="bankBranchList" className="form-label item-title required">支店名を検索して選択してください</label>
                                        <div className="input-group">
                                            <input type="text" autoComplete="off" {...register('bankBranchSearch')} className="form-control" id="bankBranchSearch" value={bankBranchSearch} onChange={onChangeBankBranchSearch} onBlur={onBlurBankBranchSearch} disabled={bankBranchSearchDisabled} placeholder="全角カタカナで１文字以上入力" />
                                            <span className="input-group-btn">
                                                <button type="button" className="btn btn-primary shadow-none" onClick={onButtonBankBranchSearch} disabled={bankBranchSearchButtonDisabled}>
                                                    <i><FontAwesomeIcon icon={faSearch} cursor='pointer' /></i>
                                                </button>
                                            </span>
                                        </div>
                                        {0 < optionBankBranchList.length ? (
                                            <select autoComplete="off" {...register('bankBranchList')} className="form-control" id="bankBranchList" value={bankBranchName} onChange={onChangeBankBranchList} disabled={bankBranchListDisabled}>
                                                <option value=''>選択してください</option>
                                                {optionBankBranchList}
                                            </select>
                                        ) : firstTimeBankBranch === false ? (
                                            <div className="text-danger">支店名は見つかりませんでした</div>
                                        ) : ('')}
                                        {bankBranchListRequired === true && <div className="text-danger">支店名を選択してください</div>}
                                    </div>
                                    <div className={yokinClassName}>
                                        <label htmlFor="depositType" className="form-label item-title required">預金種別</label>
                                        <select autoComplete="off" {...register('depositType')} className="form-control" id="depositType" defaultValue="1" value={salonMember.depositType} onChange={onChangeDepositType}>
                                            <option value={DEPOSIT_TYPE_ORDINARY}>普通</option>
                                            <option value={DEPOSIT_TYPE_CURRENT}>当座</option>
                                        </select>
                                    </div>
                                    <div className={yokinClassName}>
                                        <label htmlFor="accountNumber" className="form-label item-title required">口座番号</label>
                                        <input type="text" autoComplete="off" inputMode="numeric" {...register('accountNumber')} className="form-control" id="accountNumber" value={accountNumber} onChange={onChangeAccountNumber} placeholder="半角7桁0～9の数字" />
                                        {accountNumberRequired === true && <div className="text-danger">口座番号を半角7桁0～9の数字で入力してください</div>}
                                    </div>
                                    <div className={yokinClassName2}>
                                        <label htmlFor="accountKigou" className="form-label item-title required">記号</label>
                                        <input type="text" autoComplete="off" inputMode="numeric" {...register('accountKigou')} className="form-control" id="accountKigou" value={accountKigou} onChange={onChangeAccountKigou} placeholder="半角5文字" />
                                        {accountKigouRequired === true && <div className="text-danger">記号を半角5桁0～9の数字で入力してください。キャッシュカード券面AAAAA-BBBBBBBBのAAAAAの部分。</div>}
                                    </div>
                                    <div className={yokinClassName2}>
                                        <label htmlFor="accountKigou" className="form-label item-title required">番号</label>
                                        <input type="text" autoComplete="off" inputMode="numeric" {...register('accountBangou')} className="form-control" id="accountBangou" value={accountBangou} onChange={onChangeAccountBangou} placeholder="半角8文字" />
                                        {accountBangouRequired === true && <div className="text-danger">番号を半角8桁0～9の数字で入力してください。キャッシュカード券面AAAAA-BBBBBBBBのBBBBBBBBの部分。</div>}
                                    </div>
                                    <div className={yokinClassName3}>
                                        <label htmlFor="depositerName" className="form-label item-title required">預金者名（カナ）</label>
                                        {/*参照：https://www.tanshin.co.jp/business/netbk/pdf/zengin_moji.pdf&clen=67237&chunk=true */}
                                        <input type="text" autoComplete="off" {...register('depositerName', { required: true, pattern: /^[ァ-ヶー　（）．－「」／０-９Ａ-Ｚ]+$/ })} className="form-control" id="depositerName" value={salonMember.depositerName} onChange={onChangeDepositerName} /*onBlur={onBlurDepositerName}*/ placeholder="全角カタカナ・記号・英大文字" />
                                        {errors.depositerName?.types?.required && <div className="text-danger">預金者名を全角カナ・記号・英大文字で入力してください</div>}
                                        {errors.depositerName?.types?.pattern && <div className="text-danger">預金者名は全角カナ・記号・英大文字で入力してください</div>}
                                    </div>

                                    {/*申込コース*/}
                                    <h5 className="d-flex justify-content-between align-items-center mt-4">
                                        <label htmlFor="cource" className="form-label item-title">申込コース</label>
                                    </h5>
                                    <ul className="list-group mt-0 shadow-sm">
                                        {serviceMenus.sort((a, b) => Number(a.serviceId) > Number(b.serviceId) ? 1 : -1).map((serviceMenu, index) => {
                                            return (!isSp() ? (
                                                <li className="list-group-item d-flex justify-content-between lh-sm" key={index}>
                                                    <div className="form-check" style={{ width: 25 + '%' }}>
                                                        <input autoComplete="off" className="form-check-input" type="checkbox" checked={checks[index]} id={'cource' + index} onChange={(e) => handleChange(e, index)} />
                                                        <label className="form-check-label" htmlFor={'cource' + index}>{serviceMenu.courseName}</label>
                                                    </div>
                                                    <div className="d-flex" style={{ width: 50 + '%' }}>
                                                        <div className="d-flex" style={{ width: 250 }}>
                                                            <label style={{ width: 100 }} htmlFor="periodStart" className="form-label mt-2">開始日:</label>
                                                            <ReactDatePicker
                                                                className="me-2"
                                                                id="periodStart"
                                                                locale='ja'
                                                                //placeholderText="選択してください"
                                                                dateFormat="yyyy/MM/dd"
                                                                todayButton="今日"
                                                                onChange={(date) => onChangePeriodStart(date, index)}
                                                                selected={Date.parse(servicePeriods[index].startDate) ? new Date(servicePeriods[index].startDate) : null}
                                                                onFocus={e => e.target.blur()} // <--- ソフトウェアキーボード非表示
                                                            />
                                                        </div>
                                                        <div className="d-flex ms-4" style={{ width: 250 }}>
                                                            <label style={{ width: 100 }} htmlFor="periodEnd" className="form-label mt-2 text-align: right;">終了日:</label>
                                                            <ReactDatePicker
                                                                className="me-2"
                                                                id="periodEnd"
                                                                locale='ja'
                                                                //placeholderText="選択してください"
                                                                dateFormat="yyyy/MM/dd"
                                                                todayButton="今日"
                                                                onChange={(date) => onChangePeriodEnd(date, index)}
                                                                selected={Date.parse(servicePeriods[index].endDate) ? new Date(servicePeriods[index].endDate) : null}
                                                                onFocus={e => e.target.blur()} // <--- ソフトウェアキーボード非表示
                                                            />
                                                        </div>
                                                    </div>
                                                    <span className="text-muted">&yen;{serviceMenu.monthlyPrice.toLocaleString()}</span>
                                                </li>
                                            ) : (
                                                <>
                                                    <li className="list-group-item lh-sm" key={index}>
                                                        <div className="form-check">
                                                            <input autoComplete="off" className="form-check-input" type="checkbox" checked={checks[index]} id={'cource' + index} onChange={(e) => handleChange(e, index)} />
                                                            <label className="form-check-label" htmlFor={'cource' + index}>{serviceMenu.courseName}</label>
                                                        </div>
                                                        <span className="text-muted" style={{ margin: "0 0 0 auto" }}>&yen;{serviceMenu.monthlyPrice.toLocaleString()}</span>
                                                    </li>
                                                    <li className="list-group-item text-sm" style={{ fontSize: 12, height: 30 }} >

                                                        <div className="d-flex ms-a" style={{ margin: "0 0 0 auto" }}>
                                                            {/*<div className="d-flex"><span className="mt-2">開始日:</span><input style={{ width: 150 }} type="text" className="form-control ms-2 me-2" value={servicePeriods[index].startDate} readOnly /></div>
                                                            <div className="d-flex"><span className="mt-2">終了日:</span><input style={{ width: 150 }} type="text" className="form-control ms-2 me-2" value={servicePeriods[index].endDate} readOnly /></div>
                                            */}

                                                            <div className="d-flex me-0" style={{ width: 140 }}>
                                                                <label style={{ width: 60 }} htmlFor="periodStart" className="form-label me-0 p-0 mt-1">開始日:</label>
                                                                <ReactDatePicker
                                                                    className="me-2 p-0 ps-1"
                                                                    style={{ fontSize: 10, width: 10 }}
                                                                    id="periodStart"
                                                                    locale='ja'
                                                                    //placeholderText="選択してください"
                                                                    dateFormat="yyyy/MM/dd"
                                                                    todayButton="今日"
                                                                    onChange={(date) => onChangePeriodStart(date, index)}
                                                                    selected={Date.parse(servicePeriods[index].startDate) ? new Date(servicePeriods[index].startDate) : null}
                                                                    onFocus={e => e.target.blur()} // <--- ソフトウェアキーボード非表示
                                                                    disabled={true}
                                                                />
                                                            </div>
                                                            <div className="d-flex ms-4" style={{ width: 140 }}>
                                                                <label style={{ width: 60 }} htmlFor="periodEnd" className="form-label mt-1">終了日:</label>
                                                                <ReactDatePicker
                                                                    className="me-2 p-0 ps-1"
                                                                    style={{ fontSize: 10, width: 10 }}
                                                                    id="periodEnd"
                                                                    locale='ja'
                                                                    //placeholderText="選択してください"
                                                                    dateFormat="yyyy/MM/dd"
                                                                    todayButton="今日"
                                                                    onChange={(date) => onChangePeriodEnd(date, index)}
                                                                    selected={Date.parse(servicePeriods[index].endDate) ? new Date(servicePeriods[index].endDate) : null}
                                                                    onFocus={e => e.target.blur()} // <--- ソフトウェアキーボード非表示
                                                                    disabled={true}
                                                                />
                                                            </div>

                                                        </div>
                                                    </li>
                                                </>
                                            )
                                            )
                                        }
                                        )}
                                        {0 < totalServiceCount &&
                                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                                <div>
                                                    <h6 className="my-0"><b>振替手数料</b></h6>
                                                </div>
                                                <span><b>&yen;{salonAccount.bankTransferFee.toLocaleString()}</b></span>
                                            </li>
                                        }
                                        <li className="list-group-item d-flex justify-content-between lh-sm  list-group-item-warning">
                                            <div>
                                                <h6 className="my-0"><b>毎月振替金額</b></h6>
                                            </div>
                                            <span><b>&yen;{Number(totalServicePrice).toLocaleString()}</b></span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="row g-3 mt-1">
                                <button className="btn btn-success btn-block mx-auto" type="submit">内容を確認</button>
                            </div>
                        </div>
                    </form>

                </div>
            )}
            <Footer />
        </>
    );
};