import { useNavigate } from "react-router-dom"
import { Header } from "../atoms/Header"
import { Footer } from "../atoms/Footer"
import React, { useState, useContext, useEffect } from "react";
import { useSalonMembers } from "../hooks/useSalonMembers";
import { useSalon } from "../hooks/useSalon";
import { SelectedSalonContext } from "../providers/SelectedSalonProvider";
import { MSalon } from "../API";
import { Loading } from "../atoms/Loading";
import '../css/common.css';

export const StoreMemberRegisterDone = (props) => {

  const { setUser, setAuthState } = props;
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { selectedSalonContext, selectedMemberContext, selectedServiceMenusContext, selectedServicePeriodsContext } = useContext(SelectedSalonContext);
  const { salonMember, getSalonMember } = useSalonMembers();
  const { salonAccount, setSalonAccount } = useSalon();
  const [totalServiceCount, setTotalServiceCount] = useState<number>();
  //  const [selectedMenus, setSelectedMenus] = useState<MService[]>();

  const getData = async () => {

    setSalonAccount(selectedSalonContext as MSalon);

    await getSalonMember(selectedMemberContext.storeId, selectedMemberContext.memberId);

    //setSelectedMenus(selectedServiceMenusContext as MService[]);

    // 登録しているコース数のセット
    setTotalServiceCount(selectedServiceMenusContext.length);

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);

  }

  useEffect(() => {

    getData();

    // リロード時に確認を表示するための設定（リロードすると内容が消えるため）
    window.addEventListener("beforeunload", handleBeforeUnloadEvent);
    return () => window.removeEventListener("beforeunload", handleBeforeUnloadEvent);

  }, [])

  const getMonthlyServicePrice = () => {

    let totalPrice = 0;
    let count = 0;

    /* ワーニング対応MAP削除
    salonMember?.useServices?.items.map((service, index) => {
        totalPrice += Number ( service?.mService.monthlyPrice );
        count += 1;
    })
    */

    for (const service of salonMember?.useServices?.items) {
      totalPrice += Number(service?.mService.monthlyPrice);
      count += 1;
    }

    if (0 < count) {
      totalPrice += salonAccount.bankTransferFee; // 振替手数料
    }

    return totalPrice
  }

  const navigate = useNavigate();
  const OnClickComplete = () => navigate('/StoreMemberAgreement')

  /**
   * リロード時のイベント
   * @param event 
   */
  const handleBeforeUnloadEvent = (event: BeforeUnloadEvent): void => {
    // 表示メッセージのカスタム（GoogleChromeは固定のみで効きません）
    event.returnValue = "ページを離れると入力内容が消えます。このページを離れますか？";
  };


  const getServicePeriod = (serviceId: string) => {
    // selectedServicePeriodsContextからserviceIdをキーにして,servicePeriodを取得する
    const servicePeriod = selectedServicePeriodsContext.find(
      (servicePeriod) => {
        if (servicePeriod) {
          return servicePeriod.serviceId === serviceId
        } else {
          return false;
        }
      }
    );
    if (servicePeriod != null) {
      return servicePeriod;
    }
  }

  return (
    <>
      <Header setUser={setUser} setAuthState={setAuthState} setIsLoading={setIsLoading} />
      {isLoading ? (
        <Loading />
      ) : (
        <div className="container mt-2 mb-5">
          <section className="row">
            <section className="col-12">
              <div className="d-flex page-title-row">
                <header className="d-flex page-title">
                  <h1 className="fs-4 m-1">申込完了</h1>
                </header>
              </div>
            </section>
          </section>
          <div className="row g-3 mt-2">
            <div className="col order-md-last">

              <div className="col-md-12">
                <div className="row">
                  <p className="text-left">以下の内容でお申込みを受け付けました。</p>
                </div>
              </div>

              <div className="container">
                <div className="row">
                  {/*申込者*/}
                  <ul className="list-group mb-3 shadow-sm">
                    <li className="list-group-item d-flex justify-content-between lh-sm">
                      <div>
                        <h6 className="my-0 item-title">お申込者名</h6>
                      </div>
                      <span>{salonMember.memberName}</span>
                    </li>
                  </ul>
                  {/*申込コース*/}
                  <ul className="list-group mb-3 shadow-sm">
                    {salonMember.useServices?.items.sort((a, b) => Number(a.mService.serviceId) > Number(b.mService.serviceId) ? 1 : -1).map((serviceMenu, index) => (
                      <>
                        <li className="list-group-item d-flex justify-content-between lh-sm" key={index}>
                          <div>
                            <h6 className="my-0">
                              <label className="form-check-label" htmlFor={'cource' + index}>{serviceMenu?.mService.courseName}</label>
                            </h6>
                          </div>

                          <span>&yen;{serviceMenu?.mService.monthlyPrice.toLocaleString()}</span>
                        </li>
                        <li className="list-group-item">
                          <p style={{ fontSize: 13 }} className="ms-4 mb-0 text-end">振替開始：{getServicePeriod(serviceMenu?.mService.serviceId)?.startDate}　～　振替終了：{getServicePeriod(serviceMenu?.mService.serviceId)?.endDate}</p>
                        </li>
                      </>
                    ))}
                    {0 < totalServiceCount &&
                      <li className="list-group-item d-flex justify-content-between lh-sm">
                        <div>
                          <h6 className="my-0"><b>振替手数料</b></h6>
                        </div>
                        <span><b>&yen;{salonAccount.bankTransferFee.toLocaleString()}</b></span>
                      </li>
                    }
                    <li className="list-group-item d-flex justify-content-between lh-sm  list-group-item-warning">
                      <div>
                        <h6 className="my-0"><b>毎月振替金額</b></h6>
                      </div>
                      <span><b>&yen;{getMonthlyServicePrice().toLocaleString()}</b></span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-12">
                <div className="row g-3 mt-1">
                  <p className="text-center">ご登録ありがとうございました。</p>
                </div>
              </div>
              <div className="col-md-12">
                <div className="row g-3 mt-1">
                  <button className="btn btn-success btn-block mx-auto" type="button" onClick={OnClickComplete}>戻る</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

