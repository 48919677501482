import { BrowserRouter } from 'react-router-dom';
import { Router } from "./router/Router"
import React, { useState } from 'react';
import './App.css';
//import Amplify, { Auth } from 'aws-amplify';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import { AuthState, onAuthUIStateChange, CognitoUserInterface } from '@aws-amplify/ui-components';

import { SelectedSalonProvider } from "./providers/SelectedSalonProvider"
import { SalonLoginDialog } from './atoms/SalonLoginDialog';

//Amplify.configure(awsconfig);
Amplify.configure({
  ...awsconfig,
  Analytics: {
    disabled: true
  }
})

//console.log(Amplify.configure())

function App(): JSX.Element {
  let [authState, setAuthState] = useState<AuthState>();
  let [user, setUser] = useState<CognitoUserInterface | undefined>();

  React.useEffect(() => {

    // SalonLoginDialogでログイン時にセッションに保存したuser情報を取得する
    let sessionUser = sessionStorage.getItem('user'); // JSON
    let sessionAuthState = sessionStorage.getItem('AuthState'); // JSON
    if ((sessionUser != null) && (sessionAuthState != null)) {
      authState = JSON.parse(sessionAuthState);
      setAuthState(authState);
      user = JSON.parse(sessionUser);
      setUser(user);
    }
    else {
      return onAuthUIStateChange((nextAuthState, authData) => {
        setAuthState(nextAuthState as AuthState);
        setUser(authData as CognitoUserInterface)
      });

    }


  }, []);

  return authState === AuthState.SignedIn && user ? (
    <>
      {/*<div className="App">
        <AmplifySignOut />
  </div>*/}
      <SelectedSalonProvider>
        <BrowserRouter>
          <Router setUser={setUser} setAuthState={setAuthState} />
        </BrowserRouter>
      </SelectedSalonProvider>
    </>
  ) : (
    <>
      <SelectedSalonProvider>
        <SalonLoginDialog setUser={setUser} setAuthState={setAuthState} />
      </SelectedSalonProvider>
      {/*
      <AmplifyAuthenticator>
        <AmplifySignUp
          slot="sign-up"
          formFields={[
            { type: "username" },
            { type: "password" },
            { type: "email" }
          ]}
        />
      </AmplifyAuthenticator>
        */}
    </>
  );
}

process.env.NODE_ENV !== "development" && (console.log = () => { });

export default App;
