import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Footer } from "../../atoms/Footer"
import { Header } from "../../atoms/Header"
import { useCommonTools } from '../../hooks/useCommonTools';
import { useSalonPayment } from '../../hooks/useSalonPayment';
import { TSalonPayment } from '../../API';
import { Loading } from '../../atoms/Loading';
import { BsArrowLeftCircle } from 'react-icons/bs';
import '../../css/common.css';

export const PayCallList = (props) => {

  const { setUser, setAuthState } = props;
  const { getTransferDateString, dateFormatString } = useCommonTools();
  const { getListPaymentByStoreId } = useSalonPayment();

  const defaultYear = getTransferDateString(8, 0).substring(0, 4)

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedYear, setSelectedYear] = useState<string>(defaultYear);
  const [salonPayments, setSalonPayments] = useState<TSalonPayment[]>([]);

  const navigate = useNavigate();
  const storeId = sessionStorage.getItem('selectedSalon.storeId');;

  /**
   * 年ドロップダウンリストに表示する年リスト（現在から過去5年迄）を作成する
   * @returns 
   */
  const getYearList = () => {
    const startYear = new Date().getFullYear();
    const yearList = [...Array(5)].map((x, i) => String(startYear - i))
    return (yearList)
  }
  const [yearList /*, setYearList*/] = useState<string[]>(getYearList());

  /**
   * カードクリックイベント
   * @param paymentId 
   */
  const OnClickDetail = (paymentId: string) => {
    navigate(`/PayCallHistoryDetail?paymentId=${paymentId}`)
  }

  /**
   * 戻るアイコンクリックイベント
   * @returns 
   */
  const OnClickReturn = () => navigate('/MainMenu')

  /**
   * 年ドロップダウンリストを選択し確定した際のイベント
   * @param year 
   */
  const OnClickSetYear = async (year: string) => {

    setIsLoading(true);

    setSelectedYear(year);

    // 対象年の支払い通知書を全件取得する
    const tSalonPayment = await changeListPaymentByStoreIdYear(storeId, year);
    setSalonPayments(tSalonPayment);

    setIsLoading(false);

  }

  /**
   * 指定された年の支払い通知書を全件取得する
   * 
   * @param storeId 取得する店舗ID
   * @param year    取得する年(yyyy)
   */
  const changeListPaymentByStoreIdYear = async (storeId: string, year: string) => {

    var ret = null;

    // 全件取得
    const tSalonPayment = await getListPaymentByStoreId(storeId);
    if ((tSalonPayment == null) || (tSalonPayment.length === 0)) {
      ret = tSalonPayment;
    } else {
      // 年が一致するデータを抽出しtransferDateの降順でソートする
      ret = tSalonPayment.filter(el =>
        (el.transferDate.indexOf(`${year}`) !== -1)
      ).sort(function (a, b) {
        var transferDateA = a.transferDate;
        var transferDateB = b.transferDate;
        if (transferDateA < transferDateB) {
          return -1;
        }
        if (transferDateA > transferDateB) {
          return 1;
        }

        // accountNos must be equal
        return 0;
      });
    }

    return ret;
  }

  // 支払い履歴の初期表示
  const init = async (storeId: string) => {

    // 初期表示する年（最新の年）を取得
    const d = new Date();
    const curYear = d.getFullYear().toString()

    // 対象年の支払い通知書を全件取得する
    const tSalonPayment = await changeListPaymentByStoreIdYear(storeId, curYear);
    setSalonPayments(tSalonPayment);

    setIsLoading(false);
  }

  useEffect(() => {

    let salonJson = sessionStorage.getItem('salonJson'); // JSON
    let salon = null; if (salonJson != null) salon = JSON.parse(salonJson);
    let userId = sessionStorage.getItem('userId');
    if (salon == null || userId == null) {
      //セッション情報も無い（ログアウト状態）
      //ログイン画面に遷移
      navigate('/');
      return;
    }

    init(storeId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header setUser={setUser} setAuthState={setAuthState} setIsLoading={setIsLoading} />
      {isLoading ? (
        <Loading />
      ) : (
        <div className="container mt-2 mb-5">
          <section className="row">
            <section className="col-12 page-title-row">
              <div className="d-flex">
                <button className="btn" onClick={OnClickReturn}><h2 className="icon-gray"><BsArrowLeftCircle /></h2></button>
                <header className="d-flex page-title">
                  <h1 className="fs-4 m-1">支払い通知書</h1>
                </header>
              </div>
            </section>
          </section>

          <section className="row mb-2">
            <section className="col">
              <div className="input-group">
                <div className="dropdown">
                  <button className="btn btn-outline-primary dropdown-toggle" type="button" id="selectYear" data-bs-toggle="dropdown" aria-expanded="false">
                    {selectedYear}
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="selectYear">
                    {yearList.map((year) => (
                      <li>
                        <button className="dropdown-item" type="button" onClick={() => OnClickSetYear(year)}>{year}</button>
                      </li>
                    ))}
                  </ul>
                </div>
                <span className="h4 item-title pt-1">&nbsp;年</span>
              </div>
            </section>
          </section>

          {salonPayments.length > 0 ?
            <div className="row">
              <div className="col">
                {salonPayments.map((salonPayment, index) => (
                  <ul className="list-group mb-3 shadow-sm" onClick={() => OnClickDetail(salonPayment.id)}>
                    <li className="list-group-item d-flex justify-content-between lh-sm paycalllist-list-group">
                      <div>
                        <h6 className="my-0 item-title">振替日付</h6>
                      </div>
                      <span>{dateFormatString(salonPayment.transferDate)}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between lh-sm paycalllist-list-group">
                      <div>
                        <h6 className="my-0 item-title">全体</h6>
                      </div>
                      <span>{salonPayment.totalNumber.toLocaleString()}件　&yen;{salonPayment.totalFee.toLocaleString()}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between lh-sm paycalllist-list-group">
                      <div>
                        <h6 className="my-0 item-title">成功</h6>
                      </div>
                      <span>{salonPayment.totalNumberOfSuccess.toLocaleString()}件　&yen;{salonPayment.totalFeeOfSuccess.toLocaleString()}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between lh-sm paycalllist-list-group">
                      <div>
                        <h6 className="my-0 item-title">失敗</h6>
                      </div>
                      <span>{salonPayment.totalNumberOfFailure.toLocaleString()}件　&yen;{salonPayment.totalFeeOfFailure.toLocaleString()}</span>
                    </li>
                  </ul>
                ))}
              </div>
            </div>
            :
            <div className="row">
              <div className="col">
                <ul className="list-group shadow-sm">
                  <li className="list-group-item d-flex justify-content-center lh-sm paycalllist-no-list-group">
                    <div className="item-title">支払い通知書はありません。</div>
                  </li>
                </ul>
              </div>
            </div>
          }

        </div>
      )}
      <Footer />
    </>
  );
};