import { useState } from "react";
import { listServiceByStoreId } from '../graphql/queries';
import { MService, ListServiceByStoreIdQuery, DeleteMServiceInput, UpdateMServiceInput, CreateMServiceInput } from '../API';

import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import { createMService, deleteMService, updateMService } from "../graphql/mutations";

export const useService = () => {

    const [serviceMenus, setServiceMenus] = useState<MService[]>([]);
    const [serviceMenu, setServiceMenu] = useState<MService>();

    //const [ nextToken, setNextToken] = useState<string>("");
    const [ isLoading, setIsLoading] = useState<boolean>(false);

    const getServiceMenus = async ( storeId :string ) : Promise<MService[]> => {
        const res = (await API.graphql(graphqlOperation(listServiceByStoreId, {
            storeId: `${storeId}`,
            limit: 999999
            //nextToken: nextToken,
        })) as GraphQLResult<ListServiceByStoreIdQuery>);

        console.log(res);
        const mServices = res.data?.ListServiceByStoreId?.items as MService[];
        setServiceMenus( mServices)
        //setNextToken(res.data.ListServiceByStoreId.nextToken);
        //setIsLoading(false);
        return ( mServices );
    }

    const deleteServiceMenu = async ( index : number , menuId : string ) => {

        console.log('deleteServiceMenu');
        const res = (await API.graphql(graphqlOperation(deleteMService, {
            input: { id: `${menuId}` }
        })) as GraphQLResult<DeleteMServiceInput>);

        serviceMenus.splice( index , 1 ) ; 
        console.log(res);
    }
    
    const updateServiceMenu = async ( service : MService ) => {
        const res = (await API.graphql(graphqlOperation(updateMService, {
          input: {
            id: `${service.id}`,
            courseName: `${service.courseName}`,
            monthlyPrice: service.monthlyPrice,
          },
        })) as GraphQLResult<UpdateMServiceInput>);
    
        console.log(res);
        //navigate(`/StoreAccountDetail/${storeAccount.storeId}`);
        //const mAdmin = res.data as CreateMAdminInput ; 
        //setAdmin( mAdmin )
        //setNextToken(res.data.listMAdmins.nextToken);
        //setIsLoading(false);4
    }

    const createServiceMenu = async ( service : MService ) => {
        const res = (await API.graphql(graphqlOperation(createMService, {
            input: {
                storeId: `${service.storeId}` ,
                serviceId: `${service.serviceId}` , 
                courseName: `${service.courseName}` ,
                monthlyPrice: service.monthlyPrice ,
            },
        })) as GraphQLResult<CreateMServiceInput>);

        console.log(res);
    }

    const newServiceMenu = ( storeId : string ) => {
        
        let nextId = 0 
        
        /* ワーニング対応MAP削除
        serviceMenus.map(( service ) => {
            if ( nextId < Number(service.serviceId) ){
                nextId = Number(service.serviceId)
            }
        })
        */

        for ( const service of serviceMenus ){
            if ( nextId < Number(service.serviceId) ){
                nextId = Number(service.serviceId)
            }
        }

        nextId ++;

        return( { ...serviceMenu as MService 
            , storeId : storeId  
            , serviceId : String(nextId)
            , courseName : ""     
        })
    }

    return { serviceMenus , setServiceMenus , serviceMenu , setServiceMenu , isLoading , setIsLoading 
            , getServiceMenus , deleteServiceMenu , updateServiceMenu , createServiceMenu , newServiceMenu };
    
}
