import React, { useContext, useEffect, useState } from "react";
import { Footer } from "../atoms/Footer"
import logo50 from "../images/en_logo_bk_50.png";
import logo60 from "../images/en_logo_bk_60.png";
import { useSalon } from "../hooks/useSalon"
import { SelectedSalonContext } from "../providers/SelectedSalonProvider";
import { Loading } from "../atoms/Loading";
import { useForm } from 'react-hook-form'
import { useCognito } from "../hooks/useCognito";
import { AuthState } from '@aws-amplify/ui-components';
import '../css/common.css';
import { Amplify } from 'aws-amplify';

type Inputs = {
    salonID: string;
    userID: string;
    password: string; 
}

export const SalonLoginDialog = ( props ) => {

    const { setUser , setAuthState } = props ; 

    const {
        register, 
        handleSubmit, 
        formState: { errors }
    } = useForm<Inputs>({
        criteriaMode: "all",
        shouldFocusError: true,
    }); 

    const { signIn } = useCognito() ; 
    const { setSelectedSalonContext, setSelectedPersonelName } = useContext(SelectedSalonContext);
    const { salonAccount, checkPassword, /*isLoading, setIsLoading*/ } = useSalon();

//    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [salonId, setSalonId] = useState<string>("");
    const [userId, setUserId] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [ keepPassword , setKeepPassword ] = useState<boolean>(true) 
    const [isInvalidLogin, setIsInvalidLogin] = useState<boolean>(false);

    useEffect(() => {
        // ローカルストレージに値があれば、それをセットする。
        if ( localStorage.getItem("keepPassword") !== null ){
            const kp = localStorage.getItem("keepPassword");
            setKeepPassword( kp.toLowerCase() === 'true' );
        };
        if ( localStorage.getItem("salonId") !== null ){
            setSalonId(localStorage.getItem("salonId") as string);
        };
        if ( localStorage.getItem("userId") !== null ){
            setUserId(localStorage.getItem("userId") as string);
        };
        if ( localStorage.getItem("password") !== null ){
            setPassword(localStorage.getItem("password") as string);
        };
        setIsLoading( false );
    }, []);

    /**
     * ログインボタン押下
     */
    const onSubmit = async () => {
        //await getSalonAccount( salonId );   
        setIsLoading(true);
        console.log(salonAccount)

        const result = await signIn( userId , password )
        console.log( result ) ;  
        console.log(Amplify.configure())
 
        const mSalon = await checkPassword(salonId, userId, password)
        if ((mSalon.selectedSalon != null ) && ( result != null )) {

            localStorage.setItem("salonId", salonId);
            localStorage.setItem("userId", userId);
            localStorage.setItem("keepPassword" , keepPassword.toString() );

            if ( keepPassword ){
                localStorage.setItem("password", password);
            } else {
                localStorage.setItem("password", "" );
            }

            setSelectedSalonContext(mSalon.selectedSalon);
            let personelName = null;
            switch (mSalon.selectedPersonelIndex) {
                case 1:
                    personelName = mSalon.selectedSalon.personelName1;
                    break;
                case 2:
                    personelName = mSalon.selectedSalon.personelName2;
                    break;
                case 3:
                    personelName = mSalon.selectedSalon.personelName3;
                    break;
            }
            setSelectedPersonelName(personelName)

            // セッションに保存
            sessionStorage.setItem('userId', userId);                       // ログイン担当者ID
            sessionStorage.setItem('salonJson', JSON.stringify(mSalon));    // ログイン中のサロン情報
            //if (personelName == null) personelName = '';
            sessionStorage.setItem('personelName', String(personelName));           // ログイン担当者名
            sessionStorage.setItem('selectedSalon.storeId', String(mSalon.selectedSalon.storeId)); // サロンID
            
            sessionStorage.setItem('user', JSON.stringify(result));
            setUser(result);
            sessionStorage.setItem('AuthState', JSON.stringify(AuthState.SignedIn));
            setAuthState( AuthState.SignedIn );
            
            // 画面に遷移
            //navigate('/MainMenu');
        } else {
            console.log('login err')
            setIsInvalidLogin(true);
        }
        
        setIsLoading(false);
    }

    const onChangeSalonId = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSalonId(event.target.value) 
        //↓iPhoneだと1文字入力すると2文字になってしまうのでコメント
        //setValue('salonID', event.target.value); // 店舗IDにフォーカスがある状態でEnter押下した際、react-hook-formが値を認識出来るようにセットする。→スマホ前提なのでタップするため不要
    };
    const onChangeUserId = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUserId(event.target.value) 
        //↓iPhoneだと1文字入力すると2文字になってしまうのでコメント
        //setValue('userID', event.target.value); // ユーザIDにフォーカスがある状態でEnter押下した際、react-hook-formが値を認識出来るようにセットする。→スマホ前提なのでタップするため不要
    };
    const onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
        //↓iPhoneだと1文字入力すると2文字になってしまうのでコメント
        //setValue('password', event.target.value); // パスワードにフォーカスがある状態でEnter押下した際、react-hook-formが値を認識出来るようにセットする。→スマホ前提なのでタップするため不要
    };

    const onChangeKeepPassword = ( event: React.ChangeEvent<HTMLInputElement> ) => {
        //if (event.target.checked) {
            setKeepPassword( event.target.checked );
        //} else {
        //    setKeepPassword( false );
        //}
    }  

    return (
        <>

            <header>
                <nav className="navbar navbar-expand-md header-custom">
                    <div className="container-fluid">
                        <img src={logo50} alt="EN.Beauty logo" className="img-fluid d-md-none" />
                        <img src={logo60} alt="EN.Beauty logo" className="img-fluid d-none d-md-block me-2" />
                        {/*<span className="m-0 m-md-2 navbar-brand fs-5"> 月 １ 美 容 </span>*/}
                        {/*<div className="collapse navbar-collapse fs-6" id="navbarNav">
                            <!--ログイン画面はnavbarのMENUとログイン情報は表示しない-->
                        </div>*/}
                    </div>
                </nav>
            </header>
            {isLoading ? (
                <Loading />
            ) : (
                <div className="container mt-5">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <section className="row">
                            <section className="col">
                                <header className=" border-bottom mb-5">
                                    <p className="login-title text-center">月 １ 美 容</p>
                                </header>
                            </section>
                        </section>

                        <section className="row g-3">
                            <section className="col">
                                {(errors.salonID?.types?.required || errors.userID?.types?.required || errors.password?.types?.required || isInvalidLogin === true) &&
                                    <div className="col-md-12 text-center error-box">
                                        {errors.salonID?.types?.required && <div>加盟店番号を入力してください</div>}
                                        {errors.userID?.types?.required && <div>ユーザIDを入力してください</div>}
                                        {errors.password?.types?.required && <div>パスワードを入力してください</div>}
                                        {isInvalidLogin === true && <div>ユーザーIDまたはパスワードが正しくありません。</div>}
                                    </div>
                                }
                                <div className="col-md-12">
                                    <div className="row g-3">

                                        <div className="col-2">
                                        </div>
                                        <div className="col-md-8">
                                            {/*<label htmlFor="salonID" className="form-label">店舗ID</label>*/}
                                            <input type="text" {...register('salonID' , { required:true, })} className="form-control shadow-sm " id="salonID" inputMode="numeric" value={salonId} placeholder="加盟店番号" onChange={onChangeSalonId} autoComplete='on' />
                                        </div>
                                        <div className="col-2">
                                        </div>
                                        
                                        <div className="col-2">
                                        </div>
                                        <div className="col-md-8">
                                            {/*<label htmlFor="userID" className="form-label">ユーザID</label>*/}
                                            <input type="text" inputMode="email" {...register('userID' , { required:true, })}  className="form-control shadow-sm " id="userID" value={userId} placeholder="ユーザID" onChange={onChangeUserId} autoComplete='on' />
                                        </div>
                                        <div className="col-2">
                                        </div>
                                        
                                        <div className="col-2">
                                        </div>
                                        <div className="col-md-8">
                                            {/*<label htmlFor="password" className="form-label">パスワード</label>*/}
                                            <input type="password" {...register('password' , { required:true, })} className="form-control shadow-sm " id="password" value={password} placeholder="パスワード" onChange={onChangePassword} autoComplete='on'/>
                                        </div>
                                        <div className="col-2">
                                        </div>

                                        <div className="col-2">
                                        </div>
                                        <div className="col-md-8">
                                            <div className="form-check fs-5">
                                                <input className="form-check-input" type="checkbox" id="keepPassword" onChange={ onChangeKeepPassword}  checked={keepPassword}/>
                                                <label className="form-check-label" htmlFor="keepPassword">パスワードを保存する</label>
                                            </div>
                                        </div>
                                        <div className="row mt-5 mx-0">
                                            <button className="btn btn-primary mx-auto login-button" type="submit">ログイン</button>
                                        </div>
                                        <div className="row mt-4 mx-0 text-center">
                                            <p className="login-message">ユーザID、パスワードをお忘れの方は<br/>担当者までご連絡ください。</p>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </section>
                    </form>
                    <br />
                    <br />
                    <br />
                </div>
            )}

            <Footer />
        </>
    );
};