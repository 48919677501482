import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Footer } from "../../atoms/Footer"
import { Header } from "../../atoms/Header"
//import { Button, Modal } from 'react-bootstrap';
import { useSalonPayment } from '../../hooks/useSalonPayment';
import { TSalonPayment, TBillingData } from '../../API';
import { useBilling } from '../../hooks/useBilling';
//import { inherits } from 'util';
import { useCommonTools } from '../../hooks/useCommonTools';
import { Loading } from '../../atoms/Loading';
import { BsArrowLeftCircle } from 'react-icons/bs';
import '../../css/common.css';

export const PayCallHistoryDetailResult = (props) => {

    const { setUser, setAuthState } = props;
    const { getTransferResultString, dateFormatString } = useCommonTools();
    const { getSalonPayment } = useSalonPayment();
    const { listBillingDataByTransferDateWithStoreId } = useBilling();

    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const transferDate_storeId = query.get('transferDate_StoreId')
    const paymentId = query.get('paymentId')

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [salonPayment, setSalonPayment] = useState<TSalonPayment>();
    const [billingData, setBillingData] = useState<TBillingData[]>([]);
    const [furikaeSelect, setFurikaeSelect] = useState<number>(0);

    const FurikaeFilter = async (type: number) => {
        setIsLoading(true);

        const resultBD = await listBillingDataByTransferDateWithStoreId(transferDate_storeId);

        switch (type) {
            case 1:
                // 振替済み
                setBillingData(resultBD.filter(el => (el.transferResultCode === '0')));
                break;
            case 2:
                // 振替済み以外
                setBillingData(resultBD.filter(el => (el.transferResultCode !== '0')));
                break;
            default:
                // 全て
                setBillingData(resultBD);
                break;
        }
        setFurikaeSelect(type);
        setIsLoading(false);
    }

    const init = async () => {
        const resultSP = await getSalonPayment(paymentId);
        setSalonPayment(resultSP.data.getTSalonPayment);

        const resultBD = await listBillingDataByTransferDateWithStoreId(transferDate_storeId);
        setBillingData(resultBD);
        setIsLoading(false);
    }

    useEffect(() => {
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const navigate = useNavigate();
    const OnClickReturn = () => {
        navigate(`/PayCallHistoryDetail?paymentId=${paymentId}`)
    }
    return (
        <>
            <Header setUser={setUser} setAuthState={setAuthState} setIsLoading={setIsLoading} />
            {isLoading ? (
                <Loading />
            ) : (
                <div className="container mt-2 mb-5">
                    <section className="row">
                        <section className="col-12 page-title-row">
                            <div className="d-flex">
                                <button className="btn" onClick={OnClickReturn}><h2 className="icon-gray"><BsArrowLeftCircle /></h2></button>
                                <header className="d-flex page-title">
                                    <h1 className="fs-4 m-1">支払い通知書　請求明細</h1>
                                </header>
                            </div>
                        </section>
                    </section>

                    <div className="row g-3 mt-2">
                        <div className="col-md-12">
                            <ul className="list-group mb-1 shadow-sm">
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">契約店舗</h6>
                                    </div>
                                    <span>{salonPayment.storeName}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">振替日付</h6>
                                    </div>
                                    <span>{dateFormatString(salonPayment.transferDate)}</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-12">
                            <div className="furikae-select">
                                <div className="row">
                                    <div className={furikaeSelect === 0 ? "col-2 furikae-select-on text-center" : "col-2 furikae-select-off text-center"} onClick={() => FurikaeFilter(0)}>すべて</div>
                                    <div className={furikaeSelect === 1 ? "col-3 furikae-select-on text-center" : "col-3 furikae-select-off text-center"} onClick={() => FurikaeFilter(1)}>振替済み</div>
                                    <div className={furikaeSelect === 2 ? "col-5 furikae-select-on text-center" : "col-5 furikae-select-off text-center"} onClick={() => FurikaeFilter(2)}>振替済み以外</div>
                                </div>
                            </div>

                            {billingData.length === 0 &&
                                <ul className="list-group mb-3 shadow-sm">
                                    <li className="list-group-item justify-content-center">
                                        <div>データはありません</div>
                                    </li>
                                </ul>
                            }
                            {billingData.map((bill, index) => (
                                <ul className="list-group mb-3 shadow-sm">
                                    <li className="list-group-item d-flex justify-content-between lh-sm paycalllist-list-group">
                                        <span className={bill?.transferResultCode === "0" ? "mt-1 furikae-ok" : "mt-1 furikae-ng"}>{getTransferResultString(bill?.transferResultCode).replaceAll("<br/>", " ")}</span>
                                        <div>
                                            <span className="my-0 item-title small">{bill?.salonMember?.memberId}</span>&nbsp;&nbsp;&nbsp;
                                            <span>{bill?.salonMember?.memberName}</span>
                                        </div>
                                    </li>
                                    {/*銀行名と支店名はbillに入ってこないので表示しない
                                <li className="list-group-item d-flex justify-content-between lh-sm paycalllist-list-group">
                                    <h6 className="item-title">({bill.transferBankCode}) {bill.salonMember.bankName} ({bill.transferBankBranchCode}) {bill.salonMember.bankBranchName}</h6>
                                    <span>&yen;{Number(bill.transferAmount).toLocaleString()}</span>
                                </li>
                                */}
                                    <li className="list-group-item d-flex justify-content-between lh-sm paycalllist-list-group">
                                        <div>
                                            <span className="item-title-light">銀行ｺｰﾄﾞ&nbsp;</span><span>{bill?.transferBankCode}</span>&nbsp;<span className="item-title-light">支店ｺｰﾄﾞ&nbsp;</span><span>{bill?.transferBankBranchCode}</span>
                                        </div>
                                        <span>&yen;{Number(bill?.transferAmount).toLocaleString()}</span>
                                    </li>
                                </ul>
                            ))}
                        </div>
                    </div>

                </div>
            )}
            <Footer />
        </>
    );
};