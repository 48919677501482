import { MSalonMember } from "../API";
import { CONFIRM_PAPER_YES } from "../global-define";

export const useCommonTools = () => {

  const getTimeStampString = () => {
    // 日本時間を取得
    const d = new Date();
    // 現在日時からタイムスタンプのファイル名を生成
    //const d = new Date(); // Today
    const DateTimeFormat = 'YYYYMMDD_hhmiss'; // "2019/10/04 12:34:56" -> "20191004_123456"
    let timeStampString = DateTimeFormat
      .replace(/YYYY/g, String(d.getFullYear()))
      .replace(/MM/g, ('0' + (d.getMonth() + 1)).slice(-2))
      .replace(/DD/g, ('0' + d.getDate()).slice(-2))
      .replace(/hh/g, ('0' + d.getHours()).slice(-2))
      .replace(/mi/g, ('0' + d.getMinutes()).slice(-2))
      .replace(/ss/g, ('0' + d.getSeconds()).slice(-2));

    return (timeStampString)
  }

 /**
  * 本日から当月またはオフセット分ずらした振替日の文字列を取得する関数
  * @param   {number} length       - 振替日文字列の文字列の長さ（４または８）
  * @param   {number} monthOffset  - 当月からのオフセット月数
  * @returns {string} 当月またはオフセット分ずらした振替日の文字列 
  */
 const getTransferDateString = (length: number, monthOffSet: number): string => {

  const d = new Date();
  let DateTimeFormat;
  if (length === 4) {
    DateTimeFormat = 'MMDD';
  } else {
    DateTimeFormat = 'YYYYMMDD';
  }

  const thisYear = d.getFullYear();
  const thisMonth = d.getMonth() + 1 ;
  
  // 月の補正
  let transferYear = thisYear;
  let transferDay = 27;
  let transferMonth = thisMonth + monthOffSet ;

  // monthOffsetがマイナス又はプラスの場合の年の繰り上げ処理と月の補正
  
  if (transferMonth < 1) {
    transferYear = thisYear + Math.floor(monthOffSet / 12);
    transferMonth = 12 + ( transferMonth % 12 );
  } else if (transferMonth > 12) {
    transferYear = thisYear + Math.ceil(monthOffSet / 12);
    //transferMonth = transferMonth - 12;
    transferMonth = transferMonth % 12;
  }

//    console.log( "thisMonth:" + thisMonth );

  //    const today = d.getDate();

  // 27日引き落としのみにロジック修正
  //if (today > 15) { // 15日移行は無条件に来月27日
  //  transferDay = 27;
  //  if (thisMonth === 12) { // 12月なら来年１月
  //    transferMonth = 1;
  //    transferYear = thisYear + 1;
  //  } else {
  //    transferMonth = thisMonth + 1;
  //    transferYear = thisYear;
  //  }
  //} else { // 1～14日までは当月27日


  // "2019/10/04 12:34:56" -> "20191004_123456"
  let timeStampString = DateTimeFormat
    .replace(/YYYY/g, String(transferYear))
    .replace(/MM/g, String(transferMonth).padStart(2, "0"))
    .replace(/DD/g, String(transferDay).padStart(2, "0"))

  return (timeStampString);
}

  const getTransferResultString = (code: string) => {
    let result = 'No Message';

    switch (code) {
      case "0": result = "振替済み"
        break;
      case "1": result = "資金不足"
        break;
      case "2": result = "預金取引無し"
        break;
      case "3": result = "振替停止<br/>(預金者都合)"
        break;
      case "4": result = "口座振替<br/>依頼書無し"
        break;
      case "8": result = "振替停止<br/>委託者都合"
        break;
      case "9": result = "その他<br/>請求データエラー"
        break;
    }

    return (result);
  }

  const zenkana2Hankana = (str: string) => {
    var kanaMap = {
      "ガ": "ｶﾞ", "ギ": "ｷﾞ", "グ": "ｸﾞ", "ゲ": "ｹﾞ", "ゴ": "ｺﾞ",
      "ザ": "ｻﾞ", "ジ": "ｼﾞ", "ズ": "ｽﾞ", "ゼ": "ｾﾞ", "ゾ": "ｿﾞ",
      "ダ": "ﾀﾞ", "ヂ": "ﾁﾞ", "ヅ": "ﾂﾞ", "デ": "ﾃﾞ", "ド": "ﾄﾞ",
      "バ": "ﾊﾞ", "ビ": "ﾋﾞ", "ブ": "ﾌﾞ", "ベ": "ﾍﾞ", "ボ": "ﾎﾞ",
      "パ": "ﾊﾟ", "ピ": "ﾋﾟ", "プ": "ﾌﾟ", "ペ": "ﾍﾟ", "ポ": "ﾎﾟ",
      "ヴ": "ｳﾞ", "ヷ": "ﾜﾞ", "ヺ": "ｦﾞ",
      "ア": "ｱ", "イ": "ｲ", "ウ": "ｳ", "エ": "ｴ", "オ": "ｵ",
      "カ": "ｶ", "キ": "ｷ", "ク": "ｸ", "ケ": "ｹ", "コ": "ｺ",
      "サ": "ｻ", "シ": "ｼ", "ス": "ｽ", "セ": "ｾ", "ソ": "ｿ",
      "タ": "ﾀ", "チ": "ﾁ", "ツ": "ﾂ", "テ": "ﾃ", "ト": "ﾄ",
      "ナ": "ﾅ", "ニ": "ﾆ", "ヌ": "ﾇ", "ネ": "ﾈ", "ノ": "ﾉ",
      "ハ": "ﾊ", "ヒ": "ﾋ", "フ": "ﾌ", "ヘ": "ﾍ", "ホ": "ﾎ",
      "マ": "ﾏ", "ミ": "ﾐ", "ム": "ﾑ", "メ": "ﾒ", "モ": "ﾓ",
      "ヤ": "ﾔ", "ユ": "ﾕ", "ヨ": "ﾖ",
      "ラ": "ﾗ", "リ": "ﾘ", "ル": "ﾙ", "レ": "ﾚ", "ロ": "ﾛ",
      "ワ": "ﾜ", "ヲ": "ｦ", "ン": "ﾝ",
      "ァ": "ｧ", "ィ": "ｨ", "ゥ": "ｩ", "ェ": "ｪ", "ォ": "ｫ",
      "ッ": "ｯ", "ャ": "ｬ", "ュ": "ｭ", "ョ": "ｮ",
      "。": "｡", "、": "､", "ー": "ｰ", "「": "｢", "」": "｣", "・": "･",
      "（": "(", "）": ")", "．": ".", "―": "-", "／": "/",
      "０": "0", "１": "1", "２": "2", "３": "3", "４": "4", "５": "5",
      "６": "6", "７": "7", "８": "8", "９": "9",
      "Ａ": "A", "Ｂ": "B", "Ｃ": "C", "Ｄ": "D", "Ｅ": "E",
      "Ｆ": "F", "Ｇ": "G", "Ｈ": "H", "Ｉ": "I", "Ｊ": "J",
      "Ｋ": "K", "Ｌ": "L", "Ｍ": "M", "Ｎ": "N", "Ｏ": "O",
      "Ｐ": "P", "Ｑ": "Q", "Ｒ": "R", "Ｓ": "S", "Ｔ": "T",
      "Ｕ": "U", "Ｖ": "V", "Ｗ": "W", "Ｘ": "X", "Ｙ": "Y",
      "Ｚ": "Z"
    }
    var reg = new RegExp('(' + Object.keys(kanaMap).join('|') + ')', 'g');
    return str
      .replace(reg, function (match) {
        return kanaMap[match];
      })
      .replace(/゛/g, 'ﾞ')
      .replace(/゜/g, 'ﾟ');
  }

  const hira2ZenKana = (str: string) => {
    var kanaMap = {
      "が": "ガ", "ぎ": "ギ", "ぐ": "グ", "げ": "ゲ", "ご": "ゴ",
      "ざ": "ザ", "じ": "ジ", "ず": "ズ", "ぜ": "ゼ", "ぞ": "ゾ",
      "だ": "ダ", "ぢ": "ヂ", "づ": "ヅ", "で": "デ", "ど": "ド",
      "ば": "バ", "び": "ビ", "ぶ": "ブ", "べ": "ベ", "ぼ": "ボ",
      "ぱ": "パ", "ぴ": "ピ", "ぷ": "プ", "ぺ": "ペ", "ぽ": "ポ",
      "ゔ": "ヴ",
      "あ": "ア", "い": "イ", "う": "ウ", "え": "エ", "お": "オ",
      "か": "カ", "き": "キ", "く": "ク", "け": "ケ", "こ": "コ",
      "さ": "サ", "し": "シ", "す": "ス", "せ": "セ", "そ": "ソ",
      "た": "タ", "ち": "チ", "つ": "ツ", "て": "テ", "と": "ト",
      "な": "ナ", "に": "ニ", "ぬ": "ヌ", "ね": "ネ", "の": "ノ",
      "は": "ハ", "ひ": "ヒ", "ふ": "フ", "へ": "ヘ", "ほ": "ホ",
      "ま": "マ", "み": "ミ", "む": "ム", "め": "メ", "も": "モ",
      "や": "ヤ", "ゆ": "ユ", "よ": "ヨ",
      "ら": "ラ", "り": "リ", "る": "ル", "れ": "レ", "ろ": "ロ",
      "わ": "ワ", "を": "ヲ", "ん": "ン",
      "ぁ": "ァ", "ぃ": "ィ", "ぅ": "ゥ", "ぇ": "ェ", "ぉ": "ォ",
      "っ": "ッ", "ゃ": "ャ", "ゅ": "ュ", "ょ": "ョ",
      "ｶﾞ": "ガ", "ｷﾞ": "ギ", "ｸﾞ": "グ", "ｹﾞ": "ゲ", "ｺﾞ": "ゴ",
      "ｻﾞ": "ザ", "ｼﾞ": "ジ", "ｽﾞ": "ズ", "ｾﾞ": "ゼ", "ｿﾞ": "ゾ",
      "ﾀﾞ": "ダ", "ﾁﾞ": "ヂ", "ﾂﾞ": "ヅ", "ﾃﾞ": "デ", "ﾄﾞ": "ド",
      "ﾊﾞ": "バ", "ﾋﾞ": "ビ", "ﾌﾞ": "ブ", "ﾍﾞ": "ベ", "ﾎﾞ": "ボ",
      "ﾊﾟ": "パ", "ﾋﾟ": "ピ", "ﾌﾟ": "プ", "ﾍﾟ": "ペ", "ﾎﾟ": "ポ",
      "ｳﾞ": "ヴ",
      "ｱ": "ア", "ｲ": "イ", "ｳ": "ウ", "ｴ": "エ", "ｵ": "オ",
      "ｶ": "カ", "ｷ": "キ", "ｸ": "ク", "ｹ": "ケ", "ｺ": "コ",
      "ｻ": "サ", "ｼ": "シ", "ｽ": "ス", "ｾ": "セ", "ｿ": "ソ",
      "ﾀ": "タ", "ﾁ": "チ", "ﾂ": "ツ", "ﾃ": "テ", "ﾄ": "ト",
      "ﾅ": "ナ", "ﾆ": "ニ", "ﾇ": "ヌ", "ﾈ": "ネ", "ﾉ": "ノ",
      "ﾊ": "ハ", "ﾋ": "ヒ", "ﾌ": "フ", "ﾍ": "ヘ", "ﾎ": "ホ",
      "ﾏ": "マ", "ﾐ": "ミ", "ﾑ": "ム", "ﾒ": "メ", "ﾓ": "モ",
      "ﾔ": "ヤ", "ﾕ": "ユ", "ﾖ": "ヨ",
      "ﾗ": "ラ", "ﾘ": "リ", "ﾙ": "ル", "ﾚ": "レ", "ﾛ": "ロ",
      "ﾜ": "ワ", "ｦ": "ヲ", "ﾝ": "ン",
      "ｧ": "ァ", "ｨ": "ィ", "ｩ": "ゥ", "ｪ": "ェ", "ｫ": "ォ",
      "ｯ": "ッ", "ｬ": "ャ", "ｭ": "ュ", "ｮ": "ョ"
    }
    var reg = new RegExp('(' + Object.keys(kanaMap).join('|') + ')', 'g');
    return str
      .replace(reg, function (match) {
        return kanaMap[match];
      })
      .replace(/゛/g, 'ﾞ')
      .replace(/゜/g, 'ﾟ');
  }

  const zenNum2HanNum = (str: string) => {
    /*
    var numMap = {
      "０": "0", "１": "1", "２": "2", "３": "3", "４": "4", "５": "5",
      "６": "6", "７": "7", "８": "8", "９": "9",
    }
    var reg = new RegExp('(' + Object.keys(numMap).join('|') + ')', 'g');
    return str
      .replace(reg, function (match) {
        return numMap[match];
      })
      .replace(/゛/g, 'ﾞ')
      .replace(/゜/g, 'ﾟ');
      */
    //console.log( str );

    return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    });
  }


  /**
   * YYYY/MM/DD形式の文字列から年月日数値を取得
   * 
   * @param dateString 日付文字列 
   * 
   */
  const getNumberFromDateString = (dateString: string) => {
    const year = Number(dateString.substring(0, 4));
    const month = Number(dateString.substring(5, 7));
    const day = Number(dateString.substring(8, 10));

    return { year: year, month: month, day: day };
  }

  const getNumberOfTransferDate = () => {

    const dateString = getTransferDateString(8, 0)

    const year = Number(dateString.substring(0, 4));
    const month = Number(dateString.substring(4, 6));
    const day = Number(dateString.substring(6, 8));

    return { year: year, month: month, day: day };
  }

  /**
    * 日付が現在の振替日と同年同月か判定する
    * 
    * @param  { string } dateString 日付文字列 
    * @return { boolean }   True:同月 False:同月以外
    */
  const isValidTransferData = (member: MSalonMember): boolean => {

    let result = false;
    const app = getNumberFromDateString(member.applicationDate)
    const appDate = new Date(app.year, app.month);

    const trans = getNumberOfTransferDate();
    const transDate = new Date(trans.year, trans.month);

    // 紙なら３か月後から有効
    if (member.confirmPaper === CONFIRM_PAPER_YES) {
      appDate.setMonth(appDate.getMonth() + 3)
      if (transDate >= appDate) {
        result = true;
      }
      // それ以外の場合は２ヵ月
    } else {
      appDate.setMonth(appDate.getMonth() + 1)
      if (transDate >= appDate) {
        result = true;
      }
    }

    return (result);
  }


  const isSameMonthAsTransferDate = (member: MSalonMember): boolean => {

    // メンバーの入会日を取得
    const target = getNumberFromDateString(member.applicationDate);
    const transdate = getNumberOfTransferDate();

    if ((target.year === transdate.year)
      && (target.month === transdate.month)) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * yyyy/mm/dd形式の文字列を返します。
   * 
   * @param dateString yyyymmddの文字列
   * @returns yyyy/mm/dd形式の文字列
   */
  const dateFormatString = (dateString: string) => {
    var year = dateString.substring(0, 4);
    var month = dateString.substring(4, 6);
    var day = dateString.substring(6, 8);

    return (year + "/" + month + "/" + day);
  }

  /**
   * 数値文字列を3桁カンマ区切り形式の文字列に変換します。
   * 
   * @param digit 数値の文字列
   * @returns カンマ区切りの文字列
   */
  const localeDigit = (digit: string) => {
    try {
      var num = parseInt(digit, 10);  // （10進数の整数）
      return num.toLocaleString(); // "12,345"
    } catch (error) {
      return digit;
    }
  }
  // PC,iOS,Androidのデバイスタイプを判定する
  const isSp = () => {
    const ua = navigator.userAgent;
    const isAndroid = ua.indexOf('Android') > 0;
    const isiOS = ua.indexOf('iPhone') > 0 || ua.indexOf('iPad') > 0 || ua.indexOf('iPod') > 0;
    const isPC = !isiOS && !isAndroid;
    console.log("isSP ua:" + ua + " isAndroid:" + isAndroid + " isiOS:" + isiOS + " isPC:" + isPC)
    return !isPC;
  }

  return {
    isValidTransferData
    , isSameMonthAsTransferDate
    , getTimeStampString
    , getTransferDateString
    , getTransferResultString
    , zenkana2Hankana
    , zenNum2HanNum
    , hira2ZenKana
    , getNumberFromDateString
    , getNumberOfTransferDate
    , dateFormatString
    , localeDigit
    , isSp
  };
}
