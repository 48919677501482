import { deleteMSalon, updateMSalon, createMSalon } from './../graphql/mutations';
import { listMSalonByClassification, getSalonByStoreId, listMSalons } from './../graphql/queries';
import { GetSalonByStoreIdQuery, MSalon, ListMSalonsQuery, DeleteMSalonInput, UpdateMSalonInput, CreateMSalonInput, ListMSalonByClassificationQuery } from './../API';
import { useState } from "react";

import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import { Amplify } from 'aws-amplify';

export const useSalon = () => {

    const [salonAccount, setSalonAccount] = useState<MSalon>({ __typename: "MSalon", createdAt: "create", updatedAt: "update", storeId: "99999", classification: "00" });
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const initSalonAccount = (storeId: string) => {
        salonAccount.storeId = storeId;
        salonAccount.storeName = "";
        salonAccount.storeAddress1 = "";
        salonAccount.storeAddress2 = "";
        salonAccount.phone = "";
        salonAccount.personelName1 = "";
        salonAccount.personelMail1 = "";
        salonAccount.personelPassword1 = "";
        salonAccount.personelName2 = "";
        salonAccount.personelMail2 = "";
        salonAccount.personelPassword2 = "";
        salonAccount.personelName3 = "";
        salonAccount.personelMail3 = "";
        salonAccount.personelPassword3 = "";
        salonAccount.bankCode = "";
        salonAccount.bankBranchCode = "";
        salonAccount.bankDepositType = "";
        salonAccount.bankAccountNumber = "";
        salonAccount.bankDepositerName = "";
        salonAccount.bankConfirmStatus = "";
        salonAccount.updateDate = "";
        salonAccount.confirmDate = "";
        salonAccount.lastUpdater = "";
        salonAccount.lastUpdateDate = "";
        salonAccount.basicCharge = 0;
        salonAccount.pricePerCase = 0;
        salonAccount.bankTransferFee = 0;
        salonAccount.classification = "";

        setSalonAccount(salonAccount);
    }

    /**
     * サロン情報を取得
     * 
     * @param storeId 全てのサロンでユニークなID（UUIDのidも同じくユニークだが便宜上、短くて使いやすいstoreIdを利用する）
     * @returns storeIdに該当するサロンオブジェクト
     */
    const getSalonAccount = async (storeId: string): Promise<MSalon> => {

        console.log(Amplify.configure())
        var res = null;
        try {
            res = (await API.graphql(graphqlOperation(getSalonByStoreId, {
                storeId: `${storeId}`,
            })) as GraphQLResult<GetSalonByStoreIdQuery>);
               
        } catch (error) {
            // Cognitoの認証ユーザーが居ない情報でログインした場合など。
            console.log(error);
            return null;
        }

        console.log(res);
        const mSalon = res.data?.getSalonByStoreId?.items[0] as MSalon;
        console.log("mSalon");
        console.log(mSalon);
        setSalonAccount(mSalon);
        console.log("salonAccount");
        console.log(salonAccount)
        //setNextToken(res.data.listMAdmins.nextToken);
        //setIsLoading(false);

        return (mSalon);
    }

    // サロン一覧の習得
    const listSalonAccounts = async () => {
        const res = (await API.graphql(graphqlOperation(listMSalons, {
            limit: 999999
        })) as GraphQLResult<ListMSalonsQuery>);

        console.log(res);
        const mSalons = res.data.listMSalons.items as MSalon[];

        return (mSalons);
    }

    type SalonLoginResult = {
        selectedSalon: MSalon | null
        selectedPersonelIndex: Number | null
    }

    /**
     * 店舗側ログイン時のパスワード照会
     */
    const checkPassword = async (storeId: string, inputMail: string, inputPass: string): Promise<SalonLoginResult> => {

        const mSalon = await getSalonAccount(storeId);
        
        console.log('checkPassword')
        console.log(mSalon)
        let ret = null;
        let index = null;

        if ((inputMail === mSalon?.personelMail1) && (inputPass === mSalon?.personelPassword1)) {
            ret = mSalon;
            index = 1
        }
        else if ((inputMail === mSalon?.personelMail2) && (inputPass === mSalon?.personelPassword2)) {
            ret = mSalon;
            index = 2
        }
        else if ((inputMail === mSalon?.personelMail3) && (inputPass === mSalon?.personelPassword3)) {
            ret = mSalon;
            index = 3
        }

        return ({ selectedSalon: ret, selectedPersonelIndex: index });
    }

    /**
     * データ削除
     * @param id 
     */
    const deleteSalonAccount = async (id: string) => {
        console.log('deleteSalonAccount');
        const res = (await API.graphql(graphqlOperation(deleteMSalon, {
            input: { id: `${id}` }
        })) as GraphQLResult<DeleteMSalonInput>);

        console.log(res);
    }

    /**
     * データ更新
     */
    const updateSalonAccount = async () => {
        const res = (await API.graphql(graphqlOperation(updateMSalon, {
            input: {
                id: `${salonAccount.id}`,
                storeId: `${salonAccount.storeId}`,
                storeName: `${salonAccount.storeName}`,
                storeAddress1: `${salonAccount.storeAddress1}`,
                storeAddress2: `${salonAccount.storeAddress2}`,
                phone: `${salonAccount.phone}`,
                personelName1: `${salonAccount.personelName1}`,
                personelMail1: `${salonAccount.personelMail1}`,
                personelPassword1: `${salonAccount.personelPassword1}`,
                personelCognitoUserId1: `${salonAccount.personelCognitoUserId1}`,
                personelName2: `${salonAccount.personelName2}`,
                personelMail2: `${salonAccount.personelMail2}`,
                personelPassword2: `${salonAccount.personelPassword2}`,
                personelCognitoUserId2: `${salonAccount.personelCognitoUserId2}`,
                personelName3: `${salonAccount.personelName3}`,
                personelMail3: `${salonAccount.personelMail3}`,
                personelPassword3: `${salonAccount.personelPassword3}`,
                personelCognitoUserId3: `${salonAccount.personelCognitoUserId3}`,
                bankCode: `${salonAccount.bankCode}`,
                bankBranchCode: `${salonAccount.bankBranchCode}`,
                bankDepositType: `${salonAccount.bankDepositType}`,
                bankAccountNumber: `${salonAccount.bankAccountNumber}`,
                bankDepositerName: `${salonAccount.bankDepositerName}`,
                bankConfirmStatus: `${salonAccount.bankConfirmStatus}`,
                basicCharge: `${salonAccount.basicCharge}`,
                pricePerCase: `${salonAccount.pricePerCase}`,
                bankTransferFee: `${salonAccount.bankTransferFee}`,
                updateDate: `${salonAccount.updateDate}`,
                confirmDate: `${salonAccount.confirmDate}`,
                classification: `${salonAccount.classification}`,
                lastUpdater: `${salonAccount.lastUpdater}`,
                lastUpdateDate: `${salonAccount.lastUpdateDate}`,
            },
        })) as GraphQLResult<UpdateMSalonInput>);

        console.log(res);
    }

    /**
     * 新規の店舗IDを取得する
     * @returns 
     */
    const getNewStoreId = async () => {
        const res = (await API.graphql(graphqlOperation(listMSalons, {
            limit: 999999
        })) as GraphQLResult<ListMSalonsQuery>);

        console.log(res);
        //const mSalons = res?.data?.listMSalons?.items as MSalon[];

        let storeId = 1;
        if (res.data.listMSalons.items.length === 0) {
            // データなし
            ;
        } else {
            // 最大の加盟店番号を取得する
            let data = res.data.listMSalons.items.reduce((a, b) => a.storeId > b.storeId ? a : b);
            if (data == null) {
                // データなし
                ;
            } else {
                // 利用可能な加盟店番号を設定
                storeId = Number(data.storeId) + 1;
            }
        }

        return (storeId.toString().padStart(5, '0'))
    }

    const createSalonAccount = async (cognitoIds: string[]) => {
        const res = (await API.graphql(graphqlOperation(createMSalon, {
            input: {
                //id: `${salonAccount.storeNumber}`,
                storeId: salonAccount.storeId,
                storeName: salonAccount.storeName,
                storeAddress1: salonAccount.storeAddress1,
                storeAddress2: salonAccount.storeAddress2,
                phone: salonAccount.phone,
                personelName1: salonAccount.personelName1,
                personelMail1: salonAccount.personelMail1,
                personelPassword1: salonAccount.personelPassword1,
                personelCognitoUserId1: cognitoIds[0],
                personelName2: salonAccount.personelName2,
                personelMail2: salonAccount.personelMail2,
                personelPassword2: salonAccount.personelPassword2,
                personelCognitoUserId2: cognitoIds[1],
                personelName3: salonAccount.personelName3,
                personelMail3: salonAccount.personelMail3,
                personelPassword3: salonAccount.personelPassword3,
                personelCognitoUserId3: cognitoIds[2],
                bankCode: salonAccount.bankCode,
                bankBranchCode: salonAccount.bankBranchCode,
                bankDepositType: salonAccount.bankDepositType,
                bankAccountNumber: salonAccount.bankAccountNumber,
                bankDepositerName: salonAccount.bankDepositerName,
                bankConfirmStatus: salonAccount.bankConfirmStatus,
                basicCharge: salonAccount.basicCharge,
                pricePerCase: salonAccount.pricePerCase,
                bankTransferFee: salonAccount.bankTransferFee,
                updateDate: salonAccount.updateDate,
                confirmDate: salonAccount.confirmDate,
                classification: salonAccount.classification,
                lastUpdater: salonAccount.lastUpdater,
                lastUpdateDate: salonAccount.lastUpdateDate,
            },
        })) as GraphQLResult<CreateMSalonInput>);

        console.log(res);

    }

    /**
     * 区分コードから区分に該当尾するサロン一覧を取得
     * 
     * @param   {string}    Classification    取得するサロンの区分コード
     * @returns {MSalon[]}  区分コードに該当するサロンの一覧
     */
    const listSalonByClassification = async (Classification: string): Promise<MSalon[]> => {

        try {
            const res = (await API.graphql(graphqlOperation(listMSalonByClassification, {
                classification: Classification,
                limit: 999999
            })) as GraphQLResult<ListMSalonByClassificationQuery>);

            console.log(res);
            const mSalon = res.data?.listMSalonByClassification?.items as MSalon[];
            console.log("mSalon");
            console.log(mSalon);
            return (mSalon);
        } catch (e) {
            console.log(e) ;
        }
    }



    return {
        listSalonByClassification,
        initSalonAccount,
        getSalonAccount,
        salonAccount,
        setSalonAccount,
        listSalonAccounts,
        checkPassword,
        isLoading,
        setIsLoading,
        deleteSalonAccount,
        updateSalonAccount,
        getNewStoreId,
        createSalonAccount
    };

}
