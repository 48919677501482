import React, { useState } from "react";
import { useNavigate , useLocation } from "react-router-dom";
import { Header } from "../atoms/Header"
import { Footer } from "../atoms/Footer"
//mport { SelectedSalonContext } from "../providers/SelectedSalonProvider";
import { useService } from "../hooks/useService";
import { MService, CreateMServiceInput } from "../API";
import { Loading } from "../atoms/Loading";
import { useForm } from 'react-hook-form'
import { Button, Modal} from 'react-bootstrap';
import { BsArrowLeftCircle } from 'react-icons/bs';
import '../css/common.css';

type Inputs = {
    courseName: string;
    monthlyPrice: number;
}

export const StoreServiceMenuAdd = ( props ) => {

    const { setUser, setAuthState } = props;

    const {
        register, 
        handleSubmit, 
        //watch, 
        formState: { errors },
    } = useForm<Inputs>({
        criteriaMode: "all",
        shouldFocusError: true,
    }); 

    //const { selectedServiceContext } = useContext(SelectedSalonContext);
    const { /*serviceMenu, setServiceMenu,*/ isLoading, setIsLoading, createServiceMenu } = useService();
    const navigate = useNavigate();

    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const nextId = query.get("nextId");
    const storeId = sessionStorage.getItem('selectedSalon.storeId');

    const [ serviceMenu , setServiceMenu ] = useState<CreateMServiceInput>(
    {
        storeId : `${storeId}` ,
        serviceId : `${nextId}` ,
        courseName: "" ,
        monthlyPrice: 0
    });
    
    /**
     * 戻るボタン押下
     * @returns 
     */
    const OnClickReturn = () => navigate('/StoreServiceMenuList')

    /**
     * 登録するボタン押下
     */
    const onSubmit = async () => {
        setIsLoading(true);
        await createServiceMenu(serviceMenu as MService);
        navigate('/StoreServiceMenuList');
    }
    
    const onChangeCourseName = (event: { target: { value: any; }; }) => { setServiceMenu({ ...serviceMenu as MService, courseName: event.target.value }); }
    const onChangeMonthlyPrice = (event: { target: { valueAsNumber: any; }; }) => {
        setServiceMenu({ ...serviceMenu as MService, monthlyPrice: event.target.valueAsNumber });
    }

    /**
     * 登録確認モーダルダイアログの制御用
     */
     const [showCreateConfium, setShowCreateConfium] = useState(false);
     const handleCreateConfiumClose = () => setShowCreateConfium(false);
     const handleCreateConfiumShow = () => setShowCreateConfium(true);

    return (
        <>
            <Header setUser={setUser} setAuthState={setAuthState} setIsLoading={setIsLoading}/>
            {isLoading ? (
                <Loading />
            ) : (
                <div className="container mt-2 mb-5">

                    <section className="row">
                        <section className="col-12 page-title-row">
                            <div className="d-flex">
                                <button className="btn" onClick={OnClickReturn}><h2 className="icon-gray"><BsArrowLeftCircle/></h2></button>
                                <header className="d-flex page-title">
                                    <h1 className="fs-4 m-1">サービスメニュー新規登録</h1>
                                </header>
                            </div>
                        </section>
                    </section>
                    <section className="col">
                        <div className="col-md-12">
                            <div className="row g-2">
                                <div className="col-3">
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="courseName" className="form-label item-title required">コース名称</label>
                                    <input type="text" autoComplete="off" {...register('courseName' , { required:true, })} className="form-control shadow-sm" id="courseName" value={String(serviceMenu.courseName)} placeholder="名称" onChange={onChangeCourseName}/>
                                    {errors.courseName?.types?.required && <div className="text-danger">コース名称を入力してください</div>}
                                </div>
                                <div className="col-3">
                                </div>
                                
                                <div className="col-3">
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="monthlyPrice" className="form-label item-title required">月額（税込）</label>
                                    <div className="input-group" style={{width: 300}}>
                                        <span className="input-group-text">&yen;</span>
                                        <input type="number" autoComplete="off" style={{textAlign: 'right'}} {...register('monthlyPrice' , { required:true, min:1, })} className="form-control shadow-sm" id="monthlyPrice" value={Number(serviceMenu.monthlyPrice)} onChange={onChangeMonthlyPrice} placeholder="" />
                                    </div>
                                    {errors.monthlyPrice?.types?.required && <div className="text-danger">必須入力です。</div>}
                                    {errors.monthlyPrice?.types?.min && <div className="text-danger">&yen;1以上で入力してください。</div>}
                                </div>
                                <div className="col-3">
                                </div>

                                <div className="col-3">
                                </div>
                                <div className="col-md-6">
                                    <div className="row g-3 mt-1">
                                        <button className="btn btn-success btn-block mx-auto" type="button" onClick={handleCreateConfiumShow}>登録する</button>
                                    </div>
                                </div>
                                <div className="col-3">
                                </div>

                            </div>
                        </div>

                        {/*登録確認ダイアログ*/}
                        <Modal show={showCreateConfium} onHide={handleCreateConfiumClose}>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Modal.Header closeButton>
                                    <Modal.Title>確認</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>登録します。よろしいですか？</Modal.Body>
                                <Modal.Footer>
                                    <Button className="btn-dialog-cancel" onClick={handleCreateConfiumClose}>いいえ</Button>
                                    <button className="btn btn-dialog-ok m-1 ms-auto" type="submit" onClick={handleCreateConfiumClose}>はい</button>
                                </Modal.Footer>
                            </form>
                        </Modal>

                    </section>
                </div>
            )}
            <Footer />
        </>
    );
};