import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom"
import { Header } from "../atoms/Header"
import { Footer } from "../atoms/Footer"
import { BiPersonFill } from "../atoms/icons/BiPersonFill"
import { BiShieldLockFill } from "../atoms/icons/BiShieldLockFill"
import { BiPencilFill } from "../atoms/icons/BiPencilFill"
import { BiBank2 } from "../atoms/icons/BiBank2"
import { BiBookFill } from "../atoms/icons/BiBookFill"
import { BiDoorClosedFill } from "../atoms/icons/BiDoorClosedFill"
import { SelectedSalonContext } from "../providers/SelectedSalonProvider"
import { MSalon } from './../API';
import { MSalonMember } from '../API';
import { useSalonMember } from '../hooks/useSalonMember';
import { useCommonTools } from '../hooks/useCommonTools';
import { Loading } from "../atoms/Loading";
import { useCognito } from "../hooks/useCognito";
import { AuthState } from '@aws-amplify/ui-components';
import { CONFIRM_PAPER_YES , ANYWHERE_UNCONFIRMED, FORCE_CONFIRM_YES } from '../global-define';
import '../css/common.css';

export const MainMenu = ( props ) => {

    const { setUser, setAuthState } = props;

    const navigate = useNavigate();
    const { selectedSalonContext, selectedPersonelName } = useContext(SelectedSalonContext);
    const [salonAccount, setSalonAccount] = useState<MSalon>({ __typename: "MSalon", createdAt: "create", updatedAt: "update", storeId: "99999" });
    const [personelName, setPersonelName] = useState<String | null>();
    const [isMessage, setIsMessage] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { signOut } = useCognito() ; 
    const { listSalonMembersByConfirmStatus } = useSalonMember();
    const { isValidTransferData  } = useCommonTools();
    const [salonMembers, setSalonMembers] = useState<MSalonMember[]>([]);
    const storeId = sessionStorage.getItem('selectedSalon.storeId');;

    const getData = async() => {
        if (selectedSalonContext == null) {
            let salonJson = sessionStorage.getItem('salonJson'); // JSON
            let salon = null; if (salonJson != null) salon = JSON.parse(salonJson);
            let userId = sessionStorage.getItem('userId');
            let personelName = sessionStorage.getItem('personelName');
            if (salon == null || userId == null) {
                //セッション情報も無い（ログアウト状態）
                //ログイン画面に遷移
                navigate('/');
                return;
            }
            //セッション情報セット
            setSalonAccount(salon.selectedSalon);
            setPersonelName(personelName);
        } else {
            //コンテキスト情報セット
            setSalonAccount(selectedSalonContext);
            setPersonelName(selectedPersonelName);
        }

        /* お知らせ情報取得 */
        if (await getConfirmData() === true) {
            setIsMessage(true); // ←お知らせあるとき
        }

        setIsLoading(false);
    }

    /**
     * 管理のTransferCSVDownload.tsxのinitメソッドに同じ。
     * カード情報のエラーを取得する。
     */
    const getConfirmData = async () => {
        
        // Anywareのエラー者を抽出する
        // ここでのエラーはカードを通していない状態を意味しています。カードの承認エラーはANYWARE端末に通した際に発生するためその時点で解消されています。
        const unconfirmedMembers = await listSalonMembersByConfirmStatus(ANYWHERE_UNCONFIRMED, storeId) as MSalonMember[];
    
        if (unconfirmedMembers !== null) {
            // 対象者から振替用紙チェックとカード強制フラグ設定者を除外する
            const notPaperCheckedUnconfirmedMembers = unconfirmedMembers.filter(member => (member.confirmPaper !== CONFIRM_PAPER_YES) // 振替用紙ならカード確認不要
                && (member.forceConfirm !== FORCE_CONFIRM_YES)
                && (isValidTransferData(member)));
            setSalonMembers(notPaperCheckedUnconfirmedMembers);
            return (notPaperCheckedUnconfirmedMembers.length === 0 ? false : true);
        }
        // 0件
        return false;
    }

    useEffect(() => {
        getData();
    }, []);

    const onClickLogout = async () => {

        setIsLoading( true ) ;
        sessionStorage.clear(); 
        await signOut() ; 
        setUser( null ) ; 
        setAuthState( AuthState.SignedOut )
        setIsLoading( false ) ;
        navigate('/') 
    } 

    return (
        <>
            <Header setUser={setUser} setAuthState={setAuthState} setIsLoading={setIsLoading} />
            {isLoading ? (
                <Loading />
            ) : (
                <div className="container mt-4 mb-5">
                    <section className="row">
                        <section className="col page-title-row">
                            <header className=" border-bottom pb-2 mb-4 d-flex align-items-center">
                                <h1 className="fs-4 m-0">ポータルトップ</h1>
                            </header>
                        </section>
                    </section>

                    <section>
                        <div className="row g-3">
                            <div className="col-md-12">
                                <div>
                                    <p className="menu-welcome mb-3">ようこそ！{personelName}さま</p>
                                </div>
                                <div>
                                    <p className="menu-store-title mb-1">契約店舗</p>
                                </div>
                                <div>
                                    <p className="menu-store-name mb-4">{salonAccount.storeName}</p>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* お知らせ */}
                    { isMessage === false ? (
                        <div className="list-group">
                            <li className="list-group-item menu-message-none">お知らせはありません</li>
                        </div>
                    ) : (
                        <div className="accordion" id="accordionExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                        お知らせがあります
                                    </button>
                                </h2>
                                <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <div className="list-group">
                                            {salonMembers.sort((a, b) => Number(a.memberId) > Number(b.memberId) ? 1 : -1 ).map((salonMember, index) => (
                                                <Link to={`/StoreMemberDetail?memberId=${salonMember.memberId}&back=menu`} className="list-group-item list-group-item-action" style={{height:60}}>
                                                    <div>
                                                        <span className="card-ng" style={{width:100}}>ｶｰﾄﾞ未確認</span> &nbsp;
                                                        <span className="item-title">{salonMember.memberId}</span>&nbsp;
                                                        {salonMember.memberName}&nbsp;様
                                                    </div>
                                                </Link>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* メニュー */}
                    <section className="row mt-3">
                        {/*<h4 className="menu-title mt-4">メニュー</h4>*/}
                        <section className="col">
                            <div className="list-group fs-5 shadow-sm">
                                <Link to="/StoreAccountDetail" className="list-group-item list-group-item-action">
                                    <BiShieldLockFill />
                                    アカウント情報
                                </Link>
                                <Link to="/StoreServiceMenuList" className="list-group-item list-group-item-action">
                                    <BiBookFill />
                                    サービスメニュー管理
                                </Link>
                                <Link to="/StoreMemberList" className="list-group-item list-group-item-action">
                                    <BiPersonFill />
                                    会員管理
                                </Link>
                                <Link to="/StoreMemberAgreement" className="list-group-item list-group-item-action">
                                    <BiPencilFill />
                                    会員新規登録
                                </Link>
                                <Link to="/PayCallList" className="list-group-item list-group-item-action">
                                    <BiBank2 />
                                    支払い通知書
                                </Link>
                                <Link to="/" className="list-group-item list-group-item-action" onClick={onClickLogout}>
                                    <BiDoorClosedFill />
                                    ログアウト
                                </Link>
                            </div>
                        </section>
                    </section>
                </div>
            )}
            <Footer />
        </>
    );
};
