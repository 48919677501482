import { updateTBillingHeader, updateTBillingData, deleteTBillingData, deleteTBillingHeader } from './../graphql/mutations';
import { useCommonTools } from './useCommonTools';
import { useState } from "react";
import { CONSIGNOR_CODE, CONSIGNOR_NAME, TYPE_CODE, CODE_PARTITION } from '../global-define';
import { GraphQLResult } from '@aws-amplify/api';
import { API, graphqlOperation } from 'aws-amplify';
import { BillingByMemberIdQuery, MSalonMember, BillingOrderByDownloadQuery, UpdateTBillingHeaderMutation, TBillingHeader, TBillingData, UpdateTBillingDataMutation, BillingOrderByTransferDateQuery, ListTBillingDataQuery, CreateTBillingDataMutation, ListTBillingDataByTransferDateWithStoreIdQuery, DeleteTBillingDataMutation, DeleteTBillingHeaderMutation, MSalon } from './../API';
import { billingByMemberId, billingOrderByDownload, listTBillingData, listTBillingDataByTransferDateWithStoreId } from '../graphql/queries';
import { createTBillingData, createTBillingHeader } from "../graphql/mutations";
import { BILLING_DATA_DOWNLOADED, BILLING_FIRST, BILLING_OTHER } from '../global-define';
//import { listTBillingData, getBillingByMemberId, billingByMemberId, listTBillingHeaders, billingOrderByDownload, billingOrderByTransferDate } from './../graphql/queries';

export const useBilling = () => {

    const { getTimeStampString, getTransferDateString, zenkana2Hankana } = useCommonTools();

    const [Billings, setBillings] = useState<TBillingData[]>([]);
    //    const [nextToken, setNextToken] = useState<string>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const getBillingHeaderByTransferDate = async (transferYear: string, transferDate: string) => {

        const _query = `query BillingOrderByTransferDate(
            $transferYear: String!
            $transferDate: ModelStringKeyConditionInput
          ) {
            BillingOrderByTransferDate(
              transferYear: $transferYear
              transferDate: $transferDate
            ) {
              items {
                id
                classification
                dataPartition
                typeCode
                codePartition
                consignorCode
                consignorName
                transferYear
                transferDate
                totalNumber
                totalFee
                totalNumberOfSuccess
                totalFeeOfSuccess
                totalNumberOfFailure
                totalFeeOfFailure
                status
                downloadDateTime
                downloadFileName
                uploadDateTime
                uploadFileName
                billingData(limit : 999999 ) {
                  items {
                    id
                    transferDate
                    downloadDateTime
                    lineNo
                    dataPartition
                    transferBankCode
                    transferBankName
                    transferBankBranchCode
                    transferBankBranchName
                    dammy1
                    depositType
                    accountNumber
                    accountHolder
                    transferAmount
                    firstTransferCode
                    customerNumber
                    transferResultCode
                    dammy2
                    salonId
                    memberId
                    transferDate_storeId
                    tBillingHeaderBillingDataId
                    salonMember {
                      id
                      storeId
                      memberId
                      applicationDate
                      memberName
                      furigana
                      dateOfBirth
                      zipCode
                      address1
                      address2
                      phoneNumber
                      phoneNumberMobile
                      mailAddress
                      bankCode
                      bankName
                      bankBranchCode
                      bankBranchName
                      depositType
                      accountNumber
                      depositerName
                      confirmStatus
                      updateDate
                      confirmDate
                      confirmPaper
                      forceConfirm
                      deleteFlag
                      deleteDate
                      lastUpdater
                      lastUpdateDate
                      createdAt
                      updatedAt
                    }
                    createdAt
                    updatedAt
                    mSalonMemberBillingdDataId
                  }
                  nextToken
                }
                headerDammy
                trailerDammy
                trailerPartition
                createdAt
                updatedAt
              }
              nextToken
            }
          }`

        /*
          const res = (await API.graphql(graphqlOperation(billingOrderByTransferDate, {
                transferYear: transferYear,
                transferDate: { eq: transferDate },
                limit: 999999
            })) as GraphQLResult<BillingOrderByTransferDateQuery>);
        */
        // ���s
        try {
            const res = (await API.graphql(
                graphqlOperation(_query, { transferYear: transferYear, transferDate: { eq: transferDate } })
            ) as GraphQLResult<BillingOrderByTransferDateQuery>);
            if (res.data.BillingOrderByTransferDate.items.length === 0) {
                return (null);
            } else {
                return (res.data.BillingOrderByTransferDate.items);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const listBillingByMemberId = async (primaryId: string) => {
        const res = (await API.graphql(graphqlOperation(billingByMemberId, {
            memberId: `${primaryId}`,
            limit: 999999
            //nextToken: nextToken,
        })) as GraphQLResult<BillingByMemberIdQuery>);
        //        })) as GraphQLResult<ListTBillingDataQuery>);

        console.log(res);
        const mBillings = res.data.BillingByMemberId?.items as TBillingData[];
        //        const mBillings = res.data.listTBillingData.items as TBillingData[];

        setBillings(mBillings)
        //setNextToken(res.data.ListBillingByMemberId.nextToken);
        //setIsLoading(false);

        return (res)
    }

    const listTBillingHeaders = async () => {

        const kubunCode = "00";

        const res = (await API.graphql(graphqlOperation(billingOrderByDownload, {
            consignorCode: CONSIGNOR_CODE + kubunCode + "00",
            sortDirection: "DESC",
            limit: 999999
        })) as GraphQLResult<BillingOrderByDownloadQuery>);

        const headers = res.data.BillingOrderByDownload.items;

        return (headers);
    }

    const createBillingData = async (billingHeaderId: string, downloadDateTime: string, lineNo: number, member: MSalonMember, salon: MSalon) => {
        console.log(member);

        let totalPrice = 0;

        if (typeof member.useServices.items !== 'undefined') {
            for (const service of member.useServices.items) {
                totalPrice += service.mService.monthlyPrice
            }
            // �U�֎萔�������Z
            totalPrice += salon.bankTransferFee;
        }

        const accountHolderName = zenkana2Hankana(member.depositerName).padEnd(30)
        let accountNumber = member.accountNumber.padStart(7, '0')

        if (member.bankCode === '9900') { // �X�֋ǂ̏ꍇ
            accountNumber = member.accountNumber.substring(6, 13);
        }
        console.log(`accountNumber:${accountNumber}`);

        const firstTransferCode = getFirstTransferCode(member);

        const res = (await API.graphql(graphqlOperation(createTBillingData, {
            input: {
                transferDate: getTransferDateString(8, 0),
                downloadDateTime: downloadDateTime,
                lineNo: lineNo,
                dataPartition: '2', // �f�[�^�敪(2:�Œ�)
                transferBankCode: member.bankCode.padEnd(4),
                transferBankName: ' '.repeat(15), //member.bankName ��s���̓X�y�[�X,
                transferBankBranchCode: member.bankBranchCode.padEnd(3),
                transferBankBranchName: ' '.repeat(15), // member.bankBranchName,
                dammy1: ' '.repeat(4),
                depositType: member.depositType,
                accountNumber: accountNumber,
                accountHolder: accountHolderName,
                transferAmount: String(totalPrice).padStart(10, '0'),
                firstTransferCode: firstTransferCode,
                customerNumber: `0${CONSIGNOR_CODE.substring(0, 5)}${member.storeId}999${member.memberId}`, // �X�܃R�[�h�Ɖ���R�[�h��999�ŋ�؂�
                transferResultCode: '0',
                dammy2: ' '.repeat(8),
                salonId: salon.id,
                memberId: member.id,
                transferDate_storeId: `${getTransferDateString(8, 0)}_${member.storeId}`,
                tBillingHeaderBillingDataId: billingHeaderId,
                mSalonMemberBillingdDataId: member.id
            },
        })) as GraphQLResult<CreateTBillingDataMutation>);

        //console.log(res);

        return (res);
    }

    const getFirstTransferCode = (member: MSalonMember) => {

        const apDate = new Date(member.applicationDate);
        const apYear = apDate.getFullYear();
        const apMonth = apDate.getMonth();

        const today = new Date();
        const toYear = today.getFullYear();
        const toMonth = today.getMonth();

        let code = BILLING_OTHER; // �����l�͂Q��ڈȍ~
        // �N�������Ő\�����̗����Ȃ珉��������Ƃ�
        if ((toYear === apYear) && ((apMonth + 1) === toMonth)) {
            code = BILLING_FIRST;
        }

        return code;
    }

    const createBillingHeader = async (fileName: string, classification: string) => {

        //const kubunCode = "00" ;

        const res = (await API.graphql(graphqlOperation(createTBillingHeader, {
            input: {
                classification: classification,
                dataPartition: '1',   // �P �i�Œ�) 
                typeCode: TYPE_CODE,       //  91 (�Œ�)
                codePartition: CODE_PARTITION,
                consignorCode: CONSIGNOR_CODE + classification + "00",
                consignorName: CONSIGNOR_NAME.padEnd(40),
                transferYear: getTransferDateString(8, 0).substring(0, 4),
                transferDate: getTransferDateString(4, 0),
                totalNumber: '0'.repeat(6),
                totalFee: '0'.repeat(12),
                totalNumberOfSuccess: '0'.repeat(6),
                totalFeeOfSuccess: '0'.repeat(12),
                totalNumberOfFailure: '0'.repeat(6),
                totalFeeOfFailure: '0'.repeat(12),
                status: BILLING_DATA_DOWNLOADED,
                downloadDateTime: getTimeStampString(),
                downloadFileName: fileName,
                uploadDateTime: '',
                uploadFileName: '',
                headerDammy: ' '.repeat(62),
                trailerDammy: ' '.repeat(65),
                trailerPartition: '8'   // �P �i�Œ�) 
            },
        })) as any);
        /*as GraphQLResult<CreateTBillingHeaderInput>); */

        return (res);
    }

    const updateBillingHeader = async (header: TBillingHeader) => {
        setIsLoading(true);
        const res = (await API.graphql(graphqlOperation(updateTBillingHeader, {
            input: {
                id: header.id,
                dataPartition: header.dataPartition, // �P �i�Œ�) 
                typeCode: header.typeCode,      //  91 (�Œ�)
                codePartition: header.codePartition,
                consignorCode: header.consignorCode,
                consignorName: header.consignorName,
                transferDate: header.transferDate,
                totalNumber: String(header.totalNumber).padStart(6, '0'),
                totalFee: String(header.totalFee).padStart(12, '0'),
                totalNumberOfSuccess: String(header.totalNumberOfSuccess).padStart(6, '0'),
                totalFeeOfSuccess: String(header.totalFeeOfSuccess).padStart(12, '0'),
                totalNumberOfFailure: String(header.totalNumberOfFailure).padStart(6, '0'),
                totalFeeOfFailure: String(header.totalFeeOfFailure).padStart(12, '0'),
                status: header.status,
                downloadDateTime: header.downloadDateTime,
                downloadFileName: header.downloadFileName,
                uploadDateTime: header.uploadDateTime,
                uploadFileName: header.uploadFileName,
                headerDammy: header.headerDammy,
                trailerDammy: header.trailerDammy,
            },
        })) as GraphQLResult<UpdateTBillingHeaderMutation>);

        console.log(res);
        return (res);
    }

    const deleteBillingHeader = async (header: TBillingHeader) => {
        try {
            const res = (await API.graphql(graphqlOperation(deleteTBillingHeader, {
                input: {
                    id: header.id
                }
            })) as GraphQLResult<DeleteTBillingHeaderMutation>);

            console.log(res);
            return (res);
        } catch (e) {
            console.log(e);
            return (e);
        }
    }

    const updateBillingData = async (data: TBillingData) => {
        setIsLoading(true);
        const res = (await API.graphql(graphqlOperation(updateTBillingData, {
            input: {
                id: data.id,
                downloadDateTime: data.downloadDateTime,
                lineNo: data.lineNo,
                dataPartition: data.dataPartition,
                transferBankCode: data.transferBankBranchCode,
                transferBankName: data.transferBankName,
                transferBankBranchCode: data.transferBankBranchCode,
                transferBankBranchName: data.transferBankBranchName,
                depositType: data.depositType,
                accountNumber: data.accountNumber,
                accountHolder: data.accountHolder,
                transferAmount: data.transferAmount,
                firstTransferCode: data.firstTransferCode,
                customerNumber: data.customerNumber,
                transferResultCode: data.transferResultCode,
            },
        })) as GraphQLResult<UpdateTBillingDataMutation>);

        console.log(res);
        return (res);
    }

    const deleteBillingData = async (data: TBillingData) => {
        try {
            const res = (await API.graphql(graphqlOperation(deleteTBillingData, {
                input: {
                    id: data.id
                },
            })) as GraphQLResult<DeleteTBillingDataMutation>);

            console.log(res);
            return (res);
        } catch (e) {
            console.log(e);
            return (e);
        }
    }

    const listBillingData = async (headerId: string) => {
        const res = (await API.graphql(graphqlOperation(listTBillingData, {
            tBillingHeaderBillingDataId: headerId,
            limit: 999999
        })) as GraphQLResult<ListTBillingDataQuery>);
        //        })) as GraphQLResult<ListTBillingDataQuery>);

        console.log(res);
        const billingdata = res.data.listTBillingData.items as TBillingData[];
        //        const mBillings = res.data.listTBillingData.items as TBillingData[];

        return (billingdata);
    }

    const listBillingDataByTransferDate = async (transferDate: string) => {
        const transferYear = transferDate.substring(0, 4);
        const transferDay = transferDate.substring(4, 8);

        const headers = await getBillingHeaderByTransferDate(transferYear, transferDay);

        const billingLists = [];
        for (const header of headers) {
            const data = await listBillingData(header.id);
            billingLists.push(data);
        }

        return (billingLists);
    }

    const listBillingDataByTransferDateWithStoreId = async (transferDate_storeId: string) => {
        const res = (await API.graphql(graphqlOperation(listTBillingDataByTransferDateWithStoreId, {
            transferDate_storeId: transferDate_storeId,
            limit: 999999
        })) as GraphQLResult<ListTBillingDataByTransferDateWithStoreIdQuery>);

        const result = res.data.listTBillingDataByTransferDateWithStoreId.items as TBillingData[];

        return (result);
    }

    return {
        listTBillingHeaders
        , listBillingData
        , listBillingDataByTransferDate
        , listBillingDataByTransferDateWithStoreId
        , getBillingHeaderByTransferDate
        , createBillingHeader
        , updateBillingHeader
        , deleteBillingHeader
        , createBillingData
        , updateBillingData
        , deleteBillingData
        , listBillingByMemberId
        , Billings
        , setBillings
        , isLoading
        , setIsLoading
    }
}


