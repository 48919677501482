import { CreateTServicePeriodMutation, DeleteTServicePeriodMutation, TServicePeriod, UpdateTServicePeriodMutation } from './../API';
import { createTServicePeriod, deleteTServicePeriod, updateTServicePeriod } from './../graphql/mutations';
//import { updateTBillingHeader, updateTBillingData, deleteTBillingData, deleteTBillingHeader } from '../graphql/mutations';
//import { useCommonTools } from './useCommonTools';
//import { CONSIGNOR_CODE, CONSIGNOR_NAME, TYPE_CODE, CODE_PARTITION } from '../global-define';
import { GraphQLResult } from '@aws-amplify/api';
import { API, graphqlOperation } from 'aws-amplify';
import { MSalonMember, /*TBillingData, UpdateTBillingDataMutation, ListTBillingDataQuery, CreateTBillingDataMutation, DeleteTBillingDataMutation,*/ MService } from '../API';
//import { billingByMemberId, billingOrderByDownload, listTBillingData, listTBillingDataByTransferDateWithStoreId, listTServicePeriods } from '../graphql/queries';
//import { createTBillingData, createTBillingHeader } from "../graphql/mutations";
//import { BILLING_DATA_DOWNLOADED, BILLING_FIRST, BILLING_OTHER } from '../global-define';
//import { listTBillingData, getBillingByMemberId, billingByMemberId, listTBillingHeaders, billingOrderByDownload, billingOrderByTransferDate, listTServicePeriods } from './../graphql/queries';

export const useMemberUseServicePeriods = () => {

    //    const { getTimeStampString, getTransferDateString, zenkana2Hankana } = useCommonTools();
    const createServicePeriods = async (member: MSalonMember, services: MService[], servicePeriods: TServicePeriod[]) => {

        //serviceの数だけループしてcreateServicePeriodをコールする
        services.forEach(async (service) => {
            //servicePeriodsからserviceIdの一致するものを探す
            const period = servicePeriods.find((servicePeriod) => {
                if (servicePeriod === undefined) return false;
                return servicePeriod.serviceId === service.serviceId;
            });
            if (period) {
                //一致するものがあれば、createServicePeriodをコールする
                await createServicePeriod(member, period);
            }

        })
    }
    const createServicePeriod = async (member: MSalonMember, servicePeriod: TServicePeriod) => {

        try {
            const res = (await API.graphql(graphqlOperation(createTServicePeriod, {
                input: {
                    serviceId: servicePeriod.serviceId,
                    startDate: servicePeriod.startDate,
                    endDate: servicePeriod.endDate,
                    mSalonMemberUseServicePeriodId: member.id
                },
            })) as GraphQLResult<CreateTServicePeriodMutation>);

            return (res);
        } catch (e) {
            console.log(e);
            return (e);
        }
    }

    const updateServicePeriod = async (servicePeriod: TServicePeriod) => {
        const res = (await API.graphql(graphqlOperation(updateTServicePeriod, {
            input: {
                id: servicePeriod.id,
                startDate: servicePeriod.startDate,
                endDate: servicePeriod.endDate,
            },
        })) as GraphQLResult<UpdateTServicePeriodMutation>);

        console.log(res);
        return (res);
    }

    const deleteServicePeriod = async (data: TServicePeriod) => {
        try {
            const res = (await API.graphql(graphqlOperation(deleteTServicePeriod, {
                input: {
                    id: data.id



                },
            })) as GraphQLResult<DeleteTServicePeriodMutation>);

            console.log(res);
            return (res);
        } catch (e) {
            console.log(e);
            return (e);
        }
    }
    /*
        const listServicePeriods = async (headerId: string) => {
            const res = (await API.graphql(graphqlOperation(listTServicePeriods, {
                tBillingHeaderBillingDataId: headerId,
                limit: 999999
            })) as GraphQLResult<ListTBillingDataQuery>);
            //        })) as GraphQLResult<ListTBillingDataQuery>);
    
            console.log(res);
            const billingdata = res.data.listTBillingData.items as TBillingData[];
            //        const mBillings = res.data.listTBillingData.items as TBillingData[];
    
            return (billingdata);
        }
    */



    return {
        createServicePeriods
        , createServicePeriod
        , updateServicePeriod
        , deleteServicePeriod
    }
}


