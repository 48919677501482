import { Routes , Route } from 'react-router-dom';
//import { Home } from "../Home"
import { MainMenu } from "../pages/MainMenu"
import { StoreAccountDetail } from "../pages/StoreAccountDetail"
import { StoreServiceMenuList } from "../pages/StoreServiceMenuList"
import { StoreServiceMenuAdd } from "../pages/StoreServiceMenuAdd"
import { StoreServiceMenuEdit } from "../pages/StoreServiceMenuEdit"
import { StoreMemberList } from "../pages/StoreMemberList"
import { StoreMemberAgreement } from "../pages/StoreMemberAgreement"
import { StoreMemberDetail } from "../pages/StoreMemberDetail"
import { StoreMemberEdit } from "../pages/StoreMemberEdit"
import { StoreMemberHistory } from "../pages/StoreMemberHistory"
import { StoreMemberRegister } from "../pages/StoreMemberRegister"
import { StoreMemberRegisterConfirm } from "../pages/StoreMemberRegisterConfirm"
import { StoreMemberRegisterDone } from "../pages/StoreMemberRegisterDone"
import { StoreMemberBankEdit } from "../pages/StoreMemberBankEdit"

import { PayCallList } from "../pages/paycall/PayCallList"
import { PayCallHistoryDetail } from "../pages/paycall/PayCallHistoryDetail"
import { PayCallHistoryDetailResult } from "../pages/paycall/PayCallHistoryDetailResult"

import { Page404 } from "../pages/Page404"
import React from 'react';

export const Router = ( props ) => {

    const { setUser , setAuthState } = props ; 

    return (
        <Routes>
            {/* ログイン */}
            <Route path="/" element={<MainMenu setUser={setUser} setAuthState={setAuthState}/>} />
            <Route path="MainMenu" element={<MainMenu setUser={setUser} setAuthState={setAuthState}/>} />
            <Route path="StoreAccountDetail" element={<StoreAccountDetail setUser={setUser} setAuthState={setAuthState}/>} />
            <Route path="StoreServiceMenuList" element={<StoreServiceMenuList setUser={setUser} setAuthState={setAuthState}/>} />
            <Route path="StoreServiceMenuAdd" element={<StoreServiceMenuAdd setUser={setUser} setAuthState={setAuthState}/>} />
            <Route path="StoreServiceMenuEdit" element={<StoreServiceMenuEdit setUser={setUser} setAuthState={setAuthState}/>} />
            <Route path="StoreMemberList" element={<StoreMemberList setUser={setUser} setAuthState={setAuthState}/>} />
            <Route path="StoreMemberDetail" element={<StoreMemberDetail setUser={setUser} setAuthState={setAuthState}/>} />
            <Route path="StoreMemberEdit" element={<StoreMemberEdit setUser={setUser} setAuthState={setAuthState}/>} />
            <Route path="StoreMemberHistory" element={<StoreMemberHistory setUser={setUser} setAuthState={setAuthState}/>} />
            <Route path="StoreMemberAgreement" element={<StoreMemberAgreement setUser={setUser} setAuthState={setAuthState}/>} />
            <Route path="StoreMemberRegister" element={<StoreMemberRegister setUser={setUser} setAuthState={setAuthState}/>} />
            <Route path="StoreMemberRegisterConfirm" element={<StoreMemberRegisterConfirm setUser={setUser} setAuthState={setAuthState}/>} />
            <Route path="StoreMemberRegisterDone" element={<StoreMemberRegisterDone setUser={setUser} setAuthState={setAuthState}/>} />
            <Route path="StoreMemberBankEdit" element={<StoreMemberBankEdit setUser={setUser} setAuthState={setAuthState}/>} />
            {/* 支払い通知書 */}            
            <Route path="PayCallList" element={<PayCallList setUser={setUser} setAuthState={setAuthState}/>} />            
            <Route path="PayCallHistoryDetail" element={<PayCallHistoryDetail setUser={setUser} setAuthState={setAuthState}/>} />            
            <Route path="PayCallHistoryDetailResult" element={<PayCallHistoryDetailResult setUser={setUser} setAuthState={setAuthState}/>} />            
            <Route path="*" element={<Page404 />} />
        </Routes>
    )
}
