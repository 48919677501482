import React, { useContext, useState , useEffect } from "react";
import { useNavigate } from "react-router-dom"
import { Header } from "../atoms/Header"
import { Footer } from "../atoms/Footer"
import { SelectedSalonContext } from "../providers/SelectedSalonProvider";
import { useForm } from 'react-hook-form'
import { BsArrowLeftCircle } from 'react-icons/bs';
import { Loading } from "../atoms/Loading";
import '../css/common.css';
import { MSalon } from "../API";

type Inputs = {
    agree: string;
}

export const StoreMemberAgreement = (props) => {

    const { setUser, setAuthState } = props;

    const {
        register
    } = useForm<Inputs>({
        criteriaMode: "all",
        shouldFocusError: true,
    });

    const { initSelectedMemberContext } = useContext(SelectedSalonContext);
    const [agree, setAgree] = useState<boolean>(false);
    const [btnClass, setBtnClass] = useState<string>("btn btn-agree-danger btn-agree mx-auto");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [termsOfService , setTermsOfService  ] = useState<string>("");

    const navigate = useNavigate();

    /**
     * ページロード時の処理
     */
     useEffect(() => {

        //const storeId = sessionStorage.getItem('selectedSalon.storeId');
        let salonJson = sessionStorage.getItem('salonJson'); // JSON
        if (salonJson != null) {
            const salon = (JSON.parse(salonJson)).selectedSalon as MSalon ;
            if ( salon.termsOfService != null ){
                setTermsOfService(salon.termsOfService);
            }
        }
        //getData(storeId);
        setIsLoading( false ); 
    }, []);



    const onChangeAgree = (event) => {
        setAgree(event.target.checked);
        // on:true off:false
        if (event.target.checked) {
            setBtnClass("btn btn-agree-success btn-agree mx-auto");
        } else {
            setBtnClass("btn btn-agree-danger btn-agree mx-auto");
        }
    }
    const OnClickNext = () => {
        initSelectedMemberContext();
        navigate('/StoreMemberRegister')
    }
    const OnClickReturn = () => navigate('/MainMenu')

    return (
        <>
            <Header setUser={setUser} setAuthState={setAuthState} setIsLoading={setIsLoading} />
            {isLoading ? (
                <Loading />
            ) : (

                <div className="container mt-2 mb-5">
                    <section className="row">
                        <div className="d-flex page-title-row">
                            <button className="btn" onClick={OnClickReturn}><h2 className="icon-gray"><BsArrowLeftCircle /></h2></button>
                            <header className="d-flex page-title">
                                <h1 className="fs-4 m-1">会員規約</h1>
                            </header>
                        </div>
                    </section>

                    <section className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <p className="text-left">会員規約をお読みいただき、同意される方はページ下部の「利用規約の内容に同意します」をタップして登録画面へお進みください。</p>
                            </div>
                        </div>
                    </section>

                    <section className="row mt-2 border" /*style={{ height:100 ,  overflowY: 'scroll' }}*/>
                        <section className="col">
                            {/* <p className="fs-6">第1条〈目的〉</p>
                            <p style={{ fontSize:11 }}>本サブスクリプションサービス通して、良質な美容サービスを定期的に提供することを目的とし、より良い美容環境の実現を目指します。</p>
                            <p className="fs-6">第2条〈提供業務〉</p>
                            <p style={{ fontSize:11 }}>口座振替とする会員メニューは以下のものとする。<br/>
                                                リタッチカラー/グロスカラー/ヘッドスパ/トリートメント/頭皮洗浄/カット/organic</p>
                            <p className="fs-6">第3条〈会員料金〉</p>
                            <p style={{ fontSize:11 }}>リタッチカラー/グロスカラー/ヘッドスパ/トリートメント/頭皮洗浄/カット/organic<br/>
                                                すべて3000円　　＊所定セット割あり
                            </p>
                            <p className="fs-6">第4条〈支払い〉</p>
                            <p style={{ fontSize:11 }}>各会員料金は、毎月27日 にご登録頂いた口座より所定の料金を口座振替させて頂きます。<br/>
                                                なお、土日祝日や営業上での都合により前後することがあります。<br/>
                                                口座振替での支払いは、翌月の利用料に相当します。<br/>
                                                口座振替1回に対して、口座振替手数料として別途100円(税別)を要します。<br/>
                            </p>
                            <p className="fs-6">第5条〈入会/退会/変更〉</p>
                            <p style={{ fontSize:11 }}>
                                入会、退会、変更の申し込みは、月末締めとし、入会/退会/変更届の受理をもって、手続き完了とさせて頂きます。<br/>
                                入会は、入会手続き完了後、翌月27日から口座振替開始となります。<br/>
                                銀行口座登録が用紙による場合に限り、口座振替開始は翌々月となります。<br/>
                                口座振替開始日までの本サービス利用料は、会員価格にて現金でのお支払いとなります。<br/>
                                退会は、退会手続き完了後、翌月末日をもっての退会となります。<br/>
                                退会から1年間、全ての会員メニューへの再入会が不可となります。<br/>
                                変更は、変更手続き完了後、翌月より新しいメニューでのご利用となり、翌月分の利用料は会員価格を現金での支払いとなり、受理月の翌月27日より口座振替となります。<br/>
                            </p>
                            <p className="fs-6">第6条〈入会金、事務手数料〉</p>
                            <p style={{ fontSize:11 }}>入会金は6000円(税別)となります。<br/>
                                本サービス入会申込にあたり、登録事務手数料300円(税別)を要します。
                            </p>
                            <p className="fs-6">第7条〈利用時間〉</p>
                            <p style={{ fontSize:11 }}>平日　 9:00 〜 17:00<br/>
                                                土曜日 9:00 〜 16:00<br/>
                                                上記利用時間外での業務は一切受け付けておりません。<br/>
                            </p>
                            <p className="fs-6">第8条〈変則事項〉</p>
                            <p style={{ fontSize:11 }}>
                                口座振替ができなかった場合、次回来店時に通常料金にて現金でのお支払いとなります。<br/>
                                口座振替が3回以上滞った場合、強制的に退会して頂きます。<br/>
                                いかなる理由も問わず、本サービスをご利用頂けなかったことに対しての払い戻しは、一切受け付け致しません。<br/>
                                6カ月連続して連絡なく来店がなかった場合、自動的に退会扱いとし、払い戻しにも一切応じないものとする。<br/>
                                3回以上無断キャンセルをされたお客様は強制的に退会となります。<br/>
                                迷惑行為により本会員に相応しくないと判断した場合、強制的に退会して頂くことがあります。<br/>
                                過度の遅延が、その後のお客様に影響がでると判断した場合、並びに営業時間内での施術の終了が困難と判断した場合、<br/>
                                遅延されたお客様の予約を取り消しさせて頂くことがあります。
                            </p>
                            <p className="fs-6">第9条〈ご理解とご協力〉</p>
                            <p style={{ fontSize:11 }}>
                                Val organicでは、サブスクリプションサービスを通して、より良い美容環境をお客様に提供することで、<br/>
                                女性美容師が働きやすい職場環境の構築を１つの目標に掲げています。<br/>
                                下記内容をご理解の上、入会の程お願い申し上げます。<br/>
                                家庭、子供を持つ女性によるサロンの運営上、家庭の事情による営業時間の変更、予約の変更をお願いすることがあります。<br/>
                                午前7時の時点での暴風警報、積雪による臨時休業がございます。その際のご予約は、全て無効となりますので予めご了承ください。<br/>
                                *ライン＠からの連絡となります。<br/>
                                当店は、完全予約制により、営業を時間管理の上行っているため、過度な滞在に関しては、常識の範囲でご遠慮ください。<br/>
                                なお、セルフブロースペースは、最大30分までのご利用とさせて頂きます。<br/>
                                臨店講習、勉強会よる臨時休業や営業時間の短縮がございます。<br/>
                                ご利用頂く店舗のライン@への入会を会員条件とさせて頂きます。<br/>
                                サロンからの連絡において、ライン@への入会手続き、並びに携帯・アプリでの不備、不具合などいかなる理由による確認漏れ等の一切の不承知は自己責任とさせて頂きます。<br/>
                                なお、ライン＠への入会なしでサロンをご利用になる際は、連絡の不承知を容認したものとさせて頂きます。<br/>
                                次回予約は、翌月分までとした上で複数予約を不可とし、予約消化後、次回の予約取得が可能となります。<br/>
                                店内ではスタッフの誘導、指示のもと行動ください。勝手な行動は、ご遠慮ください。<br/>
                                当サロン内で使用される設備品に関して、スタッフに指示のもと、適正にご利用ください。<br/>
                                他のお客様のご迷惑にならないよう、店内での携帯電話による通話、並びに携帯等による音声のある視聴はご遠慮ください。<br/>
                                また、その他お客様の迷惑になる行為はご遠慮ください。<br/>
                                今後、本規約内容に変更が生じる際、ライン@での報告となり、改定内容にご承諾いただけない場合は、退会手続きをさせて頂きますので、ご連絡ください。<br/>
                                連絡がない場合は、ご承諾並びにご確認頂いたものとさせて頂きます。<br/>
                            </p>
                            <p className="fs-6">第10条〈駐車場〉</p>
                            <p style={{ fontSize:11 }}>
                            当サロンは、当駐車場内での事故、盗難、トラブルについて一切の責任を負いません。
                            </p> */}
                            <div style={{ fontSize:11 }} dangerouslySetInnerHTML={{ __html: termsOfService }} />
                        </section>

                    </section>
                    <section className="row mb-3 mt-4">
                        <div className="col-12 d-flex align-items-center">
                            <input type="checkbox" {...register('agree', { required: true, })} className="btn-check" id="agree" value="on" onChange={onChangeAgree} /*autoFocus*//>
                            <label className={btnClass} htmlFor="agree">会員規約の内容に同意します</label>
                        </div>
                    </section>
                    <div className="col-md-12">
                        <div className="row g-3 mt-3">
                            <button className="btn btn-success btn-block mx-auto" type="button" onClick={OnClickNext} disabled={!agree}>登録画面に進む</button>
                        </div>
                    </div>

                </div>
            )}
            <Footer />
        </>
    );
};