import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom"
import { Header } from "../atoms/Header"
import { Footer } from "../atoms/Footer"
import { Loading } from "../atoms/Loading";
import { useSalonMembers } from "../hooks/useSalonMembers";
import { useForm } from 'react-hook-form'
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { API, graphqlOperation } from 'aws-amplify';
import { listMemberByStoreId } from '../graphql/queries';
import { GraphQLResult } from '@aws-amplify/api';
import { ListMemberByStoreIdQuery } from '../API';
import { BsArrowLeftCircle } from 'react-icons/bs';
import '../css/common.css';

type Inputs = {
    searchStr: string ;
}

export const StoreMemberList = ( props ) => {

    const { setUser, setAuthState } = props;
    
    const {
        register, 
        handleSubmit
    } = useForm<Inputs>({
        criteriaMode: "all",
        shouldFocusError: true,
    });

    const { search } = useLocation();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    //const { selectedSalonContext , setSelectedMemberContext } = useContext(SelectedSalonContext);
    const { salonMembers, setSalonMembers, getSalonMembers } = useSalonMembers();
    const [searchStr, setSearchStr] = useState<string>('');
//    const [nextToken, setNextToken] = useState<string>('');

    const navigate = useNavigate();

    /**
     * 検索ボタン押下
     */
    const onSubmit = async () => {

        setIsLoading(true);
        console.log(search);
        const storeId = sessionStorage.getItem('selectedSalon.storeId');

        // 登録済みのデータを抽出
        let res = null;
        try {
            res = (await API.graphql(graphqlOperation(listMemberByStoreId, {
                storeId: `${storeId}`,
                limit: 999999,
                //nextToken: nextToken,
            })) as GraphQLResult<ListMemberByStoreIdQuery>);
        } catch (e) {
            if (e instanceof Error) {
                console.log(e.message);
            }
        }
        console.log(res);
        
        if ((res == null) || (res.data?.ListMemberByStoreId?.items.length === 0))
        {
            // データ0件
            ;
        } else {
            if (0 < res.data.ListMemberByStoreId.items.length) {
                // いずれかから部分一致に該当するデータを抽出する
                const mStoreMembers = res.data.ListMemberByStoreId.items.filter(el =>
                    (el.storeId.indexOf(`${searchStr}`) !== -1) ||
                    (el.memberId.indexOf(`${searchStr}`) !== -1) ||
                    (el.applicationDate.indexOf(`${searchStr}`) !== -1) ||
                    (el.memberName.indexOf(`${searchStr}`) !== -1) ||
                    (el.furigana.indexOf(`${searchStr}`) !== -1) ||
                    (el.dateOfBirth.indexOf(`${searchStr}`) !== -1) ||
                    (el.zipCode.indexOf(`${searchStr}`) !== -1) ||
                    (el.address1.indexOf(`${searchStr}`) !== -1) ||
                    (el.address2.indexOf(`${searchStr}`) !== -1) ||
                    (el.phoneNumber.indexOf(`${searchStr}`) !== -1) ||
                    (el.phoneNumberMobile.indexOf(`${searchStr}`) !== -1) ||
                    (el.mailAddress.indexOf(`${searchStr}`) !== -1) ||
                    (el.bankCode.indexOf(`${searchStr}`) !== -1) ||
                    (el.bankBranchCode.indexOf(`${searchStr}`) !== -1) ||
                    (el.depositType.indexOf(`${searchStr}`) !== -1) ||
                    (el.accountNumber.indexOf(`${searchStr}`) !== -1) ||
                    (el.depositerName.indexOf(`${searchStr}`) !== -1) ||
                    (el.confirmStatus.indexOf(`${searchStr}`) !== -1)
                );
                // 会員番号順にソートする
                mStoreMembers.sort(function(a, b) {
                    var memberIdA = a.memberId.toUpperCase(); // 大文字と小文字を無視する
                    var memberIdB = b.memberId.toUpperCase(); // 大文字と小文字を無視する
                    if (memberIdA < memberIdB) {
                        return -1;
                    }
                    if (memberIdA > memberIdB) {
                        return 1;
                    }
                
                    // accountNos must be equal
                    return 0;
                });

                setSalonMembers(mStoreMembers)
                //setNextToken(res.data.ListMemberByStoreId.nextToken);
            }
        }
        
        setIsLoading(false);
    }

    /**
     * 戻るボタン押下
     * @returns 
     */
    const OnClickReturn = () => navigate('/MainMenu')

    const onChangeSearchStr = (event) => setSearchStr(event.target.value);

    const getData = async() => {
        
        const storeId = sessionStorage.getItem('selectedSalon.storeId');
        try{
            await getSalonMembers(String(storeId));
        }catch(e){
            ;
        }

        setIsLoading(false);
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <>
            <Header setUser={setUser} setAuthState={setAuthState} setIsLoading={setIsLoading} />
            {isLoading ? (
                <Loading />
            ) : (
                <div className="container mt-2 mb-5">
                    <section className="row">
                        <section className="col-12 page-title-row">
                            <div className="d-flex">
                                <button className="btn" onClick={OnClickReturn}><h2 className="icon-gray"><BsArrowLeftCircle/></h2></button>
                                <header className="d-flex page-title">
                                    <h1 className="fs-4 m-1">会員</h1>
                                </header>
                            </div>
                        </section>
                    </section>
                    <section className="row">
                        <section className="col-8 col-md-6">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="input-group mb-3">
                                    <input type="text" {...register('searchStr')} className="form-control shadow-none" id="searchStr" value={searchStr} onChange={onChangeSearchStr} placeholder="検索キーワード" aria-label="検索キーワード" aria-describedby="search"/>
                                    <span className="input-group-btn">
                                        <button type="submit" className="btn btn-primary shadow-none" >
                                        <i><FontAwesomeIcon icon={faSearch} cursor='pointer' /></i>
                                        </button>
                                    </span>
                                </div>
                            </form>
                        </section>
                    </section>
                    <section className="row">
                        <section className="col">
                            <div className="float-end m-1 d-flex">
                                <span><b>{salonMembers.length.toLocaleString()}</b><b className="item-title">名</b></span>
                            </div>                    
                        </section>
                    </section>                    
                    <section className="row mb-3">
                        <section className="col">
                            <div className="list-group fs-6 shadow-sm">
                                <li className="list-group-header list-group-item d-flex justify-content-between lh-sm list-group-item-warning">
                                    <div>
                                        <h6 className="mb-0"><b>会員番号</b>　　<b>お名前</b></h6>
                                    </div>
                                </li>
                                {((salonMembers == null) || (salonMembers.length === 0)) &&
                                    <div className="list-group-item d-flex justify-content-between lh-sm list-group-item-light list-group-item-action"><h6>データが登録されていません</h6></div>
                                }
                                {salonMembers.sort((a, b) => Number(a.memberId) > Number(b.memberId) ? 1 : -1 ).map((salonMember, index) => (
                                    <Link to={`/StoreMemberDetail?memberId=${salonMember.memberId}`} className="list-group-item d-flex justify-content-between lh-sm list-group-item-light list-group-item-action" key={index}>
                                        <div>
                                            <span className="mb-0">{salonMember.memberId}　　　{salonMember.memberName}</span>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        </section>
                    </section>
                </div>
            )}
            <Footer />
        </>
    );
};

