import React, { useContext, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Header } from "../atoms/Header"
import { Footer } from "../atoms/Footer"
import { SelectedSalonContext } from "../providers/SelectedSalonProvider";
import { useService } from "../hooks/useService"
import { Loading } from "../atoms/Loading";
import { Button, Modal} from 'react-bootstrap';
// import { MSalon } from './../API';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import '../css/common.css';
import { MAX_SERVICE_NUM } from '../global-define';

export const StoreServiceMenuList = ( props ) => {

    const { setUser, setAuthState } = props;

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const { selectedSalonContext , setSelectedServiceContext } = useContext( SelectedSalonContext );
//    const [ salonAccount , setSalonAccount ] = useState<MSalon>({  __typename:"MSalon", createdAt:"create", updatedAt:"update" , storeId: "99999" });
    const { serviceMenus, getServiceMenus , newServiceMenu } = useService();

    const OnClickAddNew = () => {

        // サービス登録数が上限以内の時のみ追加可能
        if (MAX_SERVICE_NUM <= serviceMenus.length) {
            // サービス登録数上限
            setShowCantAddConfirm(true) ;    //削除不能メッセージを表示
        } else {
            // サービス登録可能

            setSelectedServiceContext( newServiceMenu( String( selectedSalonContext?.storeId ) ) ) ;
            //navigate('/StoreServiceMenuAdd')
            let nextId = 0 

            /* ワーニング対応MAP削除
            serviceMenus.map(( service ) => {
                if ( nextId < Number(service.serviceId) ){
                    nextId = Number(service.serviceId)
                }
            })
            */

            for ( const service of serviceMenus ){
                if ( nextId < Number(service.serviceId) ){
                    nextId = Number(service.serviceId)
                }
            }

            nextId ++;
            navigate(`/StoreServiceMenuAdd?nextId=${nextId.toString()}`) ;
        }
    }
    
    const OnClickReturn = () => navigate('/MainMenu')

    /**
     * サービス追加不能モーダルダイアログの制御用
     */
     const [showCantAddConfirm, setShowCantAddConfirm] = useState(false);
     const handleCantAddConfirmClose = () => setShowCantAddConfirm(false);

    const getData = async () => {
        let selectedSalon = null;
        if (selectedSalonContext == null){
            let salonJson = sessionStorage.getItem('salonJson'); // JSON
            let salon = null; if (salonJson != null) salon = JSON.parse(salonJson);
            let userId = sessionStorage.getItem('userId');
            if (salon == null || userId == null) {
                //セッション情報も無い（ログアウト状態）
                //ログイン画面に遷移
                navigate('/');
                return;
            }
            //セッションを情報セット
            //setSalonAccount(salon.selectedSalon);
            selectedSalon = salon.selectedSalon;
        }else{
            //コンテキスト情報セット
            //setSalonAccount(selectedSalonContext);
            selectedSalon = selectedSalonContext;
        }

        await getServiceMenus(selectedSalon.storeId);

        setIsLoading(false);
    }


    useEffect(() => {
        getData();
    }, []);

    return (
        <>
            <Header setUser={setUser} setAuthState={setAuthState} setIsLoading={setIsLoading}/>
            {isLoading ? (
                <Loading />
            ) : (
                <div className="container mt-2 mb-5">
                    <section className="row">
                        <section className="col-9 page-title-row">
                            <div className="d-flex">
                                <button className="btn" onClick={OnClickReturn}><h2 className="icon-gray"><BsArrowLeftCircle/></h2></button>
                                <header className="d-flex page-title">
                                    <h1 className="fs-4 m-1">サービスメニュー</h1>
                                </header>
                            </div>
                        </section>
                        <section className="col-3">
                            <div className="d-flex mb-0">
                                <button className="btn ms-auto" onClick={OnClickAddNew}><h2 className="icon-gray"><AiOutlinePlusCircle/></h2></button>
                            </div>
                        </section>
                    </section>
                    <section className="row mb-3">
                        <section className="col">
                            <div className="list-group fs-6 shadow-sm">
                                <li className="list-group-header list-group-item d-flex justify-content-between lh-sm list-group-item-warning">
                                    <div>
                                        <h6 className="mb-0"><b>コース名称</b></h6>
                                    </div>
                                    <div>
                                        <h6 className="mb-0"><b>月額(税込み)</b></h6>
                                    </div>
                                </li>
                                {((serviceMenus == null) || (serviceMenus.length === 0)) &&
                                    <div className="list-group-item mb-0 d-flex justify-content-between lh-sm list-group-item-light list-group-item-action"><h6 className="my-0">データが登録されていません</h6></div>
                                }
                                {serviceMenus.sort((a, b) => Number(a.serviceId) > Number(b.serviceId) ? 1 : -1 ).map((serviceMenu, index) => (
                                    <Link to={`/StoreServiceMenuEdit?serviceId=${serviceMenus[index].serviceId}&index=${index}`} className="list-group-item d-flex justify-content-between lh-sm list-group-item-light list-group-item-action" key={index}>
                                        <div>
                                            <span className="mb-0">{serviceMenu.courseName}</span>
                                        </div>
                                        <div>
                                            <span className="mb-0">&yen;{serviceMenu.monthlyPrice.toLocaleString()}</span>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        </section>

                        {/*サービス登録数最大ダイアログ*/}
                        <Modal show={showCantAddConfirm} onHide={handleCantAddConfirmClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>確認</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>サービスメニューが最大の{MAX_SERVICE_NUM}件登録されています。<br/>これ以上サービスメニューは登録できません。</Modal.Body>
                            <Modal.Footer>
                                <Button variant="btn btn-dialog-ok m-1 ms-auto" onClick={handleCantAddConfirmClose}>確認</Button>
                            </Modal.Footer>
                        </Modal>

                    </section>
              </div>
            )}
            <Footer />
        </>
    );
};