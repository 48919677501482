/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const billResultCSVtoDynamoDB = /* GraphQL */ `
  query BillResultCSVtoDynamoDB($fileName: String!) {
    billResultCSVtoDynamoDB(fileName: $fileName)
  }
`;
export const anyWhereCSVtoDynamoDB = /* GraphQL */ `
  query AnyWhereCSVtoDynamoDB($fileName: String!) {
    anyWhereCSVtoDynamoDB(fileName: $fileName)
  }
`;
export const makeTransferCSVData = /* GraphQL */ `
  query MakeTransferCSVData($fileName: String!, $BillingHeaderIds: [String]!) {
    makeTransferCSVData(
      fileName: $fileName
      BillingHeaderIds: $BillingHeaderIds
    )
  }
`;
export const getMAdmin = /* GraphQL */ `
  query GetMAdmin($id: ID!) {
    getMAdmin(id: $id) {
      id
      accountNo
      mail
      name
      contact
      password
      timestamp
      cognitoUserId
      createdAt
      updatedAt
    }
  }
`;
export const listMAdmins = /* GraphQL */ `
  query ListMAdmins(
    $filter: ModelMAdminFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMAdmins(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountNo
        mail
        name
        contact
        password
        timestamp
        cognitoUserId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAdminByIdAndPass = /* GraphQL */ `
  query GetAdminByIdAndPass(
    $mail: String!
    $password: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMAdminFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAdminByIdAndPass(
      mail: $mail
      password: $password
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountNo
        mail
        name
        contact
        password
        timestamp
        cognitoUserId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMSalon = /* GraphQL */ `
  query GetMSalon($id: ID!) {
    getMSalon(id: $id) {
      id
      storeId
      storeName
      storeAddress1
      storeAddress2
      phone
      personelName1
      personelMail1
      personelPassword1
      personelCognitoUserId1
      personelName2
      personelMail2
      personelPassword2
      personelCognitoUserId2
      personelName3
      personelMail3
      personelPassword3
      personelCognitoUserId3
      bankCode
      bankBranchCode
      bankDepositType
      bankAccountNumber
      bankDepositerName
      bankConfirmStatus
      updateDate
      confirmDate
      lastUpdater
      lastUpdateDate
      basicCharge
      pricePerCase
      bankTransferFee
      services {
        items {
          id
          storeId
          serviceId
          courseName
          monthlyPrice
          salonMembers {
            items {
              id
              mServiceID
              mSalonMemberID
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      cognitoUserId
      classification
      termsOfService
      createdAt
      updatedAt
    }
  }
`;
export const listMSalons = /* GraphQL */ `
  query ListMSalons(
    $filter: ModelMSalonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMSalons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        storeId
        storeName
        storeAddress1
        storeAddress2
        phone
        personelName1
        personelMail1
        personelPassword1
        personelCognitoUserId1
        personelName2
        personelMail2
        personelPassword2
        personelCognitoUserId2
        personelName3
        personelMail3
        personelPassword3
        personelCognitoUserId3
        bankCode
        bankBranchCode
        bankDepositType
        bankAccountNumber
        bankDepositerName
        bankConfirmStatus
        updateDate
        confirmDate
        lastUpdater
        lastUpdateDate
        basicCharge
        pricePerCase
        bankTransferFee
        services {
          items {
            id
            storeId
            serviceId
            courseName
            monthlyPrice
            salonMembers {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cognitoUserId
        classification
        termsOfService
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSalonByStoreId = /* GraphQL */ `
  query GetSalonByStoreId(
    $storeId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMSalonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSalonByStoreId(
      storeId: $storeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        storeId
        storeName
        storeAddress1
        storeAddress2
        phone
        personelName1
        personelMail1
        personelPassword1
        personelCognitoUserId1
        personelName2
        personelMail2
        personelPassword2
        personelCognitoUserId2
        personelName3
        personelMail3
        personelPassword3
        personelCognitoUserId3
        bankCode
        bankBranchCode
        bankDepositType
        bankAccountNumber
        bankDepositerName
        bankConfirmStatus
        updateDate
        confirmDate
        lastUpdater
        lastUpdateDate
        basicCharge
        pricePerCase
        bankTransferFee
        services {
          items {
            id
            storeId
            serviceId
            courseName
            monthlyPrice
            salonMembers {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cognitoUserId
        classification
        termsOfService
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listMSalonByClassification = /* GraphQL */ `
  query ListMSalonByClassification(
    $classification: String!
    $storeId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMSalonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMSalonByClassification(
      classification: $classification
      storeId: $storeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        storeId
        storeName
        storeAddress1
        storeAddress2
        phone
        personelName1
        personelMail1
        personelPassword1
        personelCognitoUserId1
        personelName2
        personelMail2
        personelPassword2
        personelCognitoUserId2
        personelName3
        personelMail3
        personelPassword3
        personelCognitoUserId3
        bankCode
        bankBranchCode
        bankDepositType
        bankAccountNumber
        bankDepositerName
        bankConfirmStatus
        updateDate
        confirmDate
        lastUpdater
        lastUpdateDate
        basicCharge
        pricePerCase
        bankTransferFee
        services {
          items {
            id
            storeId
            serviceId
            courseName
            monthlyPrice
            salonMembers {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cognitoUserId
        classification
        termsOfService
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMService = /* GraphQL */ `
  query GetMService($id: ID!) {
    getMService(id: $id) {
      id
      storeId
      serviceId
      courseName
      monthlyPrice
      salonMembers {
        items {
          id
          mServiceID
          mSalonMemberID
          mService {
            id
            storeId
            serviceId
            courseName
            monthlyPrice
            salonMembers {
              nextToken
            }
            createdAt
            updatedAt
          }
          mSalonMember {
            id
            storeId
            memberId
            applicationDate
            memberName
            furigana
            dateOfBirth
            zipCode
            address1
            address2
            phoneNumber
            phoneNumberMobile
            mailAddress
            bankSetDate
            bankCode
            bankName
            bankBranchCode
            bankBranchName
            depositType
            accountNumber
            depositerName
            confirmStatus
            updateDate
            confirmDate
            confirmPaper
            forceConfirm
            deleteFlag
            deleteDate
            lastUpdater
            lastUpdateDate
            useServices {
              nextToken
            }
            useServicePeriod {
              nextToken
            }
            billingdData {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listMServices = /* GraphQL */ `
  query ListMServices(
    $id: ID
    $filter: ModelMServiceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMServices(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        storeId
        serviceId
        courseName
        monthlyPrice
        salonMembers {
          items {
            id
            mServiceID
            mSalonMemberID
            mService {
              id
              storeId
              serviceId
              courseName
              monthlyPrice
              createdAt
              updatedAt
            }
            mSalonMember {
              id
              storeId
              memberId
              applicationDate
              memberName
              furigana
              dateOfBirth
              zipCode
              address1
              address2
              phoneNumber
              phoneNumberMobile
              mailAddress
              bankSetDate
              bankCode
              bankName
              bankBranchCode
              bankBranchName
              depositType
              accountNumber
              depositerName
              confirmStatus
              updateDate
              confirmDate
              confirmPaper
              forceConfirm
              deleteFlag
              deleteDate
              lastUpdater
              lastUpdateDate
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listServiceByStoreId = /* GraphQL */ `
  query ListServiceByStoreId(
    $storeId: ID!
    $serviceId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ListServiceByStoreId(
      storeId: $storeId
      serviceId: $serviceId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        storeId
        serviceId
        courseName
        monthlyPrice
        salonMembers {
          items {
            id
            mServiceID
            mSalonMemberID
            mService {
              id
              storeId
              serviceId
              courseName
              monthlyPrice
              createdAt
              updatedAt
            }
            mSalonMember {
              id
              storeId
              memberId
              applicationDate
              memberName
              furigana
              dateOfBirth
              zipCode
              address1
              address2
              phoneNumber
              phoneNumberMobile
              mailAddress
              bankSetDate
              bankCode
              bankName
              bankBranchCode
              bankBranchName
              depositType
              accountNumber
              depositerName
              confirmStatus
              updateDate
              confirmDate
              confirmPaper
              forceConfirm
              deleteFlag
              deleteDate
              lastUpdater
              lastUpdateDate
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMSalonMember = /* GraphQL */ `
  query GetMSalonMember($id: ID!) {
    getMSalonMember(id: $id) {
      id
      storeId
      memberId
      applicationDate
      memberName
      furigana
      dateOfBirth
      zipCode
      address1
      address2
      phoneNumber
      phoneNumberMobile
      mailAddress
      bankSetDate
      bankCode
      bankName
      bankBranchCode
      bankBranchName
      depositType
      accountNumber
      depositerName
      confirmStatus
      updateDate
      confirmDate
      confirmPaper
      forceConfirm
      deleteFlag
      deleteDate
      lastUpdater
      lastUpdateDate
      useServices {
        items {
          id
          mServiceID
          mSalonMemberID
          mService {
            id
            storeId
            serviceId
            courseName
            monthlyPrice
            salonMembers {
              nextToken
            }
            createdAt
            updatedAt
          }
          mSalonMember {
            id
            storeId
            memberId
            applicationDate
            memberName
            furigana
            dateOfBirth
            zipCode
            address1
            address2
            phoneNumber
            phoneNumberMobile
            mailAddress
            bankSetDate
            bankCode
            bankName
            bankBranchCode
            bankBranchName
            depositType
            accountNumber
            depositerName
            confirmStatus
            updateDate
            confirmDate
            confirmPaper
            forceConfirm
            deleteFlag
            deleteDate
            lastUpdater
            lastUpdateDate
            useServices {
              nextToken
            }
            useServicePeriod {
              nextToken
            }
            billingdData {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      useServicePeriod {
        items {
          id
          serviceId
          startDate
          endDate
          createdAt
          updatedAt
          mSalonMemberUseServicePeriodId
        }
        nextToken
      }
      billingdData {
        items {
          id
          transferDate
          downloadDateTime
          lineNo
          dataPartition
          transferBankCode
          transferBankName
          transferBankBranchCode
          transferBankBranchName
          dammy1
          depositType
          accountNumber
          accountHolder
          transferAmount
          firstTransferCode
          customerNumber
          transferResultCode
          dammy2
          salonId
          memberId
          transferDate_storeId
          tBillingHeaderBillingDataId
          salonMember {
            id
            storeId
            memberId
            applicationDate
            memberName
            furigana
            dateOfBirth
            zipCode
            address1
            address2
            phoneNumber
            phoneNumberMobile
            mailAddress
            bankSetDate
            bankCode
            bankName
            bankBranchCode
            bankBranchName
            depositType
            accountNumber
            depositerName
            confirmStatus
            updateDate
            confirmDate
            confirmPaper
            forceConfirm
            deleteFlag
            deleteDate
            lastUpdater
            lastUpdateDate
            useServices {
              nextToken
            }
            useServicePeriod {
              nextToken
            }
            billingdData {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          mSalonMemberBillingdDataId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listMSalonMembers = /* GraphQL */ `
  query ListMSalonMembers(
    $id: ID
    $filter: ModelMSalonMemberFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMSalonMembers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        storeId
        memberId
        applicationDate
        memberName
        furigana
        dateOfBirth
        zipCode
        address1
        address2
        phoneNumber
        phoneNumberMobile
        mailAddress
        bankSetDate
        bankCode
        bankName
        bankBranchCode
        bankBranchName
        depositType
        accountNumber
        depositerName
        confirmStatus
        updateDate
        confirmDate
        confirmPaper
        forceConfirm
        deleteFlag
        deleteDate
        lastUpdater
        lastUpdateDate
        useServices {
          items {
            id
            mServiceID
            mSalonMemberID
            mService {
              id
              storeId
              serviceId
              courseName
              monthlyPrice
              createdAt
              updatedAt
            }
            mSalonMember {
              id
              storeId
              memberId
              applicationDate
              memberName
              furigana
              dateOfBirth
              zipCode
              address1
              address2
              phoneNumber
              phoneNumberMobile
              mailAddress
              bankSetDate
              bankCode
              bankName
              bankBranchCode
              bankBranchName
              depositType
              accountNumber
              depositerName
              confirmStatus
              updateDate
              confirmDate
              confirmPaper
              forceConfirm
              deleteFlag
              deleteDate
              lastUpdater
              lastUpdateDate
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        useServicePeriod {
          items {
            id
            serviceId
            startDate
            endDate
            createdAt
            updatedAt
            mSalonMemberUseServicePeriodId
          }
          nextToken
        }
        billingdData {
          items {
            id
            transferDate
            downloadDateTime
            lineNo
            dataPartition
            transferBankCode
            transferBankName
            transferBankBranchCode
            transferBankBranchName
            dammy1
            depositType
            accountNumber
            accountHolder
            transferAmount
            firstTransferCode
            customerNumber
            transferResultCode
            dammy2
            salonId
            memberId
            transferDate_storeId
            tBillingHeaderBillingDataId
            salonMember {
              id
              storeId
              memberId
              applicationDate
              memberName
              furigana
              dateOfBirth
              zipCode
              address1
              address2
              phoneNumber
              phoneNumberMobile
              mailAddress
              bankSetDate
              bankCode
              bankName
              bankBranchCode
              bankBranchName
              depositType
              accountNumber
              depositerName
              confirmStatus
              updateDate
              confirmDate
              confirmPaper
              forceConfirm
              deleteFlag
              deleteDate
              lastUpdater
              lastUpdateDate
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            mSalonMemberBillingdDataId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listMemberByStoreId = /* GraphQL */ `
  query ListMemberByStoreId(
    $storeId: ID!
    $memberId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMSalonMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ListMemberByStoreId(
      storeId: $storeId
      memberId: $memberId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        storeId
        memberId
        applicationDate
        memberName
        furigana
        dateOfBirth
        zipCode
        address1
        address2
        phoneNumber
        phoneNumberMobile
        mailAddress
        bankSetDate
        bankCode
        bankName
        bankBranchCode
        bankBranchName
        depositType
        accountNumber
        depositerName
        confirmStatus
        updateDate
        confirmDate
        confirmPaper
        forceConfirm
        deleteFlag
        deleteDate
        lastUpdater
        lastUpdateDate
        useServices {
          items {
            id
            mServiceID
            mSalonMemberID
            mService {
              id
              storeId
              serviceId
              courseName
              monthlyPrice
              createdAt
              updatedAt
            }
            mSalonMember {
              id
              storeId
              memberId
              applicationDate
              memberName
              furigana
              dateOfBirth
              zipCode
              address1
              address2
              phoneNumber
              phoneNumberMobile
              mailAddress
              bankSetDate
              bankCode
              bankName
              bankBranchCode
              bankBranchName
              depositType
              accountNumber
              depositerName
              confirmStatus
              updateDate
              confirmDate
              confirmPaper
              forceConfirm
              deleteFlag
              deleteDate
              lastUpdater
              lastUpdateDate
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        useServicePeriod {
          items {
            id
            serviceId
            startDate
            endDate
            createdAt
            updatedAt
            mSalonMemberUseServicePeriodId
          }
          nextToken
        }
        billingdData {
          items {
            id
            transferDate
            downloadDateTime
            lineNo
            dataPartition
            transferBankCode
            transferBankName
            transferBankBranchCode
            transferBankBranchName
            dammy1
            depositType
            accountNumber
            accountHolder
            transferAmount
            firstTransferCode
            customerNumber
            transferResultCode
            dammy2
            salonId
            memberId
            transferDate_storeId
            tBillingHeaderBillingDataId
            salonMember {
              id
              storeId
              memberId
              applicationDate
              memberName
              furigana
              dateOfBirth
              zipCode
              address1
              address2
              phoneNumber
              phoneNumberMobile
              mailAddress
              bankSetDate
              bankCode
              bankName
              bankBranchCode
              bankBranchName
              depositType
              accountNumber
              depositerName
              confirmStatus
              updateDate
              confirmDate
              confirmPaper
              forceConfirm
              deleteFlag
              deleteDate
              lastUpdater
              lastUpdateDate
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            mSalonMemberBillingdDataId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listMemberByBankInfo = /* GraphQL */ `
  query ListMemberByBankInfo(
    $bankCode: String!
    $bankBranchCode: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMSalonMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ListMemberByBankInfo(
      bankCode: $bankCode
      bankBranchCode: $bankBranchCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        storeId
        memberId
        applicationDate
        memberName
        furigana
        dateOfBirth
        zipCode
        address1
        address2
        phoneNumber
        phoneNumberMobile
        mailAddress
        bankSetDate
        bankCode
        bankName
        bankBranchCode
        bankBranchName
        depositType
        accountNumber
        depositerName
        confirmStatus
        updateDate
        confirmDate
        confirmPaper
        forceConfirm
        deleteFlag
        deleteDate
        lastUpdater
        lastUpdateDate
        useServices {
          items {
            id
            mServiceID
            mSalonMemberID
            mService {
              id
              storeId
              serviceId
              courseName
              monthlyPrice
              createdAt
              updatedAt
            }
            mSalonMember {
              id
              storeId
              memberId
              applicationDate
              memberName
              furigana
              dateOfBirth
              zipCode
              address1
              address2
              phoneNumber
              phoneNumberMobile
              mailAddress
              bankSetDate
              bankCode
              bankName
              bankBranchCode
              bankBranchName
              depositType
              accountNumber
              depositerName
              confirmStatus
              updateDate
              confirmDate
              confirmPaper
              forceConfirm
              deleteFlag
              deleteDate
              lastUpdater
              lastUpdateDate
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        useServicePeriod {
          items {
            id
            serviceId
            startDate
            endDate
            createdAt
            updatedAt
            mSalonMemberUseServicePeriodId
          }
          nextToken
        }
        billingdData {
          items {
            id
            transferDate
            downloadDateTime
            lineNo
            dataPartition
            transferBankCode
            transferBankName
            transferBankBranchCode
            transferBankBranchName
            dammy1
            depositType
            accountNumber
            accountHolder
            transferAmount
            firstTransferCode
            customerNumber
            transferResultCode
            dammy2
            salonId
            memberId
            transferDate_storeId
            tBillingHeaderBillingDataId
            salonMember {
              id
              storeId
              memberId
              applicationDate
              memberName
              furigana
              dateOfBirth
              zipCode
              address1
              address2
              phoneNumber
              phoneNumberMobile
              mailAddress
              bankSetDate
              bankCode
              bankName
              bankBranchCode
              bankBranchName
              depositType
              accountNumber
              depositerName
              confirmStatus
              updateDate
              confirmDate
              confirmPaper
              forceConfirm
              deleteFlag
              deleteDate
              lastUpdater
              lastUpdateDate
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            mSalonMemberBillingdDataId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listMemberByConfirmStatus = /* GraphQL */ `
  query ListMemberByConfirmStatus(
    $confirmStatus: String!
    $storeId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMSalonMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ListMemberByConfirmStatus(
      confirmStatus: $confirmStatus
      storeId: $storeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        storeId
        memberId
        applicationDate
        memberName
        furigana
        dateOfBirth
        zipCode
        address1
        address2
        phoneNumber
        phoneNumberMobile
        mailAddress
        bankSetDate
        bankCode
        bankName
        bankBranchCode
        bankBranchName
        depositType
        accountNumber
        depositerName
        confirmStatus
        updateDate
        confirmDate
        confirmPaper
        forceConfirm
        deleteFlag
        deleteDate
        lastUpdater
        lastUpdateDate
        useServices {
          items {
            id
            mServiceID
            mSalonMemberID
            mService {
              id
              storeId
              serviceId
              courseName
              monthlyPrice
              createdAt
              updatedAt
            }
            mSalonMember {
              id
              storeId
              memberId
              applicationDate
              memberName
              furigana
              dateOfBirth
              zipCode
              address1
              address2
              phoneNumber
              phoneNumberMobile
              mailAddress
              bankSetDate
              bankCode
              bankName
              bankBranchCode
              bankBranchName
              depositType
              accountNumber
              depositerName
              confirmStatus
              updateDate
              confirmDate
              confirmPaper
              forceConfirm
              deleteFlag
              deleteDate
              lastUpdater
              lastUpdateDate
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        useServicePeriod {
          items {
            id
            serviceId
            startDate
            endDate
            createdAt
            updatedAt
            mSalonMemberUseServicePeriodId
          }
          nextToken
        }
        billingdData {
          items {
            id
            transferDate
            downloadDateTime
            lineNo
            dataPartition
            transferBankCode
            transferBankName
            transferBankBranchCode
            transferBankBranchName
            dammy1
            depositType
            accountNumber
            accountHolder
            transferAmount
            firstTransferCode
            customerNumber
            transferResultCode
            dammy2
            salonId
            memberId
            transferDate_storeId
            tBillingHeaderBillingDataId
            salonMember {
              id
              storeId
              memberId
              applicationDate
              memberName
              furigana
              dateOfBirth
              zipCode
              address1
              address2
              phoneNumber
              phoneNumberMobile
              mailAddress
              bankSetDate
              bankCode
              bankName
              bankBranchCode
              bankBranchName
              depositType
              accountNumber
              depositerName
              confirmStatus
              updateDate
              confirmDate
              confirmPaper
              forceConfirm
              deleteFlag
              deleteDate
              lastUpdater
              lastUpdateDate
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            mSalonMemberBillingdDataId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTServicePeriod = /* GraphQL */ `
  query GetTServicePeriod($id: ID!) {
    getTServicePeriod(id: $id) {
      id
      serviceId
      startDate
      endDate
      createdAt
      updatedAt
      mSalonMemberUseServicePeriodId
    }
  }
`;
export const listTServicePeriods = /* GraphQL */ `
  query ListTServicePeriods(
    $filter: ModelTServicePeriodFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTServicePeriods(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        serviceId
        startDate
        endDate
        createdAt
        updatedAt
        mSalonMemberUseServicePeriodId
      }
      nextToken
    }
  }
`;
export const getMSystemCharge = /* GraphQL */ `
  query GetMSystemCharge($id: ID!) {
    getMSystemCharge(id: $id) {
      id
      basicCharge
      pricePerCase
      bankTransferFee
      createdAt
      updatedAt
    }
  }
`;
export const listMSystemCharges = /* GraphQL */ `
  query ListMSystemCharges(
    $filter: ModelMSystemChargeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMSystemCharges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        basicCharge
        pricePerCase
        bankTransferFee
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMBank = /* GraphQL */ `
  query GetMBank($id: ID!) {
    getMBank(id: $id) {
      id
      bankName
      bankRyaku
      bankKana
      branchName
      branchKana
      bankCode
      branchCode
      updateDate
      confirmDate
      lastUpdater
      lastUpdateDate
      createdAt
      updatedAt
    }
  }
`;
export const listMBanks = /* GraphQL */ `
  query ListMBanks(
    $filter: ModelMBankFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMBanks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        bankName
        bankRyaku
        bankKana
        branchName
        branchKana
        bankCode
        branchCode
        updateDate
        confirmDate
        lastUpdater
        lastUpdateDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTAnyWhereHead = /* GraphQL */ `
  query GetTAnyWhereHead($id: ID!, $uploadDateTime: ID!) {
    getTAnyWhereHead(id: $id, uploadDateTime: $uploadDateTime) {
      id
      uploadDateTime
      fileName
      dataCount
      errorCount
      errorMessage
      details {
        items {
          uploadDateTime
          lineNo
          transactionId
          deviceNo
          companyCode
          merchantNumber
          merchantName
          messageType
          voucherNumber
          originalVoucherNumber
          MPNTID
          errorCode
          institutionCode
          institutionName
          consignorKana
          consignorNumber
          bankName
          bankCode
          bankBranchCode
          accountNumber
          depositType
          consignorCode
          consignorName
          transferOkNumber
          deviceType
          processingDate
          handlingTime
          accountHolder
          attachedInformation1
          attachedInformation2
          attachedInformation3
          attachedInformation4
          result
          salonMember {
            id
            storeId
            memberId
            applicationDate
            memberName
            furigana
            dateOfBirth
            zipCode
            address1
            address2
            phoneNumber
            phoneNumberMobile
            mailAddress
            bankSetDate
            bankCode
            bankName
            bankBranchCode
            bankBranchName
            depositType
            accountNumber
            depositerName
            confirmStatus
            updateDate
            confirmDate
            confirmPaper
            forceConfirm
            deleteFlag
            deleteDate
            lastUpdater
            lastUpdateDate
            useServices {
              nextToken
            }
            useServicePeriod {
              nextToken
            }
            billingdData {
              nextToken
            }
            createdAt
            updatedAt
          }
          tAnyWhereHeadDetailsId
          tAnyWhereHeadDetailsUploadDateTime
          tAnyWhereDetailSalonMemberId
        }
        nextToken
      }
    }
  }
`;
export const listTAnyWhereHeads = /* GraphQL */ `
  query ListTAnyWhereHeads(
    $id: ID
    $uploadDateTime: ModelIDKeyConditionInput
    $filter: ModelTAnyWhereHeadFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTAnyWhereHeads(
      id: $id
      uploadDateTime: $uploadDateTime
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        uploadDateTime
        fileName
        dataCount
        errorCount
        errorMessage
        details {
          items {
            uploadDateTime
            lineNo
            transactionId
            deviceNo
            companyCode
            merchantNumber
            merchantName
            messageType
            voucherNumber
            originalVoucherNumber
            MPNTID
            errorCode
            institutionCode
            institutionName
            consignorKana
            consignorNumber
            bankName
            bankCode
            bankBranchCode
            accountNumber
            depositType
            consignorCode
            consignorName
            transferOkNumber
            deviceType
            processingDate
            handlingTime
            accountHolder
            attachedInformation1
            attachedInformation2
            attachedInformation3
            attachedInformation4
            result
            salonMember {
              id
              storeId
              memberId
              applicationDate
              memberName
              furigana
              dateOfBirth
              zipCode
              address1
              address2
              phoneNumber
              phoneNumberMobile
              mailAddress
              bankSetDate
              bankCode
              bankName
              bankBranchCode
              bankBranchName
              depositType
              accountNumber
              depositerName
              confirmStatus
              updateDate
              confirmDate
              confirmPaper
              forceConfirm
              deleteFlag
              deleteDate
              lastUpdater
              lastUpdateDate
              createdAt
              updatedAt
            }
            tAnyWhereHeadDetailsId
            tAnyWhereHeadDetailsUploadDateTime
            tAnyWhereDetailSalonMemberId
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTAnyWhereDetail = /* GraphQL */ `
  query GetTAnyWhereDetail($uploadDateTime: ID!, $lineNo: Int!) {
    getTAnyWhereDetail(uploadDateTime: $uploadDateTime, lineNo: $lineNo) {
      uploadDateTime
      lineNo
      transactionId
      deviceNo
      companyCode
      merchantNumber
      merchantName
      messageType
      voucherNumber
      originalVoucherNumber
      MPNTID
      errorCode
      institutionCode
      institutionName
      consignorKana
      consignorNumber
      bankName
      bankCode
      bankBranchCode
      accountNumber
      depositType
      consignorCode
      consignorName
      transferOkNumber
      deviceType
      processingDate
      handlingTime
      accountHolder
      attachedInformation1
      attachedInformation2
      attachedInformation3
      attachedInformation4
      result
      salonMember {
        id
        storeId
        memberId
        applicationDate
        memberName
        furigana
        dateOfBirth
        zipCode
        address1
        address2
        phoneNumber
        phoneNumberMobile
        mailAddress
        bankSetDate
        bankCode
        bankName
        bankBranchCode
        bankBranchName
        depositType
        accountNumber
        depositerName
        confirmStatus
        updateDate
        confirmDate
        confirmPaper
        forceConfirm
        deleteFlag
        deleteDate
        lastUpdater
        lastUpdateDate
        useServices {
          items {
            id
            mServiceID
            mSalonMemberID
            mService {
              id
              storeId
              serviceId
              courseName
              monthlyPrice
              createdAt
              updatedAt
            }
            mSalonMember {
              id
              storeId
              memberId
              applicationDate
              memberName
              furigana
              dateOfBirth
              zipCode
              address1
              address2
              phoneNumber
              phoneNumberMobile
              mailAddress
              bankSetDate
              bankCode
              bankName
              bankBranchCode
              bankBranchName
              depositType
              accountNumber
              depositerName
              confirmStatus
              updateDate
              confirmDate
              confirmPaper
              forceConfirm
              deleteFlag
              deleteDate
              lastUpdater
              lastUpdateDate
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        useServicePeriod {
          items {
            id
            serviceId
            startDate
            endDate
            createdAt
            updatedAt
            mSalonMemberUseServicePeriodId
          }
          nextToken
        }
        billingdData {
          items {
            id
            transferDate
            downloadDateTime
            lineNo
            dataPartition
            transferBankCode
            transferBankName
            transferBankBranchCode
            transferBankBranchName
            dammy1
            depositType
            accountNumber
            accountHolder
            transferAmount
            firstTransferCode
            customerNumber
            transferResultCode
            dammy2
            salonId
            memberId
            transferDate_storeId
            tBillingHeaderBillingDataId
            salonMember {
              id
              storeId
              memberId
              applicationDate
              memberName
              furigana
              dateOfBirth
              zipCode
              address1
              address2
              phoneNumber
              phoneNumberMobile
              mailAddress
              bankSetDate
              bankCode
              bankName
              bankBranchCode
              bankBranchName
              depositType
              accountNumber
              depositerName
              confirmStatus
              updateDate
              confirmDate
              confirmPaper
              forceConfirm
              deleteFlag
              deleteDate
              lastUpdater
              lastUpdateDate
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            mSalonMemberBillingdDataId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      tAnyWhereHeadDetailsId
      tAnyWhereHeadDetailsUploadDateTime
      tAnyWhereDetailSalonMemberId
    }
  }
`;
export const listTAnyWhereDetails = /* GraphQL */ `
  query ListTAnyWhereDetails(
    $uploadDateTime: ID
    $lineNo: ModelIntKeyConditionInput
    $filter: ModelTAnyWhereDetailFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTAnyWhereDetails(
      uploadDateTime: $uploadDateTime
      lineNo: $lineNo
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        uploadDateTime
        lineNo
        transactionId
        deviceNo
        companyCode
        merchantNumber
        merchantName
        messageType
        voucherNumber
        originalVoucherNumber
        MPNTID
        errorCode
        institutionCode
        institutionName
        consignorKana
        consignorNumber
        bankName
        bankCode
        bankBranchCode
        accountNumber
        depositType
        consignorCode
        consignorName
        transferOkNumber
        deviceType
        processingDate
        handlingTime
        accountHolder
        attachedInformation1
        attachedInformation2
        attachedInformation3
        attachedInformation4
        result
        salonMember {
          id
          storeId
          memberId
          applicationDate
          memberName
          furigana
          dateOfBirth
          zipCode
          address1
          address2
          phoneNumber
          phoneNumberMobile
          mailAddress
          bankSetDate
          bankCode
          bankName
          bankBranchCode
          bankBranchName
          depositType
          accountNumber
          depositerName
          confirmStatus
          updateDate
          confirmDate
          confirmPaper
          forceConfirm
          deleteFlag
          deleteDate
          lastUpdater
          lastUpdateDate
          useServices {
            items {
              id
              mServiceID
              mSalonMemberID
              createdAt
              updatedAt
            }
            nextToken
          }
          useServicePeriod {
            items {
              id
              serviceId
              startDate
              endDate
              createdAt
              updatedAt
              mSalonMemberUseServicePeriodId
            }
            nextToken
          }
          billingdData {
            items {
              id
              transferDate
              downloadDateTime
              lineNo
              dataPartition
              transferBankCode
              transferBankName
              transferBankBranchCode
              transferBankBranchName
              dammy1
              depositType
              accountNumber
              accountHolder
              transferAmount
              firstTransferCode
              customerNumber
              transferResultCode
              dammy2
              salonId
              memberId
              transferDate_storeId
              tBillingHeaderBillingDataId
              createdAt
              updatedAt
              mSalonMemberBillingdDataId
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        tAnyWhereHeadDetailsId
        tAnyWhereHeadDetailsUploadDateTime
        tAnyWhereDetailSalonMemberId
      }
      nextToken
    }
  }
`;
export const getTBillingHeader = /* GraphQL */ `
  query GetTBillingHeader($id: ID!) {
    getTBillingHeader(id: $id) {
      id
      classification
      dataPartition
      typeCode
      codePartition
      consignorCode
      consignorName
      transferYear
      transferDate
      totalNumber
      totalFee
      totalNumberOfSuccess
      totalFeeOfSuccess
      totalNumberOfFailure
      totalFeeOfFailure
      status
      downloadDateTime
      downloadFileName
      uploadDateTime
      uploadFileName
      billingData {
        items {
          id
          transferDate
          downloadDateTime
          lineNo
          dataPartition
          transferBankCode
          transferBankName
          transferBankBranchCode
          transferBankBranchName
          dammy1
          depositType
          accountNumber
          accountHolder
          transferAmount
          firstTransferCode
          customerNumber
          transferResultCode
          dammy2
          salonId
          memberId
          transferDate_storeId
          tBillingHeaderBillingDataId
          salonMember {
            id
            storeId
            memberId
            applicationDate
            memberName
            furigana
            dateOfBirth
            zipCode
            address1
            address2
            phoneNumber
            phoneNumberMobile
            mailAddress
            bankSetDate
            bankCode
            bankName
            bankBranchCode
            bankBranchName
            depositType
            accountNumber
            depositerName
            confirmStatus
            updateDate
            confirmDate
            confirmPaper
            forceConfirm
            deleteFlag
            deleteDate
            lastUpdater
            lastUpdateDate
            useServices {
              nextToken
            }
            useServicePeriod {
              nextToken
            }
            billingdData {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          mSalonMemberBillingdDataId
        }
        nextToken
      }
      headerDammy
      trailerDammy
      trailerPartition
      createdAt
      updatedAt
    }
  }
`;
export const listTBillingHeaders = /* GraphQL */ `
  query ListTBillingHeaders(
    $filter: ModelTBillingHeaderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTBillingHeaders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        classification
        dataPartition
        typeCode
        codePartition
        consignorCode
        consignorName
        transferYear
        transferDate
        totalNumber
        totalFee
        totalNumberOfSuccess
        totalFeeOfSuccess
        totalNumberOfFailure
        totalFeeOfFailure
        status
        downloadDateTime
        downloadFileName
        uploadDateTime
        uploadFileName
        billingData {
          items {
            id
            transferDate
            downloadDateTime
            lineNo
            dataPartition
            transferBankCode
            transferBankName
            transferBankBranchCode
            transferBankBranchName
            dammy1
            depositType
            accountNumber
            accountHolder
            transferAmount
            firstTransferCode
            customerNumber
            transferResultCode
            dammy2
            salonId
            memberId
            transferDate_storeId
            tBillingHeaderBillingDataId
            salonMember {
              id
              storeId
              memberId
              applicationDate
              memberName
              furigana
              dateOfBirth
              zipCode
              address1
              address2
              phoneNumber
              phoneNumberMobile
              mailAddress
              bankSetDate
              bankCode
              bankName
              bankBranchCode
              bankBranchName
              depositType
              accountNumber
              depositerName
              confirmStatus
              updateDate
              confirmDate
              confirmPaper
              forceConfirm
              deleteFlag
              deleteDate
              lastUpdater
              lastUpdateDate
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            mSalonMemberBillingdDataId
          }
          nextToken
        }
        headerDammy
        trailerDammy
        trailerPartition
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const billingOrderByDownload = /* GraphQL */ `
  query BillingOrderByDownload(
    $consignorCode: String!
    $downloadDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTBillingHeaderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    BillingOrderByDownload(
      consignorCode: $consignorCode
      downloadDateTime: $downloadDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        classification
        dataPartition
        typeCode
        codePartition
        consignorCode
        consignorName
        transferYear
        transferDate
        totalNumber
        totalFee
        totalNumberOfSuccess
        totalFeeOfSuccess
        totalNumberOfFailure
        totalFeeOfFailure
        status
        downloadDateTime
        downloadFileName
        uploadDateTime
        uploadFileName
        billingData {
          items {
            id
            transferDate
            downloadDateTime
            lineNo
            dataPartition
            transferBankCode
            transferBankName
            transferBankBranchCode
            transferBankBranchName
            dammy1
            depositType
            accountNumber
            accountHolder
            transferAmount
            firstTransferCode
            customerNumber
            transferResultCode
            dammy2
            salonId
            memberId
            transferDate_storeId
            tBillingHeaderBillingDataId
            salonMember {
              id
              storeId
              memberId
              applicationDate
              memberName
              furigana
              dateOfBirth
              zipCode
              address1
              address2
              phoneNumber
              phoneNumberMobile
              mailAddress
              bankSetDate
              bankCode
              bankName
              bankBranchCode
              bankBranchName
              depositType
              accountNumber
              depositerName
              confirmStatus
              updateDate
              confirmDate
              confirmPaper
              forceConfirm
              deleteFlag
              deleteDate
              lastUpdater
              lastUpdateDate
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            mSalonMemberBillingdDataId
          }
          nextToken
        }
        headerDammy
        trailerDammy
        trailerPartition
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const billingOrderByTransferDate = /* GraphQL */ `
  query BillingOrderByTransferDate(
    $transferYear: String!
    $transferDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTBillingHeaderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    BillingOrderByTransferDate(
      transferYear: $transferYear
      transferDate: $transferDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        classification
        dataPartition
        typeCode
        codePartition
        consignorCode
        consignorName
        transferYear
        transferDate
        totalNumber
        totalFee
        totalNumberOfSuccess
        totalFeeOfSuccess
        totalNumberOfFailure
        totalFeeOfFailure
        status
        downloadDateTime
        downloadFileName
        uploadDateTime
        uploadFileName
        billingData {
          items {
            id
            transferDate
            downloadDateTime
            lineNo
            dataPartition
            transferBankCode
            transferBankName
            transferBankBranchCode
            transferBankBranchName
            dammy1
            depositType
            accountNumber
            accountHolder
            transferAmount
            firstTransferCode
            customerNumber
            transferResultCode
            dammy2
            salonId
            memberId
            transferDate_storeId
            tBillingHeaderBillingDataId
            salonMember {
              id
              storeId
              memberId
              applicationDate
              memberName
              furigana
              dateOfBirth
              zipCode
              address1
              address2
              phoneNumber
              phoneNumberMobile
              mailAddress
              bankSetDate
              bankCode
              bankName
              bankBranchCode
              bankBranchName
              depositType
              accountNumber
              depositerName
              confirmStatus
              updateDate
              confirmDate
              confirmPaper
              forceConfirm
              deleteFlag
              deleteDate
              lastUpdater
              lastUpdateDate
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            mSalonMemberBillingdDataId
          }
          nextToken
        }
        headerDammy
        trailerDammy
        trailerPartition
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTBillingData = /* GraphQL */ `
  query GetTBillingData($id: ID!) {
    getTBillingData(id: $id) {
      id
      transferDate
      downloadDateTime
      lineNo
      dataPartition
      transferBankCode
      transferBankName
      transferBankBranchCode
      transferBankBranchName
      dammy1
      depositType
      accountNumber
      accountHolder
      transferAmount
      firstTransferCode
      customerNumber
      transferResultCode
      dammy2
      salonId
      memberId
      transferDate_storeId
      tBillingHeaderBillingDataId
      salonMember {
        id
        storeId
        memberId
        applicationDate
        memberName
        furigana
        dateOfBirth
        zipCode
        address1
        address2
        phoneNumber
        phoneNumberMobile
        mailAddress
        bankSetDate
        bankCode
        bankName
        bankBranchCode
        bankBranchName
        depositType
        accountNumber
        depositerName
        confirmStatus
        updateDate
        confirmDate
        confirmPaper
        forceConfirm
        deleteFlag
        deleteDate
        lastUpdater
        lastUpdateDate
        useServices {
          items {
            id
            mServiceID
            mSalonMemberID
            mService {
              id
              storeId
              serviceId
              courseName
              monthlyPrice
              createdAt
              updatedAt
            }
            mSalonMember {
              id
              storeId
              memberId
              applicationDate
              memberName
              furigana
              dateOfBirth
              zipCode
              address1
              address2
              phoneNumber
              phoneNumberMobile
              mailAddress
              bankSetDate
              bankCode
              bankName
              bankBranchCode
              bankBranchName
              depositType
              accountNumber
              depositerName
              confirmStatus
              updateDate
              confirmDate
              confirmPaper
              forceConfirm
              deleteFlag
              deleteDate
              lastUpdater
              lastUpdateDate
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        useServicePeriod {
          items {
            id
            serviceId
            startDate
            endDate
            createdAt
            updatedAt
            mSalonMemberUseServicePeriodId
          }
          nextToken
        }
        billingdData {
          items {
            id
            transferDate
            downloadDateTime
            lineNo
            dataPartition
            transferBankCode
            transferBankName
            transferBankBranchCode
            transferBankBranchName
            dammy1
            depositType
            accountNumber
            accountHolder
            transferAmount
            firstTransferCode
            customerNumber
            transferResultCode
            dammy2
            salonId
            memberId
            transferDate_storeId
            tBillingHeaderBillingDataId
            salonMember {
              id
              storeId
              memberId
              applicationDate
              memberName
              furigana
              dateOfBirth
              zipCode
              address1
              address2
              phoneNumber
              phoneNumberMobile
              mailAddress
              bankSetDate
              bankCode
              bankName
              bankBranchCode
              bankBranchName
              depositType
              accountNumber
              depositerName
              confirmStatus
              updateDate
              confirmDate
              confirmPaper
              forceConfirm
              deleteFlag
              deleteDate
              lastUpdater
              lastUpdateDate
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            mSalonMemberBillingdDataId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      mSalonMemberBillingdDataId
    }
  }
`;
export const listTBillingData = /* GraphQL */ `
  query ListTBillingData(
    $filter: ModelTBillingDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTBillingData(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        transferDate
        downloadDateTime
        lineNo
        dataPartition
        transferBankCode
        transferBankName
        transferBankBranchCode
        transferBankBranchName
        dammy1
        depositType
        accountNumber
        accountHolder
        transferAmount
        firstTransferCode
        customerNumber
        transferResultCode
        dammy2
        salonId
        memberId
        transferDate_storeId
        tBillingHeaderBillingDataId
        salonMember {
          id
          storeId
          memberId
          applicationDate
          memberName
          furigana
          dateOfBirth
          zipCode
          address1
          address2
          phoneNumber
          phoneNumberMobile
          mailAddress
          bankSetDate
          bankCode
          bankName
          bankBranchCode
          bankBranchName
          depositType
          accountNumber
          depositerName
          confirmStatus
          updateDate
          confirmDate
          confirmPaper
          forceConfirm
          deleteFlag
          deleteDate
          lastUpdater
          lastUpdateDate
          useServices {
            items {
              id
              mServiceID
              mSalonMemberID
              createdAt
              updatedAt
            }
            nextToken
          }
          useServicePeriod {
            items {
              id
              serviceId
              startDate
              endDate
              createdAt
              updatedAt
              mSalonMemberUseServicePeriodId
            }
            nextToken
          }
          billingdData {
            items {
              id
              transferDate
              downloadDateTime
              lineNo
              dataPartition
              transferBankCode
              transferBankName
              transferBankBranchCode
              transferBankBranchName
              dammy1
              depositType
              accountNumber
              accountHolder
              transferAmount
              firstTransferCode
              customerNumber
              transferResultCode
              dammy2
              salonId
              memberId
              transferDate_storeId
              tBillingHeaderBillingDataId
              createdAt
              updatedAt
              mSalonMemberBillingdDataId
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        mSalonMemberBillingdDataId
      }
      nextToken
    }
  }
`;
export const listBillingDataByTransferDate = /* GraphQL */ `
  query ListBillingDataByTransferDate(
    $transferDate: String!
    $customerNumber: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTBillingDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBillingDataByTransferDate(
      transferDate: $transferDate
      customerNumber: $customerNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        transferDate
        downloadDateTime
        lineNo
        dataPartition
        transferBankCode
        transferBankName
        transferBankBranchCode
        transferBankBranchName
        dammy1
        depositType
        accountNumber
        accountHolder
        transferAmount
        firstTransferCode
        customerNumber
        transferResultCode
        dammy2
        salonId
        memberId
        transferDate_storeId
        tBillingHeaderBillingDataId
        salonMember {
          id
          storeId
          memberId
          applicationDate
          memberName
          furigana
          dateOfBirth
          zipCode
          address1
          address2
          phoneNumber
          phoneNumberMobile
          mailAddress
          bankSetDate
          bankCode
          bankName
          bankBranchCode
          bankBranchName
          depositType
          accountNumber
          depositerName
          confirmStatus
          updateDate
          confirmDate
          confirmPaper
          forceConfirm
          deleteFlag
          deleteDate
          lastUpdater
          lastUpdateDate
          useServices {
            items {
              id
              mServiceID
              mSalonMemberID
              createdAt
              updatedAt
            }
            nextToken
          }
          useServicePeriod {
            items {
              id
              serviceId
              startDate
              endDate
              createdAt
              updatedAt
              mSalonMemberUseServicePeriodId
            }
            nextToken
          }
          billingdData {
            items {
              id
              transferDate
              downloadDateTime
              lineNo
              dataPartition
              transferBankCode
              transferBankName
              transferBankBranchCode
              transferBankBranchName
              dammy1
              depositType
              accountNumber
              accountHolder
              transferAmount
              firstTransferCode
              customerNumber
              transferResultCode
              dammy2
              salonId
              memberId
              transferDate_storeId
              tBillingHeaderBillingDataId
              createdAt
              updatedAt
              mSalonMemberBillingdDataId
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        mSalonMemberBillingdDataId
      }
      nextToken
    }
  }
`;
export const billindOrderByLineNo = /* GraphQL */ `
  query BillindOrderByLineNo(
    $downloadDateTime: String!
    $lineNo: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTBillingDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    BillindOrderByLineNo(
      downloadDateTime: $downloadDateTime
      lineNo: $lineNo
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        transferDate
        downloadDateTime
        lineNo
        dataPartition
        transferBankCode
        transferBankName
        transferBankBranchCode
        transferBankBranchName
        dammy1
        depositType
        accountNumber
        accountHolder
        transferAmount
        firstTransferCode
        customerNumber
        transferResultCode
        dammy2
        salonId
        memberId
        transferDate_storeId
        tBillingHeaderBillingDataId
        salonMember {
          id
          storeId
          memberId
          applicationDate
          memberName
          furigana
          dateOfBirth
          zipCode
          address1
          address2
          phoneNumber
          phoneNumberMobile
          mailAddress
          bankSetDate
          bankCode
          bankName
          bankBranchCode
          bankBranchName
          depositType
          accountNumber
          depositerName
          confirmStatus
          updateDate
          confirmDate
          confirmPaper
          forceConfirm
          deleteFlag
          deleteDate
          lastUpdater
          lastUpdateDate
          useServices {
            items {
              id
              mServiceID
              mSalonMemberID
              createdAt
              updatedAt
            }
            nextToken
          }
          useServicePeriod {
            items {
              id
              serviceId
              startDate
              endDate
              createdAt
              updatedAt
              mSalonMemberUseServicePeriodId
            }
            nextToken
          }
          billingdData {
            items {
              id
              transferDate
              downloadDateTime
              lineNo
              dataPartition
              transferBankCode
              transferBankName
              transferBankBranchCode
              transferBankBranchName
              dammy1
              depositType
              accountNumber
              accountHolder
              transferAmount
              firstTransferCode
              customerNumber
              transferResultCode
              dammy2
              salonId
              memberId
              transferDate_storeId
              tBillingHeaderBillingDataId
              createdAt
              updatedAt
              mSalonMemberBillingdDataId
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        mSalonMemberBillingdDataId
      }
      nextToken
    }
  }
`;
export const billingBySalonId = /* GraphQL */ `
  query BillingBySalonId(
    $salonId: String!
    $transferDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTBillingDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    BillingBySalonId(
      salonId: $salonId
      transferDate: $transferDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        transferDate
        downloadDateTime
        lineNo
        dataPartition
        transferBankCode
        transferBankName
        transferBankBranchCode
        transferBankBranchName
        dammy1
        depositType
        accountNumber
        accountHolder
        transferAmount
        firstTransferCode
        customerNumber
        transferResultCode
        dammy2
        salonId
        memberId
        transferDate_storeId
        tBillingHeaderBillingDataId
        salonMember {
          id
          storeId
          memberId
          applicationDate
          memberName
          furigana
          dateOfBirth
          zipCode
          address1
          address2
          phoneNumber
          phoneNumberMobile
          mailAddress
          bankSetDate
          bankCode
          bankName
          bankBranchCode
          bankBranchName
          depositType
          accountNumber
          depositerName
          confirmStatus
          updateDate
          confirmDate
          confirmPaper
          forceConfirm
          deleteFlag
          deleteDate
          lastUpdater
          lastUpdateDate
          useServices {
            items {
              id
              mServiceID
              mSalonMemberID
              createdAt
              updatedAt
            }
            nextToken
          }
          useServicePeriod {
            items {
              id
              serviceId
              startDate
              endDate
              createdAt
              updatedAt
              mSalonMemberUseServicePeriodId
            }
            nextToken
          }
          billingdData {
            items {
              id
              transferDate
              downloadDateTime
              lineNo
              dataPartition
              transferBankCode
              transferBankName
              transferBankBranchCode
              transferBankBranchName
              dammy1
              depositType
              accountNumber
              accountHolder
              transferAmount
              firstTransferCode
              customerNumber
              transferResultCode
              dammy2
              salonId
              memberId
              transferDate_storeId
              tBillingHeaderBillingDataId
              createdAt
              updatedAt
              mSalonMemberBillingdDataId
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        mSalonMemberBillingdDataId
      }
      nextToken
    }
  }
`;
export const billingByMemberId = /* GraphQL */ `
  query BillingByMemberId(
    $memberId: String!
    $transferDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTBillingDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    BillingByMemberId(
      memberId: $memberId
      transferDate: $transferDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        transferDate
        downloadDateTime
        lineNo
        dataPartition
        transferBankCode
        transferBankName
        transferBankBranchCode
        transferBankBranchName
        dammy1
        depositType
        accountNumber
        accountHolder
        transferAmount
        firstTransferCode
        customerNumber
        transferResultCode
        dammy2
        salonId
        memberId
        transferDate_storeId
        tBillingHeaderBillingDataId
        salonMember {
          id
          storeId
          memberId
          applicationDate
          memberName
          furigana
          dateOfBirth
          zipCode
          address1
          address2
          phoneNumber
          phoneNumberMobile
          mailAddress
          bankSetDate
          bankCode
          bankName
          bankBranchCode
          bankBranchName
          depositType
          accountNumber
          depositerName
          confirmStatus
          updateDate
          confirmDate
          confirmPaper
          forceConfirm
          deleteFlag
          deleteDate
          lastUpdater
          lastUpdateDate
          useServices {
            items {
              id
              mServiceID
              mSalonMemberID
              createdAt
              updatedAt
            }
            nextToken
          }
          useServicePeriod {
            items {
              id
              serviceId
              startDate
              endDate
              createdAt
              updatedAt
              mSalonMemberUseServicePeriodId
            }
            nextToken
          }
          billingdData {
            items {
              id
              transferDate
              downloadDateTime
              lineNo
              dataPartition
              transferBankCode
              transferBankName
              transferBankBranchCode
              transferBankBranchName
              dammy1
              depositType
              accountNumber
              accountHolder
              transferAmount
              firstTransferCode
              customerNumber
              transferResultCode
              dammy2
              salonId
              memberId
              transferDate_storeId
              tBillingHeaderBillingDataId
              createdAt
              updatedAt
              mSalonMemberBillingdDataId
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        mSalonMemberBillingdDataId
      }
      nextToken
    }
  }
`;
export const listTBillingDataByTransferDateWithStoreId = /* GraphQL */ `
  query ListTBillingDataByTransferDateWithStoreId(
    $transferDate_storeId: String!
    $lineNo: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTBillingDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTBillingDataByTransferDateWithStoreId(
      transferDate_storeId: $transferDate_storeId
      lineNo: $lineNo
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        transferDate
        downloadDateTime
        lineNo
        dataPartition
        transferBankCode
        transferBankName
        transferBankBranchCode
        transferBankBranchName
        dammy1
        depositType
        accountNumber
        accountHolder
        transferAmount
        firstTransferCode
        customerNumber
        transferResultCode
        dammy2
        salonId
        memberId
        transferDate_storeId
        tBillingHeaderBillingDataId
        salonMember {
          id
          storeId
          memberId
          applicationDate
          memberName
          furigana
          dateOfBirth
          zipCode
          address1
          address2
          phoneNumber
          phoneNumberMobile
          mailAddress
          bankSetDate
          bankCode
          bankName
          bankBranchCode
          bankBranchName
          depositType
          accountNumber
          depositerName
          confirmStatus
          updateDate
          confirmDate
          confirmPaper
          forceConfirm
          deleteFlag
          deleteDate
          lastUpdater
          lastUpdateDate
          useServices {
            items {
              id
              mServiceID
              mSalonMemberID
              createdAt
              updatedAt
            }
            nextToken
          }
          useServicePeriod {
            items {
              id
              serviceId
              startDate
              endDate
              createdAt
              updatedAt
              mSalonMemberUseServicePeriodId
            }
            nextToken
          }
          billingdData {
            items {
              id
              transferDate
              downloadDateTime
              lineNo
              dataPartition
              transferBankCode
              transferBankName
              transferBankBranchCode
              transferBankBranchName
              dammy1
              depositType
              accountNumber
              accountHolder
              transferAmount
              firstTransferCode
              customerNumber
              transferResultCode
              dammy2
              salonId
              memberId
              transferDate_storeId
              tBillingHeaderBillingDataId
              createdAt
              updatedAt
              mSalonMemberBillingdDataId
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        mSalonMemberBillingdDataId
      }
      nextToken
    }
  }
`;
export const getTUploadHeader = /* GraphQL */ `
  query GetTUploadHeader($id: ID!) {
    getTUploadHeader(id: $id) {
      id
      classification
      uploadDateTime
      uploadFileName
      dataPartition
      typeCode
      codePartition
      consignorCode
      consignorName
      transferYear
      transferDate
      totalNumber
      totalFee
      totalNumberOfSuccess
      totalFeeOfSuccess
      totalNumberOfFailure
      totalFeeOfFailure
      uploadData {
        items {
          uploadDateTime
          lineNo
          dataPartition
          transferBankCode
          transferBankName
          transferBankBranchCode
          transferBankBranchName
          depositType
          accountNumber
          accountHolder
          transferAmount
          firstTransferCode
          customerNumber
          transferResultCode
          memberId_TransferDate
          salonId_TransferDate
          tUploadHeaderUploadDataId
        }
        nextToken
      }
    }
  }
`;
export const listTUploadHeaders = /* GraphQL */ `
  query ListTUploadHeaders(
    $filter: ModelTUploadHeaderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTUploadHeaders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        classification
        uploadDateTime
        uploadFileName
        dataPartition
        typeCode
        codePartition
        consignorCode
        consignorName
        transferYear
        transferDate
        totalNumber
        totalFee
        totalNumberOfSuccess
        totalFeeOfSuccess
        totalNumberOfFailure
        totalFeeOfFailure
        uploadData {
          items {
            uploadDateTime
            lineNo
            dataPartition
            transferBankCode
            transferBankName
            transferBankBranchCode
            transferBankBranchName
            depositType
            accountNumber
            accountHolder
            transferAmount
            firstTransferCode
            customerNumber
            transferResultCode
            memberId_TransferDate
            salonId_TransferDate
            tUploadHeaderUploadDataId
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const uploadOrderByDateTime = /* GraphQL */ `
  query UploadOrderByDateTime(
    $typeCode: String!
    $uploadDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTUploadHeaderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    UploadOrderByDateTime(
      typeCode: $typeCode
      uploadDateTime: $uploadDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        classification
        uploadDateTime
        uploadFileName
        dataPartition
        typeCode
        codePartition
        consignorCode
        consignorName
        transferYear
        transferDate
        totalNumber
        totalFee
        totalNumberOfSuccess
        totalFeeOfSuccess
        totalNumberOfFailure
        totalFeeOfFailure
        uploadData {
          items {
            uploadDateTime
            lineNo
            dataPartition
            transferBankCode
            transferBankName
            transferBankBranchCode
            transferBankBranchName
            depositType
            accountNumber
            accountHolder
            transferAmount
            firstTransferCode
            customerNumber
            transferResultCode
            memberId_TransferDate
            salonId_TransferDate
            tUploadHeaderUploadDataId
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTUploadData = /* GraphQL */ `
  query GetTUploadData($uploadDateTime: String!, $lineNo: Int!) {
    getTUploadData(uploadDateTime: $uploadDateTime, lineNo: $lineNo) {
      uploadDateTime
      lineNo
      dataPartition
      transferBankCode
      transferBankName
      transferBankBranchCode
      transferBankBranchName
      depositType
      accountNumber
      accountHolder
      transferAmount
      firstTransferCode
      customerNumber
      transferResultCode
      memberId_TransferDate
      salonId_TransferDate
      tUploadHeaderUploadDataId
    }
  }
`;
export const listTUploadData = /* GraphQL */ `
  query ListTUploadData(
    $uploadDateTime: String
    $lineNo: ModelIntKeyConditionInput
    $filter: ModelTUploadDataFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTUploadData(
      uploadDateTime: $uploadDateTime
      lineNo: $lineNo
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        uploadDateTime
        lineNo
        dataPartition
        transferBankCode
        transferBankName
        transferBankBranchCode
        transferBankBranchName
        depositType
        accountNumber
        accountHolder
        transferAmount
        firstTransferCode
        customerNumber
        transferResultCode
        memberId_TransferDate
        salonId_TransferDate
        tUploadHeaderUploadDataId
      }
      nextToken
    }
  }
`;
export const getTSalonPayment = /* GraphQL */ `
  query GetTSalonPayment($id: ID!) {
    getTSalonPayment(id: $id) {
      id
      storeId
      storeName
      transferDate
      totalNumber
      totalFee
      totalNumberOfSuccess
      totalFeeOfSuccess
      totalNumberOfFailure
      totalFeeOfFailure
      basicCharge
      pricePerCase
      bankRegistFee
      bankRegistNumber
      createdAt
      updatedAt
    }
  }
`;
export const listTSalonPayments = /* GraphQL */ `
  query ListTSalonPayments(
    $filter: ModelTSalonPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTSalonPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        storeId
        storeName
        transferDate
        totalNumber
        totalFee
        totalNumberOfSuccess
        totalFeeOfSuccess
        totalNumberOfFailure
        totalFeeOfFailure
        basicCharge
        pricePerCase
        bankRegistFee
        bankRegistNumber
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listPaymentByStoreId = /* GraphQL */ `
  query ListPaymentByStoreId(
    $storeId: String!
    $transferDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTSalonPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaymentByStoreId(
      storeId: $storeId
      transferDate: $transferDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        storeId
        storeName
        transferDate
        totalNumber
        totalFee
        totalNumberOfSuccess
        totalFeeOfSuccess
        totalNumberOfFailure
        totalFeeOfFailure
        basicCharge
        pricePerCase
        bankRegistFee
        bankRegistNumber
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listStoreByTransferDate = /* GraphQL */ `
  query ListStoreByTransferDate(
    $transferDate: String!
    $storeId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTSalonPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStoreByTransferDate(
      transferDate: $transferDate
      storeId: $storeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        storeId
        storeName
        transferDate
        totalNumber
        totalFee
        totalNumberOfSuccess
        totalFeeOfSuccess
        totalNumberOfFailure
        totalFeeOfFailure
        basicCharge
        pricePerCase
        bankRegistFee
        bankRegistNumber
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTUploadHeadUUIDs = /* GraphQL */ `
  query GetTUploadHeadUUIDs($id: ID!) {
    getTUploadHeadUUIDs(id: $id) {
      id
      UUID
      status
      createdAt
      updatedAt
    }
  }
`;
export const listTUploadHeadUUIDs = /* GraphQL */ `
  query ListTUploadHeadUUIDs(
    $filter: ModelTUploadHeadUUIDsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTUploadHeadUUIDs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        UUID
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMemberUseServices = /* GraphQL */ `
  query GetMemberUseServices($id: ID!) {
    getMemberUseServices(id: $id) {
      id
      mServiceID
      mSalonMemberID
      mService {
        id
        storeId
        serviceId
        courseName
        monthlyPrice
        salonMembers {
          items {
            id
            mServiceID
            mSalonMemberID
            mService {
              id
              storeId
              serviceId
              courseName
              monthlyPrice
              createdAt
              updatedAt
            }
            mSalonMember {
              id
              storeId
              memberId
              applicationDate
              memberName
              furigana
              dateOfBirth
              zipCode
              address1
              address2
              phoneNumber
              phoneNumberMobile
              mailAddress
              bankSetDate
              bankCode
              bankName
              bankBranchCode
              bankBranchName
              depositType
              accountNumber
              depositerName
              confirmStatus
              updateDate
              confirmDate
              confirmPaper
              forceConfirm
              deleteFlag
              deleteDate
              lastUpdater
              lastUpdateDate
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      mSalonMember {
        id
        storeId
        memberId
        applicationDate
        memberName
        furigana
        dateOfBirth
        zipCode
        address1
        address2
        phoneNumber
        phoneNumberMobile
        mailAddress
        bankSetDate
        bankCode
        bankName
        bankBranchCode
        bankBranchName
        depositType
        accountNumber
        depositerName
        confirmStatus
        updateDate
        confirmDate
        confirmPaper
        forceConfirm
        deleteFlag
        deleteDate
        lastUpdater
        lastUpdateDate
        useServices {
          items {
            id
            mServiceID
            mSalonMemberID
            mService {
              id
              storeId
              serviceId
              courseName
              monthlyPrice
              createdAt
              updatedAt
            }
            mSalonMember {
              id
              storeId
              memberId
              applicationDate
              memberName
              furigana
              dateOfBirth
              zipCode
              address1
              address2
              phoneNumber
              phoneNumberMobile
              mailAddress
              bankSetDate
              bankCode
              bankName
              bankBranchCode
              bankBranchName
              depositType
              accountNumber
              depositerName
              confirmStatus
              updateDate
              confirmDate
              confirmPaper
              forceConfirm
              deleteFlag
              deleteDate
              lastUpdater
              lastUpdateDate
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        useServicePeriod {
          items {
            id
            serviceId
            startDate
            endDate
            createdAt
            updatedAt
            mSalonMemberUseServicePeriodId
          }
          nextToken
        }
        billingdData {
          items {
            id
            transferDate
            downloadDateTime
            lineNo
            dataPartition
            transferBankCode
            transferBankName
            transferBankBranchCode
            transferBankBranchName
            dammy1
            depositType
            accountNumber
            accountHolder
            transferAmount
            firstTransferCode
            customerNumber
            transferResultCode
            dammy2
            salonId
            memberId
            transferDate_storeId
            tBillingHeaderBillingDataId
            salonMember {
              id
              storeId
              memberId
              applicationDate
              memberName
              furigana
              dateOfBirth
              zipCode
              address1
              address2
              phoneNumber
              phoneNumberMobile
              mailAddress
              bankSetDate
              bankCode
              bankName
              bankBranchCode
              bankBranchName
              depositType
              accountNumber
              depositerName
              confirmStatus
              updateDate
              confirmDate
              confirmPaper
              forceConfirm
              deleteFlag
              deleteDate
              lastUpdater
              lastUpdateDate
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            mSalonMemberBillingdDataId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listMemberUseServices = /* GraphQL */ `
  query ListMemberUseServices(
    $filter: ModelMemberUseServicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMemberUseServices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mServiceID
        mSalonMemberID
        mService {
          id
          storeId
          serviceId
          courseName
          monthlyPrice
          salonMembers {
            items {
              id
              mServiceID
              mSalonMemberID
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        mSalonMember {
          id
          storeId
          memberId
          applicationDate
          memberName
          furigana
          dateOfBirth
          zipCode
          address1
          address2
          phoneNumber
          phoneNumberMobile
          mailAddress
          bankSetDate
          bankCode
          bankName
          bankBranchCode
          bankBranchName
          depositType
          accountNumber
          depositerName
          confirmStatus
          updateDate
          confirmDate
          confirmPaper
          forceConfirm
          deleteFlag
          deleteDate
          lastUpdater
          lastUpdateDate
          useServices {
            items {
              id
              mServiceID
              mSalonMemberID
              createdAt
              updatedAt
            }
            nextToken
          }
          useServicePeriod {
            items {
              id
              serviceId
              startDate
              endDate
              createdAt
              updatedAt
              mSalonMemberUseServicePeriodId
            }
            nextToken
          }
          billingdData {
            items {
              id
              transferDate
              downloadDateTime
              lineNo
              dataPartition
              transferBankCode
              transferBankName
              transferBankBranchCode
              transferBankBranchName
              dammy1
              depositType
              accountNumber
              accountHolder
              transferAmount
              firstTransferCode
              customerNumber
              transferResultCode
              dammy2
              salonId
              memberId
              transferDate_storeId
              tBillingHeaderBillingDataId
              createdAt
              updatedAt
              mSalonMemberBillingdDataId
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
