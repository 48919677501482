/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createMAdmin = /* GraphQL */ `
  mutation CreateMAdmin(
    $input: CreateMAdminInput!
    $condition: ModelMAdminConditionInput
  ) {
    createMAdmin(input: $input, condition: $condition) {
      id
      accountNo
      mail
      name
      contact
      password
      timestamp
      cognitoUserId
      createdAt
      updatedAt
    }
  }
`;
export const updateMAdmin = /* GraphQL */ `
  mutation UpdateMAdmin(
    $input: UpdateMAdminInput!
    $condition: ModelMAdminConditionInput
  ) {
    updateMAdmin(input: $input, condition: $condition) {
      id
      accountNo
      mail
      name
      contact
      password
      timestamp
      cognitoUserId
      createdAt
      updatedAt
    }
  }
`;
export const deleteMAdmin = /* GraphQL */ `
  mutation DeleteMAdmin(
    $input: DeleteMAdminInput!
    $condition: ModelMAdminConditionInput
  ) {
    deleteMAdmin(input: $input, condition: $condition) {
      id
      accountNo
      mail
      name
      contact
      password
      timestamp
      cognitoUserId
      createdAt
      updatedAt
    }
  }
`;
export const createMSalon = /* GraphQL */ `
  mutation CreateMSalon(
    $input: CreateMSalonInput!
    $condition: ModelMSalonConditionInput
  ) {
    createMSalon(input: $input, condition: $condition) {
      id
      storeId
      storeName
      storeAddress1
      storeAddress2
      phone
      personelName1
      personelMail1
      personelPassword1
      personelCognitoUserId1
      personelName2
      personelMail2
      personelPassword2
      personelCognitoUserId2
      personelName3
      personelMail3
      personelPassword3
      personelCognitoUserId3
      bankCode
      bankBranchCode
      bankDepositType
      bankAccountNumber
      bankDepositerName
      bankConfirmStatus
      updateDate
      confirmDate
      lastUpdater
      lastUpdateDate
      basicCharge
      pricePerCase
      bankTransferFee
      services {
        items {
          id
          storeId
          serviceId
          courseName
          monthlyPrice
          salonMembers {
            items {
              id
              mServiceID
              mSalonMemberID
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      cognitoUserId
      classification
      termsOfService
      createdAt
      updatedAt
    }
  }
`;
export const updateMSalon = /* GraphQL */ `
  mutation UpdateMSalon(
    $input: UpdateMSalonInput!
    $condition: ModelMSalonConditionInput
  ) {
    updateMSalon(input: $input, condition: $condition) {
      id
      storeId
      storeName
      storeAddress1
      storeAddress2
      phone
      personelName1
      personelMail1
      personelPassword1
      personelCognitoUserId1
      personelName2
      personelMail2
      personelPassword2
      personelCognitoUserId2
      personelName3
      personelMail3
      personelPassword3
      personelCognitoUserId3
      bankCode
      bankBranchCode
      bankDepositType
      bankAccountNumber
      bankDepositerName
      bankConfirmStatus
      updateDate
      confirmDate
      lastUpdater
      lastUpdateDate
      basicCharge
      pricePerCase
      bankTransferFee
      services {
        items {
          id
          storeId
          serviceId
          courseName
          monthlyPrice
          salonMembers {
            items {
              id
              mServiceID
              mSalonMemberID
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      cognitoUserId
      classification
      termsOfService
      createdAt
      updatedAt
    }
  }
`;
export const deleteMSalon = /* GraphQL */ `
  mutation DeleteMSalon(
    $input: DeleteMSalonInput!
    $condition: ModelMSalonConditionInput
  ) {
    deleteMSalon(input: $input, condition: $condition) {
      id
      storeId
      storeName
      storeAddress1
      storeAddress2
      phone
      personelName1
      personelMail1
      personelPassword1
      personelCognitoUserId1
      personelName2
      personelMail2
      personelPassword2
      personelCognitoUserId2
      personelName3
      personelMail3
      personelPassword3
      personelCognitoUserId3
      bankCode
      bankBranchCode
      bankDepositType
      bankAccountNumber
      bankDepositerName
      bankConfirmStatus
      updateDate
      confirmDate
      lastUpdater
      lastUpdateDate
      basicCharge
      pricePerCase
      bankTransferFee
      services {
        items {
          id
          storeId
          serviceId
          courseName
          monthlyPrice
          salonMembers {
            items {
              id
              mServiceID
              mSalonMemberID
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      cognitoUserId
      classification
      termsOfService
      createdAt
      updatedAt
    }
  }
`;
export const createMService = /* GraphQL */ `
  mutation CreateMService(
    $input: CreateMServiceInput!
    $condition: ModelMServiceConditionInput
  ) {
    createMService(input: $input, condition: $condition) {
      id
      storeId
      serviceId
      courseName
      monthlyPrice
      salonMembers {
        items {
          id
          mServiceID
          mSalonMemberID
          mService {
            id
            storeId
            serviceId
            courseName
            monthlyPrice
            salonMembers {
              nextToken
            }
            createdAt
            updatedAt
          }
          mSalonMember {
            id
            storeId
            memberId
            applicationDate
            memberName
            furigana
            dateOfBirth
            zipCode
            address1
            address2
            phoneNumber
            phoneNumberMobile
            mailAddress
            bankSetDate
            bankCode
            bankName
            bankBranchCode
            bankBranchName
            depositType
            accountNumber
            depositerName
            confirmStatus
            updateDate
            confirmDate
            confirmPaper
            forceConfirm
            deleteFlag
            deleteDate
            lastUpdater
            lastUpdateDate
            useServices {
              nextToken
            }
            useServicePeriod {
              nextToken
            }
            billingdData {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateMService = /* GraphQL */ `
  mutation UpdateMService(
    $input: UpdateMServiceInput!
    $condition: ModelMServiceConditionInput
  ) {
    updateMService(input: $input, condition: $condition) {
      id
      storeId
      serviceId
      courseName
      monthlyPrice
      salonMembers {
        items {
          id
          mServiceID
          mSalonMemberID
          mService {
            id
            storeId
            serviceId
            courseName
            monthlyPrice
            salonMembers {
              nextToken
            }
            createdAt
            updatedAt
          }
          mSalonMember {
            id
            storeId
            memberId
            applicationDate
            memberName
            furigana
            dateOfBirth
            zipCode
            address1
            address2
            phoneNumber
            phoneNumberMobile
            mailAddress
            bankSetDate
            bankCode
            bankName
            bankBranchCode
            bankBranchName
            depositType
            accountNumber
            depositerName
            confirmStatus
            updateDate
            confirmDate
            confirmPaper
            forceConfirm
            deleteFlag
            deleteDate
            lastUpdater
            lastUpdateDate
            useServices {
              nextToken
            }
            useServicePeriod {
              nextToken
            }
            billingdData {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteMService = /* GraphQL */ `
  mutation DeleteMService(
    $input: DeleteMServiceInput!
    $condition: ModelMServiceConditionInput
  ) {
    deleteMService(input: $input, condition: $condition) {
      id
      storeId
      serviceId
      courseName
      monthlyPrice
      salonMembers {
        items {
          id
          mServiceID
          mSalonMemberID
          mService {
            id
            storeId
            serviceId
            courseName
            monthlyPrice
            salonMembers {
              nextToken
            }
            createdAt
            updatedAt
          }
          mSalonMember {
            id
            storeId
            memberId
            applicationDate
            memberName
            furigana
            dateOfBirth
            zipCode
            address1
            address2
            phoneNumber
            phoneNumberMobile
            mailAddress
            bankSetDate
            bankCode
            bankName
            bankBranchCode
            bankBranchName
            depositType
            accountNumber
            depositerName
            confirmStatus
            updateDate
            confirmDate
            confirmPaper
            forceConfirm
            deleteFlag
            deleteDate
            lastUpdater
            lastUpdateDate
            useServices {
              nextToken
            }
            useServicePeriod {
              nextToken
            }
            billingdData {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMSalonMember = /* GraphQL */ `
  mutation CreateMSalonMember(
    $input: CreateMSalonMemberInput!
    $condition: ModelMSalonMemberConditionInput
  ) {
    createMSalonMember(input: $input, condition: $condition) {
      id
      storeId
      memberId
      applicationDate
      memberName
      furigana
      dateOfBirth
      zipCode
      address1
      address2
      phoneNumber
      phoneNumberMobile
      mailAddress
      bankSetDate
      bankCode
      bankName
      bankBranchCode
      bankBranchName
      depositType
      accountNumber
      depositerName
      confirmStatus
      updateDate
      confirmDate
      confirmPaper
      forceConfirm
      deleteFlag
      deleteDate
      lastUpdater
      lastUpdateDate
      useServices {
        items {
          id
          mServiceID
          mSalonMemberID
          mService {
            id
            storeId
            serviceId
            courseName
            monthlyPrice
            salonMembers {
              nextToken
            }
            createdAt
            updatedAt
          }
          mSalonMember {
            id
            storeId
            memberId
            applicationDate
            memberName
            furigana
            dateOfBirth
            zipCode
            address1
            address2
            phoneNumber
            phoneNumberMobile
            mailAddress
            bankSetDate
            bankCode
            bankName
            bankBranchCode
            bankBranchName
            depositType
            accountNumber
            depositerName
            confirmStatus
            updateDate
            confirmDate
            confirmPaper
            forceConfirm
            deleteFlag
            deleteDate
            lastUpdater
            lastUpdateDate
            useServices {
              nextToken
            }
            useServicePeriod {
              nextToken
            }
            billingdData {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      useServicePeriod {
        items {
          id
          serviceId
          startDate
          endDate
          createdAt
          updatedAt
          mSalonMemberUseServicePeriodId
        }
        nextToken
      }
      billingdData {
        items {
          id
          transferDate
          downloadDateTime
          lineNo
          dataPartition
          transferBankCode
          transferBankName
          transferBankBranchCode
          transferBankBranchName
          dammy1
          depositType
          accountNumber
          accountHolder
          transferAmount
          firstTransferCode
          customerNumber
          transferResultCode
          dammy2
          salonId
          memberId
          transferDate_storeId
          tBillingHeaderBillingDataId
          salonMember {
            id
            storeId
            memberId
            applicationDate
            memberName
            furigana
            dateOfBirth
            zipCode
            address1
            address2
            phoneNumber
            phoneNumberMobile
            mailAddress
            bankSetDate
            bankCode
            bankName
            bankBranchCode
            bankBranchName
            depositType
            accountNumber
            depositerName
            confirmStatus
            updateDate
            confirmDate
            confirmPaper
            forceConfirm
            deleteFlag
            deleteDate
            lastUpdater
            lastUpdateDate
            useServices {
              nextToken
            }
            useServicePeriod {
              nextToken
            }
            billingdData {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          mSalonMemberBillingdDataId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateMSalonMember = /* GraphQL */ `
  mutation UpdateMSalonMember(
    $input: UpdateMSalonMemberInput!
    $condition: ModelMSalonMemberConditionInput
  ) {
    updateMSalonMember(input: $input, condition: $condition) {
      id
      storeId
      memberId
      applicationDate
      memberName
      furigana
      dateOfBirth
      zipCode
      address1
      address2
      phoneNumber
      phoneNumberMobile
      mailAddress
      bankSetDate
      bankCode
      bankName
      bankBranchCode
      bankBranchName
      depositType
      accountNumber
      depositerName
      confirmStatus
      updateDate
      confirmDate
      confirmPaper
      forceConfirm
      deleteFlag
      deleteDate
      lastUpdater
      lastUpdateDate
      useServices {
        items {
          id
          mServiceID
          mSalonMemberID
          mService {
            id
            storeId
            serviceId
            courseName
            monthlyPrice
            salonMembers {
              nextToken
            }
            createdAt
            updatedAt
          }
          mSalonMember {
            id
            storeId
            memberId
            applicationDate
            memberName
            furigana
            dateOfBirth
            zipCode
            address1
            address2
            phoneNumber
            phoneNumberMobile
            mailAddress
            bankSetDate
            bankCode
            bankName
            bankBranchCode
            bankBranchName
            depositType
            accountNumber
            depositerName
            confirmStatus
            updateDate
            confirmDate
            confirmPaper
            forceConfirm
            deleteFlag
            deleteDate
            lastUpdater
            lastUpdateDate
            useServices {
              nextToken
            }
            useServicePeriod {
              nextToken
            }
            billingdData {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      useServicePeriod {
        items {
          id
          serviceId
          startDate
          endDate
          createdAt
          updatedAt
          mSalonMemberUseServicePeriodId
        }
        nextToken
      }
      billingdData {
        items {
          id
          transferDate
          downloadDateTime
          lineNo
          dataPartition
          transferBankCode
          transferBankName
          transferBankBranchCode
          transferBankBranchName
          dammy1
          depositType
          accountNumber
          accountHolder
          transferAmount
          firstTransferCode
          customerNumber
          transferResultCode
          dammy2
          salonId
          memberId
          transferDate_storeId
          tBillingHeaderBillingDataId
          salonMember {
            id
            storeId
            memberId
            applicationDate
            memberName
            furigana
            dateOfBirth
            zipCode
            address1
            address2
            phoneNumber
            phoneNumberMobile
            mailAddress
            bankSetDate
            bankCode
            bankName
            bankBranchCode
            bankBranchName
            depositType
            accountNumber
            depositerName
            confirmStatus
            updateDate
            confirmDate
            confirmPaper
            forceConfirm
            deleteFlag
            deleteDate
            lastUpdater
            lastUpdateDate
            useServices {
              nextToken
            }
            useServicePeriod {
              nextToken
            }
            billingdData {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          mSalonMemberBillingdDataId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteMSalonMember = /* GraphQL */ `
  mutation DeleteMSalonMember(
    $input: DeleteMSalonMemberInput!
    $condition: ModelMSalonMemberConditionInput
  ) {
    deleteMSalonMember(input: $input, condition: $condition) {
      id
      storeId
      memberId
      applicationDate
      memberName
      furigana
      dateOfBirth
      zipCode
      address1
      address2
      phoneNumber
      phoneNumberMobile
      mailAddress
      bankSetDate
      bankCode
      bankName
      bankBranchCode
      bankBranchName
      depositType
      accountNumber
      depositerName
      confirmStatus
      updateDate
      confirmDate
      confirmPaper
      forceConfirm
      deleteFlag
      deleteDate
      lastUpdater
      lastUpdateDate
      useServices {
        items {
          id
          mServiceID
          mSalonMemberID
          mService {
            id
            storeId
            serviceId
            courseName
            monthlyPrice
            salonMembers {
              nextToken
            }
            createdAt
            updatedAt
          }
          mSalonMember {
            id
            storeId
            memberId
            applicationDate
            memberName
            furigana
            dateOfBirth
            zipCode
            address1
            address2
            phoneNumber
            phoneNumberMobile
            mailAddress
            bankSetDate
            bankCode
            bankName
            bankBranchCode
            bankBranchName
            depositType
            accountNumber
            depositerName
            confirmStatus
            updateDate
            confirmDate
            confirmPaper
            forceConfirm
            deleteFlag
            deleteDate
            lastUpdater
            lastUpdateDate
            useServices {
              nextToken
            }
            useServicePeriod {
              nextToken
            }
            billingdData {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      useServicePeriod {
        items {
          id
          serviceId
          startDate
          endDate
          createdAt
          updatedAt
          mSalonMemberUseServicePeriodId
        }
        nextToken
      }
      billingdData {
        items {
          id
          transferDate
          downloadDateTime
          lineNo
          dataPartition
          transferBankCode
          transferBankName
          transferBankBranchCode
          transferBankBranchName
          dammy1
          depositType
          accountNumber
          accountHolder
          transferAmount
          firstTransferCode
          customerNumber
          transferResultCode
          dammy2
          salonId
          memberId
          transferDate_storeId
          tBillingHeaderBillingDataId
          salonMember {
            id
            storeId
            memberId
            applicationDate
            memberName
            furigana
            dateOfBirth
            zipCode
            address1
            address2
            phoneNumber
            phoneNumberMobile
            mailAddress
            bankSetDate
            bankCode
            bankName
            bankBranchCode
            bankBranchName
            depositType
            accountNumber
            depositerName
            confirmStatus
            updateDate
            confirmDate
            confirmPaper
            forceConfirm
            deleteFlag
            deleteDate
            lastUpdater
            lastUpdateDate
            useServices {
              nextToken
            }
            useServicePeriod {
              nextToken
            }
            billingdData {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          mSalonMemberBillingdDataId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createTServicePeriod = /* GraphQL */ `
  mutation CreateTServicePeriod(
    $input: CreateTServicePeriodInput!
    $condition: ModelTServicePeriodConditionInput
  ) {
    createTServicePeriod(input: $input, condition: $condition) {
      id
      serviceId
      startDate
      endDate
      createdAt
      updatedAt
      mSalonMemberUseServicePeriodId
    }
  }
`;
export const updateTServicePeriod = /* GraphQL */ `
  mutation UpdateTServicePeriod(
    $input: UpdateTServicePeriodInput!
    $condition: ModelTServicePeriodConditionInput
  ) {
    updateTServicePeriod(input: $input, condition: $condition) {
      id
      serviceId
      startDate
      endDate
      createdAt
      updatedAt
      mSalonMemberUseServicePeriodId
    }
  }
`;
export const deleteTServicePeriod = /* GraphQL */ `
  mutation DeleteTServicePeriod(
    $input: DeleteTServicePeriodInput!
    $condition: ModelTServicePeriodConditionInput
  ) {
    deleteTServicePeriod(input: $input, condition: $condition) {
      id
      serviceId
      startDate
      endDate
      createdAt
      updatedAt
      mSalonMemberUseServicePeriodId
    }
  }
`;
export const createMSystemCharge = /* GraphQL */ `
  mutation CreateMSystemCharge(
    $input: CreateMSystemChargeInput!
    $condition: ModelMSystemChargeConditionInput
  ) {
    createMSystemCharge(input: $input, condition: $condition) {
      id
      basicCharge
      pricePerCase
      bankTransferFee
      createdAt
      updatedAt
    }
  }
`;
export const updateMSystemCharge = /* GraphQL */ `
  mutation UpdateMSystemCharge(
    $input: UpdateMSystemChargeInput!
    $condition: ModelMSystemChargeConditionInput
  ) {
    updateMSystemCharge(input: $input, condition: $condition) {
      id
      basicCharge
      pricePerCase
      bankTransferFee
      createdAt
      updatedAt
    }
  }
`;
export const deleteMSystemCharge = /* GraphQL */ `
  mutation DeleteMSystemCharge(
    $input: DeleteMSystemChargeInput!
    $condition: ModelMSystemChargeConditionInput
  ) {
    deleteMSystemCharge(input: $input, condition: $condition) {
      id
      basicCharge
      pricePerCase
      bankTransferFee
      createdAt
      updatedAt
    }
  }
`;
export const createMBank = /* GraphQL */ `
  mutation CreateMBank(
    $input: CreateMBankInput!
    $condition: ModelMBankConditionInput
  ) {
    createMBank(input: $input, condition: $condition) {
      id
      bankName
      bankRyaku
      bankKana
      branchName
      branchKana
      bankCode
      branchCode
      updateDate
      confirmDate
      lastUpdater
      lastUpdateDate
      createdAt
      updatedAt
    }
  }
`;
export const updateMBank = /* GraphQL */ `
  mutation UpdateMBank(
    $input: UpdateMBankInput!
    $condition: ModelMBankConditionInput
  ) {
    updateMBank(input: $input, condition: $condition) {
      id
      bankName
      bankRyaku
      bankKana
      branchName
      branchKana
      bankCode
      branchCode
      updateDate
      confirmDate
      lastUpdater
      lastUpdateDate
      createdAt
      updatedAt
    }
  }
`;
export const deleteMBank = /* GraphQL */ `
  mutation DeleteMBank(
    $input: DeleteMBankInput!
    $condition: ModelMBankConditionInput
  ) {
    deleteMBank(input: $input, condition: $condition) {
      id
      bankName
      bankRyaku
      bankKana
      branchName
      branchKana
      bankCode
      branchCode
      updateDate
      confirmDate
      lastUpdater
      lastUpdateDate
      createdAt
      updatedAt
    }
  }
`;
export const createTAnyWhereHead = /* GraphQL */ `
  mutation CreateTAnyWhereHead(
    $input: CreateTAnyWhereHeadInput!
    $condition: ModelTAnyWhereHeadConditionInput
  ) {
    createTAnyWhereHead(input: $input, condition: $condition) {
      id
      uploadDateTime
      fileName
      dataCount
      errorCount
      errorMessage
      details {
        items {
          uploadDateTime
          lineNo
          transactionId
          deviceNo
          companyCode
          merchantNumber
          merchantName
          messageType
          voucherNumber
          originalVoucherNumber
          MPNTID
          errorCode
          institutionCode
          institutionName
          consignorKana
          consignorNumber
          bankName
          bankCode
          bankBranchCode
          accountNumber
          depositType
          consignorCode
          consignorName
          transferOkNumber
          deviceType
          processingDate
          handlingTime
          accountHolder
          attachedInformation1
          attachedInformation2
          attachedInformation3
          attachedInformation4
          result
          salonMember {
            id
            storeId
            memberId
            applicationDate
            memberName
            furigana
            dateOfBirth
            zipCode
            address1
            address2
            phoneNumber
            phoneNumberMobile
            mailAddress
            bankSetDate
            bankCode
            bankName
            bankBranchCode
            bankBranchName
            depositType
            accountNumber
            depositerName
            confirmStatus
            updateDate
            confirmDate
            confirmPaper
            forceConfirm
            deleteFlag
            deleteDate
            lastUpdater
            lastUpdateDate
            useServices {
              nextToken
            }
            useServicePeriod {
              nextToken
            }
            billingdData {
              nextToken
            }
            createdAt
            updatedAt
          }
          tAnyWhereHeadDetailsId
          tAnyWhereHeadDetailsUploadDateTime
          tAnyWhereDetailSalonMemberId
        }
        nextToken
      }
    }
  }
`;
export const updateTAnyWhereHead = /* GraphQL */ `
  mutation UpdateTAnyWhereHead(
    $input: UpdateTAnyWhereHeadInput!
    $condition: ModelTAnyWhereHeadConditionInput
  ) {
    updateTAnyWhereHead(input: $input, condition: $condition) {
      id
      uploadDateTime
      fileName
      dataCount
      errorCount
      errorMessage
      details {
        items {
          uploadDateTime
          lineNo
          transactionId
          deviceNo
          companyCode
          merchantNumber
          merchantName
          messageType
          voucherNumber
          originalVoucherNumber
          MPNTID
          errorCode
          institutionCode
          institutionName
          consignorKana
          consignorNumber
          bankName
          bankCode
          bankBranchCode
          accountNumber
          depositType
          consignorCode
          consignorName
          transferOkNumber
          deviceType
          processingDate
          handlingTime
          accountHolder
          attachedInformation1
          attachedInformation2
          attachedInformation3
          attachedInformation4
          result
          salonMember {
            id
            storeId
            memberId
            applicationDate
            memberName
            furigana
            dateOfBirth
            zipCode
            address1
            address2
            phoneNumber
            phoneNumberMobile
            mailAddress
            bankSetDate
            bankCode
            bankName
            bankBranchCode
            bankBranchName
            depositType
            accountNumber
            depositerName
            confirmStatus
            updateDate
            confirmDate
            confirmPaper
            forceConfirm
            deleteFlag
            deleteDate
            lastUpdater
            lastUpdateDate
            useServices {
              nextToken
            }
            useServicePeriod {
              nextToken
            }
            billingdData {
              nextToken
            }
            createdAt
            updatedAt
          }
          tAnyWhereHeadDetailsId
          tAnyWhereHeadDetailsUploadDateTime
          tAnyWhereDetailSalonMemberId
        }
        nextToken
      }
    }
  }
`;
export const deleteTAnyWhereHead = /* GraphQL */ `
  mutation DeleteTAnyWhereHead(
    $input: DeleteTAnyWhereHeadInput!
    $condition: ModelTAnyWhereHeadConditionInput
  ) {
    deleteTAnyWhereHead(input: $input, condition: $condition) {
      id
      uploadDateTime
      fileName
      dataCount
      errorCount
      errorMessage
      details {
        items {
          uploadDateTime
          lineNo
          transactionId
          deviceNo
          companyCode
          merchantNumber
          merchantName
          messageType
          voucherNumber
          originalVoucherNumber
          MPNTID
          errorCode
          institutionCode
          institutionName
          consignorKana
          consignorNumber
          bankName
          bankCode
          bankBranchCode
          accountNumber
          depositType
          consignorCode
          consignorName
          transferOkNumber
          deviceType
          processingDate
          handlingTime
          accountHolder
          attachedInformation1
          attachedInformation2
          attachedInformation3
          attachedInformation4
          result
          salonMember {
            id
            storeId
            memberId
            applicationDate
            memberName
            furigana
            dateOfBirth
            zipCode
            address1
            address2
            phoneNumber
            phoneNumberMobile
            mailAddress
            bankSetDate
            bankCode
            bankName
            bankBranchCode
            bankBranchName
            depositType
            accountNumber
            depositerName
            confirmStatus
            updateDate
            confirmDate
            confirmPaper
            forceConfirm
            deleteFlag
            deleteDate
            lastUpdater
            lastUpdateDate
            useServices {
              nextToken
            }
            useServicePeriod {
              nextToken
            }
            billingdData {
              nextToken
            }
            createdAt
            updatedAt
          }
          tAnyWhereHeadDetailsId
          tAnyWhereHeadDetailsUploadDateTime
          tAnyWhereDetailSalonMemberId
        }
        nextToken
      }
    }
  }
`;
export const createTAnyWhereDetail = /* GraphQL */ `
  mutation CreateTAnyWhereDetail(
    $input: CreateTAnyWhereDetailInput!
    $condition: ModelTAnyWhereDetailConditionInput
  ) {
    createTAnyWhereDetail(input: $input, condition: $condition) {
      uploadDateTime
      lineNo
      transactionId
      deviceNo
      companyCode
      merchantNumber
      merchantName
      messageType
      voucherNumber
      originalVoucherNumber
      MPNTID
      errorCode
      institutionCode
      institutionName
      consignorKana
      consignorNumber
      bankName
      bankCode
      bankBranchCode
      accountNumber
      depositType
      consignorCode
      consignorName
      transferOkNumber
      deviceType
      processingDate
      handlingTime
      accountHolder
      attachedInformation1
      attachedInformation2
      attachedInformation3
      attachedInformation4
      result
      salonMember {
        id
        storeId
        memberId
        applicationDate
        memberName
        furigana
        dateOfBirth
        zipCode
        address1
        address2
        phoneNumber
        phoneNumberMobile
        mailAddress
        bankSetDate
        bankCode
        bankName
        bankBranchCode
        bankBranchName
        depositType
        accountNumber
        depositerName
        confirmStatus
        updateDate
        confirmDate
        confirmPaper
        forceConfirm
        deleteFlag
        deleteDate
        lastUpdater
        lastUpdateDate
        useServices {
          items {
            id
            mServiceID
            mSalonMemberID
            mService {
              id
              storeId
              serviceId
              courseName
              monthlyPrice
              createdAt
              updatedAt
            }
            mSalonMember {
              id
              storeId
              memberId
              applicationDate
              memberName
              furigana
              dateOfBirth
              zipCode
              address1
              address2
              phoneNumber
              phoneNumberMobile
              mailAddress
              bankSetDate
              bankCode
              bankName
              bankBranchCode
              bankBranchName
              depositType
              accountNumber
              depositerName
              confirmStatus
              updateDate
              confirmDate
              confirmPaper
              forceConfirm
              deleteFlag
              deleteDate
              lastUpdater
              lastUpdateDate
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        useServicePeriod {
          items {
            id
            serviceId
            startDate
            endDate
            createdAt
            updatedAt
            mSalonMemberUseServicePeriodId
          }
          nextToken
        }
        billingdData {
          items {
            id
            transferDate
            downloadDateTime
            lineNo
            dataPartition
            transferBankCode
            transferBankName
            transferBankBranchCode
            transferBankBranchName
            dammy1
            depositType
            accountNumber
            accountHolder
            transferAmount
            firstTransferCode
            customerNumber
            transferResultCode
            dammy2
            salonId
            memberId
            transferDate_storeId
            tBillingHeaderBillingDataId
            salonMember {
              id
              storeId
              memberId
              applicationDate
              memberName
              furigana
              dateOfBirth
              zipCode
              address1
              address2
              phoneNumber
              phoneNumberMobile
              mailAddress
              bankSetDate
              bankCode
              bankName
              bankBranchCode
              bankBranchName
              depositType
              accountNumber
              depositerName
              confirmStatus
              updateDate
              confirmDate
              confirmPaper
              forceConfirm
              deleteFlag
              deleteDate
              lastUpdater
              lastUpdateDate
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            mSalonMemberBillingdDataId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      tAnyWhereHeadDetailsId
      tAnyWhereHeadDetailsUploadDateTime
      tAnyWhereDetailSalonMemberId
    }
  }
`;
export const updateTAnyWhereDetail = /* GraphQL */ `
  mutation UpdateTAnyWhereDetail(
    $input: UpdateTAnyWhereDetailInput!
    $condition: ModelTAnyWhereDetailConditionInput
  ) {
    updateTAnyWhereDetail(input: $input, condition: $condition) {
      uploadDateTime
      lineNo
      transactionId
      deviceNo
      companyCode
      merchantNumber
      merchantName
      messageType
      voucherNumber
      originalVoucherNumber
      MPNTID
      errorCode
      institutionCode
      institutionName
      consignorKana
      consignorNumber
      bankName
      bankCode
      bankBranchCode
      accountNumber
      depositType
      consignorCode
      consignorName
      transferOkNumber
      deviceType
      processingDate
      handlingTime
      accountHolder
      attachedInformation1
      attachedInformation2
      attachedInformation3
      attachedInformation4
      result
      salonMember {
        id
        storeId
        memberId
        applicationDate
        memberName
        furigana
        dateOfBirth
        zipCode
        address1
        address2
        phoneNumber
        phoneNumberMobile
        mailAddress
        bankSetDate
        bankCode
        bankName
        bankBranchCode
        bankBranchName
        depositType
        accountNumber
        depositerName
        confirmStatus
        updateDate
        confirmDate
        confirmPaper
        forceConfirm
        deleteFlag
        deleteDate
        lastUpdater
        lastUpdateDate
        useServices {
          items {
            id
            mServiceID
            mSalonMemberID
            mService {
              id
              storeId
              serviceId
              courseName
              monthlyPrice
              createdAt
              updatedAt
            }
            mSalonMember {
              id
              storeId
              memberId
              applicationDate
              memberName
              furigana
              dateOfBirth
              zipCode
              address1
              address2
              phoneNumber
              phoneNumberMobile
              mailAddress
              bankSetDate
              bankCode
              bankName
              bankBranchCode
              bankBranchName
              depositType
              accountNumber
              depositerName
              confirmStatus
              updateDate
              confirmDate
              confirmPaper
              forceConfirm
              deleteFlag
              deleteDate
              lastUpdater
              lastUpdateDate
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        useServicePeriod {
          items {
            id
            serviceId
            startDate
            endDate
            createdAt
            updatedAt
            mSalonMemberUseServicePeriodId
          }
          nextToken
        }
        billingdData {
          items {
            id
            transferDate
            downloadDateTime
            lineNo
            dataPartition
            transferBankCode
            transferBankName
            transferBankBranchCode
            transferBankBranchName
            dammy1
            depositType
            accountNumber
            accountHolder
            transferAmount
            firstTransferCode
            customerNumber
            transferResultCode
            dammy2
            salonId
            memberId
            transferDate_storeId
            tBillingHeaderBillingDataId
            salonMember {
              id
              storeId
              memberId
              applicationDate
              memberName
              furigana
              dateOfBirth
              zipCode
              address1
              address2
              phoneNumber
              phoneNumberMobile
              mailAddress
              bankSetDate
              bankCode
              bankName
              bankBranchCode
              bankBranchName
              depositType
              accountNumber
              depositerName
              confirmStatus
              updateDate
              confirmDate
              confirmPaper
              forceConfirm
              deleteFlag
              deleteDate
              lastUpdater
              lastUpdateDate
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            mSalonMemberBillingdDataId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      tAnyWhereHeadDetailsId
      tAnyWhereHeadDetailsUploadDateTime
      tAnyWhereDetailSalonMemberId
    }
  }
`;
export const deleteTAnyWhereDetail = /* GraphQL */ `
  mutation DeleteTAnyWhereDetail(
    $input: DeleteTAnyWhereDetailInput!
    $condition: ModelTAnyWhereDetailConditionInput
  ) {
    deleteTAnyWhereDetail(input: $input, condition: $condition) {
      uploadDateTime
      lineNo
      transactionId
      deviceNo
      companyCode
      merchantNumber
      merchantName
      messageType
      voucherNumber
      originalVoucherNumber
      MPNTID
      errorCode
      institutionCode
      institutionName
      consignorKana
      consignorNumber
      bankName
      bankCode
      bankBranchCode
      accountNumber
      depositType
      consignorCode
      consignorName
      transferOkNumber
      deviceType
      processingDate
      handlingTime
      accountHolder
      attachedInformation1
      attachedInformation2
      attachedInformation3
      attachedInformation4
      result
      salonMember {
        id
        storeId
        memberId
        applicationDate
        memberName
        furigana
        dateOfBirth
        zipCode
        address1
        address2
        phoneNumber
        phoneNumberMobile
        mailAddress
        bankSetDate
        bankCode
        bankName
        bankBranchCode
        bankBranchName
        depositType
        accountNumber
        depositerName
        confirmStatus
        updateDate
        confirmDate
        confirmPaper
        forceConfirm
        deleteFlag
        deleteDate
        lastUpdater
        lastUpdateDate
        useServices {
          items {
            id
            mServiceID
            mSalonMemberID
            mService {
              id
              storeId
              serviceId
              courseName
              monthlyPrice
              createdAt
              updatedAt
            }
            mSalonMember {
              id
              storeId
              memberId
              applicationDate
              memberName
              furigana
              dateOfBirth
              zipCode
              address1
              address2
              phoneNumber
              phoneNumberMobile
              mailAddress
              bankSetDate
              bankCode
              bankName
              bankBranchCode
              bankBranchName
              depositType
              accountNumber
              depositerName
              confirmStatus
              updateDate
              confirmDate
              confirmPaper
              forceConfirm
              deleteFlag
              deleteDate
              lastUpdater
              lastUpdateDate
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        useServicePeriod {
          items {
            id
            serviceId
            startDate
            endDate
            createdAt
            updatedAt
            mSalonMemberUseServicePeriodId
          }
          nextToken
        }
        billingdData {
          items {
            id
            transferDate
            downloadDateTime
            lineNo
            dataPartition
            transferBankCode
            transferBankName
            transferBankBranchCode
            transferBankBranchName
            dammy1
            depositType
            accountNumber
            accountHolder
            transferAmount
            firstTransferCode
            customerNumber
            transferResultCode
            dammy2
            salonId
            memberId
            transferDate_storeId
            tBillingHeaderBillingDataId
            salonMember {
              id
              storeId
              memberId
              applicationDate
              memberName
              furigana
              dateOfBirth
              zipCode
              address1
              address2
              phoneNumber
              phoneNumberMobile
              mailAddress
              bankSetDate
              bankCode
              bankName
              bankBranchCode
              bankBranchName
              depositType
              accountNumber
              depositerName
              confirmStatus
              updateDate
              confirmDate
              confirmPaper
              forceConfirm
              deleteFlag
              deleteDate
              lastUpdater
              lastUpdateDate
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            mSalonMemberBillingdDataId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      tAnyWhereHeadDetailsId
      tAnyWhereHeadDetailsUploadDateTime
      tAnyWhereDetailSalonMemberId
    }
  }
`;
export const createTBillingHeader = /* GraphQL */ `
  mutation CreateTBillingHeader(
    $input: CreateTBillingHeaderInput!
    $condition: ModelTBillingHeaderConditionInput
  ) {
    createTBillingHeader(input: $input, condition: $condition) {
      id
      classification
      dataPartition
      typeCode
      codePartition
      consignorCode
      consignorName
      transferYear
      transferDate
      totalNumber
      totalFee
      totalNumberOfSuccess
      totalFeeOfSuccess
      totalNumberOfFailure
      totalFeeOfFailure
      status
      downloadDateTime
      downloadFileName
      uploadDateTime
      uploadFileName
      billingData {
        items {
          id
          transferDate
          downloadDateTime
          lineNo
          dataPartition
          transferBankCode
          transferBankName
          transferBankBranchCode
          transferBankBranchName
          dammy1
          depositType
          accountNumber
          accountHolder
          transferAmount
          firstTransferCode
          customerNumber
          transferResultCode
          dammy2
          salonId
          memberId
          transferDate_storeId
          tBillingHeaderBillingDataId
          salonMember {
            id
            storeId
            memberId
            applicationDate
            memberName
            furigana
            dateOfBirth
            zipCode
            address1
            address2
            phoneNumber
            phoneNumberMobile
            mailAddress
            bankSetDate
            bankCode
            bankName
            bankBranchCode
            bankBranchName
            depositType
            accountNumber
            depositerName
            confirmStatus
            updateDate
            confirmDate
            confirmPaper
            forceConfirm
            deleteFlag
            deleteDate
            lastUpdater
            lastUpdateDate
            useServices {
              nextToken
            }
            useServicePeriod {
              nextToken
            }
            billingdData {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          mSalonMemberBillingdDataId
        }
        nextToken
      }
      headerDammy
      trailerDammy
      trailerPartition
      createdAt
      updatedAt
    }
  }
`;
export const updateTBillingHeader = /* GraphQL */ `
  mutation UpdateTBillingHeader(
    $input: UpdateTBillingHeaderInput!
    $condition: ModelTBillingHeaderConditionInput
  ) {
    updateTBillingHeader(input: $input, condition: $condition) {
      id
      classification
      dataPartition
      typeCode
      codePartition
      consignorCode
      consignorName
      transferYear
      transferDate
      totalNumber
      totalFee
      totalNumberOfSuccess
      totalFeeOfSuccess
      totalNumberOfFailure
      totalFeeOfFailure
      status
      downloadDateTime
      downloadFileName
      uploadDateTime
      uploadFileName
      billingData {
        items {
          id
          transferDate
          downloadDateTime
          lineNo
          dataPartition
          transferBankCode
          transferBankName
          transferBankBranchCode
          transferBankBranchName
          dammy1
          depositType
          accountNumber
          accountHolder
          transferAmount
          firstTransferCode
          customerNumber
          transferResultCode
          dammy2
          salonId
          memberId
          transferDate_storeId
          tBillingHeaderBillingDataId
          salonMember {
            id
            storeId
            memberId
            applicationDate
            memberName
            furigana
            dateOfBirth
            zipCode
            address1
            address2
            phoneNumber
            phoneNumberMobile
            mailAddress
            bankSetDate
            bankCode
            bankName
            bankBranchCode
            bankBranchName
            depositType
            accountNumber
            depositerName
            confirmStatus
            updateDate
            confirmDate
            confirmPaper
            forceConfirm
            deleteFlag
            deleteDate
            lastUpdater
            lastUpdateDate
            useServices {
              nextToken
            }
            useServicePeriod {
              nextToken
            }
            billingdData {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          mSalonMemberBillingdDataId
        }
        nextToken
      }
      headerDammy
      trailerDammy
      trailerPartition
      createdAt
      updatedAt
    }
  }
`;
export const deleteTBillingHeader = /* GraphQL */ `
  mutation DeleteTBillingHeader(
    $input: DeleteTBillingHeaderInput!
    $condition: ModelTBillingHeaderConditionInput
  ) {
    deleteTBillingHeader(input: $input, condition: $condition) {
      id
      classification
      dataPartition
      typeCode
      codePartition
      consignorCode
      consignorName
      transferYear
      transferDate
      totalNumber
      totalFee
      totalNumberOfSuccess
      totalFeeOfSuccess
      totalNumberOfFailure
      totalFeeOfFailure
      status
      downloadDateTime
      downloadFileName
      uploadDateTime
      uploadFileName
      billingData {
        items {
          id
          transferDate
          downloadDateTime
          lineNo
          dataPartition
          transferBankCode
          transferBankName
          transferBankBranchCode
          transferBankBranchName
          dammy1
          depositType
          accountNumber
          accountHolder
          transferAmount
          firstTransferCode
          customerNumber
          transferResultCode
          dammy2
          salonId
          memberId
          transferDate_storeId
          tBillingHeaderBillingDataId
          salonMember {
            id
            storeId
            memberId
            applicationDate
            memberName
            furigana
            dateOfBirth
            zipCode
            address1
            address2
            phoneNumber
            phoneNumberMobile
            mailAddress
            bankSetDate
            bankCode
            bankName
            bankBranchCode
            bankBranchName
            depositType
            accountNumber
            depositerName
            confirmStatus
            updateDate
            confirmDate
            confirmPaper
            forceConfirm
            deleteFlag
            deleteDate
            lastUpdater
            lastUpdateDate
            useServices {
              nextToken
            }
            useServicePeriod {
              nextToken
            }
            billingdData {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          mSalonMemberBillingdDataId
        }
        nextToken
      }
      headerDammy
      trailerDammy
      trailerPartition
      createdAt
      updatedAt
    }
  }
`;
export const createTBillingData = /* GraphQL */ `
  mutation CreateTBillingData(
    $input: CreateTBillingDataInput!
    $condition: ModelTBillingDataConditionInput
  ) {
    createTBillingData(input: $input, condition: $condition) {
      id
      transferDate
      downloadDateTime
      lineNo
      dataPartition
      transferBankCode
      transferBankName
      transferBankBranchCode
      transferBankBranchName
      dammy1
      depositType
      accountNumber
      accountHolder
      transferAmount
      firstTransferCode
      customerNumber
      transferResultCode
      dammy2
      salonId
      memberId
      transferDate_storeId
      tBillingHeaderBillingDataId
      salonMember {
        id
        storeId
        memberId
        applicationDate
        memberName
        furigana
        dateOfBirth
        zipCode
        address1
        address2
        phoneNumber
        phoneNumberMobile
        mailAddress
        bankSetDate
        bankCode
        bankName
        bankBranchCode
        bankBranchName
        depositType
        accountNumber
        depositerName
        confirmStatus
        updateDate
        confirmDate
        confirmPaper
        forceConfirm
        deleteFlag
        deleteDate
        lastUpdater
        lastUpdateDate
        useServices {
          items {
            id
            mServiceID
            mSalonMemberID
            mService {
              id
              storeId
              serviceId
              courseName
              monthlyPrice
              createdAt
              updatedAt
            }
            mSalonMember {
              id
              storeId
              memberId
              applicationDate
              memberName
              furigana
              dateOfBirth
              zipCode
              address1
              address2
              phoneNumber
              phoneNumberMobile
              mailAddress
              bankSetDate
              bankCode
              bankName
              bankBranchCode
              bankBranchName
              depositType
              accountNumber
              depositerName
              confirmStatus
              updateDate
              confirmDate
              confirmPaper
              forceConfirm
              deleteFlag
              deleteDate
              lastUpdater
              lastUpdateDate
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        useServicePeriod {
          items {
            id
            serviceId
            startDate
            endDate
            createdAt
            updatedAt
            mSalonMemberUseServicePeriodId
          }
          nextToken
        }
        billingdData {
          items {
            id
            transferDate
            downloadDateTime
            lineNo
            dataPartition
            transferBankCode
            transferBankName
            transferBankBranchCode
            transferBankBranchName
            dammy1
            depositType
            accountNumber
            accountHolder
            transferAmount
            firstTransferCode
            customerNumber
            transferResultCode
            dammy2
            salonId
            memberId
            transferDate_storeId
            tBillingHeaderBillingDataId
            salonMember {
              id
              storeId
              memberId
              applicationDate
              memberName
              furigana
              dateOfBirth
              zipCode
              address1
              address2
              phoneNumber
              phoneNumberMobile
              mailAddress
              bankSetDate
              bankCode
              bankName
              bankBranchCode
              bankBranchName
              depositType
              accountNumber
              depositerName
              confirmStatus
              updateDate
              confirmDate
              confirmPaper
              forceConfirm
              deleteFlag
              deleteDate
              lastUpdater
              lastUpdateDate
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            mSalonMemberBillingdDataId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      mSalonMemberBillingdDataId
    }
  }
`;
export const updateTBillingData = /* GraphQL */ `
  mutation UpdateTBillingData(
    $input: UpdateTBillingDataInput!
    $condition: ModelTBillingDataConditionInput
  ) {
    updateTBillingData(input: $input, condition: $condition) {
      id
      transferDate
      downloadDateTime
      lineNo
      dataPartition
      transferBankCode
      transferBankName
      transferBankBranchCode
      transferBankBranchName
      dammy1
      depositType
      accountNumber
      accountHolder
      transferAmount
      firstTransferCode
      customerNumber
      transferResultCode
      dammy2
      salonId
      memberId
      transferDate_storeId
      tBillingHeaderBillingDataId
      salonMember {
        id
        storeId
        memberId
        applicationDate
        memberName
        furigana
        dateOfBirth
        zipCode
        address1
        address2
        phoneNumber
        phoneNumberMobile
        mailAddress
        bankSetDate
        bankCode
        bankName
        bankBranchCode
        bankBranchName
        depositType
        accountNumber
        depositerName
        confirmStatus
        updateDate
        confirmDate
        confirmPaper
        forceConfirm
        deleteFlag
        deleteDate
        lastUpdater
        lastUpdateDate
        useServices {
          items {
            id
            mServiceID
            mSalonMemberID
            mService {
              id
              storeId
              serviceId
              courseName
              monthlyPrice
              createdAt
              updatedAt
            }
            mSalonMember {
              id
              storeId
              memberId
              applicationDate
              memberName
              furigana
              dateOfBirth
              zipCode
              address1
              address2
              phoneNumber
              phoneNumberMobile
              mailAddress
              bankSetDate
              bankCode
              bankName
              bankBranchCode
              bankBranchName
              depositType
              accountNumber
              depositerName
              confirmStatus
              updateDate
              confirmDate
              confirmPaper
              forceConfirm
              deleteFlag
              deleteDate
              lastUpdater
              lastUpdateDate
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        useServicePeriod {
          items {
            id
            serviceId
            startDate
            endDate
            createdAt
            updatedAt
            mSalonMemberUseServicePeriodId
          }
          nextToken
        }
        billingdData {
          items {
            id
            transferDate
            downloadDateTime
            lineNo
            dataPartition
            transferBankCode
            transferBankName
            transferBankBranchCode
            transferBankBranchName
            dammy1
            depositType
            accountNumber
            accountHolder
            transferAmount
            firstTransferCode
            customerNumber
            transferResultCode
            dammy2
            salonId
            memberId
            transferDate_storeId
            tBillingHeaderBillingDataId
            salonMember {
              id
              storeId
              memberId
              applicationDate
              memberName
              furigana
              dateOfBirth
              zipCode
              address1
              address2
              phoneNumber
              phoneNumberMobile
              mailAddress
              bankSetDate
              bankCode
              bankName
              bankBranchCode
              bankBranchName
              depositType
              accountNumber
              depositerName
              confirmStatus
              updateDate
              confirmDate
              confirmPaper
              forceConfirm
              deleteFlag
              deleteDate
              lastUpdater
              lastUpdateDate
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            mSalonMemberBillingdDataId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      mSalonMemberBillingdDataId
    }
  }
`;
export const deleteTBillingData = /* GraphQL */ `
  mutation DeleteTBillingData(
    $input: DeleteTBillingDataInput!
    $condition: ModelTBillingDataConditionInput
  ) {
    deleteTBillingData(input: $input, condition: $condition) {
      id
      transferDate
      downloadDateTime
      lineNo
      dataPartition
      transferBankCode
      transferBankName
      transferBankBranchCode
      transferBankBranchName
      dammy1
      depositType
      accountNumber
      accountHolder
      transferAmount
      firstTransferCode
      customerNumber
      transferResultCode
      dammy2
      salonId
      memberId
      transferDate_storeId
      tBillingHeaderBillingDataId
      salonMember {
        id
        storeId
        memberId
        applicationDate
        memberName
        furigana
        dateOfBirth
        zipCode
        address1
        address2
        phoneNumber
        phoneNumberMobile
        mailAddress
        bankSetDate
        bankCode
        bankName
        bankBranchCode
        bankBranchName
        depositType
        accountNumber
        depositerName
        confirmStatus
        updateDate
        confirmDate
        confirmPaper
        forceConfirm
        deleteFlag
        deleteDate
        lastUpdater
        lastUpdateDate
        useServices {
          items {
            id
            mServiceID
            mSalonMemberID
            mService {
              id
              storeId
              serviceId
              courseName
              monthlyPrice
              createdAt
              updatedAt
            }
            mSalonMember {
              id
              storeId
              memberId
              applicationDate
              memberName
              furigana
              dateOfBirth
              zipCode
              address1
              address2
              phoneNumber
              phoneNumberMobile
              mailAddress
              bankSetDate
              bankCode
              bankName
              bankBranchCode
              bankBranchName
              depositType
              accountNumber
              depositerName
              confirmStatus
              updateDate
              confirmDate
              confirmPaper
              forceConfirm
              deleteFlag
              deleteDate
              lastUpdater
              lastUpdateDate
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        useServicePeriod {
          items {
            id
            serviceId
            startDate
            endDate
            createdAt
            updatedAt
            mSalonMemberUseServicePeriodId
          }
          nextToken
        }
        billingdData {
          items {
            id
            transferDate
            downloadDateTime
            lineNo
            dataPartition
            transferBankCode
            transferBankName
            transferBankBranchCode
            transferBankBranchName
            dammy1
            depositType
            accountNumber
            accountHolder
            transferAmount
            firstTransferCode
            customerNumber
            transferResultCode
            dammy2
            salonId
            memberId
            transferDate_storeId
            tBillingHeaderBillingDataId
            salonMember {
              id
              storeId
              memberId
              applicationDate
              memberName
              furigana
              dateOfBirth
              zipCode
              address1
              address2
              phoneNumber
              phoneNumberMobile
              mailAddress
              bankSetDate
              bankCode
              bankName
              bankBranchCode
              bankBranchName
              depositType
              accountNumber
              depositerName
              confirmStatus
              updateDate
              confirmDate
              confirmPaper
              forceConfirm
              deleteFlag
              deleteDate
              lastUpdater
              lastUpdateDate
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            mSalonMemberBillingdDataId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      mSalonMemberBillingdDataId
    }
  }
`;
export const createTUploadHeader = /* GraphQL */ `
  mutation CreateTUploadHeader(
    $input: CreateTUploadHeaderInput!
    $condition: ModelTUploadHeaderConditionInput
  ) {
    createTUploadHeader(input: $input, condition: $condition) {
      id
      classification
      uploadDateTime
      uploadFileName
      dataPartition
      typeCode
      codePartition
      consignorCode
      consignorName
      transferYear
      transferDate
      totalNumber
      totalFee
      totalNumberOfSuccess
      totalFeeOfSuccess
      totalNumberOfFailure
      totalFeeOfFailure
      uploadData {
        items {
          uploadDateTime
          lineNo
          dataPartition
          transferBankCode
          transferBankName
          transferBankBranchCode
          transferBankBranchName
          depositType
          accountNumber
          accountHolder
          transferAmount
          firstTransferCode
          customerNumber
          transferResultCode
          memberId_TransferDate
          salonId_TransferDate
          tUploadHeaderUploadDataId
        }
        nextToken
      }
    }
  }
`;
export const updateTUploadHeader = /* GraphQL */ `
  mutation UpdateTUploadHeader(
    $input: UpdateTUploadHeaderInput!
    $condition: ModelTUploadHeaderConditionInput
  ) {
    updateTUploadHeader(input: $input, condition: $condition) {
      id
      classification
      uploadDateTime
      uploadFileName
      dataPartition
      typeCode
      codePartition
      consignorCode
      consignorName
      transferYear
      transferDate
      totalNumber
      totalFee
      totalNumberOfSuccess
      totalFeeOfSuccess
      totalNumberOfFailure
      totalFeeOfFailure
      uploadData {
        items {
          uploadDateTime
          lineNo
          dataPartition
          transferBankCode
          transferBankName
          transferBankBranchCode
          transferBankBranchName
          depositType
          accountNumber
          accountHolder
          transferAmount
          firstTransferCode
          customerNumber
          transferResultCode
          memberId_TransferDate
          salonId_TransferDate
          tUploadHeaderUploadDataId
        }
        nextToken
      }
    }
  }
`;
export const deleteTUploadHeader = /* GraphQL */ `
  mutation DeleteTUploadHeader(
    $input: DeleteTUploadHeaderInput!
    $condition: ModelTUploadHeaderConditionInput
  ) {
    deleteTUploadHeader(input: $input, condition: $condition) {
      id
      classification
      uploadDateTime
      uploadFileName
      dataPartition
      typeCode
      codePartition
      consignorCode
      consignorName
      transferYear
      transferDate
      totalNumber
      totalFee
      totalNumberOfSuccess
      totalFeeOfSuccess
      totalNumberOfFailure
      totalFeeOfFailure
      uploadData {
        items {
          uploadDateTime
          lineNo
          dataPartition
          transferBankCode
          transferBankName
          transferBankBranchCode
          transferBankBranchName
          depositType
          accountNumber
          accountHolder
          transferAmount
          firstTransferCode
          customerNumber
          transferResultCode
          memberId_TransferDate
          salonId_TransferDate
          tUploadHeaderUploadDataId
        }
        nextToken
      }
    }
  }
`;
export const createTUploadData = /* GraphQL */ `
  mutation CreateTUploadData(
    $input: CreateTUploadDataInput!
    $condition: ModelTUploadDataConditionInput
  ) {
    createTUploadData(input: $input, condition: $condition) {
      uploadDateTime
      lineNo
      dataPartition
      transferBankCode
      transferBankName
      transferBankBranchCode
      transferBankBranchName
      depositType
      accountNumber
      accountHolder
      transferAmount
      firstTransferCode
      customerNumber
      transferResultCode
      memberId_TransferDate
      salonId_TransferDate
      tUploadHeaderUploadDataId
    }
  }
`;
export const updateTUploadData = /* GraphQL */ `
  mutation UpdateTUploadData(
    $input: UpdateTUploadDataInput!
    $condition: ModelTUploadDataConditionInput
  ) {
    updateTUploadData(input: $input, condition: $condition) {
      uploadDateTime
      lineNo
      dataPartition
      transferBankCode
      transferBankName
      transferBankBranchCode
      transferBankBranchName
      depositType
      accountNumber
      accountHolder
      transferAmount
      firstTransferCode
      customerNumber
      transferResultCode
      memberId_TransferDate
      salonId_TransferDate
      tUploadHeaderUploadDataId
    }
  }
`;
export const deleteTUploadData = /* GraphQL */ `
  mutation DeleteTUploadData(
    $input: DeleteTUploadDataInput!
    $condition: ModelTUploadDataConditionInput
  ) {
    deleteTUploadData(input: $input, condition: $condition) {
      uploadDateTime
      lineNo
      dataPartition
      transferBankCode
      transferBankName
      transferBankBranchCode
      transferBankBranchName
      depositType
      accountNumber
      accountHolder
      transferAmount
      firstTransferCode
      customerNumber
      transferResultCode
      memberId_TransferDate
      salonId_TransferDate
      tUploadHeaderUploadDataId
    }
  }
`;
export const createTSalonPayment = /* GraphQL */ `
  mutation CreateTSalonPayment(
    $input: CreateTSalonPaymentInput!
    $condition: ModelTSalonPaymentConditionInput
  ) {
    createTSalonPayment(input: $input, condition: $condition) {
      id
      storeId
      storeName
      transferDate
      totalNumber
      totalFee
      totalNumberOfSuccess
      totalFeeOfSuccess
      totalNumberOfFailure
      totalFeeOfFailure
      basicCharge
      pricePerCase
      bankRegistFee
      bankRegistNumber
      createdAt
      updatedAt
    }
  }
`;
export const updateTSalonPayment = /* GraphQL */ `
  mutation UpdateTSalonPayment(
    $input: UpdateTSalonPaymentInput!
    $condition: ModelTSalonPaymentConditionInput
  ) {
    updateTSalonPayment(input: $input, condition: $condition) {
      id
      storeId
      storeName
      transferDate
      totalNumber
      totalFee
      totalNumberOfSuccess
      totalFeeOfSuccess
      totalNumberOfFailure
      totalFeeOfFailure
      basicCharge
      pricePerCase
      bankRegistFee
      bankRegistNumber
      createdAt
      updatedAt
    }
  }
`;
export const deleteTSalonPayment = /* GraphQL */ `
  mutation DeleteTSalonPayment(
    $input: DeleteTSalonPaymentInput!
    $condition: ModelTSalonPaymentConditionInput
  ) {
    deleteTSalonPayment(input: $input, condition: $condition) {
      id
      storeId
      storeName
      transferDate
      totalNumber
      totalFee
      totalNumberOfSuccess
      totalFeeOfSuccess
      totalNumberOfFailure
      totalFeeOfFailure
      basicCharge
      pricePerCase
      bankRegistFee
      bankRegistNumber
      createdAt
      updatedAt
    }
  }
`;
export const createTUploadHeadUUIDs = /* GraphQL */ `
  mutation CreateTUploadHeadUUIDs(
    $input: CreateTUploadHeadUUIDsInput!
    $condition: ModelTUploadHeadUUIDsConditionInput
  ) {
    createTUploadHeadUUIDs(input: $input, condition: $condition) {
      id
      UUID
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateTUploadHeadUUIDs = /* GraphQL */ `
  mutation UpdateTUploadHeadUUIDs(
    $input: UpdateTUploadHeadUUIDsInput!
    $condition: ModelTUploadHeadUUIDsConditionInput
  ) {
    updateTUploadHeadUUIDs(input: $input, condition: $condition) {
      id
      UUID
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteTUploadHeadUUIDs = /* GraphQL */ `
  mutation DeleteTUploadHeadUUIDs(
    $input: DeleteTUploadHeadUUIDsInput!
    $condition: ModelTUploadHeadUUIDsConditionInput
  ) {
    deleteTUploadHeadUUIDs(input: $input, condition: $condition) {
      id
      UUID
      status
      createdAt
      updatedAt
    }
  }
`;
export const createMemberUseServices = /* GraphQL */ `
  mutation CreateMemberUseServices(
    $input: CreateMemberUseServicesInput!
    $condition: ModelMemberUseServicesConditionInput
  ) {
    createMemberUseServices(input: $input, condition: $condition) {
      id
      mServiceID
      mSalonMemberID
      mService {
        id
        storeId
        serviceId
        courseName
        monthlyPrice
        salonMembers {
          items {
            id
            mServiceID
            mSalonMemberID
            mService {
              id
              storeId
              serviceId
              courseName
              monthlyPrice
              createdAt
              updatedAt
            }
            mSalonMember {
              id
              storeId
              memberId
              applicationDate
              memberName
              furigana
              dateOfBirth
              zipCode
              address1
              address2
              phoneNumber
              phoneNumberMobile
              mailAddress
              bankSetDate
              bankCode
              bankName
              bankBranchCode
              bankBranchName
              depositType
              accountNumber
              depositerName
              confirmStatus
              updateDate
              confirmDate
              confirmPaper
              forceConfirm
              deleteFlag
              deleteDate
              lastUpdater
              lastUpdateDate
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      mSalonMember {
        id
        storeId
        memberId
        applicationDate
        memberName
        furigana
        dateOfBirth
        zipCode
        address1
        address2
        phoneNumber
        phoneNumberMobile
        mailAddress
        bankSetDate
        bankCode
        bankName
        bankBranchCode
        bankBranchName
        depositType
        accountNumber
        depositerName
        confirmStatus
        updateDate
        confirmDate
        confirmPaper
        forceConfirm
        deleteFlag
        deleteDate
        lastUpdater
        lastUpdateDate
        useServices {
          items {
            id
            mServiceID
            mSalonMemberID
            mService {
              id
              storeId
              serviceId
              courseName
              monthlyPrice
              createdAt
              updatedAt
            }
            mSalonMember {
              id
              storeId
              memberId
              applicationDate
              memberName
              furigana
              dateOfBirth
              zipCode
              address1
              address2
              phoneNumber
              phoneNumberMobile
              mailAddress
              bankSetDate
              bankCode
              bankName
              bankBranchCode
              bankBranchName
              depositType
              accountNumber
              depositerName
              confirmStatus
              updateDate
              confirmDate
              confirmPaper
              forceConfirm
              deleteFlag
              deleteDate
              lastUpdater
              lastUpdateDate
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        useServicePeriod {
          items {
            id
            serviceId
            startDate
            endDate
            createdAt
            updatedAt
            mSalonMemberUseServicePeriodId
          }
          nextToken
        }
        billingdData {
          items {
            id
            transferDate
            downloadDateTime
            lineNo
            dataPartition
            transferBankCode
            transferBankName
            transferBankBranchCode
            transferBankBranchName
            dammy1
            depositType
            accountNumber
            accountHolder
            transferAmount
            firstTransferCode
            customerNumber
            transferResultCode
            dammy2
            salonId
            memberId
            transferDate_storeId
            tBillingHeaderBillingDataId
            salonMember {
              id
              storeId
              memberId
              applicationDate
              memberName
              furigana
              dateOfBirth
              zipCode
              address1
              address2
              phoneNumber
              phoneNumberMobile
              mailAddress
              bankSetDate
              bankCode
              bankName
              bankBranchCode
              bankBranchName
              depositType
              accountNumber
              depositerName
              confirmStatus
              updateDate
              confirmDate
              confirmPaper
              forceConfirm
              deleteFlag
              deleteDate
              lastUpdater
              lastUpdateDate
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            mSalonMemberBillingdDataId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateMemberUseServices = /* GraphQL */ `
  mutation UpdateMemberUseServices(
    $input: UpdateMemberUseServicesInput!
    $condition: ModelMemberUseServicesConditionInput
  ) {
    updateMemberUseServices(input: $input, condition: $condition) {
      id
      mServiceID
      mSalonMemberID
      mService {
        id
        storeId
        serviceId
        courseName
        monthlyPrice
        salonMembers {
          items {
            id
            mServiceID
            mSalonMemberID
            mService {
              id
              storeId
              serviceId
              courseName
              monthlyPrice
              createdAt
              updatedAt
            }
            mSalonMember {
              id
              storeId
              memberId
              applicationDate
              memberName
              furigana
              dateOfBirth
              zipCode
              address1
              address2
              phoneNumber
              phoneNumberMobile
              mailAddress
              bankSetDate
              bankCode
              bankName
              bankBranchCode
              bankBranchName
              depositType
              accountNumber
              depositerName
              confirmStatus
              updateDate
              confirmDate
              confirmPaper
              forceConfirm
              deleteFlag
              deleteDate
              lastUpdater
              lastUpdateDate
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      mSalonMember {
        id
        storeId
        memberId
        applicationDate
        memberName
        furigana
        dateOfBirth
        zipCode
        address1
        address2
        phoneNumber
        phoneNumberMobile
        mailAddress
        bankSetDate
        bankCode
        bankName
        bankBranchCode
        bankBranchName
        depositType
        accountNumber
        depositerName
        confirmStatus
        updateDate
        confirmDate
        confirmPaper
        forceConfirm
        deleteFlag
        deleteDate
        lastUpdater
        lastUpdateDate
        useServices {
          items {
            id
            mServiceID
            mSalonMemberID
            mService {
              id
              storeId
              serviceId
              courseName
              monthlyPrice
              createdAt
              updatedAt
            }
            mSalonMember {
              id
              storeId
              memberId
              applicationDate
              memberName
              furigana
              dateOfBirth
              zipCode
              address1
              address2
              phoneNumber
              phoneNumberMobile
              mailAddress
              bankSetDate
              bankCode
              bankName
              bankBranchCode
              bankBranchName
              depositType
              accountNumber
              depositerName
              confirmStatus
              updateDate
              confirmDate
              confirmPaper
              forceConfirm
              deleteFlag
              deleteDate
              lastUpdater
              lastUpdateDate
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        useServicePeriod {
          items {
            id
            serviceId
            startDate
            endDate
            createdAt
            updatedAt
            mSalonMemberUseServicePeriodId
          }
          nextToken
        }
        billingdData {
          items {
            id
            transferDate
            downloadDateTime
            lineNo
            dataPartition
            transferBankCode
            transferBankName
            transferBankBranchCode
            transferBankBranchName
            dammy1
            depositType
            accountNumber
            accountHolder
            transferAmount
            firstTransferCode
            customerNumber
            transferResultCode
            dammy2
            salonId
            memberId
            transferDate_storeId
            tBillingHeaderBillingDataId
            salonMember {
              id
              storeId
              memberId
              applicationDate
              memberName
              furigana
              dateOfBirth
              zipCode
              address1
              address2
              phoneNumber
              phoneNumberMobile
              mailAddress
              bankSetDate
              bankCode
              bankName
              bankBranchCode
              bankBranchName
              depositType
              accountNumber
              depositerName
              confirmStatus
              updateDate
              confirmDate
              confirmPaper
              forceConfirm
              deleteFlag
              deleteDate
              lastUpdater
              lastUpdateDate
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            mSalonMemberBillingdDataId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteMemberUseServices = /* GraphQL */ `
  mutation DeleteMemberUseServices(
    $input: DeleteMemberUseServicesInput!
    $condition: ModelMemberUseServicesConditionInput
  ) {
    deleteMemberUseServices(input: $input, condition: $condition) {
      id
      mServiceID
      mSalonMemberID
      mService {
        id
        storeId
        serviceId
        courseName
        monthlyPrice
        salonMembers {
          items {
            id
            mServiceID
            mSalonMemberID
            mService {
              id
              storeId
              serviceId
              courseName
              monthlyPrice
              createdAt
              updatedAt
            }
            mSalonMember {
              id
              storeId
              memberId
              applicationDate
              memberName
              furigana
              dateOfBirth
              zipCode
              address1
              address2
              phoneNumber
              phoneNumberMobile
              mailAddress
              bankSetDate
              bankCode
              bankName
              bankBranchCode
              bankBranchName
              depositType
              accountNumber
              depositerName
              confirmStatus
              updateDate
              confirmDate
              confirmPaper
              forceConfirm
              deleteFlag
              deleteDate
              lastUpdater
              lastUpdateDate
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      mSalonMember {
        id
        storeId
        memberId
        applicationDate
        memberName
        furigana
        dateOfBirth
        zipCode
        address1
        address2
        phoneNumber
        phoneNumberMobile
        mailAddress
        bankSetDate
        bankCode
        bankName
        bankBranchCode
        bankBranchName
        depositType
        accountNumber
        depositerName
        confirmStatus
        updateDate
        confirmDate
        confirmPaper
        forceConfirm
        deleteFlag
        deleteDate
        lastUpdater
        lastUpdateDate
        useServices {
          items {
            id
            mServiceID
            mSalonMemberID
            mService {
              id
              storeId
              serviceId
              courseName
              monthlyPrice
              createdAt
              updatedAt
            }
            mSalonMember {
              id
              storeId
              memberId
              applicationDate
              memberName
              furigana
              dateOfBirth
              zipCode
              address1
              address2
              phoneNumber
              phoneNumberMobile
              mailAddress
              bankSetDate
              bankCode
              bankName
              bankBranchCode
              bankBranchName
              depositType
              accountNumber
              depositerName
              confirmStatus
              updateDate
              confirmDate
              confirmPaper
              forceConfirm
              deleteFlag
              deleteDate
              lastUpdater
              lastUpdateDate
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        useServicePeriod {
          items {
            id
            serviceId
            startDate
            endDate
            createdAt
            updatedAt
            mSalonMemberUseServicePeriodId
          }
          nextToken
        }
        billingdData {
          items {
            id
            transferDate
            downloadDateTime
            lineNo
            dataPartition
            transferBankCode
            transferBankName
            transferBankBranchCode
            transferBankBranchName
            dammy1
            depositType
            accountNumber
            accountHolder
            transferAmount
            firstTransferCode
            customerNumber
            transferResultCode
            dammy2
            salonId
            memberId
            transferDate_storeId
            tBillingHeaderBillingDataId
            salonMember {
              id
              storeId
              memberId
              applicationDate
              memberName
              furigana
              dateOfBirth
              zipCode
              address1
              address2
              phoneNumber
              phoneNumberMobile
              mailAddress
              bankSetDate
              bankCode
              bankName
              bankBranchCode
              bankBranchName
              depositType
              accountNumber
              depositerName
              confirmStatus
              updateDate
              confirmDate
              confirmPaper
              forceConfirm
              deleteFlag
              deleteDate
              lastUpdater
              lastUpdateDate
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            mSalonMemberBillingdDataId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
