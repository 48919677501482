import { useNavigate, useLocation } from "react-router-dom"
import { Header } from "../atoms/Header"
import { Footer } from "../atoms/Footer"
import React, { useEffect, useState } from "react";
//import { SelectedSalonContext } from "../providers/SelectedSalonProvider";
import { MSalon } from "../API";
import { useBilling } from "../hooks/useBilling" 
import { useSalon } from "../hooks/useSalon";
import { useSalonMembers } from "../hooks/useSalonMembers";
import { useCommonTools } from '../hooks/useCommonTools';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { Loading } from "../atoms/Loading";
import '../css/common.css';

export const StoreMemberHistory = ( props ) => {
    
    const { setUser, setAuthState } = props;

    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const memberId = query.get("memberId");

    const navigate = useNavigate();

    const [ isLoading, setIsLoading] = useState<boolean>(true);
    const { listBillingByMemberId, Billings } = useBilling();
    const { salonAccount, setSalonAccount } = useSalon();
    const { salonMember, setSalonMember, getSalonMember } = useSalonMembers();
    //const [Billings, setBillings] = useState<TBillingData[]>([]);
    const { getTransferResultString, dateFormatString } = useCommonTools();
    var [marker, setMaker] = useState<number>(10);  // ページ開始時に表示する履歴件数
    const OnClickReturn = () => navigate(`/StoreMemberDetail?memberId=${memberId}`)

    /**
     * 次のデータを表示ボタン押下
     */
    const loadList = () => {
        marker = marker + 10; // 履歴表示行を10行追加
        setMaker(marker);
    }

    const getData = async(storeId : string, memberId : string) => {
        
        // 会員情報の取得
        const salonMember = await getSalonMember(storeId, memberId);
        setSalonMember(salonMember);            // 画面表示用

        // 履歴情報の取得
        await listBillingByMemberId(salonMember.id);

        setIsLoading(false);
    }

    useEffect(() => {

        // 店舗ID・店舗名のみセッションから取得
        const storeId = sessionStorage.getItem('selectedSalon.storeId');
        let salonJson = sessionStorage.getItem('salonJson'); // JSON
        let salon = null; if (salonJson != null) salon = JSON.parse(salonJson);
        let userId = sessionStorage.getItem('userId');
        if (salon == null || userId == null) {
            //セッション情報も無い（ログアウト状態）
            //ログイン画面に遷移
            navigate('/');
            return;
        }
        setSalonAccount({ ...salon, storeId: storeId } as MSalon);
        setSalonAccount({ ...salon, storeName: salon.selectedSalon.storeName } as MSalon);


        // 履歴情報の取得
        getData(storeId, memberId);

    }, []);
    
    return (
        <>
            <Header setUser={setUser} setAuthState={setAuthState} setIsLoading={setIsLoading}/>
            {isLoading ? (
                <Loading />
            ) : (
                <div className="container mt-2 mb-5">
                    <section className="row">

                        <section className="col-12">
                            <div className="d-flex page-title-row">
                                <button className="btn" onClick={OnClickReturn}><h2 className="icon-gray"><BsArrowLeftCircle/></h2></button>
                                <header className="d-flex page-title">
                                    <h1 className="fs-4 m-1">会員　振替履歴</h1>
                                </header>
                            </div>
                        </section>
                    </section>

                    <div className="row g-3 mt-2">
                        <div className="col-md-12">
                        <h5 className="item-title">会員情報</h5>
                        <ul className="list-group mb-3 shadow-sm">
                            <li className="list-group-item d-flex justify-content-between lh-sm">
                            <div>
                                <h6 className="my-0 item-title">契約店舗</h6>
                            </div>
                            <span>{salonAccount.storeName}</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between lh-sm">
                            <div>
                                <h6 className="my-0 item-title">会員番号</h6>
                            </div>
                            <span>{salonMember.memberId}</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between lh-sm">
                            <div>
                                <h6 className="my-0 item-title">会員氏名</h6>
                            </div>
                            <span>{salonMember.memberName}</span>
                            </li>
                        </ul>
                        </div>
                    </div>

                    <section className="row mt-3">
                        <h5 className="item-title">振替履歴</h5>
                        <section className="col">

                        <ul className="list-group shadow-sm">
                            <li className="list-group-item d-flex justify-content-between lh-sm paycalllist-list-group">
                                <div className="col-4 item-title text-center">振替日付</div>
                                <div className="col-4 item-title text-center">振替金額</div>
                                <div className="col-4 item-title text-center">振替結果</div>
                            </li>
                            {Billings.length === 0 &&
                            <ul className="list-group">
                                <li className="list-group-item justify-content-center">
                                    <div>データはありません</div>
                                </li>
                            </ul>
                            }
                            {Billings.sort((a, b) => Number(a.transferDate) > Number(b.transferDate) ? 1 : -1 ).slice(0, marker).map((item, index) => (
                                <>
                                <li className="list-group-item h-auto" >
                                    <div className="col-4 text-center">{dateFormatString(item.transferDate)}</div>
                                    <div className="col-4 history-transferAmount">&yen;{Number(item.transferAmount).toLocaleString()}</div>
                                    <div className={item.transferResultCode === "0" ? "col-4 mt-1 furikae-ok text-center" : "col-4 mt-1 furikae-ng text-center"}>{getTransferResultString(item.transferResultCode).replaceAll("<br/>"," ")}</div>
                                </li>
                                </>
                            ))}
                        </ul>
                        {Billings.length > marker ? (
                            <div className="row justify-content-center">
                                <button className="col-10 history-more" onClick={()=>loadList()}>さらに表示する</button>
                            </div>
                         ) : (
                            ''
                        )}
                        </section>
                    </section>

                </div>
            )}
            <Footer />
        </>
    ); 
};