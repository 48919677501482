import { createMSalonMember } from './../graphql/mutations';
import { CreateMSalonMemberMutation, DeleteMSalonMemberMutation } from './../API';
import { useState } from "react";
import { listMemberByStoreId } from '../graphql/queries';
import { MSalonMember, ListMemberByStoreIdQuery, UpdateMSalonMemberInput } from '../API';
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import { deleteMSalonMember, updateMSalonMember } from "../graphql/mutations";
import { DEPOSIT_TYPE_ORDINARY, CONFIRM_PAPER_NO, FORCE_CONFIRM_NO, ANYWHERE_UNCONFIRMED } from '../global-define';

export const useSalonMembers = () => {

    const [salonMembers, setSalonMembers] = useState<MSalonMember[]>([]);
    const [salonMember, setSalonMember] = useState<MSalonMember>(
        {
            __typename: "MSalonMember",
            id: "",
            storeId: "",
            memberId: "",
            applicationDate: "",
            memberName: "",
            furigana: "",
            dateOfBirth: "",
            zipCode: "",
            address1: "",
            address2: "",
            phoneNumber: "",
            phoneNumberMobile: "",
            mailAddress: "",
            bankCode: "",
            bankName: "",
            bankBranchCode: "",
            bankBranchName: "",
            depositType: "",
            accountNumber: "",
            depositerName: "",
            confirmStatus: "",
            updateDate: "",
            confirmDate: "",
            confirmPaper: "",
            forceConfirm: "",
            deleteFlag: "",
            deleteDate: "",
            lastUpdater: "",
            lastUpdateDate: "",
            createdAt: "",
            updatedAt: ""
        });


    //    const [ nextToken, setNextToken] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const initSalonMember = (sId: string, memId: string) => {
        // 呼び出し元ですぐに参照するので実データにもセットする
        salonMember.storeId = sId;
        salonMember.memberId = memId;
        salonMember.depositType = DEPOSIT_TYPE_ORDINARY;
        salonMember.confirmStatus = ANYWHERE_UNCONFIRMED;
        salonMember.confirmPaper = CONFIRM_PAPER_NO;
        salonMember.forceConfirm = FORCE_CONFIRM_NO;
        // ステート関数を更新する
        setSalonMember({ ...salonMember, storeId: sId, memberId: memId, depositType: DEPOSIT_TYPE_ORDINARY /*1:普通 2:当座*/, confirmStatus: "0" /* 0:未承認 */, confirmPaper: "0" /* 0:振込用紙チェック未 */, forceConfirm: FORCE_CONFIRM_NO /* 0:カード強制確認チェック未 */ } as MSalonMember);
    }

    const getSalonMembers = async (storeId: string) => {

        try {
            const res = (await API.graphql(graphqlOperation(listMemberByStoreId, {
                storeId: `${storeId}`,
                limit: 999999
                //nextToken: nextToken,
            })) as GraphQLResult<ListMemberByStoreIdQuery>);

            console.log(res);
            const mStoreMembers = res.data?.ListMemberByStoreId?.items as MSalonMember[];
            setSalonMembers(mStoreMembers)
            //setNextToken( String( res.data?.ListMemberByStoreId?.nextToken ));
            //setIsLoading(false);
        } catch (err) {
            // 途中でエラーが出たら強制でエラーをスロー
            throw new Error(err.status);
        }
    }

    const getLastSalonMembers = async (storeId: string) => {

        try {
            const res = (await API.graphql(graphqlOperation(listMemberByStoreId, {
                sortDirection: "DESC",
                storeId: `${storeId}`,
                limit: 1, //default = 10
                //nextToken: nextToken,
            })) as GraphQLResult<ListMemberByStoreIdQuery>);

            console.log(res);
            const mStoreMembers = res.data?.ListMemberByStoreId?.items as MSalonMember[];
            //setSalonMembers( mStoreMembers )
            //setNextToken( String( res.data?.listMemberByStoreId?.nextToken ));
            //setIsLoading(false);

            return (mStoreMembers[0])

        } catch (err) {
            // 途中でエラーが出たら強制でエラーをスロー
            throw new Error(err.status);
        }
    }

    /**
     * 
     * @param storeId 
     * @param memberId 
     * @returns 
     */
    const getSalonMember = async (storeId: string, memberId: string) => {

        const res = (await API.graphql(graphqlOperation(listMemberByStoreId, {
            // idのみの指定の場合、クエリにIDを持っていると改竄で多店舗の情報も取得できるので
            // セッションからstoreIdを固定で利用するようにする。
            storeId: `${storeId}`,
            memberId: { eq: `${memberId}` },
            limit: 1, //default = 10
            //nextToken: nextToken,
        })) as GraphQLResult<ListMemberByStoreIdQuery>);

        console.log(res);
        //const mSalonMember = res.data.ListMemberByStoreId.items[0] as MSalonMember;
        //setSalonMember(mSalonMember)
        let mSalonMember = null;
        if (res.data.ListMemberByStoreId.items.length >= 1) {
            mSalonMember = res.data.ListMemberByStoreId.items[0] as MSalonMember;
            setSalonMember(mSalonMember)
        }
        //setIsLoading(false);

        return (mSalonMember)
    }

    const updateSalonMember = async () => {
        //setIsLoading(true);
        const res = (await API.graphql(graphqlOperation(updateMSalonMember, {
            input: {
                id: `${salonMember?.id}`,
                memberId: `${salonMember?.memberId}`,
                applicationDate: `${salonMember?.applicationDate}`,
                memberName: `${salonMember?.memberName}`,
                furigana: `${salonMember?.furigana}`,
                dateOfBirth: `${salonMember?.dateOfBirth}`,
                zipCode: `${salonMember?.zipCode}`,
                address1: `${salonMember?.address1}`,
                address2: `${salonMember?.address2}`,
                phoneNumber: `${salonMember?.phoneNumber}`,
                phoneNumberMobile: `${salonMember?.phoneNumberMobile}`,
                mailAddress: `${salonMember?.mailAddress}`,
                bankSetDate: `${salonMember?.bankSetDate}`,
                bankCode: `${salonMember?.bankCode}`,
                bankName: `${salonMember?.bankName}`,
                bankBranchCode: `${salonMember?.bankBranchCode}`,
                bankBranchName: `${salonMember?.bankBranchName}`,
                depositType: `${salonMember?.depositType}`,
                accountNumber: `${salonMember?.accountNumber}`,
                depositerName: `${salonMember?.depositerName}`,
                confirmStatus: `${salonMember?.confirmStatus}`,
                updateDate: `${salonMember?.updateDate}`,
                confirmDate: `${salonMember?.confirmDate}`,
                confirmPaper: `${salonMember?.confirmPaper}`,
                forceConfirm: `${salonMember?.forceConfirm}`,
                deleteFlag: `${salonMember?.deleteFlag}`,
                deleteDate: `${salonMember?.deleteDate}`,
                lastUpdater: `${salonMember?.lastUpdater}`,
                lastUpdateDate: `${salonMember?.lastUpdateDate}`
            },
        })) as GraphQLResult<UpdateMSalonMemberInput>);

        console.log(res);
    }

    const createSalonMember = async () => {
        //setIsLoading(true);
        const res = (await API.graphql(graphqlOperation(createMSalonMember, {
            input: {
                storeId: `${salonMember?.storeId}`,
                memberId: `${salonMember?.memberId}`,
                applicationDate: `${salonMember?.applicationDate}`,
                memberName: `${salonMember?.memberName}`,
                furigana: `${salonMember?.furigana}`,
                dateOfBirth: `${salonMember?.dateOfBirth}`,
                zipCode: `${salonMember?.zipCode}`,
                address1: `${salonMember?.address1}`,
                address2: `${salonMember?.address2}`,
                phoneNumber: `${salonMember?.phoneNumber}`,
                phoneNumberMobile: `${salonMember?.phoneNumberMobile}`,
                mailAddress: `${salonMember?.mailAddress}`,
                bankSetDate: `${salonMember?.bankSetDate}`,
                bankCode: `${salonMember?.bankCode}`,
                bankName: `${salonMember?.bankName}`,
                bankBranchCode: `${salonMember?.bankBranchCode}`,
                bankBranchName: `${salonMember?.bankBranchName}`,
                depositType: `${salonMember?.depositType}`,
                accountNumber: `${salonMember?.accountNumber}`,
                depositerName: `${salonMember?.depositerName}`,
                confirmStatus: `${salonMember?.confirmStatus}`,
                updateDate: `${salonMember?.updateDate}`,
                confirmDate: `${salonMember?.confirmDate}`,
                confirmPaper: `${salonMember?.confirmPaper}`,
                forceConfirm: `${salonMember?.forceConfirm}`,
                deleteFlag: `${salonMember?.deleteFlag}`,
                deleteDate: `${salonMember?.deleteDate}`,
                lastUpdater: `${salonMember?.lastUpdater}`,
                lastUpdateDate: `${salonMember?.lastUpdateDate}`
            },
        })) as GraphQLResult<CreateMSalonMemberMutation>);

        console.log(res);
        return (res);
    }

    /**
     * １件データ削除
     */
    const deleteSalonMember = async () => {

        try {
            console.log('deleteSalonMember');

            const res = (await API.graphql(graphqlOperation(deleteMSalonMember, {
                input: { id: `${salonMember?.id}` }
            })) as GraphQLResult<DeleteMSalonMemberMutation>);

            console.log(res);
            //setIsLoading(false);

        } catch (error) {
            console.log(error);
        }
    }

    // 新規会員のIDを算出
    const getNewMemberId = async (storeId: string) => {
        try {
            const member = await getLastSalonMembers(storeId);
            const nextId: string = String(Number(member.memberId) + 1).padStart(6, '0');
            return (nextId)
        } catch (e) {
            // データ無し（初回登録）
            return String(1).padStart(6, '0');
        }
    }
    /**
 * サロンメンバー会員番号の存在チェック
 * 
 *  @param {string} storeId     確認する店舗
 *  @param {string} memberId    チェック対象ID
 *  @return {boolean}   true : 会員が存在する
 *                      false : 会員は存在しない。
 */
    const existSalonMember = async (storeId: string, memberId: string) => {
        let exist = true;

        const originMember = { ...salonMember };

        const existMember = await getSalonMember(storeId, memberId);

        if (existMember === null) {
            exist = false;
        }

        setSalonMember(originMember);

        return exist;
    }

    return {
        salonMembers, setSalonMembers, salonMember, setSalonMember, isLoading, setIsLoading
        , getSalonMembers
        , getSalonMember
        , createSalonMember
        , updateSalonMember
        , deleteSalonMember
        , initSalonMember
        , getLastSalonMembers
        , getNewMemberId
        , existSalonMember
    }

}
