import { useNavigate } from "react-router-dom"
import { Header } from "../atoms/Header"
import { Footer } from "../atoms/Footer"
import React, { useContext, useEffect, useState } from "react";
import { SelectedSalonContext } from "../providers/SelectedSalonProvider";
import { useSalon } from "../hooks/useSalon";
import { useSalonMembers } from "../hooks/useSalonMembers";
import { useMemberUseServices } from "../hooks/useMemberUseServices";
import { useMemberUseServicePeriods } from "../hooks/useMemberUseServicePeriods";
import { MSalonMember, MService, MSalon, TServicePeriod } from "../API";
import { Loading } from '../atoms/Loading';
import { Button, Modal } from 'react-bootstrap';
import { BsArrowLeftCircle } from 'react-icons/bs';
import '../css/common.css';
import { DEPOSIT_TYPE_ORDINARY } from "../global-define";

export const StoreMemberRegisterConfirm = (props) => {

    const { setUser, setAuthState } = props;

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { setSelectedSalonContext, selectedSalonContext, selectedMemberContext, selectedServiceMenusContext, selectedServicePeriodsContext, setSelectedMemberContext, setSelectedServiceMenusContext } = useContext(SelectedSalonContext);
    const { salonAccount, setSalonAccount } = useSalon();
    const { salonMember, setSalonMember, createSalonMember } = useSalonMembers();
    const { createMemberUseServiceMenus } = useMemberUseServices();
    const { createServicePeriods } = useMemberUseServicePeriods();
    const [selectedMenus, setSelectedMenus] = useState<MService[]>();
    const [servicePeriods, setServicePeriods] = useState<TServicePeriod[]>([]);
    const [classNameTypeBank, setClassNameTypeBank] = useState<string>(null);
    const [classNameTypePost, setClassNameTypePost] = useState<string>(null);
    const [totalServiceCount, setTotalServiceCount] = useState<number>();

    useEffect(() => {

        setIsLoading(true);

        // 店舗ID・店舗名のみセッションから取得
        //const storeId = sessionStorage.getItem('selectedSalon.storeId');
        let salonJson = sessionStorage.getItem('salonJson'); // JSON
        let salon = null; if (salonJson != null) salon = JSON.parse(salonJson);
        let userId = sessionStorage.getItem('userId');
        if (salon == null || userId == null) {
            //セッション情報も無い（ログアウト状態）
            //ログイン画面に遷移
            navigate('/');
            return;
        }
        setSalonAccount(salon.selectedSalon as MSalon);
        setSalonMember(selectedMemberContext as MSalonMember);
        setSelectedMenus(selectedServiceMenusContext as MService[]);
        setServicePeriods(selectedServicePeriodsContext as TServicePeriod[]);

        // 月額コースの選択数を取得
        setTotalServiceCount(selectedServiceMenusContext.length);

        if (selectedMemberContext.accountNumber != null && selectedMemberContext.accountNumber.length === 14) {
            // 郵便局
            setClassNameTypeBank("list-group mb-3 shadow-sm d-none");
            setClassNameTypePost("list-group mb-3 shadow-sm");
        } else {
            // 銀行
            setClassNameTypeBank("list-group mb-3 shadow-sm");
            setClassNameTypePost("list-group mb-3 shadow-sm d-none");
        }

        setIsLoading(false);

        // リロード時に確認を表示するための設定（リロードすると内容が消えるため）
        window.addEventListener("beforeunload", handleBeforeUnloadEvent);
        return () => window.removeEventListener("beforeunload", handleBeforeUnloadEvent);

    }, []);

    const getMonthlyServicePrice = () => {
        let servicePrice = 0;
        let count = 0;
        selectedMenus?.map((service, index) => {
            servicePrice += Number(service.monthlyPrice);
            count += 1;
            return servicePrice;
        }
        )

        if (0 < count) {
            // １個以上のコースが選択されている場合は手数料を追加する
            servicePrice += selectedSalonContext.bankTransferFee;
        }

        return servicePrice;
    }

    const navigate = useNavigate();
    const OnClickRegister = async () => {

        setIsLoading(true);

        handleCreateConfiumClose();//ダイアログ閉じる

        const res = await createSalonMember();

        setSelectedSalonContext(salonAccount);
        setSelectedMemberContext(res.data?.createMSalonMember as MSalonMember);
        await createMemberUseServiceMenus(String(res.data?.createMSalonMember?.id), selectedMenus as MService[]);
        await createServicePeriods(res.data?.createMSalonMember as MSalonMember, selectedMenus, servicePeriods);

        setSelectedServiceMenusContext(selectedMenus);

        setIsLoading(false);

        navigate('/StoreMemberRegisterDone');
    }

    /**
     * 戻るボタン押下
     */
    const OnClickReturn = () => {
        setSalonAccount(selectedSalonContext as MSalon);
        setSalonMember(selectedMemberContext as MSalonMember);
        setSelectedMenus(selectedServiceMenusContext as MService[]);
        navigate('/StoreMemberRegister')
    }

    /**
     * 更新確認モーダルダイアログの制御用
     */
    const [showCreateConfium, setShowCreateConfium] = useState(false);
    const handleCreateConfiumClose = () => setShowCreateConfium(false);
    const handleCreateConfiumShow = () => setShowCreateConfium(true);

    /**
     * リロード時のイベント
     * @param event 
     */
    const handleBeforeUnloadEvent = (event: BeforeUnloadEvent): void => {
        // 表示メッセージのカスタム（GoogleChromeは固定のみで効きません）
        event.returnValue = "ページを離れると入力内容が消えます。このページを離れますか？";
    };

    const getServicePeriod = (serviceId: string) => {
        // selectedServicePeriodsContextからserviceIdをキーにして,servicePeriodを取得する
        const servicePeriod = selectedServicePeriodsContext.find(
            (servicePeriod) => {
                if (servicePeriod) {
                    return servicePeriod.serviceId === serviceId
                } else {
                    return false;
                }
            }
        );
        if (servicePeriod != null) {
            return servicePeriod;
        }
    }

    return (
        <>
            <Header setUser={setUser} setAuthState={setAuthState} setIsLoading={setIsLoading} />
            {isLoading ? (
                <Loading />
            ) : (

                <div className="container mt-2 mb-5">
                    <section className="row">
                        <section className="col-12">
                            <div className="d-flex page-title-row">
                                <button className="btn" onClick={OnClickReturn}><h2 className="icon-gray"><BsArrowLeftCircle /></h2></button>
                                <header className="d-flex page-title">
                                    <h1 className="fs-4 m-1">登録内容確認</h1>
                                </header>
                            </div>
                        </section>
                    </section>
                    <div className="row g-3 mt-2">
                        <div className="col-lg-7 ">
                            <h5 className="item-title">会員情報</h5>
                            <ul className="list-group mb-3 shadow-sm">
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">契約店舗</h6>
                                    </div>
                                    <span>{String(salonAccount?.storeName)}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">申込日</h6>
                                    </div>
                                    <span>{String(salonMember?.applicationDate)}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">お名前</h6>
                                    </div>
                                    <span>{String(salonMember?.memberName)}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">フリガナ</h6>
                                    </div>
                                    <span>{String(salonMember?.furigana)}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">生年月日</h6>
                                    </div>
                                    <span>{String(salonMember?.dateOfBirth)}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">郵便番号</h6>
                                    </div>
                                    <span>{String(salonMember?.zipCode)}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">住所１</h6>
                                    </div>
                                    <span>{String(salonMember?.address1)}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">住所２</h6>
                                    </div>
                                    <span>{String(salonMember?.address2)}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">電話番号</h6>
                                    </div>
                                    <span>{String(salonMember?.phoneNumber)}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">携帯番号</h6>
                                    </div>
                                    <span>{String(salonMember?.phoneNumberMobile)}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">メールアドレス</h6>
                                    </div>
                                    <span>{String(salonMember?.mailAddress)}</span>
                                </li>
                            </ul>
                        </div>

                        <div className="col-lg-5 order-md-last">
                            {/*申込コース*/}
                            <h5 className="d-flex justify-content-between align-items-center item-title">
                                申込コース
                            </h5>
                            <ul className="list-group mb-3 shadow-sm">
                                {selectedMenus?.sort((a, b) => Number(a.serviceId) > Number(b.serviceId) ? 1 : -1).map((serviceMenu, index) => (
                                    <>
                                        <li className="list-group-item d-flex justify-content-between lh-sm" key={index}>
                                            <div>
                                                <h6 className="my-0">
                                                    <label className="form-check-label" htmlFor={'cource' + index}>{serviceMenu?.courseName}</label>
                                                </h6>
                                            </div>
                                            <span>&yen;{serviceMenu?.monthlyPrice.toLocaleString()}</span>
                                        </li>
                                        <li className="list-group-item">
                                            <p style={{ fontSize: 13 }} className="ms-4 mb-0 text-end">振替開始：{getServicePeriod(serviceMenu.serviceId)?.startDate}　～　振替終了：{getServicePeriod(serviceMenu.serviceId)?.endDate}</p>
                                        </li>
                                    </>
                                ))}
                                {0 < totalServiceCount &&
                                    <li className="list-group-item d-flex justify-content-between lh-sm">
                                        <div>
                                            <h6 className="my-0"><b>振替手数料</b></h6>
                                        </div>
                                        <span><b>&yen;{salonAccount.bankTransferFee.toLocaleString()}</b></span>
                                    </li>
                                }
                                <li className="list-group-item d-flex justify-content-between lh-sm  list-group-item-warning">
                                    <div>
                                        <h6 className="my-0"><b>毎月振替金額</b></h6>
                                    </div>
                                    <span><b>&yen;{getMonthlyServicePrice().toLocaleString()}</b></span>
                                </li>
                            </ul>

                            <h5 className="d-flex justify-content-between align-items-center mt-4 item-title">
                                預金口座振替情報
                            </h5>
                            <ul className={classNameTypeBank}>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">口座登録日</h6>
                                    </div>
                                    <span className="text-muted">{String(salonMember?.bankSetDate)}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <span className="my-0">({salonMember?.bankCode}) {salonMember?.bankName}</span>
                                    </div>
                                    <span>({salonMember?.bankBranchCode}) {salonMember?.bankBranchName}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">預金種別</h6>
                                    </div>
                                    <span>{salonMember?.depositType === DEPOSIT_TYPE_ORDINARY ? <span>普通</span> : <span>当座</span>}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">口座番号</h6>
                                    </div>
                                    <span>{salonMember?.accountNumber}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">預金者名（カナ）</h6>
                                    </div>
                                    <span>{salonMember?.depositerName}</span>
                                </li>
                            </ul>
                            <ul className={classNameTypePost}>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <span className="my-0">ゆうちょ銀行</span>
                                    </div>
                                    <span></span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">記号番号</h6>
                                    </div>
                                    <span>{salonMember?.accountNumber}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">預金者名（カナ）</h6>
                                    </div>
                                    <span>{salonMember?.depositerName}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="row g-3 mt-1">
                            <button className="btn btn-success btn-block mx-auto" type="button" onClick={handleCreateConfiumShow}>上記の内容で<br />申し込む</button>
                        </div>
                    </div>

                    {/*登録確認ダイアログ*/}
                    <Modal show={showCreateConfium} onHide={handleCreateConfiumClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>確認</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>登録します。よろしいですか？</Modal.Body>
                        <Modal.Footer>
                            <Button className="btn-dialog-cancel" onClick={handleCreateConfiumClose}>いいえ</Button>
                            <button className="btn btn-dialog-ok m-1 ms-auto" type="button" onClick={OnClickRegister}>はい</button>
                        </Modal.Footer>
                    </Modal>

                </div>
            )}
            <Footer />
        </>
    );
};