import { useNavigate, useLocation } from "react-router-dom"
import { Header } from "../atoms/Header"
import { Footer } from "../atoms/Footer"
import React, { useEffect, useState } from "react";
import { useSalonMembers } from "../hooks/useSalonMembers";
import { MemberUseServices, TServicePeriod } from "../API";
import { useSalon } from "../hooks/useSalon";
import { useMemberUseServices } from "../hooks/useMemberUseServices";
import { Loading } from "../atoms/Loading";
import { Button, Modal } from 'react-bootstrap';
import { BsPencilSquare, BsTrash, BsArrowLeftCircle } from 'react-icons/bs';
import { MdPayment } from 'react-icons/md';
import { ANYWHERE_CONFIRMED, CONFIRM_PAPER_YES, FORCE_CONFIRM_YES, DEPOSIT_TYPE_ORDINARY } from '../global-define';
import '../css/common.css';
import { useCommonTools } from "../hooks/useCommonTools";

export const StoreMemberDetail = (props) => {

    const { setUser, setAuthState } = props;

    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const { salonAccount, getSalonAccount } = useSalon();
    const { salonMember, deleteSalonMember, getSalonMember } = useSalonMembers();
    const { deleteUseServices } = useMemberUseServices();
    const { isSp, getTransferDateString, dateFormatString } = useCommonTools();

    const [classNameTypeBank, setClassNameTypeBank] = useState<string>(null);
    const [classNameTypePost, setClassNameTypePost] = useState<string>(null);
    const getMonthlyServicePrice = () => {

        let totalPrice = 0;
        let count = 0;

        /* ワーニング対応MAP削除
        salonMember?.useServices?.items.map((service, index) => {
            totalPrice += Number ( service?.mService.monthlyPrice );
            count += 1;
        }
        );
        */
        for (const service of salonMember?.useServices?.items) {
            const currentPeriod = getServicePeriod(service?.mService.serviceId);
            // 翌月以降も有効なメニューのみ足し合わせる
            if (isServicePeriodValid(currentPeriod, 1)) {
                totalPrice += Number(service?.mService.monthlyPrice);
                count += 1;
            }
        }

        if (0 < count) {
            totalPrice += salonAccount.bankTransferFee; // 振替手数料
        }

        return totalPrice
    }


    /**
     * 削除確認モーダルダイアログの制御用
     */
    const [showDeleteConfium, setShowDeleteConfium] = useState(false);
    const handleDeleteConfiumClose = () => setShowDeleteConfium(false);
    const handleDeleteConfiumShow = () => setShowDeleteConfium(true);

    const [totalServiceCount, setTotalServiceCount] = useState<number>();

    /**
     * ページロード時の処理
     */
    useEffect(() => {

        const storeId = sessionStorage.getItem('selectedSalon.storeId');
        let salonJson = sessionStorage.getItem('salonJson'); // JSON
        let salon = null; if (salonJson != null) salon = JSON.parse(salonJson);
        let userId = sessionStorage.getItem('userId');
        if (salon == null || userId == null) {
            //セッション情報も無い（ログアウト状態）
            //ログイン画面に遷移
            navigate('/');
            return;
        }

        getData(storeId);

    }, []);

    /**
     * １件データ取得
     */
    const getData = async (storeId: string) => {

        await getSalonAccount(storeId);

        // 会員情報の取得・セット
        var salonMember = await getSalonMember(String(storeId), query.get("memberId"))

        // 月額コースの選択数を取得
        setTotalServiceCount(salonMember.useServices.items.length);

        //setSelectedMemberContext(member)

        if (salonMember.accountNumber != null && salonMember.accountNumber.length === 14) {
            // 郵便局
            setClassNameTypeBank("list-group mb-3 shadow-sm d-none");
            setClassNameTypePost("list-group mb-3 shadow-sm");
        } else {
            // 銀行
            setClassNameTypeBank("list-group mb-3 shadow-sm");
            setClassNameTypePost("list-group mb-3 shadow-sm d-none");
        }

        setIsLoading(false);

    }

    const navigate = useNavigate();

    /**
     * 編集ボタン押下
     * @returns 
     */
    const OnClickEdit = (memberId: string) => navigate(`/StoreMemberEdit?memberId=${memberId}`)

    /**
     * 戻るボタン押下
     * @returns 
     */
    const OnClickReturn = () => {
        if (query.get("back") === "menu") {
            navigate('/MainMenu')
        } else {
            navigate('/StoreMemberList')
        }
    }

    /**
     * 削除ボタン押下
     */
    const OnClickDelete = async () => {
        setIsLoading(true);

        // 登録済みの月額サービスを削除する
        await salonMember?.useServices?.items.map(async (service, index) => {
            try {
                await deleteUseServices((service as MemberUseServices).id);
            } catch (error) {
                console.log(error);
            }
        })

        // 会員を削除する(salonMember.idが内部で利用される)
        await deleteSalonMember();

        setIsLoading(false);

        navigate('/StoreMemberList')
    }

    /**
     * 履歴ボタン押下
     * @returns 
     */
    const OnClickHistory = (memberId: string) => navigate(`/StoreMemberHistory?memberId=${memberId}`)

    const getServicePeriod = (serviceId: string) => {
        const servicePeriod = salonMember?.useServicePeriod?.items.find((period) => period.serviceId === serviceId);
        return servicePeriod;
    }

    /** 
     * サービス終了日からサービスが終了しているかを判定する関数
     * @param isSp クライアントSPかどうか true:SP false:PC
     * @param endDate サービス終了日
     * @returns クラス名
     * 
    */
    const getClassNameByServiceEnd = (Sp: boolean, endDate: string, line: number) => {

        // 初期値はPC以外のBaseClass
        let baseClass = "list-group-item d-flex justify-content-between lh-sm";
        if (Sp) {
            if (line === 1) { //SPの１行目
                baseClass = "list-group-item lh-sm border-0"
            } else {
                baseClass = "list-group-item text-sm border-0"
            }
            if (endDate === null || endDate === "") {
                return baseClass;
            }
        }
        const td = dateFormatString(getTransferDateString(8, 0))
        if (endDate <= td) {
            return baseClass + " bg-secondary text-white";
        }

        return baseClass;
    };

    // Endボタンの表示条件を確認する
    // チェック有りでサービス終了日が未入力または未来日の場合はEndボタンを表示する
    // 当月なら、サービス終了予定を表示する
    // 過去なら、サービス終了済みを表示する
    //
    const getEndStatus = (startDate: string, endDate: string) => {
        if (endDate === null || (startDate <= dateFormatString(getTransferDateString(8, 0)))) {
            if (endDate === dateFormatString(getTransferDateString(8, 0))) {
                return <span className="ms-3 mt-1 text-warning">サービス終了予定</span>;
            }
            if (endDate < dateFormatString(getTransferDateString(8, 0))) {
                return <span className="ms-3 mt-1 text-info">サービス終了済　</span>;
            }
        }
        return "";
    }

    const isServicePeriodValid = (servicePeriod: TServicePeriod, offset: number) => {

        // 指定していない場合は有効とする
        if (!servicePeriod) {
            return true;
        }
        // 翌月の振替日時を取得
        const nextTransferDate = getTransferDateString(8, offset);

        //const startDate = servicePeriod.startDate.replace(/\//g, '');
        const endDate = servicePeriod.endDate.replace(/\//g, '');

        return (/*startDate <= nextTransferDate && */nextTransferDate <= endDate);
    }

    return (
        <>
            <Header setUser={setUser} setAuthState={setAuthState} setIsLoading={setIsLoading} />
            {isLoading ? (
                <Loading />
            ) : (
                <div className="container mt-2 mb-5">
                    <section className="row">
                        <section className="col-8">
                            <div className="d-flex page-title-row">
                                <button className="btn" onClick={OnClickReturn}><h2 className="icon-gray"><BsArrowLeftCircle /></h2></button>
                                <header className="d-flex page-title">
                                    <h1 className="fs-4 m-1">会員　詳細</h1>
                                </header>
                            </div>
                        </section>
                        <section className="col-4">
                            <div className="d-flex mb-0">
                                <button className="btn ms-auto" type="button" onClick={() => OnClickEdit(salonMember.memberId)}><h3 className="icon-gray"><BsPencilSquare /></h3></button>
                                <button className="btn ms-auto" type="button" onClick={handleDeleteConfiumShow}><h3 className="icon-gray"><BsTrash /></h3></button>
                            </div>
                        </section>
                    </section>
                    <div className="row g-3 mt-2">
                        <div className="col-md-12">
                            <h5 className="mb-3 item-title">会員情報</h5>
                            <ul className="list-group mb-1 shadow-sm">
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">契約店舗</h6>
                                    </div>
                                    <span>{salonAccount?.storeName}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">申込日</h6>
                                    </div>
                                    <span>{salonMember?.applicationDate}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">会員番号</h6>
                                    </div>
                                    <span>{salonMember?.memberId}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">お名前</h6>
                                    </div>
                                    <span>{salonMember?.memberName}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">フリガナ</h6>
                                    </div>
                                    <span>{salonMember?.furigana}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">生年月日</h6>
                                    </div>
                                    <span>{salonMember?.dateOfBirth}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">郵便番号</h6>
                                    </div>
                                    <span>{salonMember?.zipCode}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">住所</h6>
                                    </div>
                                    <span>{salonMember?.address1} {salonMember?.address2}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">電話番号</h6>
                                    </div>
                                    <span>{salonMember?.phoneNumber}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">携帯番号</h6>
                                    </div>
                                    <span>{salonMember?.phoneNumberMobile}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">メールアドレス</h6>
                                    </div>
                                    <span>{salonMember?.mailAddress}</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-12 order-md-last">
                            {/*申込コース*/}
                            <h5 className="d-flex justify-content-between align-items-center item-title">
                                申込コース
                                <button className="btn ms-auto" type="button" onClick={() => OnClickHistory(salonMember.memberId)}><h3 className="icon-gray"><MdPayment /></h3></button>
                            </h5>
                            <ul className="list-group mb-4 mt-0 shadow-sm">
                                {salonMember?.useServices?.items.sort((a, b) => Number(a.mService.serviceId) > Number(b.mService.serviceId) ? 1 : -1).map((service, index) => {

                                    return (!isSp() ?

                                        (
                                            <li className={getClassNameByServiceEnd(false, getServicePeriod(service?.mService.serviceId)?.endDate, 1)} key={index}>
                                                { /*<li className="list-group-item d-flex justify-content-between lh-sm" key={index}>*/}
                                                <div style={{ width: 40 + '%' }}>
                                                    <h6 className="my-0">
                                                        <label className="form-check-label" htmlFor="Course1">{service?.mService.courseName}
                                                            {getEndStatus(getServicePeriod(service?.mService.serviceId)?.startDate, getServicePeriod(service?.mService.serviceId)?.endDate)}
                                                        </label>
                                                    </h6>
                                                </div>
                                                {getServicePeriod(service?.mService.serviceId) &&
                                                    <div className="d-flex" style={{ width: 40 + '%' }}>
                                                        {console.log(getServicePeriod(service?.mService.serviceId))}
                                                        <div>
                                                            <p className="my-0">振替開始日 : {getServicePeriod(service?.mService.serviceId)?.startDate}　～</p>
                                                        </div>
                                                        <div>
                                                            <p className="my-0">　終了日 : {getServicePeriod(service?.mService.serviceId)?.endDate}</p>
                                                        </div>
                                                    </div>
                                                }
                                                <span>&yen;{service?.mService.monthlyPrice.toLocaleString()}</span>
                                            </li>
                                        ) : ( // スマホ表示の場合は複数liの縦積みにする
                                            <>
                                                <li className={getClassNameByServiceEnd(isSp(), getServicePeriod(service?.mService.serviceId)?.endDate, 1)} key={index}>
                                                    {/* <li className="list-group-item lh-sm"> */}
                                                    <div>
                                                        <h6 className="my-0">
                                                            <label className="form-check-label" htmlFor="Course1">{service?.mService.courseName}</label>
                                                        </h6>
                                                    </div>
                                                    <span style={{ margin: "0 0 0 auto" }}>&yen;{service?.mService.monthlyPrice.toLocaleString()}</span>
                                                </li>
                                                <li className={getClassNameByServiceEnd(isSp(), getServicePeriod(service?.mService.serviceId)?.endDate, 2)} style={{ fontSize: 12, height: 18 }} >
                                                    {/*<li className="list-group-item text-sm" style={{ fontSize: 12, height: 18 }} > */}
                                                    {getServicePeriod(service?.mService.serviceId) &&

                                                        <div style={{ fontSize: 13, margin: "0 0 0 auto" }} >

                                                            <span>振替開始日 : {getServicePeriod(service?.mService.serviceId)?.startDate}　～</span>
                                                            <span>　終了日 : {getServicePeriod(service?.mService.serviceId)?.endDate}</span>
                                                        </div>
                                                    }

                                                </li>
                                            </>
                                        ))
                                }
                                )}
                                {0 < totalServiceCount &&
                                    <li className="list-group-item d-flex justify-content-between lh-sm">
                                        <div>
                                            <h6 className="my-0"><b>振替手数料</b></h6>
                                        </div>
                                        <span><b>&yen;{salonAccount.bankTransferFee?.toLocaleString()}</b></span>
                                    </li>
                                }
                                <li className="list-group-item d-flex justify-content-between lh-sm  list-group-item-warning">
                                    <div>
                                        <h6 className="my-0"><b>毎月振替金額</b></h6>
                                    </div>
                                    <span><b>&yen;{getMonthlyServicePrice().toLocaleString()}</b></span>
                                </li>
                            </ul>
                        </div>

                        <div className="col-md-12 order-md-last">
                            {/*預金口座振替情報*/}
                            <h5 className="mb-3 item-title">
                                預金口座振替情報
                            </h5>
                            <ul className={classNameTypeBank}>
                                <li className={(salonMember?.confirmStatus === ANYWHERE_CONFIRMED || ((salonMember?.confirmPaper === CONFIRM_PAPER_YES) || (salonMember?.forceConfirm === FORCE_CONFIRM_YES)) ? " list-group-item-success card-checked " : " list-group-item-danger card-unchecked ") + " list-group-item d-flex justify-content-between lh-sm"}>
                                    <div style={{ margin: "0 auto" }}>
                                        <h6 className="my-0"><b>{salonMember?.confirmStatus === ANYWHERE_CONFIRMED || ((salonMember?.confirmPaper === CONFIRM_PAPER_YES) || (salonMember?.forceConfirm === FORCE_CONFIRM_YES)) ? <span>カード確認済</span> : <span>カード未確認</span>}</b></h6>
                                    </div>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">口座登録日</h6>
                                    </div>
                                    <span className="text-muted">{salonMember?.bankSetDate != null ? salonMember?.bankSetDate : ''}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <span className="my-0">({salonMember?.bankCode}) {salonMember?.bankName}</span>
                                    </div>
                                    <span>({salonMember?.bankBranchCode}) {salonMember?.bankBranchName}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">預金種別</h6>
                                    </div>
                                    <span>
                                        {salonMember?.depositType === DEPOSIT_TYPE_ORDINARY ? <span>普通</span> : <span>当座</span>}
                                    </span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">口座番号</h6>
                                    </div>
                                    <span>
                                        {salonMember?.accountNumber}
                                    </span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">預金者名（カナ）</h6>
                                    </div>
                                    <span>
                                        {salonMember?.depositerName}
                                    </span>
                                </li>
                            </ul>
                            <ul className={classNameTypePost}>
                                <li className={(salonMember?.confirmStatus === ANYWHERE_CONFIRMED || ((salonMember?.confirmPaper === CONFIRM_PAPER_YES) || (salonMember?.forceConfirm === FORCE_CONFIRM_YES)) ? " list-group-item-success card-checked " : " list-group-item-danger card-unchecked ") + " list-group-item d-flex justify-content-between lh-sm"}>
                                    <div style={{ margin: "0 auto" }}>
                                        <h6 className="my-0"><b>{salonMember?.confirmStatus === ANYWHERE_CONFIRMED || ((salonMember?.confirmPaper === CONFIRM_PAPER_YES) || (salonMember?.forceConfirm === FORCE_CONFIRM_YES)) ? <span>カード確認済</span> : <span>カード未確認</span>}</b></h6>
                                    </div>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <span className="my-0">ゆうちょ銀行</span>
                                    </div>
                                    <span></span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">記号番号</h6>
                                    </div>
                                    <span>{salonMember?.accountNumber}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">預金者名（カナ）</h6>
                                    </div>
                                    <span>{salonMember?.depositerName}</span>
                                </li>
                            </ul>
                            <div className="row mt-0">
                                {/* 店舗側では操作出来ないようにする
                                <div className="col-12">
                                    <h6 className="mt-0">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="forceConfirm" checked={salonMember?.forceConfirm === FORCE_CONFIRM_YES ? true : false} disabled />
                                            <label className="form-check-label" htmlFor="forceConfirm">カード強制確認済み</label>
                                        </div>
                                    </h6>
                                </div>
                                */}
                                <div className="col-12">
                                    <h6 className="mt-0">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="confirmPaper" checked={salonMember?.confirmPaper === CONFIRM_PAPER_YES ? true : false} disabled />
                                            <label className="form-check-label" htmlFor="confirmPaper">振替用紙チェック済み</label>
                                        </div>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*削除確認ダイアログ*/}
                    <Modal show={showDeleteConfium} onHide={handleDeleteConfiumClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>確認</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>削除します。よろしいですか？</Modal.Body>
                        <Modal.Footer>
                            <Button className="btn-dialog-cancel" onClick={handleDeleteConfiumClose}>いいえ</Button>
                            <button className="btn btn-dialog-ok m-1 ms-auto" type="button" onClick={OnClickDelete}>はい</button>
                        </Modal.Footer>
                    </Modal>

                </div>
            )}
            <Footer />
        </>
    );
};