import { useBankcodejp, Mbank, Mbranch } from "../hooks/useBankcodejp";
import { useNavigate } from "react-router-dom"
import { Header } from "../atoms/Header"
import { Footer } from "../atoms/Footer"
import React, { useEffect, useState, useContext } from "react";
import { useSalonMembers } from "../hooks/useSalonMembers";
import { MSalonMember } from "../API";
import { Loading } from '../atoms/Loading';
import { SelectedSalonContext } from "../providers/SelectedSalonProvider";
import { useForm } from 'react-hook-form'
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal } from 'react-bootstrap';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { DEPOSIT_TYPE_ORDINARY, ANYWHERE_UNCONFIRMED } from '../global-define';
import '../css/common.css';
import { useCommonTools } from '../hooks/useCommonTools';

import ReactDatePicker/*, { registerLocale } */ from "react-datepicker";

type Inputs = {
    bankType: string;
    bankSearch: string;
    bankList: string;
    bankBranchSearch: string;
    bankBranchList: string;
    depositType: string;
    accountNumber: string;
    accountKigou: string;
    accountBangou: string;
    depositerName: string;
}

export const StoreMemberBankEdit = (props) => {

    const { setUser, setAuthState } = props;

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue
    } = useForm<Inputs>({
        criteriaMode: "all",
        shouldFocusError: true,
    });

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { salonMember, setSalonMember, updateSalonMember } = useSalonMembers();
    const { selectedMemberContext, setSelectedMemberContext } = useContext(SelectedSalonContext);
    const { getBanks, getBranches, getBank, getBranch } = useBankcodejp();
    const { zenNum2HanNum, hira2ZenKana } = useCommonTools();

    const [accountKigouRequired, setAccountKigouRequired] = useState<boolean>(false);
    const [accountBangouRequired, setAccountBangouRequired] = useState<boolean>(false);
    const [bankListRequired, setBankListRequired] = useState<boolean>(false);
    const [bankBranchListRequired, setBankBranchListRequired] = useState<boolean>(false);
    const [accountNumberRequired, setAccountNumberRequired] = useState<boolean>(false);

    // 口座登録日のフォーム用
    const [formBankSetDate, setFormBankSetDate] = useState(new Date());

    const navigate = useNavigate();

    const Y = (new Date()).getFullYear();
    const M = ("00" + ((new Date()).getMonth() + 1)).slice(-2);
    const D = ("00" + (new Date()).getDate()).slice(-2);
    const TodayString = Y + "/" + M + "/" + D

    /**
     * 登録するボタン押下
     */
    const onSubmit = async () => {
        setIsLoading(true);

        // 郵便局の場合（記号、番号の入力チェック）
        if ((typePost === true) && (accountKigou == null || accountKigou.length !== 5)) {
            setAccountKigouRequired(true);
        }
        else if ((typePost === true) && (accountBangou == null || accountBangou.length !== 8)) {
            setAccountKigouRequired(false);
            setAccountBangouRequired(true);
        }
        // 銀行の場合（銀行名、支店名、口座番号の入力チェック）
        else if ((typeBank === true) && (bankList == null || bankList.length === 0)) {
            setBankListRequired(true);
        }
        else if ((typeBank === true) && (bankBranchList == null || bankBranchList.length === 0)) {
            setBankListRequired(false);
            setBankBranchListRequired(true);
        }
        // 口座番号
        else if ((typeBank === true) && (accountNumber == null || accountNumber.length !== 7)) {
            setBankListRequired(false);
            setBankBranchListRequired(false);
            setAccountNumberRequired(true);
        }
        else {
            // 更新内容に変更があるか比較する。
            var update = false;
            if ((selectedMemberContext.accountNumber !== salonMember.accountNumber) ||
                (selectedMemberContext.depositerName !== salonMember.depositerName) ||
                (selectedMemberContext.depositType !== salonMember.depositType) ||
                (selectedMemberContext.bankSetDate !== salonMember.bankSetDate) ||
                (selectedMemberContext.bankCode !== salonMember.bankCode) ||
                (selectedMemberContext.bankName !== salonMember.bankName) ||
                (selectedMemberContext.bankBranchCode !== salonMember.bankBranchCode) ||
                (selectedMemberContext.bankBranchName !== salonMember.bankBranchName)) {
                // 変更あり
                update = true;
            }

            // 更新内容に変更があれば変更を実施する。
            if (update === true) {
                if (typePost === true) {
                    // 郵便局の場合は初期値をセットする SWEBデータレイアウトに合わせる
                    salonMember.depositType = DEPOSIT_TYPE_ORDINARY; // 普通預金固定
                    salonMember.bankCode = "9900"; // ゆうちょ銀行固定
                    salonMember.bankName = ' '.repeat(15);// スペース
                    salonMember.bankBranchCode = accountKigou.slice(1, 4);
                    salonMember.bankBranchName = ' '.repeat(15);// スペース
                }

                salonMember.confirmStatus = ANYWHERE_UNCONFIRMED;/*カード確認状態を未確認に変更*/
                await updateSalonMember();
                setSelectedMemberContext(salonMember as MSalonMember);
            }
            setIsLoading(false);
            navigate(`/StoreMemberEdit?memberId=${salonMember.memberId}`);
        }
        setIsLoading(false);

        return;
    }

    /**
     * 戻るボタン押下
     */
    const OnClickReturn = () => navigate(`/StoreMemberEdit?memberId=${salonMember.memberId}`)
    /**
     * 更新確認モーダルダイアログの制御用
     */
    const [showEditConfium, setShowEditConfium] = useState(false);
    const handleEditConfiumClose = () => setShowEditConfium(false);
    const handleEditConfiumShow = () => setShowEditConfium(true);

    const getData = async () => {

        if (selectedMemberContext == null) {
            // リロードなどでデータが消えた場合（一覧画面に遷移）
            navigate('/StoreMemberList');
            return;
        }

        // セット手順１．編集画面から渡されたサロンメンバー情報をセット（全てのカラム）
        salonMember.id = selectedMemberContext.id;
        salonMember.memberId = selectedMemberContext.memberId;
        salonMember.applicationDate = selectedMemberContext.applicationDate;
        salonMember.memberName = selectedMemberContext.memberName;
        salonMember.furigana = selectedMemberContext.furigana;
        salonMember.dateOfBirth = selectedMemberContext.dateOfBirth;
        salonMember.zipCode = selectedMemberContext.zipCode;
        salonMember.address1 = selectedMemberContext.address1;
        salonMember.address2 = selectedMemberContext.address2;
        salonMember.phoneNumber = selectedMemberContext.phoneNumber;
        salonMember.phoneNumberMobile = selectedMemberContext.phoneNumberMobile;
        salonMember.mailAddress = selectedMemberContext.mailAddress;
        salonMember.bankCode = selectedMemberContext.bankCode;
        salonMember.bankName = selectedMemberContext.bankName;
        salonMember.bankBranchCode = selectedMemberContext.bankBranchCode;
        salonMember.bankBranchName = selectedMemberContext.bankBranchName;
        salonMember.depositType = selectedMemberContext.depositType;
        salonMember.accountNumber = selectedMemberContext.accountNumber;
        salonMember.depositerName = selectedMemberContext.depositerName;
        salonMember.confirmStatus = selectedMemberContext.confirmStatus;
        salonMember.updateDate = selectedMemberContext.updateDate;
        salonMember.confirmDate = selectedMemberContext.confirmDate;
        salonMember.deleteFlag = selectedMemberContext.deleteFlag;
        salonMember.deleteDate = selectedMemberContext.deleteDate;
        salonMember.lastUpdater = selectedMemberContext.lastUpdater;
        salonMember.lastUpdateDate = selectedMemberContext.lastUpdateDate;

        // セット手順２．画面表示用にstate変数にセット
        setSalonMember(salonMember);

        // セット手順３．form変数にセット（inputフォームなど編集せずにSubmitした場合などに値を認識させるため）
        //setValue('bankName', salonMember.bankName); // 検索ボックスの値
        setValue('bankList', salonMember.bankCode); // Selectの値
        //setValue('bankBranchName', salonMember.bankBranchName); // 検索ボックスの値
        setValue('bankBranchList', salonMember.bankBranchCode); // Selectの値
        setValue('depositType', salonMember.depositType);
        setValue('accountNumber', salonMember.accountNumber);
        setValue('depositerName', salonMember.depositerName);

        // 初期化
        setValue('accountKigou', '');
        setAccountKigou('');
        setValue('accountBangou', '');
        setAccountBangou('');
        setAccountNumber('');
        // 格納
        if (salonMember.accountNumber != null && salonMember.accountNumber.length === 14) {
            // 郵便局の場合
            setValue('bankType', '2');
            updateBankType("2");
            setValue('accountKigou', salonMember.accountNumber.slice(0, 5));
            setAccountKigou(salonMember.accountNumber.slice(0, 5));
            setValue('accountBangou', salonMember.accountNumber.slice(6));
            setAccountBangou(salonMember.accountNumber.slice(6));

            setIsLoading(false);
        } else {
            // 銀行の場合
            setValue('bankType', '1');
            updateBankType("1");

            setAccountNumber(salonMember.accountNumber);

            // リストの更新
            await getBankInfo(salonMember);

            setIsLoading(false);
        }

    }

    const onChangeBankSetDate = (date) => {
        try {
            const Y = date.getFullYear();
            const M = ("00" + (date.getMonth() + 1)).slice(-2);
            const D = ("00" + date.getDate()).slice(-2);
            setSalonMember({ ...salonMember, bankSetDate: Y + "/" + M + "/" + D } as MSalonMember);
            setFormBankSetDate(new Date(Y + "/" + M + "/" + D));
            //setValue('applicationDate', Y+"/"+M+"/"+D);
        } catch (e) {
            // 入力フォームの内容が消された場合（必須項目なので今日の日付をセットする）
            setSalonMember({ ...salonMember, bankSetDate: TodayString } as MSalonMember);
            setFormBankSetDate(new Date(TodayString));
            //setValue('applicationDate', TodayString);
        }
    }

    /**
     * ページロード時の処理
     */
    useEffect(() => {

        getData();

        // リロード時に確認を表示するための設定（リロードすると内容が消えるため）
        window.addEventListener("beforeunload", handleBeforeUnloadEvent);
        return () => window.removeEventListener("beforeunload", handleBeforeUnloadEvent);

    }, []);

    const getBankInfo = async (salonMember: MSalonMember) => {

        // 銀行名取得
        var bank = await getBank(salonMember.bankCode);
        // 検索ボックスにセット
        setBankSearch(bank.fullWidthKana);
        try {
            // 検索を行いドロップダウンを更新する
            var bankList = await getBanks("fullWidthKana==" + bank.fullWidthKana + "*");
            setBankList(bankList);
        }
        catch (err) {
        }
        // ドロップダウンの値を設定する
        setBankName(salonMember.bankCode);
        // ドロップダウンのdisableを解除する
        setBankSearchButtonDisabled(false);
        setBankListDisabled(false);

        // 支店名取得
        var bankBranch = await getBranch(salonMember.bankCode, salonMember.bankBranchCode);
        // 検索ボックスにセット
        if (bankBranch !== undefined) setBankBranchSearch(bankBranch.fullWidthKana);
        try {
            // 検索を行いドロップダウンを更新する
            var bankBranchesList = await getBranches(salonMember.bankCode, "fullWidthKana==" + bankBranch.fullWidthKana + "*");
            setBankBranchList(bankBranchesList);
        }
        catch (err) {
        }
        // ドロップダウンの値を設定する
        setBankBranchName(salonMember.bankBranchCode);
        // ドロップダウンのdisableを解除する
        setBankBranchSearchDisabled(false);
        setBankBranchSearchButtonDisabled(false);
        setBankBranchListDisabled(false);
    }

    /**
     * リロード時のイベント
     * @param event 
     */
    const handleBeforeUnloadEvent = (event: BeforeUnloadEvent): void => {
        // 表示メッセージのカスタム（GoogleChromeは固定のみで効きません）
        event.returnValue = "ページを離れると入力内容が消えます。このページを離れますか？";
    };

    /**
     * イベント処理
     */
    const onChangeAccountNumber = (event) => {
        setAccountNumber(zenNum2HanNum(event.target.value));
        setSalonMember({ ...salonMember, accountNumber: zenNum2HanNum(event.target.value) });
    }
    const onChangeDepositerName = (event) => { setSalonMember({ ...salonMember, depositerName: event.target.value }); }
    //const onBlurDepositerName = (event) => { setSalonMember({ ...salonMember , depositerName: hira2ZenKana(event.target.value) }); }
    const onChangeDepositType = (event) => { setSalonMember({ ...salonMember, depositType: event.target.value }); }

    /**
     * 銀行名・支店名関連
     */
    const [yokinClassName, setYokinClassName] = useState<string>("col-sm-6");
    const [yokinClassName2, setYokinClassName2] = useState<string>("col-sm-6");
    const [yokinClassName3, setYokinClassName3] = useState<string>("col-sm-6");
    const [typeBank, setTypeBank] = useState<boolean>(false);
    const [typePost, setTypePost] = useState<boolean>(false);
    // 区別
    const onChangeBankType = (event) => {
        updateBankType(event.target.value);
    }
    const updateBankType = (type) => {
        if (type === "1") {
            // 銀行の場合
            setTypeBank(true);
            setTypePost(false);
            // 入力項目を制限を更新する
            setYokinClassName("col-sm-6");
            setYokinClassName2("col-sm-6 d-none");
            setYokinClassName3("col-sm-6");
        }
        else if (type === "2") {
            // 郵便局の場合
            setTypeBank(false);
            setTypePost(true);
            // 入力項目を制限を更新する
            setYokinClassName("col-sm-6 d-none");
            setYokinClassName2("col-sm-6");
            setYokinClassName3("col-sm-6");
        }
    }
    // 銀行名検索
    const [bankSearch, setBankSearch] = useState<string>('');
    // 銀行名検索ボタンの有効/無効
    const [bankSearchButtonDisabled, setBankSearchButtonDisabled] = useState<boolean>(true);
    // 銀行名リストselectコントロールの値保存用
    const [bankName, setBankName] = useState<string>('');
    // 銀行名リスト
    const [bankList, setBankList] = useState<Mbank[]>([]);
    // 銀行名Optionリストの有効/無効
    const [bankListDisabled, setBankListDisabled] = useState<boolean>(true);
    // 銀行名OptionHTML
    const optionBankList = bankList.map((data, index) => <option value={data.code}>({data.code}) {data.name}</option>)
    // イベント　銀行名検索ボックス入力
    const onChangeBankSearch = (event) => {
        if (event.target.value.length === 0) {
            setBankSearchButtonDisabled(true);
        } else {
            setBankSearchButtonDisabled(false);
        }
        setBankSearch(event.target.value);
    }
    const onBlurBankSearch = (event) => { setBankSearch(hira2ZenKana(event.target.value)); };

    // イベント　銀行名検索ボタン押下
    const onButtonBankSearch = async () => {
        if (0 < bankSearch.length) {
            // 1文字以上の場合は検索を行いドロップダウンを更新する
            var bankList = await getBanks("fullWidthKana==" + bankSearch + "*");
            setBankList(bankList);
            // 銀行名が更新されたので支店情報をリセット
            setBankBranchSearch("");
            setBankBranchList([]);
            // ドロップダウンのdisableを解除する
            setBankListDisabled(false);
        } else {
            // ドロップダウンのdisableを有効にする
            setBankListDisabled(true);
        }
    }
    // イベント　銀行名Optionリストの変更確定時イベント
    const onChangeBankList = (event) => {
        salonMember.bankCode = event.target.value;
        setSalonMember({ ...salonMember, bankCode: event.target.value });
        salonMember.bankName = event.target.options[event.target.selectedIndex].label.split(' ')[1];
        setSalonMember({ ...salonMember, bankName: salonMember.bankName });
        // 銀行名が確定したので支店名入力を有効にする。
        setBankBranchSearchDisabled(false);
        // Selectの値を更新する
        setBankName(event.target.value);
    }
    // 支店名検索
    const [bankBranchSearch, setBankBranchSearch] = useState<string>('');
    // 支店名リストselectコントロールの値保存用
    const [bankBranchName, setBankBranchName] = useState<string>('');
    // 支店名検索ボックスの有効/無効
    const [bankBranchSearchDisabled, setBankBranchSearchDisabled] = useState<boolean>(true);
    // 支店名検索ボタンの有効/無効
    const [bankBranchSearchButtonDisabled, setBankBranchSearchButtonDisabled] = useState<boolean>(true);
    // 支店名リスト
    const [bankBranchList, setBankBranchList] = useState<Mbranch[]>([]);
    // 支店名Optionリストの有効/無効
    const [bankBranchListDisabled, setBankBranchListDisabled] = useState<boolean>(true);
    // イベント　支店名検索ボックス入力
    const onChangeBankBranchSearch = (event) => {
        if (event.target.value.length === 0) {
            setBankBranchSearchButtonDisabled(true);
        } else {
            setBankBranchSearchButtonDisabled(false);
        }
        setBankBranchSearch(hira2ZenKana(event.target.value));
    }

    const onBlurBankBranchSearch = (event) => { setBankBranchSearch(hira2ZenKana(event.target.value)); };

    // イベント　支店名OptionHTML
    const optionBankBranchList = bankBranchList.map((data, index) => <option value={data.code}>({data.code}) {data.name}</option>)
    // イベント　支店検索ボタン押下
    const onButtonBankBranchSearch = async () => {
        if (0 < bankBranchSearch.length) {
            // 1文字以上の場合は検索を行いドロップダウンを更新する
            var bankBranchesList = await getBranches(salonMember.bankCode, "fullWidthKana==" + bankBranchSearch + "*");
            setBankBranchList(bankBranchesList);
            // ドロップダウンのdisableを解除する
            setBankBranchListDisabled(false);
        } else {
            // ドロップダウンのdisableを有効にする
            setBankBranchListDisabled(true);
        }
    }
    // イベント　支店名Optionリストの変更確定時イベント
    const onChangeBankBranchList = (event) => {
        salonMember.bankBranchCode = event.target.value;
        setSalonMember({ ...salonMember, bankBranchCode: event.target.value });
        salonMember.bankBranchName = event.target.options[event.target.selectedIndex].label.split(' ')[1];
        setSalonMember({ ...salonMember, bankBranchName: salonMember.bankBranchName });
        // Selectの値を更新する
        setBankBranchName(event.target.value);
    }
    const [accountNumber, setAccountNumber] = useState<string>(null);
    const [accountKigou, setAccountKigou] = useState<string>(null);
    const [accountBangou, setAccountBangou] = useState<string>(null);
    const onChangeAccountKigou = (event) => {
        setAccountKigou(zenNum2HanNum(event.target.value));
        // 記号番号を"-"で結合した値を保存
        salonMember.accountNumber = zenNum2HanNum(event.target.value) + "-" + accountBangou;
        setSalonMember({ ...salonMember, accountNumber: salonMember.accountNumber });
    }
    const onChangeAccountBangou = (event) => {
        setAccountBangou(zenNum2HanNum(event.target.value));
        // 記号番号を"-"で結合した値を保存
        salonMember.accountNumber = accountKigou + "-" + zenNum2HanNum(event.target.value);
        setSalonMember({ ...salonMember, accountNumber: salonMember.accountNumber });
    }

    return (
        <>
            <Header setUser={setUser} setAuthState={setAuthState} setIsLoading={setIsLoading} />
            {isLoading ? (
                <Loading />
            ) : (
                <div className="container mt-2">
                    <section className="row">
                        <section className="col-12">
                            <div className="d-flex page-title-row">
                                <button className="btn" onClick={OnClickReturn}><h2 className="icon-gray"><BsArrowLeftCircle /></h2></button>
                                <header className="d-flex page-title">
                                    <h1 className="fs-4 m-1">預金口座振替情報　編集</h1>
                                </header>
                            </div>
                        </section>
                    </section>

                    <div className="row g-3 mt-2">
                        <div className="col-md-12">
                            <h4 className="item-title">会員情報</h4>
                            <ul className="list-group mb-3 shadow-sm">
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">会員番号</h6>
                                    </div>
                                    <span>{salonMember?.memberId}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0 item-title">会員氏名</h6>
                                    </div>
                                    <span>{salonMember?.memberName}</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="row g-3 mt-2">
                        <h4 className="item-title">預金口座振替情報</h4>
                        <div className="col-md-6">
                            <label htmlFor="bankSetDate" className="form-label">口座登録日</label>
                            <ReactDatePicker
                                id="bankSetDate"
                                locale='ja'
                                placeholderText="選択してください"
                                dateFormat="yyyy/MM/dd"
                                todayButton="今日"
                                onChange={onChangeBankSetDate}
                                selected={formBankSetDate ? formBankSetDate as Date : null}
                                onFocus={e => e.target.blur()} // <--- ソフトウェアキーボード非表示
                            />
                        </div>
                        <label htmlFor="bankType" className="form-label item-title required">銀行またはゆうちょ銀行を選択してください</label>
                        <div className="btn-group m-1">
                            <input type="radio" autoComplete="off" {...register('bankType', { required: true })} className="btn-check" id="bank" value="1" onChange={onChangeBankType} checked={typeBank} />
                            <label htmlFor="bank" className="btn btn-outline-primary w-50">銀行</label>

                            <input type="radio" autoComplete="off" {...register('bankType', { required: true })} className="btn-check" id="post" value="2" onChange={onChangeBankType} checked={typePost} />
                            <label htmlFor="post" className="btn btn-outline-primary w-50">ゆうちょ銀行</label>
                        </div>
                        {errors.bankType?.types?.required && <div className="text-danger">銀行またはゆうちょ銀行を選択してください</div>}

                        <div className={yokinClassName}>
                            <label htmlFor="bankList" className="form-label item-title required">銀行名を検索して選択してください</label>
                            <div className="input-group">
                                <input type="text" autoComplete="off" {...register('bankSearch')} className="form-control" id="bankSearch" value={bankSearch} onChange={onChangeBankSearch} onBlur={onBlurBankSearch} placeholder="全角カタカナで１文字以上入力" />
                                <span className="input-group-btn">
                                    <button type="button" className="btn btn-primary shadow-none" onClick={onButtonBankSearch} disabled={bankSearchButtonDisabled}>
                                        <i><FontAwesomeIcon icon={faSearch} cursor='pointer' /></i>
                                    </button>
                                </span>
                            </div>
                            {0 < optionBankList.length ? (
                                <select autoComplete="off" {...register('bankList')} className="form-control" id="bankList" value={bankName} onChange={onChangeBankList} disabled={bankListDisabled}>
                                    <option value=''>選択してください</option>
                                    {optionBankList}
                                </select>
                            ) : (
                                <div className="text-danger">銀行名は見つかりませんでした</div>
                            )}
                            {bankListRequired === true && <div className="text-danger">銀行名を選択してください</div>}
                        </div>
                        <div className={yokinClassName}>
                            <label htmlFor="bankBranchList" className="form-label item-title required">支店名を検索して選択してください</label>
                            <div className="input-group">
                                <input type="text" autoComplete="off" {...register('bankBranchSearch')} className="form-control" id="bankBranchSearch" value={bankBranchSearch} onChange={onChangeBankBranchSearch} onBlur={onBlurBankBranchSearch} disabled={bankBranchSearchDisabled} placeholder="全角カタカナで１文字以上入力" />
                                <span className="input-group-btn">
                                    <button type="button" className="btn btn-primary shadow-none" onClick={onButtonBankBranchSearch} disabled={bankBranchSearchButtonDisabled}>
                                        <i><FontAwesomeIcon icon={faSearch} cursor='pointer' /></i>
                                    </button>
                                </span>
                            </div>
                            {0 < optionBankBranchList.length ? (
                                <select autoComplete="off" {...register('bankBranchList')} className="form-control" id="bankBranchList" value={bankBranchName} onChange={onChangeBankBranchList} disabled={bankBranchListDisabled}>
                                    <option value=''>選択してください</option>
                                    {optionBankBranchList}
                                </select>
                            ) : (
                                <div className="text-danger">支店名は見つかりませんでした</div>
                            )}
                            {bankBranchListRequired === true && <div className="text-danger">支店名を選択してください</div>}
                        </div>
                        <div className={yokinClassName}>
                            <label htmlFor="depositType" className="form-label item-title required">預金種別</label>
                            <select autoComplete="off" {...register('depositType')} className="form-control" id="depositType" value={salonMember.depositType} onChange={onChangeDepositType}>
                                <option value="1">普通</option>
                                <option value="2">当座</option>
                            </select>
                        </div>
                        <div className={yokinClassName}>
                            <label htmlFor="accountNumber" className="form-label item-title required">口座番号</label>
                            <input type="text" autoComplete="off" inputMode="numeric" {...register('accountNumber')} className="form-control" id="accountNumber" value={accountNumber} onChange={onChangeAccountNumber} placeholder="半角7桁0～9の数字" />
                            {accountNumberRequired === true && <div className="text-danger">口座番号を半角7桁0～9の数字で入力してください</div>}
                        </div>
                        <div className={yokinClassName2}>
                            <label htmlFor="accountKigou" className="form-label item-title required">記号</label>
                            <input type="text" autoComplete="off" inputMode="numeric" {...register('accountKigou')} className="form-control" id="accountKigou" value={accountKigou} onChange={onChangeAccountKigou} placeholder="半角5文字" />
                            {accountKigouRequired === true && <div className="text-danger">記号を半角5桁0～9の数字で入力してください。キャッシュカード券面AAAAA-BBBBBBBBのAAAAAの部分。</div>}
                        </div>
                        <div className={yokinClassName2}>
                            <label htmlFor="accountKigou" className="form-label item-title required">番号</label>
                            <input type="text" autoComplete="off" inputMode="numeric" {...register('accountBangou')} className="form-control" id="accountBangou" value={accountBangou} onChange={onChangeAccountBangou} placeholder="半角8文字" />
                            {accountBangouRequired === true && <div className="text-danger">番号を半角8桁0～9の数字で入力してください。キャッシュカード券面AAAAA-BBBBBBBBのBBBBBBBBの部分。</div>}
                        </div>
                        <div className={yokinClassName3}>
                            <label htmlFor="depositerName" className="form-label item-title required">預金者名（カナ）</label>
                            {/*参照：https://www.tanshin.co.jp/business/netbk/pdf/zengin_moji.pdf&clen=67237&chunk=true */}
                            <input type="text" autoComplete="off" {...register('depositerName', { required: true, pattern: /^[ァ-ヶー　（）．－「」／０-９Ａ-Ｚ]+$/ })} className="form-control" id="depositerName" value={salonMember.depositerName} onChange={onChangeDepositerName} /*onBlur={onBlurDepositerName}*/ placeholder="全角カタカナ・記号・英大文字" />
                            {errors.depositerName?.types?.required && <div className="text-danger">預金者名を全角カナ・記号・英大文字で入力してください</div>}
                            {errors.depositerName?.types?.pattern && <div className="text-danger">預金者名は全角カナ・記号・英大文字で入力してください</div>}
                        </div>
                    </div>

                    <section className="row">
                        <section className="col">
                            <div className="attention-box">預金口座情報の変更後は<br />カードの再読み込みが必要です。</div>
                        </section>
                    </section>

                    <div className="col-md-12 mb-5">
                        <div className="row">
                            <button className="btn btn-success btn-block mx-auto" type="button" onClick={handleEditConfiumShow}>更新する</button>
                        </div>
                    </div>

                    {/*更新確認ダイアログ*/}
                    <Modal show={showEditConfium} onHide={handleEditConfiumClose}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Modal.Header closeButton>
                                <Modal.Title>確認</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>更新します。よろしいですか？</Modal.Body>
                            <Modal.Footer>
                                <Button className="btn-dialog-cancel" type="button" onClick={handleEditConfiumClose}>いいえ</Button>
                                <button className="btn btn-dialog-ok m-1 ms-auto" type="submit" onClick={handleEditConfiumClose}>はい</button>
                            </Modal.Footer>
                        </form>
                    </Modal>

                </div>
            )}
            <Footer />
        </>
    );
};