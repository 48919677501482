import { useNavigate, useLocation } from "react-router-dom"
import { Header } from "../atoms/Header"
import { Footer } from "../atoms/Footer"
import React, { useContext, useEffect, useState } from "react";
import { SelectedSalonContext } from "../providers/SelectedSalonProvider";
import { useSalon } from "../hooks/useSalon";
import { useSalonMembers } from "../hooks/useSalonMembers";
import { useMemberUseServices } from "../hooks/useMemberUseServices"
import { MSalonMember, MSalon, TServicePeriod } from "../API";
import { useService } from "../hooks/useService";
import { useForm } from 'react-hook-form'
import { Loading } from "../atoms/Loading";
import { BsPencilSquare, BsArrowLeftCircle } from 'react-icons/bs';
import { Button, Modal } from 'react-bootstrap';
/* ReactDatePicker(ここから) */
import ReactDatePicker, { registerLocale } from "react-datepicker";
import getYear from "date-fns/getYear";
import getMonth from "date-fns/getMonth";
import ja from 'date-fns/locale/ja';
import '../css/common.css';
import { ANYWHERE_CONFIRMED, CONFIRM_PAPER_NO, CONFIRM_PAPER_YES, FORCE_CONFIRM_YES, /*FORCE_CONFIRM_NO,*/ DEPOSIT_TYPE_ORDINARY } from '../global-define';
import { useCommonTools } from '../hooks/useCommonTools';
//import { on } from "events";

ja.options.weekStartsOn = 0;    // jaのロケールの設定が週頭が月曜始まりになっているので日曜始まりにする
registerLocale('ja', ja);       // ReactDatepickerのロケール登録
/* ReactDatePicker(ここまで) */

type Inputs = {
    storeId: string;
    memberId: string;
    applicationDate: string;
    memberName: string;
    furigana: string;
    dateOfBirth: string;
    zipCode: string;
    address1: string;
    address2: string;
    phoneNumber: string;
    phoneNumberMobile: string;
    mailAddress: string;
    bankCode: string;
    bankBranchCode: string;
    depositType: string;
    accountNumber: string;
    depositerName: string;
    confirmPaper: string;
    forceConfirm: string;
}

export const StoreMemberEdit = (props) => {

    const { setUser, setAuthState } = props;

    const Y = (new Date()).getFullYear();
    const M = ("00" + ((new Date()).getMonth() + 1)).slice(-2);
    const D = ("00" + (new Date()).getDate()).slice(-2);
    const TodayString = Y + "/" + M + "/" + D

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        //getValues
    } = useForm<Inputs>({
        criteriaMode: "all",
        shouldFocusError: true,
    });

    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const memberId = query.get("memberId");

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const { setSelectedMemberContext } = useContext(SelectedSalonContext);
    const { salonAccount, getSalonAccount } = useSalon();
    const { salonMember, setSalonMember, getSalonMember, updateSalonMember, existSalonMember } = useSalonMembers();
    const { serviceMenus, getServiceMenus } = useService();
    const { updateMemberUseServiceMenus } = useMemberUseServices();
    const { zenNum2HanNum, /*hira2ZenKana,*/ getTransferDateString, dateFormatString, isSp } = useCommonTools();

    const [checks, setChecks] = useState<boolean[]>([...Array(99)].map(() => false));
    const [servicePeriods, setServicePeriods] = useState<TServicePeriod[]>([...Array(99)].map(
        () => ({ __typename: "TServicePeriod", id: "", serviceId: "", startDate: "", endDate: "", createdAt: "", updatedAt: "" })
    ));

    const [confirmPaperCheck, setConfirmPaperCheck] = useState<boolean>(false);
    //const [forceConfirmCheck, setForceConfirmCheck] = useState<boolean>(false);
    const [totalServicePrice, setTotalServicePrice] = useState<number>();
    const [totalServiceCount, setTotalServiceCount] = useState<number>();
    const [isPhoneCheck, setIsPhoneCheck] = useState<boolean>(true);
    // 申込日の入力フォーム用
    const [formApplicationDate, setFormApplicationDate] = useState(new Date());
    // 誕生日の入力フォーム用
    const [formDateOfBirth, setFormDateOfBirth] = useState(null);
    const [classNameTypeBank, setClassNameTypeBank] = useState<string>(null);
    const [classNameTypePost, setClassNameTypePost] = useState<string>(null);
    const [duplicateMemberId, setDuplicateMemberId] = useState<boolean>(false);

    /**
     * １件データを取得する
     * @param storeId 
     * @param memberId 
     */
    const getEditData = async (storeId: string, memberId: string) => {

        const mSalon = await getSalonAccount(storeId);

        const salonMember = await getSalonMember(storeId, memberId);
        setSalonMember(salonMember);            // 画面表示用
        await setServiceChecks(salonMember, mSalon);  // 画面表示用
        setSelectedMemberContext(salonMember);  // 口座変更画面に渡す用
        // 振替用紙チェック用
        await setConfirmPaper(salonMember);           // 振替用紙チェック用
        // カード強制確認
        //await setForceConfirm(salonMember);           // カード強制確認チェック用

        if (salonMember.applicationDate !== '') {
            setFormApplicationDate(new Date(salonMember.applicationDate)); // 画面表示用（カレンダーコントロール）
            setValue('applicationDate', salonMember.applicationDate); // Form変数(react-hook-form用)
        } else {
            setValue('applicationDate', '');
        }

        if (salonMember.dateOfBirth !== '') {
            setFormDateOfBirth(new Date(salonMember.dateOfBirth)); // 画面表示用（カレンダーコントロール）
            setValue('dateOfBirth', salonMember.dateOfBirth); // Form変数(react-hook-form用)
        } else {
            setValue('dateOfBirth', '');
        }

        // 表示切替え
        if (salonMember.accountNumber != null && salonMember.accountNumber.length === 14) {
            // 郵便局
            setClassNameTypeBank("list-group mb-3 shadow-sm d-none");
            setClassNameTypePost("list-group mb-3 shadow-sm");
        } else {
            // 銀行
            setClassNameTypeBank("list-group mb-3 shadow-sm");
            setClassNameTypePost("list-group mb-3 shadow-sm d-none");
        }

        setIsLoading(false);                    // 非同期完了後のローディング解除（asyncの完了となるこのメソッド内で行う必要があります）
    }

    /**
     * ページロード時の処理
     */
    useEffect(() => {

        // 店舗ID・店舗名のみセッションから取得
        const storeId = sessionStorage.getItem('selectedSalon.storeId');
        let salonJson = sessionStorage.getItem('salonJson'); // JSON
        let salon = null; if (salonJson != null) salon = JSON.parse(salonJson);
        let userId = sessionStorage.getItem('userId');
        if (salon == null || userId == null) {
            //セッション情報も無い（ログアウト状態）
            //ログイン画面に遷移
            navigate('/');
            return;
        }

        // DynamoDBから1件取得しsalonMemberにセットする
        getEditData(storeId, memberId);

    }, []);

    /**
     * サービスの有効期限が翌月の振替日時時点で有効かどうかを判定する
     * @param servicePeriod
     * @param offset 月数のオフセット（0:当月 1:翌月 2:翌々月）
     * @returns　true:有効 false:無効
     */
    const isServicePeriodValid = (servicePeriod: TServicePeriod, offset: number) => {

        // 指定していない場合は有効とする
        if (servicePeriod === null) {
            return true;
        }
        // 翌月の振替日時を取得
        const nextTransferDate = getTransferDateString(8, offset);

        //const startDate = servicePeriod.startDate.replace(/\//g, '');
        const endDate = servicePeriod.endDate.replace(/\//g, '');

        return (/*startDate <= nextTransferDate &&*/ nextTransferDate <= endDate);
    }

    /**
     * 月額サービスリストのチェック状態を更新する
     * @param member 
     */
    const setServiceChecks = async (member: MSalonMember, salon: MSalon) => {
        const services = await getServiceMenus(String(member.storeId));

        let checkArray = Array(services.length).fill(false);
        let servicePrice = 0;
        let serviceCount = 0;
        console.log(checkArray);

        const newPeriods = [...servicePeriods];
        services.sort((a, b) => Number(a.serviceId) > Number(b.serviceId) ? 1 : -1).map((service, index) => {
            let currentPeriod: TServicePeriod = null;
            member.useServices?.items.map((useService) => {
                if (service.id === String(useService?.mServiceID)) {


                    //console.log( checks); 
                    //console.log( checks.map(( check , i  ) => ( i === index ? true : check )) ); 
                    console.log(checkArray);
                    member.useServicePeriod.items.forEach((useServicePeriod) => {
                        if (service.serviceId === useServicePeriod.serviceId) {
                            //console.log( useServicePeriod?.period ); 
                            newPeriods[index] = useServicePeriod;
                            currentPeriod = useServicePeriod;
                        }
                    });

                    checkArray[index] = true;

                    if (isServicePeriodValid(currentPeriod, 1)) {
                        servicePrice += Number(service.monthlyPrice);
                        serviceCount += 1;
                    }
                }
                return [];
            })
            return [];
        })
        setChecks(checkArray);  // 全部falseにする
        setServicePeriods(newPeriods);

        if (0 < serviceCount) {
            // 1つ以上選択がある場合に手数料を追加する
            servicePrice += salon.bankTransferFee;
        }

        setTotalServicePrice(servicePrice);
        setTotalServiceCount(serviceCount);
    }

    /**
     * 振替用紙チェック済みのチェック状態を更新する
     * @param member 
     */
    const setConfirmPaper = async (member: MSalonMember) => {

        if (member.confirmPaper === CONFIRM_PAPER_YES) {
            setConfirmPaperCheck(true);
        }
        else {
            setConfirmPaperCheck(false);
        }
    }

    /**
     * カード強制確認済みのチェック状態を更新する
     * @param member 
     
    const setForceConfirm = async (member: MSalonMember) => {

        if (member.forceConfirm === FORCE_CONFIRM_YES) {
            setForceConfirmCheck(true);
        }
        else {
            setForceConfirmCheck(false);
        }
    }
*/
    /**
     * 戻るボタン押下
     * @returns 
     */
    const OnClickReturn = () => navigate(`/StoreMemberDetail?memberId=${memberId}`)

    /**
     * 口座情報更新ボタン押下
     * @returns 
     */
    const OnClickBankEdit = () => navigate(`/StoreMemberBankEdit`)

    /**
     * 登録するボタン押下
     */
    const onSubmit = async () => {

        setIsLoading(true);
        let duplicateError = false;
        // 最初とメンバーIDが変更されている
        if (memberId !== salonMember.memberId) {
            duplicateError = await existSalonMember(salonMember.storeId, salonMember.memberId);
            setDuplicateMemberId(duplicateError);
        }
        if (!duplicateError) {

            // 電話番号か携帯電話番号のいずれかがセットされているか？
            setIsPhoneCheck(true);
            if (salonMember.phoneNumber.length === 0 && salonMember.phoneNumberMobile.length === 0) {
                setIsPhoneCheck(false);
            }
            else {

                //setIsLoading(true);
                await updateSalonMember();
                await updateMemberUseServiceMenus(salonMember as MSalonMember, serviceMenus, checks, servicePeriods);

                setSelectedMemberContext(salonMember as MSalonMember);
                //setIsLoading(false);
                // 遷移
                navigate(`/StoreMemberDetail?memberId=${salonMember.memberId}`)
            }
        }
        setIsLoading(false);
    }

    /**
     * フォーム内のアイテムのonChangeイベント処理
     * @param date 
     */
    const onChangeApplicationDate = (date) => {
        try {
            const Y = date.getFullYear();
            const M = ("00" + (date.getMonth() + 1)).slice(-2);
            const D = ("00" + date.getDate()).slice(-2);
            setSalonMember({ ...salonMember, applicationDate: Y + "/" + M + "/" + D } as MSalonMember);
            setFormApplicationDate(new Date(Y + "/" + M + "/" + D));
            setValue('applicationDate', Y + "/" + M + "/" + D);
        } catch (e) {
            // 入力フォームの内容が消された場合（必須項目なので今日の日付をセットする）
            setSalonMember({ ...salonMember, applicationDate: TodayString } as MSalonMember);
            setFormApplicationDate(new Date(TodayString));
            setValue('applicationDate', TodayString);
        }
    }
    const onChangeMemberName = (event: React.ChangeEvent<HTMLInputElement>) => { setSalonMember({ ...salonMember, memberName: zenNum2HanNum(event.target.value) } as MSalonMember); }
    const onChangeFurigana = (event: React.ChangeEvent<HTMLInputElement>) => { setSalonMember({ ...salonMember, furigana: event.target.value } as MSalonMember); }
    //const onBlurFurigana = (event: React.ChangeEvent<HTMLInputElement>) => { setSalonMember({ ...salonMember, furigana: hira2ZenKana(event.target.value) } as MSalonMember); }
    const onChangeDateOfBirth = (date) => {
        try {
            const Y = date.getFullYear();
            const M = ("00" + (date.getMonth() + 1)).slice(-2);
            const D = ("00" + date.getDate()).slice(-2);
            setSalonMember({ ...salonMember, dateOfBirth: Y + "/" + M + "/" + D } as MSalonMember);
            setFormDateOfBirth(new Date(Y + "/" + M + "/" + D));
        } catch (e) {
            // 入力フォームの内容が消された場合
            setSalonMember({ ...salonMember, dateOfBirth: "" } as MSalonMember);
            setFormDateOfBirth(null);
        }
    }

    const onChanageMemberId = (event: React.ChangeEvent<HTMLInputElement>) => { setSalonMember({ ...salonMember, memberId: event.target.value } as MSalonMember); }
    const onChangeZipCode = (event: React.ChangeEvent<HTMLInputElement>) => { setSalonMember({ ...salonMember, zipCode: zenNum2HanNum(event.target.value) } as MSalonMember); }
    const onChangeAddress1 = (event: React.ChangeEvent<HTMLInputElement>) => { setSalonMember({ ...salonMember, address1: event.target.value } as MSalonMember); }
    const onChangeAddress2 = (event: React.ChangeEvent<HTMLInputElement>) => { setSalonMember({ ...salonMember, address2: event.target.value } as MSalonMember); }
    const onChangePhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => { setSalonMember({ ...salonMember, phoneNumber: zenNum2HanNum(event.target.value) } as MSalonMember); }
    const onChangePhoneNumberMobile = (event: React.ChangeEvent<HTMLInputElement>) => { setSalonMember({ ...salonMember, phoneNumberMobile: zenNum2HanNum(event.target.value) } as MSalonMember); }
    const onChangeMailAddress = (event: React.ChangeEvent<HTMLInputElement>) => { setSalonMember({ ...salonMember, mailAddress: event.target.value } as MSalonMember); }

    /**
     * 申込コース
     */
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {

        if (!event.target.checked) { // チェックが外れた場合
            // 選択中のindexを保存
            setCurrentServiceIndex(index);
            // モーダル表示して警告
            setShowDeleteService(true);
        } else { // チェックが入った場合はそのまま処理
            handleServiceCheck(event.target.checked, index);
        }
    }

    // 申込コースチェック処理
    const handleServiceCheck = (serviceChecked: boolean, index: number) => {
        setShowDeleteService(false);
        setChecks(checks.map((check, i) => (i === index ? serviceChecked : check)));

        if (serviceChecked) {
            calcTotalPriceAndCount(serviceChecked, index);
            /*
            if (Number(totalServiceCount) === 0) {
                // 0個→1個 手数料,コース費用を追加
                setTotalServicePrice(Number(totalServicePrice) + Number(serviceMenus[index].monthlyPrice) + Number(salonAccount.bankTransferFee));
            }
            else {
                // コース費用のみ加算
                setTotalServicePrice(Number(totalServicePrice) + Number(serviceMenus[index].monthlyPrice));
            }
            // コース選択数を更新
            setTotalServiceCount(Number(totalServiceCount) + 1);
            */
            // インデックスの一致するServicePeriodのStartDateとEndDateを設定する
            setServicePeriods(servicePeriods.map((servicePeriod, i) => (i === index ? { ...servicePeriod, serviceId: serviceMenus[index].serviceId, startDate: dateFormatString(getTransferDateString(8, 1)), endDate: "2099/12/31" } : servicePeriod)));

        } else {
            calcTotalPriceAndCount(serviceChecked, index);
            /*
            if (Number(totalServiceCount) === 1) {
                // 1個→0個 手数料を減算
                setTotalServicePrice(Number(totalServicePrice) - Number(serviceMenus[index].monthlyPrice) - Number(salonAccount.bankTransferFee));
            }
            else {
                // コース費用のみ減算
                setTotalServicePrice(Number(totalServicePrice) - Number(serviceMenus[index].monthlyPrice));
            }
            // コース選択数を更新
            setTotalServiceCount(Number(totalServiceCount) - 1);
            */
            setServicePeriods(servicePeriods.map((servicePeriod, i) => (i === index ? { ...servicePeriod, serviceId: '', startDate: '', endDate: '' } : servicePeriod)));
        }
    }


    /**
     * サービス終了ボタン処理
     * @param index
     */
    const OnClickEndService = (index: number) => {
        // カレントインデックスを保存
        setCurrentServiceIndex(index);
        // モーダル表示して警告
        setShowEndService(true);
    }

    /**
    * サービス終了処理
    */
    const handleEndService = (index: number) => {
        // サービス終了日を設定する
        // インデックスの一致するServicePeriodのEndDateを設定する
        setServicePeriods(servicePeriods.map(
            (servicePeriod, i) => {
                const currentTransferDate = getTransferDateString(8, 0);

                let startDateString = servicePeriod.startDate;
                const startDate = servicePeriod.startDate.replace(/\//g, '');
                if (startDate === "" || (startDate > currentTransferDate)) {
                    startDateString = dateFormatString(currentTransferDate);
                }

                const newPeriod = (i === index) ?
                    {
                        ...servicePeriod, serviceId: serviceMenus[index].serviceId
                        , startDate: startDateString
                        , endDate: dateFormatString(currentTransferDate)
                    }
                    : servicePeriod;
                return newPeriod;
            }
        ))

        // モーダル表示して警告
        setShowEndService(false);
        // 終了させたサービスについて費用と数から除く
        calcTotalPriceAndCount(false, index);

    }

    /**
     * 振替用紙チェック済み
     */
    const handleChangeConfirmPaper = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setSalonMember({ ...salonMember, confirmPaper: CONFIRM_PAPER_YES } as MSalonMember);
            setConfirmPaperCheck(true);
            setShowConfirm(true); // 確認ダイアログ表示
        } else {
            setSalonMember({ ...salonMember, confirmPaper: CONFIRM_PAPER_NO } as MSalonMember);
            setConfirmPaperCheck(false);
        }
    }

    /**
     * 振替用紙確認モーダルダイアログの制御用
     */
    const [showConfirm, setShowConfirm] = useState(false);
    const handleConfirmClose = () => setShowConfirm(false);

    /**
    * カード強制確認済み確認モーダルダイアログの制御用
    */
    const [showForceConfirm, setShowForceConfirm] = useState(false);
    const handleForceConfirmClose = () => setShowForceConfirm(false);

    /**
    * 利用中サービス制御用選択中Index
    */
    const [currentServiceIndex, setCurrentServiceIndex] = useState(-1);

    /**
    * 利用中サービス削除ダイアログの制御用
    */

    const [showDeleteService, setShowDeleteService] = useState(false);
    const handleDeleteServiceClose = () => setShowDeleteService(false);

    /**
    * 利用中サービス終了ダイアログの制御用
    */
    const [showEndService, setShowEndService] = useState(false);
    const handleEndServiceClose = () => setShowEndService(false);

    /* ReactDatePicker設定（ここから） */
    var eraHandler = yearNow => {
        const generate = (era, startYear) => {
            let yearDsp = yearNow - startYear + 1;
            let yearTop = "";
            if (yearDsp === 1) {
                yearTop = "元";
            } else {
                yearTop = ('00' + yearDsp).slice(-2);
            }
            return `${era}${yearTop}年`;
        };

        if (yearNow >= 2019) {
            return generate('令和', 2019);
        }

        if (yearNow >= 1989) {
            return generate('平成', 1989);
        }

        if (yearNow >= 1926) {
            return generate('昭和', 1926);
        }

        if (yearNow >= 1912) {
            return generate('大正', 1912);
        }
    }
    var startYear = 1912; // カレンダーに表示する最初の西暦（大正元年となる1912を指定）
    var futureListUp = 0; // カレンダーに表示する未来の年数
    var years = Array.from({ length: getYear(new Date()) - startYear + futureListUp }, (v, k) => k + startYear).reverse();
    const months = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
    /* ReactDatePicker設定（ここまで） */

    /** 選択サービス数と合計金額の加算、減算関数
     * @param serviceChecked

     */
    const calcTotalPriceAndCount = (serviceChecked: boolean, index: number) => {

        if (serviceChecked) {
            if (Number(totalServiceCount) === 0) {
                // 0個→1個 手数料,コース費用を追加
                // 有効期限チェック
                setTotalServicePrice(Number(totalServicePrice) + Number(serviceMenus[index].monthlyPrice) + Number(salonAccount.bankTransferFee));
            }
            else {
                // コース費用のみ加算
                setTotalServicePrice(Number(totalServicePrice) + Number(serviceMenus[index].monthlyPrice));
            }
            // コース選択数を更新
            setTotalServiceCount(Number(totalServiceCount) + 1);
        } else {
            if (isServicePeriodValid(servicePeriods[index], 1)) {
                if (Number(totalServiceCount) === 1) {
                    // 1個→0個 手数料を減算
                    setTotalServicePrice(Number(totalServicePrice) - Number(serviceMenus[index].monthlyPrice) - Number(salonAccount.bankTransferFee));
                }
                else {
                    // コース費用のみ減算
                    setTotalServicePrice(Number(totalServicePrice) - Number(serviceMenus[index].monthlyPrice));
                }
                // コース選択数を更新
                setTotalServiceCount(Number(totalServiceCount) - 1);
            }
        }
    }

    const onChangePeriodStart = (date, index) => {

        // チェックがされていない場合は、処理しない
        if (checks[index] === false) {
            return;
        }

        try {
            const Y = date.getFullYear();
            const M = ("00" + (date.getMonth() + 1)).slice(-2);
            const D = ("00" + date.getDate()).slice(-2);
            const periods = servicePeriods.map(
                (servicePeriod, i) => {
                    // endDateがstartDateより前の日付の場合は、endDateにstartDateをセットする。
                    const startdate = Y + "/" + M + "/" + D
                    const enddate = startdate <= servicePeriod.endDate ? servicePeriod.endDate : startdate;

                    const result = (i === index) ? { ...servicePeriod, serviceId: serviceMenus[index].serviceId, startDate: startdate, endDate: enddate } : servicePeriod;
                    return result;
                }
            );

            const before = isServicePeriodValid(servicePeriods[index], 1);
            const after = isServicePeriodValid(periods[index], 1);

            // 変化が有った場合は、合計金額と合計回数を再計算する
            if ((before === false && after === true) || (before === true && after === false)) {
                calcTotalPriceAndCount(after, index);
            }
            setServicePeriods(periods);
        } catch (e) {
            // クリアする
            setServicePeriods(servicePeriods.map((servicePeriod, i) => (i === index ? { ...servicePeriod, serviceId: '', startDate: '', endDate: '' } : servicePeriod)));
        }
    }

    const onChangePeriodEnd = (date, index) => {

        // チェックがされていない場合は、処理しない
        if (checks[index] === false) {
            return;
        }

        try {
            const Y = date.getFullYear();
            const M = ("00" + (date.getMonth() + 1)).slice(-2);
            const D = ("00" + date.getDate()).slice(-2);
            const periods = servicePeriods.map(
                (servicePeriod, i) => {
                    // startDateがEndDateより後の日付の場合は、startDateにendDateをセットする。
                    const enddate = Y + "/" + M + "/" + D
                    const startdate = servicePeriod.startDate < enddate ? servicePeriod.startDate : enddate;
                    const result = (i === index) ? { ...servicePeriod, serviceId: serviceMenus[index].serviceId, startDate: startdate, endDate: enddate } : servicePeriod;
                    return result;
                }
            );

            const before = isServicePeriodValid(servicePeriods[index], 1);
            const after = isServicePeriodValid(periods[index], 1);

            // 変化が有った場合は、合計金額と合計回数を再計算する
            if ((before === false && after === true) || (before === true && after === false)) {
                calcTotalPriceAndCount(after, index);
            }
            setServicePeriods(periods);
        } catch (e) {
            // クリアする
            setServicePeriods(servicePeriods.map((servicePeriod, i) => (i === index ? { ...servicePeriod, serviceId: '', startDate: '', endDate: '' } : servicePeriod)));
        }
    }

    // Endボタンの表示条件を確認する
    // チェック有りでサービス終了日が未入力または未来日の場合はEndボタンを表示する
    /*
    const isEndButton = (checked: boolean, endDate: string) => {

        if (checked) {
            if (endDate === null || endDate === "") {
                return true;
            }
            if (endDate > dateFormatString(getTransferDateString(8, 0))) {
                return true;
            }
        }
        return false;
    }
    */
    // Endボタンの表示条件を確認する
    // チェック有りでサービス終了日が未入力または未来日の場合はEndボタンを表示する
    // 当月なら、サービス終了予定を表示する
    // 過去なら、サービス終了済みを表示する
    //
    const getEndButton = (checked: boolean, endDate: string, index: number) => {

        if (checked) {
            if (endDate === null || endDate === "" || endDate > dateFormatString(getTransferDateString(8, 0))) {
                return <button className="ms-3 btn btn-sm btn-outline-primary " type="button" onClick={(e) => OnClickEndService(index)}>終了</button>;
            }
            if (endDate === dateFormatString(getTransferDateString(8, 0))) {
                return <span className="ms-3 mt-1 text-warning">サービス終了予定</span>;
            }
            if (endDate < dateFormatString(getTransferDateString(8, 0))) {
                return <span className="ms-3 mt-1 text-info">サービス終了</span>;
            }
        }
        return "";
    }

    // サービス終了日からサービスが終了しているかを判定する
    const getClassNameByServiceEnd = (endDate: string, line: number) => {

        // lineの番号によってbaseClassを変更する
        let baseClass = "list-group-item d-flex justify-content-between lh-sm border-0 pb-0"

        if (line === 2) {
            baseClass = "list-group-item text-sm border-0 pt-0"
        }

        if (endDate === null || endDate === "") {
            return baseClass;
        }
        const td = dateFormatString(getTransferDateString(8, 0))
        if (endDate <= td) {
            return baseClass + " bg-secondary text-white";
        }
        return baseClass;
    }

    return (
        <>
            <Header setUser={setUser} setAuthState={setAuthState} setIsLoading={setIsLoading} />
            {isLoading ? (
                <Loading />
            ) : (
                <div className="container mt-2 mb-5">
                    <section className="row">
                        <section className="col-12">
                            <div className="d-flex page-title-row">
                                <button className="btn" onClick={OnClickReturn}><h2 className="icon-gray"><BsArrowLeftCircle /></h2></button>
                                <header className="d-flex page-title">
                                    <h1 className="fs-4 m-1">会員　編集</h1>
                                </header>
                            </div>
                        </section>
                    </section>
                    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                        <div className="row g-3 mt-2">
                            <div className="col-md-12">
                                <div className="row g-3">
                                    <div className="col-6">
                                        <label htmlFor="StoreNumber" className="form-label item-title">契約店舗</label>
                                        <div className="input-group">
                                            <input type="text" autoComplete="off" className="form-control" id="storeName" value={String(salonAccount?.storeName)} readOnly />
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <label htmlFor="applicationDate" className="form-label item-title">会員登録日</label>
                                        <ReactDatePicker
                                            id="applicationDate"
                                            locale='ja'
                                            placeholderText="選択してください"
                                            dateFormat="yyyy/MM/dd"
                                            //isClearable
                                            todayButton="今日"
                                            onChange={onChangeApplicationDate}
                                            selected={formApplicationDate as Date}
                                            onFocus={e => e.target.blur()} // <--- ソフトウェアキーボード非表示
                                            disabled={true}
                                        />
                                    </div>

                                    <div className="col-sm-6">
                                        <label htmlFor="MemberId" className="form-label item-title">会員番号</label>
                                        <input type="text" {...register('memberId', { required: true, pattern: /^\d{6}$/ })} autoComplete="off" className="form-control" id="memberId" value={String(salonMember?.memberId)} onChange={onChanageMemberId} placeholder="半角数字６桁" />
                                        {errors.memberId?.types?.required && <div className="text-danger">会員番号を入力してください</div>}
                                        {errors.memberId?.types?.pattern && <div className="text-danger">会員番号は6桁の0～9の数字で入力してください</div>}
                                        {duplicateMemberId && <div className="text-danger">会員番号が他会員と重複しています。</div>}
                                    </div>
                                    <div className="col-6">
                                    </div>

                                    <div className="col-sm-6">
                                        <label htmlFor="memberName" className="form-label item-title required">お名前</label>
                                        <input type="text" autoComplete="off" {...register('memberName', { required: true })} className="form-control" id="memberName" placeholder="氏名" value={salonMember.memberName} onChange={onChangeMemberName} />
                                        {errors.memberName?.types?.required && <div className="text-danger">お名前を入力してください</div>}
                                    </div>

                                    <div className="col-sm-6">
                                        <label htmlFor="furigana" className="form-label item-title required">フリガナ</label>
                                        <input type="text" autoComplete="off" {...register('furigana', { required: true })} className="form-control" id="furigana" placeholder="フリガナ" value={salonMember.furigana} onChange={onChangeFurigana} /*onBlur={onBlurFurigana}*/ />
                                        {errors.furigana?.types?.required && <div className="text-danger">フリガナを入力してください</div>}
                                    </div>

                                    <div className="col-6">
                                        <label htmlFor="dateOfBirth" className="form-label item-title">生年月日</label>
                                        <ReactDatePicker
                                            id="dateOfBirth"
                                            locale='ja'
                                            placeholderText="選択してください"
                                            dateFormat="yyyy/MM/dd"
                                            isClearable
                                            showMonthDropdown
                                            showYearDropdown
                                            //todayButton="今日"
                                            dropdownMode="select"
                                            onChange={onChangeDateOfBirth}
                                            selected={formDateOfBirth as Date}
                                            onFocus={e => e.target.blur()} // <--- ソフトウェアキーボード非表示
                                            // カレンダーのヘッダ部分をカスタマイズする
                                            renderCustomHeader={({
                                                date,
                                                changeYear,
                                                changeMonth,
                                                decreaseMonth,
                                                increaseMonth,
                                                prevMonthButtonDisabled,
                                                nextMonthButtonDisabled
                                            }) => {
                                                return (
                                                    <div
                                                        style={{ margin: 10, display: "flex", justifyContent: "center" }}>
                                                        {/* 前月ボタン */}
                                                        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</button>
                                                        {/* 年の部分 */}
                                                        <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)} >
                                                            {years.map((option) => (
                                                                // eraHandler()で年のプルダウンに元号を付ける
                                                                <option key={option} value={option}>{option}年（{eraHandler(option)}）</option>
                                                            ))}
                                                        </select>
                                                        {/* 月の部分 */}
                                                        <select value={months[getMonth(date)]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))} >
                                                            {months.map(option => (
                                                                <option key={option} value={option}> {option}月 </option>
                                                            ))}
                                                        </select>
                                                        {/* 次月ボタン */}
                                                        <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</button>
                                                    </div>
                                                );
                                            }
                                            }
                                        />
                                    </div>
                                    <div className="col-6">
                                    </div>

                                    <div className="col-sm-6">
                                        <label htmlFor="zipCode" className="form-label item-title required">郵便番号</label>
                                        <input type="text" autoComplete="off" inputMode="numeric" {...register('zipCode', { required: true, pattern: /[0-9]{7}/ })} className="form-control" id="zipCode" value={salonMember.zipCode} onChange={onChangeZipCode} placeholder="半角7桁ハイフン無し0～9の数字" />
                                        {errors.zipCode?.types?.required && <div className="text-danger">郵便番号を入力してください</div>}
                                        {errors.zipCode?.types?.pattern && <div className="text-danger">郵便番号はハイフン無しの7桁の0～9の数字で入力してください</div>}
                                    </div>
                                    <div className="col-6">
                                    </div>

                                    <div className="col-sm-6">
                                        <label htmlFor="address1" className="form-label item-title required">住所１</label>
                                        <input type="text" autoComplete="off" {...register('address1', { required: true })} className="form-control" id="address1" value={salonMember.address1} onChange={onChangeAddress1} placeholder="県市区町村" />
                                        {errors.address1?.types?.required && <div className="text-danger">住所１を入力してください</div>}
                                    </div>
                                    <div className="col-sm-6">
                                        <label htmlFor="address2" className="form-label item-title">住所２</label>
                                        <input type="text" autoComplete="off" {...register('address2', { required: false })} className="form-control" id="address2" value={salonMember.address2} onChange={onChangeAddress2} placeholder="番地・ビル名・部屋番号" />
                                    </div>

                                    <div className="col-md-6">
                                        <label htmlFor="phoneNumber" className="form-label item-title required">電話番号</label>
                                        <input type="text" autoComplete="off" inputMode="tel" {...register('phoneNumber', { required: false, pattern: /^[0-9]+$/ })} className="form-control" id="phoneNumber" value={salonMember.phoneNumber} onChange={onChangePhoneNumber} placeholder="半角ハイフン無し0～9の数字" />
                                        {isPhoneCheck === false && <div className="text-danger">電話番号か携帯番号のいずれかを入力してください</div>}
                                        {errors.phoneNumber?.types?.pattern && <div className="text-danger">ハイフン無しの0～9の数字で入力してください</div>}
                                    </div>

                                    <div className="col-md-6">
                                        <label htmlFor="phoneNumberMobile" className="form-label item-title required">携帯番号</label>
                                        <input type="text" autoComplete="off" inputMode="tel" {...register('phoneNumberMobile', { required: false, pattern: /^[0-9]+$/ })} className="form-control" id="phoneNumberMobile" value={salonMember.phoneNumberMobile} onChange={onChangePhoneNumberMobile} placeholder="半角ハイフン無し0～9の数字" />
                                        {isPhoneCheck === false && <div className="text-danger">電話番号か携帯番号のいずれかを入力してください</div>}
                                        {errors.phoneNumberMobile?.types?.pattern && <div className="text-danger">ハイフン無しの0～9の数字で入力してください</div>}
                                    </div>

                                    <div className="col-md-6">
                                        <label htmlFor="mailAddress" className="form-label item-title">メールアドレス</label>
                                        <input type="text" autoComplete="off" inputMode="email" {...register('mailAddress', { required: false, pattern: /\S+@\S+\.\S+/ })} className="form-control" id="mailAddress" value={salonMember.mailAddress} onChange={onChangeMailAddress} />
                                        {errors.mailAddress?.types?.pattern && <div className="text-danger">メールアドレスの形式が違います</div>}
                                    </div>

                                    <div className="col-md-6">
                                    </div>

                                    <div className="col-md-12 order-md-last mt-4">
                                        {/*申込コース*/}
                                        <h5 className="d-flex justify-content-between align-items-center">
                                            <label htmlFor="cource" className="form-label item-title required">申込コース</label>
                                        </h5>
                                        <ul className="list-group mb-4 mt-0 shadow-sm">
                                            {serviceMenus.sort((a, b) => Number(a.serviceId) > Number(b.serviceId) ? 1 : -1).map((serviceMenu, index) => {
                                                return (!isSp() ? (
                                                    /* <li className="list-group-item d-flex justify-content-between lh-sm" key={index}> */
                                                    <li className={getClassNameByServiceEnd(servicePeriods[index].endDate, 1)} key={index}>
                                                        <div className="form-check" style={{ width: 25 + '%' }}>
                                                            <input autoComplete="off" className="form-check-input" type="checkbox" checked={checks[index]} id={'cource' + index} onChange={(e) => handleChange(e, index)} />
                                                            <label className="form-check-label" htmlFor={'cource' + index}>{serviceMenu.courseName}
                                                                {getEndButton(checks[index], servicePeriods[index].endDate, index)}</label>
                                                        </div>
                                                        <div className="d-flex" style={{ width: 50 + '%' }}>
                                                            <div className="d-flex" style={{ width: 250 }}>
                                                                <label style={{ width: 100 }} htmlFor="periodStart" className="form-label mt-2">開始日:</label>
                                                                <ReactDatePicker
                                                                    className="me-2"
                                                                    id="periodStart"
                                                                    locale='ja'
                                                                    //placeholderText="選択してください"
                                                                    dateFormat="yyyy/MM/dd"
                                                                    todayButton="今日"
                                                                    onChange={(date) => onChangePeriodStart(date, index)}
                                                                    selected={Date.parse(servicePeriods[index].startDate) ? new Date(servicePeriods[index].startDate) : null}
                                                                    onFocus={e => e.target.blur()} // <--- ソフトウェアキーボード非表示
                                                                />
                                                            </div>
                                                            <div className="d-flex ms-4" style={{ width: 250 }}>
                                                                <label style={{ width: 100 }} htmlFor="periodEnd" className="form-label mt-2 text-align: right;">終了日:</label>
                                                                <ReactDatePicker
                                                                    className="me-2"
                                                                    id="periodEnd"
                                                                    locale='ja'
                                                                    //placeholderText="選択してください"
                                                                    dateFormat="yyyy/MM/dd"
                                                                    todayButton="今日"
                                                                    onChange={(date) => onChangePeriodEnd(date, index)}
                                                                    selected={Date.parse(servicePeriods[index].endDate) ? new Date(servicePeriods[index].endDate) : null}
                                                                    onFocus={e => e.target.blur()} // <--- ソフトウェアキーボード非表示
                                                                />
                                                            </div>
                                                        </div>

                                                        <span className="text-muted">&yen;{serviceMenu.monthlyPrice.toLocaleString()}</span>
                                                    </li>)
                                                    : (
                                                        <>
                                                            <li className={getClassNameByServiceEnd(servicePeriods[index].endDate, 1)} key={index}>
                                                                <div className="form-check">
                                                                    <input autoComplete="off" className="form-check-input" type="checkbox" checked={checks[index]} id={'cource' + index} onChange={(e) => handleChange(e, index)} />
                                                                    <label className="form-check-label" htmlFor={'cource' + index}>{serviceMenu.courseName}
                                                                        {getEndButton(checks[index], servicePeriods[index].endDate, index)}</label>
                                                                </div>
                                                                <span className="text-muted" style={{ margin: "0 0 0 auto" }}>&yen;{serviceMenu.monthlyPrice.toLocaleString()}</span>
                                                            </li>
                                                            <li className={getClassNameByServiceEnd(servicePeriods[index].endDate, 2)} style={{ fontSize: 12 }} >

                                                                <div className="d-flex ms-a" style={{ margin: "0 0 0 auto" }}>
                                                                    <div className="d-flex me-0" style={{ width: 140 }}>
                                                                        <label style={{ width: 60 }} htmlFor="periodStart" className="form-label mt-2 me-0 p-0">開始日:</label>
                                                                        <ReactDatePicker
                                                                            className="ms-1 ps-1 pt-0 border-1 mt-1"
                                                                            style={{ fontSize: 10, width: 10 }}
                                                                            id="periodStart"
                                                                            locale='ja'
                                                                            //placeholderText="選択してください"
                                                                            dateFormat="yyyy/MM/dd"
                                                                            todayButton="今日"
                                                                            onChange={(date) => onChangePeriodStart(date, index)}
                                                                            selected={Date.parse(servicePeriods[index].startDate) ? new Date(servicePeriods[index].startDate) : null}
                                                                            onFocus={e => e.target.blur()} // <--- ソフトウェアキーボード非表示
                                                                        />
                                                                    </div>
                                                                    <div className="d-flex ms-4" style={{ width: 140 }}>
                                                                        <label style={{ width: 60 }} htmlFor="periodEnd" className="form-label mt-2">終了日:</label>
                                                                        <ReactDatePicker
                                                                            className="ms-1 ps-1 pt-0 border-1 mt-1"
                                                                            style={{ fontSize: 10, width: 10 }}
                                                                            id="periodEnd"
                                                                            locale='ja'
                                                                            //placeholderText="選択してください"
                                                                            dateFormat="yyyy/MM/dd"
                                                                            todayButton="今日"
                                                                            onChange={(date) => onChangePeriodEnd(date, index)}
                                                                            selected={Date.parse(servicePeriods[index].endDate) ? new Date(servicePeriods[index].endDate) : null}
                                                                            onFocus={e => e.target.blur()} // <--- ソフトウェアキーボード非表示
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </>
                                                    )
                                                )
                                            })}

                                            {0 < totalServiceCount &&
                                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                                    <div>
                                                        <h6 className="my-0"><b>振替手数料</b></h6>
                                                    </div>
                                                    <span className="text-muted"><b>&yen;{salonAccount.bankTransferFee.toLocaleString()}</b></span>
                                                </li>
                                            }
                                            <li className="list-group-item d-flex justify-content-between lh-sm  list-group-item-warning">
                                                <div>
                                                    <h6 className="my-0"><b>毎月振替金額</b></h6>
                                                </div>
                                                <span className="text-muted"><b>&yen;{Number(totalServicePrice).toLocaleString()}</b></span>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="col-md-12 order-md-last mt-1">
                                        {/*預金口座振替情報*/}
                                        <h5 className="d-flex justify-content-between align-items-center item-title">
                                            預金口座振替情報
                                            <button className="btn ms-auto" type="button" onClick={OnClickBankEdit}><h3 className="icon-gray"><BsPencilSquare /></h3></button>
                                        </h5>
                                        <ul className={classNameTypeBank}>
                                            <li className={(salonMember?.confirmStatus === ANYWHERE_CONFIRMED || ((salonMember?.confirmPaper === CONFIRM_PAPER_YES) || (salonMember?.forceConfirm === FORCE_CONFIRM_YES)) ? " list-group-item-success card-checked " : " list-group-item-danger card-unchecked ") + " list-group-item d-flex justify-content-between lh-sm"}>
                                                <div style={{ margin: "0 auto" }}>
                                                    <h6 className="my-0"><b>{salonMember?.confirmStatus === ANYWHERE_CONFIRMED || ((salonMember?.confirmPaper === CONFIRM_PAPER_YES) || (salonMember?.forceConfirm === FORCE_CONFIRM_YES)) ? <span >カード確認済</span> : <span>カード未確認</span>}</b></h6>
                                                </div>
                                            </li>
                                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                                <div>
                                                    <h6 className="my-0">口座登録日</h6>
                                                </div>
                                                <span className="text-muted">{salonMember?.bankSetDate != null ? salonMember?.bankSetDate : ''}</span>
                                            </li>
                                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                                <div>
                                                    <span className="my-0">({salonMember?.bankCode}) {salonMember?.bankName}</span>
                                                </div>
                                                <span>({salonMember?.bankBranchCode}) {salonMember?.bankBranchName}</span>
                                            </li>
                                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                                <div>
                                                    <h6 className="my-0 item-title">預金種別</h6>
                                                </div>
                                                <span>
                                                    {salonMember?.depositType === DEPOSIT_TYPE_ORDINARY ? <span>普通</span> : <span>当座</span>}
                                                </span>
                                            </li>
                                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                                <div>
                                                    <h6 className="my-0 item-title">口座番号</h6>
                                                </div>
                                                <span>
                                                    {salonMember?.accountNumber}
                                                </span>
                                            </li>
                                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                                <div>
                                                    <h6 className="my-0 item-title">預金者名（カナ）</h6>
                                                </div>
                                                <span>
                                                    {salonMember?.depositerName}
                                                </span>
                                            </li>
                                        </ul>
                                        <ul className={classNameTypePost}>
                                            <li className={(salonMember?.confirmStatus === ANYWHERE_CONFIRMED || ((salonMember?.confirmPaper === CONFIRM_PAPER_YES) || (salonMember?.forceConfirm === FORCE_CONFIRM_YES)) ? " list-group-item-success card-checked " : " list-group-item-danger card-unchecked ") + " list-group-item d-flex justify-content-between lh-sm"}>
                                                <div style={{ margin: "0 auto" }}>
                                                    <h6 className="my-0"><b>{salonMember?.confirmStatus === ANYWHERE_CONFIRMED || ((salonMember?.confirmPaper === CONFIRM_PAPER_YES) || (salonMember?.forceConfirm === FORCE_CONFIRM_YES)) ? <span>カード確認済</span> : <span>カード未確認</span>}</b></h6>
                                                </div>
                                            </li>
                                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                                <div>
                                                    <h6 className="my-0">口座登録日</h6>
                                                </div>
                                                <span className="text-muted">{salonMember?.bankSetDate != null ? salonMember?.bankSetDate : ''}</span>
                                            </li>
                                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                                <div>
                                                    <span className="my-0">ゆうちょ銀行</span>
                                                </div>
                                                <span></span>
                                            </li>
                                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                                <div>
                                                    <h6 className="my-0 item-title">記号番号</h6>
                                                </div>
                                                <span>{salonMember?.accountNumber}</span>
                                            </li>
                                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                                <div>
                                                    <h6 className="my-0 item-title">預金者名（カナ）</h6>
                                                </div>
                                                <span>{salonMember?.depositerName}</span>
                                            </li>
                                        </ul>
                                        <div className="row mt-0">
                                            {/* 店舗側では操作出来ないようにする
                                            <div className="col-12">
                                                <h6 className="mt-0">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" id="forceConfirm" {...register("forceConfirm", {})} checked={forceConfirmCheck} onChange={(e) => handleChangeForceConfirm(e)} />
                                                        <label className="form-check-label item-title" htmlFor="forceConfirm">カード強制確認済み</label>
                                                    </div>
                                                </h6>
                                            </div>
                                            */}
                                            <div className="col-12">
                                                <h6 className="mt-0">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" id="confirmPaper" {...register("confirmPaper", {})} checked={confirmPaperCheck} onChange={(e) => handleChangeConfirmPaper(e)} />
                                                        <label className="form-check-label item-title" htmlFor="confirmPaper">振替用紙チェック済み</label>
                                                    </div>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="row g-3 mt-1">
                                <button className="btn btn-success btn-block mx-auto" type="submit">更新する</button>
                            </div>
                        </div>
                    </form>

                    {/*振替用紙確認済み確認ダイアログ*/}
                    <Modal show={showConfirm} onHide={handleConfirmClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>確認</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>キャッシュカードでの確認が出来なかった場合に<br />チェックを入れてください。<br />チェックを入れると振替用紙の内容を元に請求を行います。<br /><span className="text-danger">振替用紙は本部に送付してください。</span></Modal.Body>
                        <Modal.Footer>
                            <Button className="btn btn-dialog-ok m-1 ms-auto" onClick={handleConfirmClose}>閉じる</Button>
                        </Modal.Footer>
                    </Modal>

                    {/*カード強制確認の確認ダイアログ*/}
                    <Modal show={showForceConfirm} onHide={handleForceConfirmClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>確認</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>銀行支店の統廃合などで預金口座振替情報を手動で更新した場合にチェックを入れてください。<br />チェックを入れると預金口座振替情報の内容を元に請求を行います。</Modal.Body>
                        <Modal.Footer>
                            <Button className="btn btn-primary m-1 ms-auto" style={{ width: 150 }} onClick={handleForceConfirmClose}>閉じる</Button>
                        </Modal.Footer>
                    </Modal>
                    {/* チェック削除ダイアログ */}
                    <Modal show={showDeleteService} onHide={handleDeleteServiceClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>サービス削除確認</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>チェックを外すとサービスが即日削除されます。<br /><br />
                            今月でサービス終了する場合はキャンセルボタンを押して<br />改めてサービス名の横にある終了ボタンを押してください。<br /><br />
                            間違えてチェックした場合はこのままチェックを外すボタンを<br />押して削除を実行してください。</Modal.Body>
                        <Modal.Footer>
                            <Button className="btn btn-primary m-1 ms-auto" style={{ width: 150 }} onClick={(e) => handleServiceCheck(false, currentServiceIndex)}>チェックを外す</Button>
                            <Button className="btn btn-primary m-1 ms-auto" style={{ width: 150 }} onClick={handleDeleteServiceClose}>キャンセル</Button>
                        </Modal.Footer>
                    </Modal>
                    {/* サービス終了ダイアログ */}
                    <Modal show={showEndService} onHide={handleEndServiceClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>サービス終了確認</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>該当サービスを今月で終了します。<br />よろしいですか？</Modal.Body>
                        <Modal.Footer>
                            <Button className="btn btn-primary m-1 ms-auto" style={{ width: 150 }} onClick={(e) => handleEndService(currentServiceIndex)}>サービス終了</Button>
                            <Button className="btn btn-primary m-1 ms-auto" style={{ width: 150 }} onClick={handleEndServiceClose}>キャンセル</Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            )}
            <Footer />
        </>
    );
};
