import React from "react";
import '../css/common.css';

export const Footer = () => {
    return (
        <>
            <footer className="position-fixed bottom-0 w-100 text-center p-2 footer-custom">
                <div className="container">
                    <span>© EN.beauty works All Rights Reserved.</span>
                </div>
            </footer>
        </>
    )
}