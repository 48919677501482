import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom" ; 
import logo50 from "../images/en_logo_bk_50.png" ;
import logo60 from "../images/en_logo_bk_60.png" ;
import { BiHouseFill } from "../atoms/icons/BiHouseFill"
import { BiPersonFill } from "../atoms/icons/BiPersonFill"
import { BiShieldLockFill } from "../atoms/icons/BiShieldLockFill"
import { BiBookFill } from "../atoms/icons/BiBookFill"
import { BiPencilFill } from "../atoms/icons/BiPencilFill"
import { BiBank2 } from "../atoms/icons/BiBank2"
import { BiDoorClosedFill } from "../atoms/icons/BiDoorClosedFill"
import { useCognito } from "../hooks/useCognito";
import { AuthState } from '@aws-amplify/ui-components';
import '../css/common.css';

export const Header = ( props ) => {

    const { setUser , setAuthState , setIsLoading } = props ; 

    const [personelName, setPersonelName] = useState<string|null|undefined>("");
    const {  signOut } = useCognito() ; 

    useEffect(() => {
        //セッション情報セット
        setPersonelName(sessionStorage.getItem('personelName'));
    }, []);
    const navigate = useNavigate();

    const onClickLogout = async () => {

        setIsLoading( true ) ; 
        sessionStorage.clear();
        await signOut() ; 
        setUser( null ) ; 
        setAuthState( AuthState.SignedOut )
        setIsLoading( false ) ;
        navigate('/') 
    } 

    return (
        <>
          <header>
                <nav className="navbar navbar-expand-md header-custom">
                    <div className="container-fluid">
                        <img src={logo50} alt="EN.Beauty logo" className="img-fluid d-md-none" />
                        <img src={logo60} alt="EN.Beauty logo" className="img-fluid d-none d-md-block me-2" />
                        <span className="m-0 m-md-2 navbar-brand fs-5"> 月 １ 美 容 </span>
                        
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse fs-6" id="navbarNav">
                            <div className="dropdown ms-auto my-2 me-2">
                                <button className="btn btn-outline-light dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="bi bi-menu-down me-2"></i>MENU
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <li>
                                        <Link to="/MainMenu" className="dropdown-item">
                                            <BiHouseFill />
                                            ポータルトップ
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/StoreAccountDetail" className="dropdown-item">
                                            <BiShieldLockFill />
                                            アカウント情報
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/StoreServiceMenuList" className="dropdown-item">
                                            <BiBookFill />
                                            サービスメニュー管理
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/StoreMemberList" className="dropdown-item">
                                            <BiPersonFill />
                                            会員管理
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/StoreMemberAgreement" className="dropdown-item">
                                            <BiPencilFill />
                                            会員新規登録
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/PayCallList" className="dropdown-item">
                                            <BiBank2 />
                                            支払い通知書
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="dropdown my-2">
                                <button className="btn btn-outline-light dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-display="static" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="bi bi-person-fill me-2"></i>{personelName}
                                </button>
                                {/*dropdown-menu-md-end ≥768px for iPad */}
                                <ul className="dropdown-menu dropdown-menu-md-end" aria-labelledby="dropdownMenuButton">
                                    <li>
                                        <button className="dropdown-item" onClick={onClickLogout} >
                                            <BiDoorClosedFill />
                                            ログアウト
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>    
            </header>
        </>
    )
}